import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { actionCreators as actionRootCreators } from "src/actions/root";
import { actionCreators } from "src/actions/product-type";
import { productTypeService } from "src/services";

//antd design
import { Modal, Form, Input, Radio, Divider, Button, message } from "antd";

const rules = {
  prodTypeDes: [
    { required: true, message: "請輸入商品類型說明", trigger: "blur" }
  ],
  prodTypeDisplay: [
    { required: true }
  ],
};

const AddProductType = (props) => {
  const [appType, setAppType] = useState(null);

  // 取出 store 中的 state
  const isVisible = useSelector(state => state.Root.modalVisible);

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.appTypeOptions.length > 0) {
      const type = props.appTypeOptions.filter(item => item.appTypeNo === props.appTypeNo);
      setAppType(type[0].appCaseType);
    }
  }, [isVisible]);

  // 取消
  const handleCancel = () => {
    dispatch(actionRootCreators.modalVisible());
  };

  // 儲存
  const onFinish = (values) => {
    const payload = {
      appTypeNo: props.appTypeNo,
      prodTypeDes: values.prodTypeDes,
      prodTypeDisplay: values.prodTypeDisplay,
    };
    productTypeService.addProductType(payload)
    .then(
      res => {
        if (res.status === 201) {
          dispatch(actionCreators.getProductType(props.appTypeNo));
          dispatch(actionRootCreators.modalVisible());
          message.success("新增成功");
        }
        else if (res.status === 401) message.warning("存取拒絕");
        else if (res.status === 404) message.warning("找不到案件類型");
        else message.error("新增失敗");
      },
      error => {
        console.log(error);
      }
    );
  };
  
  // 儲存失敗
  const onFinishFailed = () => false;

  return (
    <Modal
      title="新增"
      visible={isVisible}
      onCancel={handleCancel}
      maskClosable={false}
      destroyOnClose
      footer={false}
    >
      <Form
        labelAlign="left"
        labelCol={{ flex: "120px" }}
        wrapperCol={{ span: 24, flex: 1 }}
        colon={false}
        initialValues={{ appType: 1, prodTypeDisplay: 1 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className="rootFormItem">
          <Form.Item label="案件類型" name="appType">
            <span>{appType}</span>
          </Form.Item>
          <Form.Item label="商品類型說明" name="prodTypeDes" rules={rules.prodTypeDes}>
            <Input />
          </Form.Item>
          <Form.Item label="商品類型狀態" name="prodTypeDisplay" rules={rules.prodTypeDisplay}>
            <Radio.Group>
              <Radio value={1}>顯示</Radio>
              <Radio value={0}>隱藏</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        <Divider style={{margin: 0}} />
        <div style={{textAlign: "end", padding: "10px 16px"}}>
            <Button onClick={handleCancel} style={{marginRight: 10}}>取消</Button>
            <Button type="primary" htmlType="submit">儲存</Button>
        </div>
      </Form>
    </Modal>
  );
}

export default AddProductType;
