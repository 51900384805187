import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { actionCreators as actionRootCreators } from "src/actions/root";
import { actionCreators } from "src/actions/apply-type";
import { applyTypeService } from "src/services";

// ant design
import { Modal, Form, Input, Radio, Divider, Button, message } from "antd";

const rules = {
  appSymbol: [
    {required: true, message: "請輸入案件類型編碼", trigger: "blur"},
  ],
  appCaseType: [
    {required: true, message: "請輸入案件類型說明", trigger: "blur"},
  ],
  appTypeDisplay: [
    {required: true},
  ],
};

const AddApplyType = () => {
  // 取出 store 中的 state
  const isVisible = useSelector(state => state.Root.modalVisible);
  
  const dispatch = useDispatch();
  
  // 取消
  const handleCancel = () => {
    dispatch(actionRootCreators.modalVisible());
  };

  // 儲存
  const onFinish = (values) => {
    const payload = {
      appSymbol: values.appSymbol,
      appCaseType: values.appCaseType,
      appTypeDisplay: values.appTypeDisplay,
    };
    applyTypeService.addApplyType(payload)
    .then(
      res => {
        if (res.status === 201) {
          dispatch(actionCreators.getApplyType());
          dispatch(actionRootCreators.modalVisible());
          message.success("新增成功");
        }
        else if (res.status === 401) message.warning("存取拒絕");
        else if (res.status === 409) message.warning("案件類型編碼已存在");
        else message.error("新增失敗");
      },
      error => {
        console.log(error);
      },
    );
  };

  // 儲存失敗(驗證 Form 失敗)
  const onFinishFailed = () => false;

  return (
    <Modal
      title="新增"
      visible={isVisible}
      onCancel={handleCancel}
      maskClosable={false}
      destroyOnClose
      footer={false}
    >
      <Form
        labelAlign="left"
        labelCol={{flex: "120px"}}
        wrapperCol={{span: 24, flex: 1}}
        colon={false}
        initialValues={{appTypeDisplay: 1}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className="rootFormItem">
          <Form.Item label="案件類型編碼" name="appSymbol" rules={rules.appSymbol}>
            <Input maxLength={2} showCount />
          </Form.Item>
          <Form.Item label="案件類型說明" name="appCaseType" rules={rules.appCaseType}>
            <Input />
          </Form.Item>
          <Form.Item label="案件類型狀態" name="appTypeDisplay" rules={rules.appTypeDisplay}>
            <Radio.Group>
              <Radio value={1}>顯示</Radio>
              <Radio value={0}>隱藏</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        <Divider style={{margin: 0}} />
        <div style={{textAlign: "end", padding: "10px 16px"}}>
            <Button onClick={handleCancel} style={{marginRight: 10}}>取消</Button>
            <Button type="primary" htmlType="submit">儲存</Button>
        </div>
      </Form>      
    </Modal>
  );
}

export default AddApplyType;
