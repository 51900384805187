import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { actionCreators } from "src/actions/banner";

// ant design
import { Modal, Image } from "antd";

const BannerImg = (props) => {
    const [image, setImage] = useState([]);

    const dispatch = useDispatch();

    // 取出 store 中的 state
    const isVisible = useSelector(state => state.banner.modalViewImgVisible);

    // 配值
    useEffect(() => {
        let imgData = [];
        imgData.push(props.bannerInfo.bannerDocImg);
        setImage(imgData);
    }, [isVisible]);

    // 取消
    const handleCancel = () => {
        dispatch(actionCreators.modalViewImgVisible());
    };

    return (
        <Modal 
          title="圖片" 
          visible={isVisible}
          onCancel={handleCancel} 
          destroyOnClose
          footer={false}
          width={500}
        >
            <div style={{padding: 24, textAlign: "center"}}>
                <Image 
                  width={400}
                  src={image[0] !== undefined ? image[0] : null} 
                />
            </div>
        </Modal>
    );
}

export default BannerImg;