import React from "react";

// antd
import { Row, Col } from "antd";

// component
import FrontDivCenter from "src/components/Front/Divider/DivCenter";

import icon07 from "src/assets/merchant-guide/icon_07.png";
import icon08 from "src/assets/merchant-guide/icon_08.png";
import icon09 from "src/assets/merchant-guide/icon_09.png";
import icon10 from "src/assets/merchant-guide/icon_10.png";
import icon11 from "src/assets/merchant-guide/icon_11.png";
import icon12 from "src/assets/merchant-guide/icon_12.png";
import icon13 from "src/assets/merchant-guide/icon_13.png";
import icon14 from "src/assets/merchant-guide/icon_14.png";
import icon15 from "src/assets/merchant-guide/icon_15.png";
import icon16 from "src/assets/merchant-guide/icon_16.png";

const industries = [
  { industry: "醫美", icon: icon07 },
  { industry: "3C", icon: icon08 },
  { industry: "精品", icon: icon09 },
  { industry: "裝潢", icon: icon10 },
  { industry: "家電", icon: icon11 },
  { industry: "機車", icon: icon12 },
  { industry: "寵物", icon: icon13 },
  { industry: "旅遊", icon: icon14 },
  { industry: "傢俱", icon: icon15 },
  { industry: "電商", icon: icon16 },
];

const Section4 = () => {
  return (
    <Row className="linearBackground" justify="center" style={{ marginTop: 70 }}>
      <Col span={24}>
        <h2 className="front" style={{ margin: 0 }}>
          適用商品分期的產業
        </h2>
        <FrontDivCenter />
      </Col>
      {
        industries.map((item, idx) => (
          <Col className="industrySection" span={6} md={4} sm={4} key={idx}>
            <div>
              <img src={item.icon} alt={item.industry} />
              <h4 className="front">{item.industry}</h4>
            </div>
          </Col>
        ))
      }
    </Row>
  );
}

export default Section4;
