import React from "react";

// antd
import { Row, Col, Collapse } from "antd";

// component
import FrontDivJcoin from "src/components/Front/Divider/DivJcoin";

const Section4 = () => {
  return (
    <>
      <Row justify="center" style={{ marginTop: 70, marginBottom: 70 }}>
        <Col span={20} id="FAQ">
          <FrontDivJcoin title={"　常見問題　"} />
        </Col>
        <Col span={20} md={20} sm={20} xs={20}>
          <Collapse>
            <Collapse.Panel
              header="Q1. 我第一次使用 JetShop 服務可以參加集點活動嗎？"
            >
              <div className="faq-expanded-item">
                <p className="pre-line">
                  不行。成為 JetShop 會員需滿三十天後才可參加集點活動。
                </p>
              </div>
            </Collapse.Panel>

            <Collapse.Panel
              header="Q2. 我該如何確認我的 Jcoin 點數？"
            >
              <div className="faq-expanded-item">
                <p className="pre-line">
                  Jcoin 點數剩餘查詢您可於 Line 線上客服帳號：
                  <a href="https://lin.ee/R5LfSO7" target="_blank" rel="noreferrer">https://lin.ee/R5LfSO7</a>
                  洽詢。
                </p>
              </div>
            </Collapse.Panel>

            <Collapse.Panel
              header="Q3. 推薦人的身份證字號填寫錯誤怎麼辦？"
            >
              <div className="faq-expanded-item">
                <p className="pre-line">
                  若被推薦人申辦時輸入錯誤的推薦人身份證字號則該筆訂單無法累計點數。
                </p>
              </div>
            </Collapse.Panel>

            <Collapse.Panel
              header="Q4. 兌換禮品可以多種不同禮品一起兌換嗎？"
            >
              <div className="faq-expanded-item">
                <p className="pre-line">
                  不行。 Jcoin 點數兌換一次只可選擇一個禮品，且經兌換後將不會再累積 Jcoin 點數。
                </p>
              </div>
            </Collapse.Panel>

            <Collapse.Panel
              header="Q5. 活動期間內可以一直累積 Jcoin 點數嗎？"
            >
              <div className="faq-expanded-item">
                <p className="pre-line">
                  是的。但請留意若您已兌換過禮品，活動期間內將不會再累積 Jcoin 點數。
                </p>
              </div>
            </Collapse.Panel>

            <Collapse.Panel
              header="Q6. 兌換表單送出後，還可以更換要兌換的贈品嗎？"
            >
              <div className="faq-expanded-item">
                <p className="pre-line">
                  點數兌換表單一經送出後即不可修改及取消，請務必確認欲兌換禮品後再送出表單。
                </p>
              </div>
            </Collapse.Panel>
          </Collapse>
        </Col>
      </Row>
    </>
  );
}

export default Section4;
