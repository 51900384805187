// 案件狀態
const dictStatus = {
  underReview: '審核中',
  additionalDoucment: '補件中',
  auditComplete: '審核完成',
  pendingFunding: '待撥款',
  transactionComplete: '交易完成',
};

// 案件指標
const dictIndicator = {
  complete: '過件數',
  return: '撤件數',
  reject: '婉拒數',
  total: '總計',
};

const dictCaseAmt = {
  merCompName: '商家名稱',
  merAcctId: '商家編號',
  appBaseAmtSum: '分期基準價總金額',
  merTotalPayAmtHandleSum: '應撥款總金額',
  merTotalPayAmtPaidSum: '已撥金額',
  merTotalPayAmtPendingSum: '待撥金額',
  ordProdPriceAmtSum: '建議售價總金額',
};

export const dictList = {
  dictStatus,
  dictIndicator,
  dictCaseAmt,
};
