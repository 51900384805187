import React from "react";

// antd
import { Row, Col } from "antd";

// component
import FrontDivCenter from "src/components/Front/Divider/DivCenter";

import icon13 from "src/assets/guide/icon_13.png";
import icon14 from "src/assets/guide/icon_14.png";
import icon15 from "src/assets/guide/icon_15.png";
import icon16 from "src/assets/guide/icon_16.png";

const boxes = [
  {
    title: "0元購物",
    icon: icon13,
    color: "orange",
    content: "靈活消費、多樣商品，只要安心選購。"
  },
  {
    title: "線上申請",
    icon: icon14,
    color: "red",
    content: "隨時隨地一支手機e化申請，免紙筆愛地球。"
  },
  {
    title: "快速審核",
    icon: icon15,
    color: "green",
    content: "Line行動專人客服，貼心快速不囉唆。"
  },
  {
    title: "付款方便",
    icon: icon16,
    color: "blue",
    content: "四大超商超方便、網銀轉帳省下手續費。"
  },
];

const Section2 = () => {
  return (
    <Row justify="center" style={{ marginTop: 70 }}>
      <Col span={24}>
        <h2 className="front" style={{ margin: 0 }}>
          <p>與JetShop一同購物，</p>
          <p>輕鬆享樂生活每一刻</p>
        </h2>
        <FrontDivCenter />
      </Col>
      {
        boxes.map((item, idx) => (
          <Col className="d-flex justify-center" span={11} xl={4} lg={5} md={5} sm={6} key={idx} style={{ marginTop: 20 }}>
            <div>
              <div className="d-flex justify-center">
                <div className="squareLinear">
                  <img src={item.icon} alt={item.title} />
                </div>
              </div>
              <div className="contentBox">
                <h3 className={`front ${item.color}`}>{item.title}</h3>
                <h4 className="front">{item.content}</h4>
              </div>
            </div>
          </Col>
        ))
      }
    </Row>
  );
}

export default Section2;
