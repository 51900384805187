import React from "react";

// antd
import { Row, Col } from "antd";

// component
import FrontDivCenter from "src/components/Front/Divider/DivCenter";

import btnPointer from "src/assets/btn_pointer.png";
import icon04 from "src/assets/merchant-guide/icon_04.png";
import icon05 from "src/assets/merchant-guide/icon_05.png";
import icon06 from "src/assets/merchant-guide/icon_06.png";

const boxes = [
  {
    title: "好隱密",
    icon: icon04,
    color: "red",
    content: "封閉會員制、不怕客戶被對手洗走，自由經營，JetShop不干預商家決策。"
  },
  {
    title: "好拿錢",
    icon: icon05,
    color: "orange",
    content: "交易撥款直接入帳，資金周轉快速，經營無壓力。"
  },
  {
    title: "好方便",
    icon: icon06,
    color: "blue",
    content: "交易流程全程線上操作，不管客戶在哪，一機在手即可快速下單，搭配線上客服即時解答，溝通完全不費力。"
  },
];

const Section3 = () => {
  return (
    <Row justify="center" style={{ marginTop: 70 }}>
      <Col span={24}>
        <h2 className="front" style={{ margin: 0 }}>
          商家最好的金流推手
        </h2>
        <FrontDivCenter />
        <h3 className="front" style={{ marginBottom: 20 }}>
          無壓力、無風險、輕鬆開啟快速獲利模式
        </h3>
        <a href="/merchant/apply">
          <button className="frontBtn" style={{ width: 180 }}>
            點我立即加入
            <img src={btnPointer} alt="pointer" style={{ width: 20, marginLeft: 6 }} />
          </button>
        </a>
      </Col>
      {
        boxes.map((item, idx) => (
          <Col className="d-flex justify-center" span={24} lg={6} md={7} sm={7} key={idx} style={{ marginTop: 20 }}>
            <div style={{ width: 250, height: "fit-content", gap: "5px" }}>
              <div className="d-flex justify-center">
                <div style={{ lineHeight: 4, marginRight: 20 }}>
                  <h3 className="front">{item.title}</h3>
                </div>
                <div>
                  <img src={item.icon} alt={item.title} style={{ width: 90 }} />
                </div>
              </div>
              <div className={`colorBox ${item.color}`}>
                <h4 className="front">{item.content}</h4>
              </div>
            </div>
          </Col>
        ))
      }
    </Row>
  );
}

export default Section3;
