import React from "react";

// antd
import { Row, Col, Card, Statistic } from "antd";
import { PlusOutlined } from "@ant-design/icons";

// component
import FrontDivCenter from "src/components/Front/Divider/DivCenter";

const Section2 = () => {
  return (
    <Row justify="center" style={{ marginTop: 70 }}>
      <Col span={24}>
        <h2 className="front" style={{ margin: 0 }}>
          Jetshop金融科技支付
        </h2>
        <FrontDivCenter />
      </Col>
      <Col span={10} xl={4} lg={6} md={6} sm={10} style={{ marginRight: 30 }}>
        <Card className="frontCard">
          <Statistic
            title="合作夥伴"
            value={1000}
            valueStyle={{
              color: "#FF797B",
              fontWeight: "bold",
            }}
            suffix={<PlusOutlined />}
          />
        </Card>
      </Col>
      <Col span={10} xl={4} lg={6} md={6} sm={10}>
        <Card className="frontCard">
          <Statistic
            title="分期消費者"
            value={100}
            valueStyle={{
              color: "#FA8C16",
              fontWeight: "bold",
            }}
            suffix={<div>K<PlusOutlined /></div>}
          />
        </Card>
      </Col>
      <Col span={24} xl={22} lg={22}>
        <h4 className="front about">
          JetShop致力打造全台服務體驗最佳的商品分期支付服務，我們協助超過1000家合作特店夥伴，
          服務超過10萬名善用靈活分期的消費者，透過不斷推出多元產品服務及活用創新方式，
          打造符合不同族群需要的金融商品，持續追求商家營銷增長、消費者享購就GO的第一選擇。
        </h4>
      </Col>
    </Row>
  );
}

export default Section2;
