import { installmentTypes } from "src/constants";
import { installmentService } from "src/services";

// 取得分期說明列表
const getInstallment = (intNo) => {
  return (dispatch) => {
    installmentService.getInstallment(intNo)
    .then(
      res => {
        res.json().then(result => {
          dispatch({ type: installmentTypes.GET_INSTALLMENT_LIST, data: result.data });
        });
      },
      error => {
        console.log(error);
      }
    );
  };
};

// 取得分期說明詳細
const getInstallmentDetail = (insSerialNumber) => {
  return (dispatch) => {
    installmentService.getInstallmentDetail(insSerialNumber)
    .then(
      res => {
        res.json().then(result => {
          dispatch({ type: installmentTypes.GET_INSTALLMENT_DETAIL, data: result });
        });
      },
      error => {
        console.log(error);
      }
    );
  };
};

// 取得使用中利率表
const getInterestRate = () => {
  return (dispatch) => {
    installmentService.getInterestRate()
    .then(
      res => {
        res.json().then(result => {
          dispatch({ type: installmentTypes.GET_INTEREST_RATE_LIST, data: result.data });
        });
      },
      error => {
        console.log(error);
      }
    );
  };
};

export const actionCreators = {
  getInstallment,
  getInstallmentDetail,
  getInterestRate
};