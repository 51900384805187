import React from "react";

const FrontGuide = () => {
  return (
    <table className="ins-table">
      <thead>
        <tr>
          <th></th>
          <th>商品分期</th>
          <th>信用卡分期</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>申辦條件</td>
          <td>
            <ul style={{ padding: '0 1em' }}>
              <li>年滿18歲</li>
              <li>雙證件（學生/軍警證件）</li>
              <li>必要時提供財力證明</li>
            </ul>
          </td>
          <td>
            <ul style={{ padding: '0 1em' }}>
              <li>年滿20歲</li>
              <li>有穩定工作、財力證明</li>
              <li>信用良好</li>
              <li>雙證件</li>
            </ul>
          </td>
        </tr>

        <tr>
          <td>審核時間</td>
          <td>約30分鐘</td>
          <td>約3~14天</td>
        </tr>

        <tr>
          <td>分期期數</td>
          <td>3期~36期</td>
          <td>3期~24期</td>
        </tr>

        <tr>
          <td>財力證明</td>
          <td><span className="highlight-text">依個案評估</span></td>
          <td>需要</td>
        </tr>

        <tr>
          <td>審核條件</td>
          <td><span className="highlight-text">依個案評估</span></td>
          <td>嚴格</td>
        </tr>

        <tr>
          <td>債權歸屬</td>
          <td>第三方支付公司</td>
          <td>發卡銀行</td>
        </tr>

        <tr>
          <td>優點</td>
          <td>
            <ul style={{ padding: '0 1em' }}>
              <li>申辦快</li>
              <li>不需固定收入及信用紀錄</li>
              <li>不受信用額度限制</li>
              <li>分期較長且彈性</li>
            </ul>
          </td>
          <td>
            <ul style={{ padding: '0 1em' }}>
              <li>部分分期可享零利率</li>
              <li>部分卡片分期享回饋</li>
              <li>支援刷卡的通路皆可使用</li>
            </ul>
          </td>
        </tr>

        <tr>
          <td>缺點</td>
          <td>
            <ul style={{ padding: '0 1em' }}>
              <li>利率較高</li>
              <li>指定合作通路才可使用</li>
            </ul>
          </td>
          <td>
            <ul style={{ padding: '0 1em' }}>
              <li>加入門檻高</li>
              <li>受信用卡額度限制</li>
              <li>循環利息高</li>
            </ul>
          </td>
        </tr>

        <tr>
          <td>適用族群</td>
          <td>無穩定收入或信用紀錄</td>
          <td>有穩定工作和信用紀錄</td>
        </tr>
      </tbody>
    </table>
  );
}

export default FrontGuide;
