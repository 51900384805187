import Cookies from "universal-cookie";
import { ADMIN_API_URL as api_admin_url } from 'src/config';

const cookies = new Cookies();
const reqOptions = {
  get: {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${cookies.get('_root_auth_token')}`
    },
  },
  post: {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${cookies.get('_root_auth_token')}`
    },
  },
  patch: {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${cookies.get('_root_auth_token')}`
    },
  },
  delete: {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${cookies.get('_root_auth_token')}`
    },
  },
};

// 取得分期說明列表
const getInstallment = async (intNo) => {
  const queryString = intNo ? `?intNo=${intNo}` : ""
  return fetch(
    `${api_admin_url}/installments${queryString}`,
    reqOptions.get
  );
};

// 新增分期說明
const addInstallment = async (payload) => {
  return fetch(
    `${api_admin_url}/installments`,
    {
      ...reqOptions.post,
      body: JSON.stringify(payload)
    }
  );
};

// 取得分期說明詳細
const getInstallmentDetail = async (insSerialNumber) => {
  return fetch(
    `${api_admin_url}/installments/${insSerialNumber}`,
    reqOptions.get
  );
};

// 刪除分期說明
const deleteInstallment = async (insSerialNumber) => {
  return fetch(
    `${api_admin_url}/installments/${insSerialNumber}`,
    reqOptions.delete
  );
};

// 修改分期說明
const editInstallment = async (insSerialNumber, payload) => {
  return fetch(
    `${api_admin_url}/installments/${insSerialNumber}`,
    {
      ...reqOptions.patch,
      body: JSON.stringify(payload)
    }
  );
};

// 取得利率表
const getInterestRate = async () => {
  return fetch(
    `${api_admin_url}/interest-rate-indexes`,
    reqOptions.get
  );
};

export const installmentService = {
  getInstallment,
  addInstallment,
  getInstallmentDetail,
  deleteInstallment,
  editInstallment,
  getInterestRate
};
