import React, { useRef, useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { actionCreators } from 'src/actions/product';
import { productService } from 'src/services';
import { HOME_URL } from 'src/config';

import { format } from 'src/utils/format';
import { code } from './statusCode';

// Ant design
import { Layout, Row, Col, Card, Table, Alert, Select, Input, Button, Radio, message } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined, LockOutlined, AppstoreOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';

import './ProductList.css';

const ProductList = () => {
  // Ant design table filter
  const [, setSearchText] = useState('');
  const [, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input ref={searchInput} value={selectedKeys[0]} onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])} onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)} style={{ marginBottom: 8, display: 'block' }} />
        <Row justify="end">
          <Button onClick={() => handleReset(clearFilters)} size="small">
            重置
          </Button>
          <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ marginLeft: 5 }}>
            搜尋
          </Button>
        </Row>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  // Table 欄位
  const [columns] = useState([
    // {
    //   title: '預覽畫面',
    //   dataIndex: 'preview',
    //   width: '80px',
    //   render: (text, col) => {
    //     return <Button onClick={()=> window.open(col.prodLinkUrl, "_blank")}>預覽</Button>
    //   }
    // },
    {
      title: '複製連結',
      dataIndex: 'prodLinkUrl',
      render: (url) => {
        const fullUrl = `${HOME_URL}${url}`;
        return <Button onClick={() => copyToClipboard(fullUrl)}>複製連結</Button>;
      },
    },
    {
      title: '複製商品',
      dataIndex: 'copy',
      render: (text, col) => {
        return (
          <Button>
            <Link to={'/admin/product/list/edit?cloneNo=' + col.key}>複製商品</Link>
          </Button>
        );
      },
    },
    {
      title: '商品名稱',
      dataIndex: 'prodName',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        a = a.prodName || '';
        b = b.prodName || '';
        return a.localeCompare(b);
      },
      render: (text, col) => {
        return <Link to={'/admin/product/list/edit?no=' + col.key}>{text}</Link>;
      },
      ...getColumnSearchProps('prodName'),
    },
    {
      title: '商家代碼',
      dataIndex: 'merAcctId',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        a = a.merAcctId || '';
        b = b.merAcctId || '';
        return a.localeCompare(b);
      },
      ...getColumnSearchProps('merAcctId'),
    },
    {
      title: '分店名稱',
      dataIndex: 'merCompName',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        a = a.merCompName || '';
        b = b.merCompName || '';
        return a.localeCompare(b);
      },
      ...getColumnSearchProps('merCompName'),
    },
    {
      title: '建議售價',
      dataIndex: 'prodPriceAmt',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        return a.prodPriceAmt - b.prodPriceAmt;
      },
      ...getColumnSearchProps('prodPriceAmt'),
    },
    {
      title: '撥款金額',
      dataIndex: 'prodPayAmt',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        return a.prodPayAmt - b.prodPayAmt;
      },
      ...getColumnSearchProps('prodPayAmt'),
    },
    {
      title: '狀態',
      dataIndex: 'prodMgtStatus',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        return a.prodMgtStatus - b.prodMgtStatus;
      },
      render: (text) => {
        return code.prodMgtStatusList()[text];
      },
      ...getColumnSearchProps('prodPayAmt'),
    },
    {
      title: '商品分類',
      dataIndex: 'prodTypeDesc',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        a = a.prodTypeDesc || '';
        b = b.prodTypeDesc || '';
        return a.localeCompare(b);
      },
      ...getColumnSearchProps('prodTypeDesc'),
    },
    {
      title: '建立時間',
      dataIndex: 'prodCreateDate',
      defaultSortOrder: 'descend',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        a = a.prodCreateDate || '';
        b = b.prodCreateDate || '';
        return a.localeCompare(b);
      },
      ...getColumnSearchProps('prodCreateDate'),
    },
  ]);

  const [rowData, setRowData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [mgtStatus, setMgtStatus] = useState('');
  const [alertStautus, setAlertStatus] = useState({ message: null, type: null });

  const { Option } = Select;

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  // 取出 store 中的 state
  const productData = useSelector((state) => state.product.productList);
  const productTypeIndex = useSelector((state) => state.product.productTypeIndex);

  // 新增/編輯後，返回此列表頁，顯示成功或失敗提示
  useEffect(() => {
    if (location && location.state && location.state.status && location.state.msg) {
      setAlertStatus({
        message: location.state.msg,
        type: location.state.status,
      });
    }
  }, [location]);

  // action : 1. 取得商品列表 2. 取得所有商品類型項目列表
  useEffect(() => {
    dispatch(
      actionCreators.getProductList({
        limit: 99000, // 目前去要最大值
        page: 1,
        prodMgtStatus: '1,2,3', // 全部
      })
    );
    dispatch(actionCreators.getProductTypeIndex());
  }, []);

  // 設定初始值
  useEffect(() => {
    let data = [];
    if (productData && productData.rows) {
      productData.rows.map((item) => {
        data.push({
          key: item.prodNo,
          merAcctId: item.merAcctId,
          merCompName: item.merchantBasic.merCompName,
          prodName: item.prodName,
          prodPriceAmt: item.prodPriceAmt,
          prodPayAmt: item.prodPayAmt,
          prodMgtStatus: item.prodMgtStatus,
          prodTypeDesc: getProdTypeString(item.prodTypeNo, item.prodItemNo),
          prodCreateDate: format.toDateTime(item.prodCreateDate),
          prodLinkUrl: item.prodLinkUrl,
        });
      });
    }
    setRowData(data);
  }, [productData, productTypeIndex]);

  const getProdTypeString = (typeNo, itemNo) => {
    let typeDes = '';
    let itemDes = '';
    productTypeIndex.map((item) => {
      if (item.prodTypeNo === typeNo) {
        typeDes = item.prodTypeDes;
        item.PII.map((subItem) => {
          if (subItem.prodItemNo === itemNo) itemDes = subItem.prodItemDes;
        });
      }
    });
    return `${typeDes}/${itemDes}`;
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const batchProduct = () => {
    // 批次修改商品狀態
    let payload = {
      prodMgtStatus: mgtStatus,
      prodNoList: selectedRowKeys,
    };
    if (!mgtStatus || selectedRowKeys.length === 0) {
      let msg = selectedRowKeys.length === 0 ? '請選擇商品' : '請選擇商品修改狀態';
      setAlertStatus({
        message: msg,
        type: 'warning',
      });
      return false;
    } else {
      productService.batchProductData(payload).then(
        (res) => {
          res.json().then((result) => {
            setAlertStatus({
              message: '修改成功',
              type: 'success',
            });
            // 重新再取得最新商品資料
            dispatch(
              actionCreators.getProductList({
                limit: 99000, // 目前去要最大值
                page: 1,
                prodMgtStatus: '1,2,3', // 全部
              })
            );
          });
        },
        (error) => {
          console.log(error);
          setAlertStatus({
            message: '修改失敗',
            type: 'error',
          });
        }
      );
    }
  };

  const onSearchStatus = (e) => {
    // 快速查詢
    let status = e.target.value;
    dispatch(
      actionCreators.getProductList({
        limit: 99000, // 目前去要最大值
        page: 1,
        prodMgtStatus: status,
      })
    );
  };

  const copyToClipboard = (url) => {
    // 複製連結
    const el = document.createElement('input');
    el.value = url;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    message.success({
      style: { marginLeft: 200, fontWeight: 'bold' },
      content: '複製成功',
    });
  };

  const closeAlert = () => {
    setAlertStatus({
      message: '',
      type: '',
    });
    navigate('/admin/product/list', { state: {} });
  };

  return (
    <Layout className="merLayout product" type="flex" align="left">
      <Row justify="center">
        <Col span={23}>
          <div className="title">商品管理</div>

          <Card className="merLayoutCard">
            {alertStautus.message ? <Alert message={alertStautus.message} type={alertStautus.type} banner closable afterClose={closeAlert} style={{ marginBottom: 10 }} /> : null}

            <Row>
              <Col align="left" span={12} xs={24} sm={24} md={12} style={{ marginBottom: 5 }}>
                <span style={{ marginRight: 10 }}>快速查詢</span>
                <Radio.Group onChange={onSearchStatus} defaultValue="1,2,3">
                  <Radio.Button value="1">
                    <ArrowUpOutlined />
                    <span className="btnText">上架</span>
                  </Radio.Button>
                  <Radio.Button value="2">
                    <ArrowDownOutlined />
                    <span className="btnText">下架</span>
                  </Radio.Button>
                  <Radio.Button value="3">
                    <LockOutlined />
                    <span className="btnText">閉鎖</span>
                  </Radio.Button>
                  <Radio.Button value="1,2,3">
                    <AppstoreOutlined />
                    <span className="btnText">全部</span>
                  </Radio.Button>
                </Radio.Group>
              </Col>

              <Col align="right" span={12} xs={24} sm={24} md={12}>
                <Button type="primary" style={{ marginRight: 5 }}>
                  <Link to={'/admin/product/list/edit'}>
                    <PlusOutlined />
                    新增
                  </Link>
                </Button>
                <Select style={{ width: 180, textAlign: 'left', margin: '0 10px 0 10px' }} placeholder="選擇商品修改狀態" onChange={(value) => setMgtStatus(value)}>
                  <Option value={1}>上架</Option>
                  <Option value={2}>下架</Option>
                  <Option value={3}>閉鎖</Option>
                  {/* <Option value={4}>已售完</Option> */}
                  <Option value={5}>刪除</Option>
                </Select>
                <Button type="primary" onClick={batchProduct}>
                  確認
                </Button>
              </Col>
            </Row>

            <Col span={24} style={{ marginTop: 10 }}>
              <Table rowSelection={rowSelection} columns={columns} dataSource={rowData} scroll={{ x: 1500, y: 360 }} pagination={{ defaultPageSize: 50 }} />
            </Col>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
};

export default ProductList;
