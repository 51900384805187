import React from "react";
// import styles
import "swiper/css";
import "swiper/css/navigation";
// import img
import activityShareTitle4 from "src/assets/jetdai/activity-share/activity_share_title4.png";

// antd
import { Row, Col } from "antd";

const Section3 = () => {
  return (
    <Row className="frontActivityShareSection3" justify="center" style={{ marginTop: 10 }}>
      <Col className="d-flex justify-center" span={24} style={{ marginTop: 20 }}>
        <div>
          <img src={activityShareTitle4} alt="常見問題" className="activityShareTitle" />
        </div>
      </Col>
      <Col className="d-flex justify-center activityShareMain" span={24} style={{ marginTop: 10 }}>
        <div className="activityShareBox">
          <div className="activityShareBoxTitle">Q. 我推薦的朋友已申請核准，為甚麼我沒有拿到獎勵?</div>
          <div className="activityShareBoxTitle">A. 推薦獎勵僅會針對透過專屬連結申請的用戶回饋，若您尚有其他問題，請與客服人員聯繫。</div>
          <div className="activityShareBoxTitle">Q. 我的朋友已完成申請，為甚麼沒有拿到50元回饋?</div>
          <div className="activityShareBoxTitle">A. 50元回饋獎勵為針對(JetShop)新用戶申請，且數量有限。</div>
          <div className="activityShareBoxTitle">Q. 若重複推薦同一個朋友申請且核准，會算任務完成嗎?</div>
          <div className="activityShareBoxTitle">A. 不會，每位好友不重複計算。</div>    
        </div>
      </Col>
    </Row>
  );
}

export default Section3;