import React from "react";

// antd & css
import { Breadcrumb } from "antd";
import "./Breadcrumb.css";

const FrontBreadcrumb = (props) => {
  return (
    <div className="frontBreadcrumb">
      <Breadcrumb>
        <Breadcrumb.Item>
          <a href="/">首頁</a>
        </Breadcrumb.Item>

        {props.link.map((item, index) => {
          return (
            <Breadcrumb.Item key={item.name}>
              {item.href
                ? <a href={item.href}>{item.name}</a>
                : <span>{item.name}</span>
              }
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    </div>
  );
}

export default FrontBreadcrumb;
