import Cookies from 'universal-cookie';
import {
	ADMIN_API_URL as api_admin_url,
	API_URL as api_url,
} from 'src/config';

const cookies = new Cookies();
const reqOptions = {
  get: {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  },
  getWithJwt: {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${cookies.get('_merchant_auth_token')}`
    },
  },
  patch: {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
  },
  patchWithJwt: {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${cookies.get('_merchant_auth_token')}`
    },
  },
  post: {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  },
  delete: {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
  }
};

// 取得商品列表
const getProducts = async (keyword, limit, page, sortData, excludeProdNos = []) => {
  let word = keyword ? `&keyword=${keyword}` : "";
  let lmt = limit ? `&limit=${limit}` : "";
  let pg = page ? `&page=${page}` : "";
  let sData = sortData ? `&sort=${sortData}` : "";
  let exProdNos = "";
  excludeProdNos.forEach(item => {
    exProdNos += `&excludeProdNos=${item}`
  });
  return fetch(
    `${api_admin_url}/products?prodMgtStatus=1${word}${lmt}${pg}${sData}${exProdNos}`,
    reqOptions.getWithJwt
  );
};

// 取得商品列表 deprecated
const getProductList = async (payload) => {
  let { limit, page, prodMgtStatus } = payload;
  return fetch(
    `${api_admin_url}/product?access_token=${cookies.get('_merchant_auth_token')}&limit=${limit}&page=${page}&prodMgtStatus=${prodMgtStatus}`,
    reqOptions.get
  );
};

// 取得指定商品
const getProductData = async (prodNo) => {
  return fetch(
    `${api_admin_url}/product/${prodNo}?access_token=${cookies.get('_merchant_auth_token')}`,
    reqOptions.get
  );
};

// 新增指定商品
const addProductData = async (payload) => {
  return fetch(
    `${api_admin_url}/product?access_token=${cookies.get('_merchant_auth_token')}`,
    {
      ...reqOptions.post,
      body: JSON.stringify(payload)
    }
  );
};

// 修改指定商品
const editProductData = async (prodNo, payload) => {
  return fetch(
    `${api_admin_url}/products/${prodNo}`,
    {
      ...reqOptions.patchWithJwt,
      body: JSON.stringify(payload)
    }
  );
};

// 批次修改商品
const batchProductData = async (payload) => {
  return fetch(
    `${api_admin_url}/product/batch?access_token=${cookies.get('_merchant_auth_token')}`,
    {
      ...reqOptions.patch,
      body: JSON.stringify(payload)
    }
  );
};

// 取得商品類型列表
const getProductTypeList = async (appTypeNo) => {
  return fetch(
    `${api_admin_url}/product/applyTypeIndex?access_token=${cookies.get('_merchant_auth_token')}&appTypeNo=${appTypeNo}`,
    reqOptions.get
  );
};

// 取得商品類型項目列表
const getProductTypeItemsList = async (prodTypeNo) => {
  return fetch(
    `${api_admin_url}/product/productItemIndex?access_token=${cookies.get('_merchant_auth_token')}&prodTypeNo=${prodTypeNo}`,
    reqOptions.get
  );
};

// 取得品牌列表
const getProductBrand = async () => {
  return fetch(
    `${api_admin_url}/product/brand?access_token=${cookies.get('_merchant_auth_token')}`,
    reqOptions.get
  );
};

// 取得利率表
const getProductInterestRate = async () => {
  return fetch(
    `${api_admin_url}/product/interestRate?access_token=${cookies.get('_merchant_auth_token')}`,
    reqOptions.get
  );
};

// 取得所有商品類型項目列表
const getProductTypeIndex = async () => {
  return fetch(
    `${api_admin_url}/product/productTypeIndex?access_token=${cookies.get('_merchant_auth_token')}`,
    reqOptions.get
  );
};

// 取得利率表計算結果
const getPrcieEstimation = async (payload) => {
  return fetch(
    `${api_url}/estimation/price-estimation-GCF`,
    {
      ...reqOptions.post,
      body: JSON.stringify(payload)
    }
  );
};

// 新增商品照片
const addProductImg = async (prodNo, docType, payload) => {
  // const formData = new FormData();
  // formData.append('doc-image', payload['doc-image']);

  return fetch(
    `${api_admin_url}/product/${prodNo}/images/${docType}?access_token=${cookies.get('_merchant_auth_token')}`,
    {
      method: "POST",
      body: payload
      // body: formData
    }
  );
};

// 刪除商品照片
const deleteProductImg = async (prodNo, docType, photoSerNo) => {
  return fetch(
    `${api_admin_url}/product/${prodNo}/images/${docType}/${photoSerNo}?access_token=${cookies.get('_merchant_auth_token')}`,
    reqOptions.delete
  );
};

export const productService = {
  getProducts,
  getProductList,
  getProductData,
  addProductData,
  editProductData,
  batchProductData,
  getProductTypeList,
  getProductTypeItemsList,
  getProductBrand,
  getProductInterestRate,
  getProductTypeIndex,
  getPrcieEstimation,
  addProductImg,
  deleteProductImg
}
