import React from "react";

import { Row, Col } from "antd";

import "src/components/Footer/Footer.css";

const RootFooter = () => {
    return (
        <Row>
            <Col className="footer">
                @Jetshop 維護系統
            </Col>
        </Row>
    );
}

export default RootFooter;
