// 商品狀態
const prodMgtStatusList = () => {
  return {
    1: '上架',
    2: '下架',
    3: '閉鎖',
    // 4: '已售完',
    5: '封存'
  };
};

// 活動代碼
const prodPromtStatus = () => {
  return {
    1: '最新上架',
    2: '促銷優惠',
    3: '熱門商品',
    4: '限量商品',
    5: '快閃活動'
  };
};

export const code = {
  prodMgtStatusList,
  prodPromtStatus
};
