import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { actionCreators } from "src/actions/jet";
import { jetService } from "src/services";

// antd
import { Row, Col, Form, Switch, Tag, Input, Button, Tooltip, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";

// components
import ModalSample from "src/components/Jet/ModalSample";
import TextEditor from "src/components/Editor";

import SampleActivity from "src/assets/sample_activity.png";

const ActivitySet = () => {
  const [form] = Form.useForm();
  const [defaultValue] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isSmallSize, setIsSmallSize] = useState(windowWidth < 576);
  const [saDescription, setSaDescription] = useState("");
  const [saTags, setSaTags] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);

  const dispatch = useDispatch();

  // 取得 store 中的 state
  const editingShopActivity = useSelector(state => state.jet.editingShopActivity);

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);
    onWindowResize();

    return () => window.removeEventListener("resize", onWindowResize);
  }, []);

  useEffect(() => {
    if (editingShopActivity && JSON.stringify(editingShopActivity) !== "{}") {
      form.setFieldsValue({
        saStatus: editingShopActivity.saStatus === 1 ? true : false,
      });
      setSaDescription(editingShopActivity.saDescription);
      if (editingShopActivity.saTags) {
        setSaTags(editingShopActivity.saTags.split(","));
      }
    }
  }, [editingShopActivity]);

  useEffect(() => {
    if (inputVisible) inputRef.current?.focus();
  }, [inputVisible]);

  const onWindowResize = () => {
    setWindowWidth(window.innerWidth);
    setIsSmallSize(window.innerWidth < 576);
  };

  // 編輯框
  const editorCallBack = (htmlString) => {
    setSaDescription(htmlString);
  };

  // Add & Remove Tags Dynamically
  const handleRemoveTag = (removedTag) => {
    const newTags = saTags.filter((tag) => tag !== removedTag);
    setSaTags(newTags);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && saTags.indexOf(inputValue) === -1) {
      setSaTags([...saTags, inputValue]);
    }
    setInputVisible(false);
    setInputValue("");
  };

  // submit form
  const onFinish = (values) => {
    let payload = {
      saStatus: values.saStatus ? 1 : 0,
      saDescription: saDescription,
      saTags: saTags.length > 0 ? saTags.join(",") : undefined,
    };
    jetService.updateShopActivity(payload)
    .then(
      res => {
        if (res.status === 201) {
          dispatch(actionCreators.getEditingShopActivity());
          message.success("儲存成功");
        } else if (res.status === 401) {
          message.warning("存取拒絕");
        } else {
          message.error("儲存失敗");
        }
      },
      error => {
        console.log(error);
      },
    );
  };

  const onFinishFailed = () => false;

  return (
    <Form
      form={form}
      className="jet-form"
      labelCol={{ flex: "100px" }}
      wrapperCol={{ span: 18 }}
      labelAlign="left"
      colon={false}
      initialValues={defaultValue}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row gutter={30}>
        <Col xs={24} sm={12} md={12}>
          {isSmallSize && <ModalSample sourceType="activity" />}
          <Form.Item label="活動說明">
            <TextEditor
              textString={saDescription}
              parentCallback={editorCallBack}
            />
          </Form.Item>
          <Form.Item name="saStatus" label="活動狀態" valuePropName="checked">
            <Switch checkedChildren="顯示" unCheckedChildren="關閉" />
          </Form.Item>
          <Form.Item label="活動標籤">
            <Form.Item style={{ marginBottom: 12 }}>
              {saTags.map(tag => {
                const isLongTag = tag.length > 20;
                const tagElement = (
                  <Tag
                    className="edit-tag"
                    key={tag}
                    closable={true}
                    onClose={() => handleRemoveTag(tag)}
                  >
                    <span>
                      {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                    </span>
                  </Tag>
                );
                return isLongTag ? (
                  <Tooltip title={tag} key={tag}>
                    {tagElement}
                  </Tooltip>
                ) : (
                  tagElement
                )
              })}

              {inputVisible && (
                <Input
                  ref={inputRef}
                  type="text"
                  size="small"
                  value={inputValue}
                  onChange={handleInputChange}
                  onBlur={handleInputConfirm}
                  onPressEnter={handleInputConfirm}
                  style={{ width: 66 }}
                />
              )}
              
              {!inputVisible && saTags.length < 4 && (
                <Button type="dashed" size="small" onClick={showInput}>
                  <PlusOutlined /> 新增
                </Button>
              )}
            </Form.Item>
            <Form.Item>
              <p style={{ color: "#B0B0B0", marginBottom: 0 }}>• 打完標籤說明後按下Enter即可新增，最多4個標籤</p>
              <p style={{ color: "#B0B0B0" }}>• 此標籤將會顯示於活動專區、商品列表中</p>
            </Form.Item>
          </Form.Item>
        </Col>
        {/* 示意圖 */}
        {!isSmallSize && (
          <Col sm={12} md={12}>
            <div className="jet-sample" style={{ marginTop: 0 }}>
              <img className="jet-sample-img" src={SampleActivity} alt="活動專區設定示意圖" />
            </div>
          </Col>
        )}
      </Row>

      <Row align="center">
        <Button
          type="primary"
          htmlType="submit"
        >
          儲存
        </Button>
      </Row>
    </Form>
  );
}

export default ActivitySet;
