import { frontJetTypes } from 'src/constants';
import { frontJetService } from 'src/services';

// 取得商品列表
const getFrontShopProducts = (shopRouteName, prodTypeNo, keyword, page, sortData) => {
  return (dispatch) => {
    frontJetService.getShopProducts(shopRouteName, prodTypeNo, keyword, page, sortData)
    .then(
      res => {
        res.json().then(result => {
          dispatch({type: frontJetTypes.GET_FRONT_SHOP_PRODUCTS, data: result});
        });
      },
      error => {
        console.log(error)
      },
    );
  };
};

// 取得商品分類列表
const getFrontShopProdType = (shopRouteName) => {
  return (dispatch) => {
    frontJetService.getShopProdTypes(shopRouteName)
    .then(
      res => {
        res.json().then(result => {
          dispatch({type: frontJetTypes.GET_FRONT_SHOP_PRODUCT_TYPE, data: result.data});
        });
      },
      error => {
        console.log(error)
      },
    );
  };
};

// 取得商店渲染資訊
const getFrontShopInfo = (shopRouteName) => {
  return (dispatch) => {
    frontJetService.getFrontShopInfo(shopRouteName)
    .then(
      res => {
        res.json().then(result => {
          dispatch({type: frontJetTypes.GET_FRONT_SHOP_INFO, data: result});
        });
      },
      error => {
        console.log(error);
      }
    );
  };
};

// 取得購物須知
const getShopInfoNotice = (shopRouteName) => {
  return (dispatch) => {
    frontJetService.getShopInfoNotice(shopRouteName)
    .then(
      res => {
        res.json().then(result => {
          dispatch({type: frontJetTypes.GET_FRONT_SHOP_INFO_NOTICE, data: result.shopInfoNotice});
        });
      },
      error => {
        console.log(error);
      }
    );
  };
};

export const actionCreators = {
  getFrontShopProducts,
  getFrontShopProdType,
  getFrontShopInfo,
  getShopInfoNotice,
}