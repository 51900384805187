import React from "react";
import { LINE_LIFF_ID_MEMBER } from "src/config";
// import styles
import "swiper/css";
import "swiper/css/navigation";
// import img
import activityShareTitle3 from "src/assets/jetdai/activity-share/activity_share_title3.png";

// antd
import { Row, Col } from "antd";

const Section3 = () => {
  return (
    <Row className="frontActivityShareSection3" justify="center" style={{ marginTop: 10 }}>
      <Col className="d-flex justify-center" span={24} style={{ marginTop: 20 }}>
        <div>
          <img src={activityShareTitle3} alt="活動規則" className="activityShareTitle" />
        </div>
      </Col>
      <Col className="d-flex justify-center activityShareMain" span={24} style={{ marginTop: 10 }}>
        <ol className="activityShareBox activityShareBoxOl">
          <li className="activityShareBoxTitle activityShareBoxLi">參加資格：加入JetShop 會員即可參加(曾經申請過任一服務)。</li>
          <li className="activityShareBoxTitle activityShareBoxLi">活動舉辦時間：2024年03月25日 至 2024年6月30日 止。</li>
          <li className="activityShareBoxTitle activityShareBoxLi">獎勵兌換時間： 公告日起至2024年7月31日止。</li>
          <li className="activityShareBoxTitle activityShareBoxLi">注意事項：
            <ul className="activityShareBoxUlStyle">
              <li className="activityShareBoxTitle activityShareBoxLi" style={{ marginTop: 0 }}>活動僅限JetShop 會員本人使用，請勿轉讓、抵扣或折換現金。</li>
              <li className="activityShareBoxTitle activityShareBoxLi" style={{ marginTop: 0 }}>好友推薦申辦核准獎勵金採累積制，推薦越多領越多，數量不限最高回饋 2,600元。</li>
              <li className="activityShareBoxTitle activityShareBoxLi" style={{ marginTop: 0 }}>新戶申請享 50元回饋，僅針對新申請用戶，且數量有限，兌完為止。</li>
              <li className="activityShareBoxTitle activityShareBoxLi" style={{ marginTop: 0 }}>獎勵發送後若未於期限內兌換，將視同放棄權益，獎勵將不重新寄送。</li>
              <li className="activityShareBoxTitle activityShareBoxLi" style={{ marginTop: 0 }}>JetShop 好友推薦獎勵活動回饋秉持道德公義原則，若發生從中採不正當手段非法獲利之詐欺情事，本司將保留法律追訴權。</li>
              <li className="activityShareBoxTitle activityShareBoxLi" style={{ marginTop: 0 }}>JetShop 好友推薦獎勵活動累計回饋自公告實施日起算，不溯及既往。</li>
              <li className="activityShareBoxTitle activityShareBoxLi" style={{ marginTop: 0 }}>JetShop 保有最終解釋、調整與修改本活動辦法之權利。</li>
            </ul>
          </li>

        </ol>
      </Col>
      <Col span={24} style={{ marginTop: "30px", marginBottom: "10px" }}>
        <Row justify="center" gutter={[24, 16]} style={{ marginLeft: 0, marginRight: 0 }}>
          <Col>
            <a href={`https://liff.line.me/${LINE_LIFF_ID_MEMBER}?via=share`}>
              <button className="frontLandingBtn-dark frontLandingBtn-dark-orange">
                {"前 往 推 薦 獎 勵 專 區"}
              </button>
            </a>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Section3;