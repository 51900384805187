import React from "react";

import "./Divider.css";

const FrontDivCenter = () => {
  return (
    <div className="divider-center">
      <div className="divider"> 
        <span></span>
      </div>
    </div>
  );
}

export default FrontDivCenter;
