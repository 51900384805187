import React, { useState, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { actionCreators as actionRootCreators } from "src/actions/root";
import { actionCreators } from "src/actions/product-type";
import { productTypeService } from "src/services";

// components
import AddProductItem from "./AddProductItem";

// antd & css
import { Layout, Row, Col, Card, Space, Tooltip, Table, Empty, Button, Switch, Input, Form, Modal, message } from "antd";
import { MenuOutlined, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import "./ProductItem.css";

// drag components
const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: "grab", color: "#999" }} />);
const SortableItem = SortableElement(props => <tr {...props} />);
const SortableBody = SortableContainer(props => <tbody {...props} />);

// render editable cell
const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
  let inputNode = null;
  if (inputType === "switch") {
    inputNode = <Switch defaultChecked={record.productTypeDisplay} checkedChildren="顯示" unCheckedChildren="隱藏" />;
  } else {
    inputNode = <Input />
  }
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          valuePropName={ inputType === "switch" ? "checked" : "value" }
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: "必填"
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (children)}
    </td>
  )
};

const RootProductItem = () => {
  // 取得 query string 的值
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);

  const [form] = Form.useForm();
  const [prodTypeDes, setProdTypeDes] = useState("");
  const [rowData, setRowData] = useState([]);
  const [editingKey, setEditingKey] = useState("");

  // 取出 store 中的 state
  const productTypeList = useSelector(state => state.productType.productTypeList);
  const productItemList = useSelector(state => state.productType.productItemList);

  const dispatch = useDispatch();

  useEffect(() => {
    const newData = productTypeList.filter(item => item.prodTypeNo == params.productTypeNo);
    setProdTypeDes(newData[0].prodTypeDes);
  }, []);

  useEffect(() => {
    dispatch(actionCreators.getProductItem(params.productTypeNo));
  }, [dispatch])

  useEffect(() => {
    let data = [];
    let index = 0;
    if (productItemList && productItemList.length >= 1) {
      productItemList.forEach(item => {
        data.push({
          key: item.prodItemNo,
          prodItemNo: item.prodItemNo,
          prodItemSerNo: item.prodItemSerNo,
          prodItemDes: item.prodItemDes,
          prodItemDisplay: item.prodItemDisplay,
          index: index,
        });
        index += 1;
      });
    };
    data.sort((a, b) => {
      return a.prodItemSerNo - b.prodItemSerNo;
    });
    setRowData(data);
  }, [productItemList]);

  // open: 新增視窗
  const handleAddModal = () => {
    dispatch(actionRootCreators.modalVisible());
  };

  const isEditing = (record) => record.key === editingKey;
  
  const edit = (record) => {
    form.setFieldsValue({
      productItem: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  // 編輯-儲存
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      // 前端更新表格
      const newData = [...rowData];
      const index = newData.findIndex(item => key === item.key);
      const item  = newData[index];
      newData.splice(index, 1, { ...item, ...row });

      // api 處理
      handleEditProductItem(newData, index);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditProductItem = (newData, index) => {
    const newInfo = newData[index];
    const payload = {
      prodItemDes: newInfo.prodItemDes,
      prodItemDisplay: newInfo.prodItemDisplay,
    };
    productTypeService.editProductItem(params.productTypeNo, newInfo.prodItemNo, payload)
    .then(
      res => {
        if (res.status === 204) {
          setRowData(newData);
          setEditingKey("");
          message.success("編輯成功")
        }
        else if (res.status === 401) message.warning("存取拒絕");
        else if (res.status === 404) message.warning("找不到商品項目");
        else message.error("編輯失敗");
      },
      error => {
        console.log(error);
      }
    );
  };

  // 編輯-刪除
  const handleDelete = async (key) => {
    const { confirm } = Modal;
    confirm({
      title: "確定要刪除此筆資料嗎？",
      onOk: () => {
        try {
          const dataSource = [...rowData];
          productTypeService.deleteProductItem(params.productTypeNo, key)
          .then(
            res => {
              if (res.status === 204) {
                setRowData(() => dataSource.filter(item => item.key !== key));
                setEditingKey("");
                message.success("刪除成功");
              }
              else if (res.status === 401) message.warning("存取拒絕");
              else if (res.status === 404) message.warning("找不到商品項目");
              else if (res.status === 409) message.warning("無法刪除已使用的商品項目");
              else message.error("刪除失敗");
            },
            error => {
              console.log(error);
            }
          );
        } catch (error) {
          console.log(error);
        }
      },
    });
  };

  // 排序後結果
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      // 前端更新表格
      const newData = arrayMoveImmutable([].concat(rowData), oldIndex, newIndex)
                      .filter(el => !!el);
      newData.forEach((item, idx) => item.productTypeOrder = idx + 1);

      // api 處理
      handleResort(newData, newIndex);
    };
  };

  const handleResort = async (data, index) => {
    const prodItemNo = data[index].prodItemNo;
    const payload = { prodItemSerNo: index + 1 };
    productTypeService.editProductItemOrder(params.productTypeNo, prodItemNo, payload)
    .then(
      res => {
        if (res.status === 204) {
          setRowData(data);
          message.success("排序修改成功");
        }
        else if (res.status === 401) message.warning("存取拒絕");
        else if (res.status === 404) message.warning("找不到商品項目");
        else if (res.status === 409) message.warning("排序超出範圍");
        else message.error("修改失敗");
      },
      error => {
        console.log(error);
      }
    );
  };

  // 拖曳內容區塊
  const DraggableContainer = props => (
    <SortableBody
      useDragHandle
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = rowData.findIndex(x => x.index === restProps["data-row-key"]);
    return <SortableItem index={index} {...restProps} />;
  };

  const columns = [
    {
      title:
        <div>
          拖曳&nbsp;
          <Tooltip placement="top" title="拖曳以改變順序">
            <QuestionCircleOutlined style={{ cursor: "help" }} />
          </Tooltip>
        </div>,
      dataIndex: "drag",
      width: "70px",
      render: () => <DragHandle />
    },
    {
      title: "商品項目代碼",
      dataIndex: "prodItemNo",
      width: "100px",
    },
    {
      title: "商品項目排序",
      dataIndex: "prodItemSerNo",
      width: "100px",
    },
    {
      title: "商品項目說明",
      dataIndex: "prodItemDes",
      editable: true,
      width: "150px",
    },
    {
      title: "商品項目狀態",
      dataIndex: "prodItemDisplay",
      editable: true,
      width: "100px",
      render: (prodItemDisplay) => (<span>{prodItemDisplay ? "顯示" : "隱藏"}</span>)
    },
    {
      title: "操作",
      dataIndex: "operation",
      width: "150px",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Space>
            <a onClick={() => save(record.key)}>儲存</a>
            <a onClick={() => setEditingKey("")}>取消</a>
            <a onClick={() => handleDelete(record.key)} style={{ color: "red" }}>刪除</a>
          </Space>
        ) : (
          <a disabled={editingKey !== ""} onClick={() => edit(record)}>
            編輯
          </a>
        );
      },
    },
  ];

  const mergedColumns = columns.map(col => {
    if (!col.editable) return col;
    return {
      ...col,
      onCell: record => ({
        record,
        inputType: col.dataIndex === "prodItemDisplay" ? "switch" : "input",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Layout className="rootList">
      <Row justify="center">
        <Col span={23}>
          <div className="title">{"商品類型設定 > 商品項目"}</div>

          <Card className="rootListCard">
            <Row>
              <Col align="left" span={8} xs={8} sm={8} md={8} >
                <span className="productType text">商品類型</span>
                <span className="text">{prodTypeDes}</span>
              </Col>
              <Col align="right" span={16} xs={16} sm={16} md={16} >
                <Button type="primary" style={{ marginRight: "12px" }}>
                  <Link to={"/root/product-type/list"}>回上一頁</Link>
                </Button>
                <Button icon={<PlusOutlined />} onClick={handleAddModal}>新增</Button>
                <AddProductItem prodTypeNo={params.productTypeNo} prodTypeDes={prodTypeDes} />
              </Col>
            </Row>
            <Row style={{margin: "20px 0"}}>
              <Form form={form} component={false}>
                <Table
                  components={{body: {
                    cell: EditableCell,
                    wrapper: DraggableContainer,
                    row: DraggableBodyRow,
                  }}}
                  columns={mergedColumns}
                  dataSource={rowData}
                  rowClassName="editable-row"
                  rowKey={record => record.index}
                  locale={{emptyText: (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>無資料</span>} />
                  )}}
                  size="small"
                  pagination={false}
                  scroll={{y: 400}}
                />
              </Form>
              </Row>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}

export default RootProductItem;
