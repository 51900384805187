import React from "react";

// antd
import { Row, Col } from "antd";

// component
import FrontDivCenter from "src/components/Front/Divider/DivCenter";

import icon_coming from "src/assets/merchant-guide/icon_coming.png";
import icon_soon from "src/assets/merchant-guide/icon_soon.png";

const Section5 = () => {
  return (
    <Row justify="center" style={{ marginTop: 70 }}>
      <Col span={24}>
        <h2 className="front" style={{ margin: 0 }}>
          更多優質服務，敬請期待
        </h2>
        <FrontDivCenter />
      </Col>
      <Col className="comingSoon" span={24} lg={18} md={22}>
        <div>
          <img className="coming" src={icon_coming} alt="coming" />
          <img className="soon" src={icon_soon} alt="soon" />
          <h3 className="front" style={{ fontWeight: "bold" }}>{"[Jetshop 推出金流串接服務]"}</h3>
          <h4 className="comingSoonContent front">
            無論是否有程式串接能力，皆有適合您的服務方案，貼近您的使用需求，讓支付應用更豐富。
          </h4>
          <a href="/ecommerce">
            <button className="frontBtn" style={{ width: 140, marginTop: "24px" }}>
              {"瞭解更多 >>"}
            </button>
          </a>
        </div> 
      </Col>
    </Row>
  );
}

export default Section5;
