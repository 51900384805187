import { orderTypes } from "src/constants";
import { orderService } from "src/services";

const getOrderList = (payload) => {
    return (dispatch) => {
        orderService.getOrderList(payload)
            .then(
                res => {
                    res.json().then(result => {
                        dispatch({type: orderTypes.GET_ORDER_LIST, data: result});
                    });
                },
                error => {
                    console.log(error);
                },
            );
    };
};

export const actionCreators = {
    getOrderList,
};