import React, { useState, useEffect } from "react";

// antd
import { Row, Col } from "antd";

// component
import FrontDivCenter from "src/components/Front/Divider/DivCenter";

import btnPointer from "src/assets/btn_pointer.png";
import icon12 from "src/assets/ecommerce/icon_12.png";

const Section1 = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isSmallSize, setIsSmallSize] = useState(windowWidth <= 600);

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);
    onWindowResize();
  
    return () => window.removeEventListener("resize", onWindowResize);
  }, [windowWidth]);

  const onWindowResize = () => {
    setWindowWidth(window.innerWidth);
    setIsSmallSize(window.innerWidth <= 600);
  };

  const content = () => {
    return (
      <div>
        <p>您已經有線上商城了嗎？</p>
        <h3 className="front">
          火速申請
          <span className="textHighlight">「商品分期金流API」</span>
          串接
        </h3>
        <h3 className="front">讓消費者先享受，後付款，銷售成績A++！</h3>
        <a href="/merchant/apply">
          <button className="frontBtn" style={{ width: 180 }}>
            點我立即加入
            <img src={btnPointer} alt="pointer" style={{ width: 20, marginLeft: 6 }} />
          </button>
        </a>
      </div>
    )
  };

  return (
    <Row justify="center" style={{ marginTop: 70 }}>
      <Col span={24}>
        <h2 className="front" style={{ margin: 0 }}>
          <p>品牌線上商城 + 商品分期支付</p>
          <p>= 效益最大化</p>
        </h2>
        <FrontDivCenter />
      </Col>
      {
        isSmallSize ? (
          <Col span={22} md={22} sm={22}>
            <div>
              <img className="shopIcon" src={icon12} alt="Shop" />
            </div>
            <div className="frontEcommerceProfit">
              {content()}
            </div>
          </Col>
        ) : (
          <Col className="d-flex justify-end" span={20} md={20} sm={20} style={{ marginTop: 20 }}>
            <div className="frontEcommerceProfit">
              <img className="shopIcon" src={icon12} alt="Shop" />
              {content()}
            </div>
          </Col>
        )
      }
    </Row>
  );
}

export default Section1;
