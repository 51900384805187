import React, { useState, useEffect, useRef } from 'react';

// Ant design
import { Table, Empty } from 'antd';
// pagination={{ position: ['none'] }}

const ResultTable = ({ rowKey, tableCols = [], dataSource = [], pagination = { position: ['bottomRight'] }, bordered = false, tableScroll = { x: 1750, y: 300 }, size = 'default' }) => {
  return <Table rowKey={rowKey} dataSource={dataSource} columns={tableCols} pagination={pagination} bordered={bordered} scroll={tableScroll} size={size} locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>無資料</span>} /> }} />;
};

export default ResultTable;
