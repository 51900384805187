import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import ResponsiveAntMenu from "responsive-ant-menu";
import { Row, Col, Menu } from "antd";
import { MenuOutlined, SettingOutlined } from "@ant-design/icons";

import "src/components/DrawerHide/DrawerHide.css";
import logo from "src/assets/JetShop_logo_orange.png";

const RootDrawerHide = () => {
    const { SubMenu } = Menu;
    
    const navigate = useNavigate();

    const changeRouter = (path, onLinkClick) => {
        navigate(path);
        onLinkClick();
    };

    return (
        <Row className="menuHide" align="middle">
            <Col className="d-flex" span={24}>
                <ResponsiveAntMenu
                  activeLinkKey={"/root"}
                  mobileMenuContent={isMenuShown => isMenuShown ? <button><MenuOutlined /></button> : <button><MenuOutlined /></button>}
                  menuClassName={"responsive-menu"}
                  mode={"vertical"}
                  mobileBreakPoint={1000}
                >
                    {(onLinkClick) =>
                        <Menu
                          theme="dark"
                          style={{color: "#324057", border: "none"}}
                        >
                            <SubMenu
                              popupClassName={"responsive-subMenu"}
                              key="100"
                              icon={<SettingOutlined />}
                              title="維護設定"
                            >
                                <Menu.Item
                                  key="101"
                                  onClick={() => changeRouter('/root/apply-type/list', onLinkClick)}
                                >
                                    案件類型設定
                                </Menu.Item>
                                <Menu.Item
                                  key="102"
                                  onClick={() => changeRouter('/root/product-type/list', onLinkClick)}
                                >
                                    商品類型設定
                                </Menu.Item>
                                <Menu.Item
                                  key="103"
                                  onClick={() => changeRouter('/root/banner/list', onLinkClick)}
                                >
                                    廣告資料設定
                                </Menu.Item>
                                <Menu.Item
                                  key="104"
                                  onClick={() => changeRouter('/root/installment/list', onLinkClick)}
                                >
                                    分期說明設定
                                </Menu.Item>
                                <Menu.Item
                                  key="105"
                                  onClick={() => changeRouter('/root/FAQ/list', onLinkClick)}
                                >
                                    FAQ 設定
                                </Menu.Item>
                            </SubMenu>
                        </Menu>
                    }
                </ResponsiveAntMenu>

                <Link to="/root">
                    <img className="logoImg" src={logo} alt="JetShop Logo" />
                </Link>
            </Col>
        </Row>
    );
}

export default RootDrawerHide;
