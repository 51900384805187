import React from "react";

// css
import "./FrontHome.css"

// component
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";

const FrontHome = () => {
  return (
    <div style={{ marginBottom: 50 }}>
      <Section1 />
      <Section2 />
      <Section3 />
    </div>
  );
}

export default FrontHome;
