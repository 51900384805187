const INITIAL_STATE = {
  modalViewImgVisible: false,
  bannerList: [],
  bannerImgs: []
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "MODAL_VIEW_IMG_VISIBLE":
      return {
        ...state,
        modalViewImgVisible: !state.modalViewImgVisible,
      };
    case "GET_BANNER_LIST":
      return {
        ...state,
        bannerList: action.data
      };
    case 'GET_BANNER_IMGS':
      return {
        ...state,
        bannerImgs: action.data
      };
    default:
      return state;
  }
};

export default reducer;