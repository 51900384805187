import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper";

// antd
import { Row, Col } from "antd";

// component
import FrontDivCenter from "src/components/Front/Divider/DivCenter";
import FrontPersona from "src/components/Front/Persona";
import FrontPersonaSlide from "src/components/Front/Persona/Slide";

import step01 from "src/assets/guide/step_01.png";
import step02 from "src/assets/guide/step_02.png";
import step03 from "src/assets/guide/step_03.png";
import step04 from "src/assets/guide/step_04.png";
import icon01 from "src/assets/guide/icon_01.png";
import icon02 from "src/assets/guide/icon_02.png";
import icon03 from "src/assets/guide/icon_03.png";
import icon04 from "src/assets/guide/icon_04.png";
import icon05 from "src/assets/guide/icon_05.png";
import icon06 from "src/assets/guide/icon_06.png";
import icon07 from "src/assets/guide/icon_07.png";
import icon08 from "src/assets/guide/icon_08.png";

const steps = [
  {
    step: 1,
    title: "選購商品",
    prefix: icon01,
    icon: icon05,
    content: [
      "店家提供商品連結",
      "進入專屬商城買東西"
    ]
  },
  {
    step: 2,
    title: "線上申請商品分期",
    prefix: icon02,
    icon: icon06,
    content: [
      "無紙化申請流程",
      "一支手機就能解決"
    ]
  },
  {
    step: 3,
    title: "JetShop專人審核",
    prefix: icon03,
    icon: icon07,
    content: [
      "從申請到通知只需要一杯咖啡的時間",
      "Line線上專屬客服處理您所有疑難雜症"
    ]
  },
  {
    step: 4,
    title: "分期繳款",
    prefix: icon04,
    icon: icon08,
    content: [
      "6~36期自由選擇",
      "超商及ATM便利繳款"
    ]
  },
];

const Section1 = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isSmallSize, setIsSmallSize] = useState(windowWidth < 768);
	const [swiper, setSwiper] = useState(null);
	const [activeSwiperIndex, setActiveSwiperIndex] = useState(0);

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);
    onWindowResize();
  
    return () => window.removeEventListener("resize", onWindowResize);
  }, [windowWidth]);

  const onWindowResize = () => {
    setWindowWidth(window.innerWidth);
    setIsSmallSize(window.innerWidth < 768);
  };

  const slideTo = (index) => swiper.slideTo(index);

  return (
    <div className="frontGuideSection1">
      {/* Section1-1 */}
      <Row justify="center">
        <Col span={22} xl={13} lg={14} md={14} sm={22} className="frontGuideIntro">
          <div className="text-left">
            <div className="title">
              <h1 className="front">商品分期指南</h1>
            </div>
            <div className="intro">
              <h2 className="front">
                先買後付，彈性的支付選擇
              </h2>
              <h2 className="front">
                30分鐘快速審核，輕鬆將喜愛的商品帶回家！
              </h2>
            </div>
            {/* For PC and pad */}
            {!isSmallSize &&
              <Row style={{ marginTop: 50 }}>
                {steps.map((item, idx) => (
                  <Col span={12} xl={10} lg={12} md={12} sm={12} key={idx}>
                    <div
                      className="d-flex"
                      onClick={
                        () => slideTo(idx)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <div style={{ marginBottom: 20 }}>
                        <div className="d-flex justify-center align-start">
                          <img className="stepPrefix" src={item.prefix} alt="+" />
                          <h3 className={activeSwiperIndex === idx ? "stepTitle red" : "stepTitle"}>
                            <b>{`Step${item.step}： ${item.title}`}</b>
                          </h3>
                        </div>
                        <div className="stepContent">
                          <p>{item.content[0]}</p>
                          <p>{item.content[1]}</p>
                        </div>
                      </div>
                      <div className="stepIcon">
                        <img src={item.icon} alt={item.title} />
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            }
          </div>
        </Col>
        {/* For all */}
        <Col className="stepSwiperSection" span={22} lg={6} md={10} sm={22}>
          <Swiper
						pagination={{ clickable: true }}
						modules={[Pagination]}
						className="stepSwiper"
						onSwiper={setSwiper}
						onSlideChange={(e) => setActiveSwiperIndex(e.activeIndex)}
					>
						<SwiperSlide>
							<img src={step01} alt="Step1：選購商品" />
						</SwiperSlide>
						<SwiperSlide>
							<img src={step02} alt="Step2：線上申請商品分期" />
						</SwiperSlide>
						<SwiperSlide>
							<img src={step03} alt="Step3：JetShop專人審核" />
						</SwiperSlide>
						<SwiperSlide>
							<img src={step04} alt="Step4：分期繳款" />
						</SwiperSlide>
					</Swiper>
        </Col>
        {/* For phone */}
				{isSmallSize && (
					<Col span={22} style={{ marginTop: 30 }}>
						<div>
              <div className="d-flex justify-center align-start">
                <img className="stepPrefix" src={steps[activeSwiperIndex].prefix} alt="+" />
                  <h3 className="stepTitle red">
                    <b>{`Step${steps[activeSwiperIndex].step}： ${steps[activeSwiperIndex].title}`}</b>
                  </h3>
              </div>
							<div className="stepContent">
								<p>{steps[activeSwiperIndex].content[0]}</p>
								<p>{steps[activeSwiperIndex].content[1]}</p>
							</div>
						</div>
					</Col>
				)}
      </Row>
      {/* Section1-2 */}
      <Row justify="center" style={{ marginTop: 70 }}>
        <Col span={24}>
          <h2 className="front" style={{ margin: 0 }}>
            選擇使用商品分期，消費零負擔！
          </h2>
          <FrontDivCenter />
          <a href="/installment/guide/faq" target="_blank" rel="noreferrer">
            <button className="frontBtn" style={{ width: 165, marginTop: 10 }}>
              {"商品分期懶人包 >>"}
            </button>
          </a>
        </Col>
        <Col span={24}>
					{isSmallSize ? <FrontPersonaSlide /> : <FrontPersona />}
        </Col>
      </Row>
    </div>
  );
}

export default Section1;
