import React from "react";

// antd
import { Row, Col } from "antd";

// component
import FrontDivCenter from "src/components/Front/Divider/DivCenter";

import icon04 from "src/assets/about/icon_04.png";

const Section4 = () => {
  return (
    <Row justify="center" style={{ marginTop: 70 }}>
      <Col span={24}>
        <h2 className="front" style={{ margin: 0 }}>
          商品優勢
        </h2>
        <FrontDivCenter />
      </Col>
      <Col className="frontAboutSection4" span={24}>
        <Row justify="center" style={{ margin: "70px 0 30px 0px" }}>
          <Col span={16} xl={9} lg={10} md={10} sm={16}>
            <img src={icon04} alt="Icon" />
            <div className="text-left">
              <h3 className="front about">商家0負擔申請商品分期購物</h3>
            </div>
            </Col>
            <Col span={16} xl={9} lg={10} md={10} sm={16}>
            <img src={icon04} alt="Icon" />
            <div className="text-left">
              <h3 className="front about">申請到繳款，數位e化一機搞定</h3>
            </div>
          </Col>
          <Col span={16} xl={9} lg={10} md={10} sm={16}>
            <img src={icon04} alt="Icon" />
            <div className="text-left">
              <h3 className="front about">業界最快的審核流程</h3>
            </div>
            </Col>
            <Col span={16} xl={9} lg={10} md={10} sm={16}>
            <img src={icon04} alt="Icon" />
            <div className="text-left">
              <h3 className="front about">合理費用、多元彈性繳款方式</h3>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Section4;
