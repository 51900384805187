import { bannerTypes } from 'src/constants';
import { bannerService } from 'src/services';

// 變更 Modal 顯示/隱藏
const modalViewImgVisible = () => {
  return (dispatch) => {
    dispatch({type: bannerTypes.MODAL_VIEW_IMG_VISIBLE, data: null});
  };
};

// 取得banner資料
const getBannerList = (type) => {
  return (dispatch) => {
    bannerService.getBannerList(type)
      .then(
        res => {
          res.json().then(result => {
            dispatch({type: bannerTypes.GET_BANNER_LIST, data: result.data});
          });
        },
        error => {
          console.log(error);
        }
      );
  };
};

// 取得banner圖檔
const getBannerImgs = (payload) => {
  return (dispatch) => {
    bannerService.getBannerImgs(payload)
      .then(
        res => {
          res.json().then(result => {
            dispatch({type: bannerTypes.GET_BANNER_IMGS, data: result});
          })
        },
        error => {
          console.log(error);
        }
      );
  }
}

export const actionCreators = {
  modalViewImgVisible,
  getBannerList,
  getBannerImgs
}