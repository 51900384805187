import { API_URL as api_url } from 'src/config';

const reqOptions = {
  get: {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  },
};

// 取得FAQ
const getFrontFAQ = async (FAQTypeSerialNumber) => {
  return fetch(
    `${api_url}/frequently-asked-questions?FAQTypeSerialNumber=${FAQTypeSerialNumber}`,
    reqOptions.get
  );
};

// 取得FAQ類型
const getFrontFAQType = async () => {
  return fetch(
    `${api_url}/frequently-asked-question-types`,
    reqOptions.get
  );
};

export const frontFAQService = {
  getFrontFAQ,
  getFrontFAQType
};
