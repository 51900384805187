import React from "react";

import "./index.css";

const Loading = () => {
  return (
    <div className="loading-box">
      <div className="img-container"></div>
      <div className="img-shadow"></div>
      <div className="loading-text">Loading...</div>
    </div>
  );
}

export default Loading;
