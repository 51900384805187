import { productTypes } from 'src/constants';
import { productService } from 'src/services';

// 取得商品列表
const getProducts = (keyword, limit, page, sortData, excludeProdNos) => {
  return (dispatch) => {
    productService.getProducts(keyword, limit, page, sortData, excludeProdNos)
      .then(
        res => {
          res.json().then(result => {
            dispatch({type: productTypes.GET_PRODUCTS, data: result});
          });
        },
        error => {
          console.log(error);
        },
      );
  };
};

// 取得商品列表 deprecated
const getProductList = (payload) => {
  return (dispatch) => {
    productService.getProductList(payload)
      .then(
        res => {
          res.json().then(result => {
            dispatch({type: productTypes.GET_PRODUCT_LIST, data: result});
          });
        },
        error => {
          console.log(error);
        },
      );
  }
};

// 取得指定商品
const getProductData = (prodNo) => {
  return (dispatch) => {
    productService.getProductData(prodNo)
      .then(
        res => {
          res.json().then(result => {
            dispatch({type: productTypes.GET_PRODUCT_DATA, data: result});
          });
        },
        error => {
          console.log(error);
        },
      );
  }
};

// 取得商品類型列表
const getProductTypeList = (appTypeNo) => {
  return (dispatch) => {
    productService.getProductTypeList(appTypeNo)
      .then(
        res => {
          res.json().then(result => {
            dispatch({type: productTypes.GET_PRODUCT_TYPE_LIST, data: result});
          });
        },
        error => {
          console.log(error);
        },
      );
  }
};

// 取得商品類型項目列表
const getProductTypeItemsList = (prodTypeNo) => {
  return (dispatch) => {
    productService.getProductTypeItemsList(prodTypeNo)
      .then(
        res => {
          res.json().then(result => {
            dispatch({type: productTypes.GET_PRODUCT_TYPE_ITEMS_LIST, data: result});
          });
        },
        error => {
          console.log(error);
        },
      );
  }
};

// 取得品牌列表
const getProductBrand = () => {
  return (dispatch) => {
    productService.getProductBrand()
      .then(
        res => {
          res.json().then(result => {
            dispatch({type: productTypes.GET_PRODUCT_BRAND, data: result});
          });
        },
        error => {
          console.log(error);
        },
      );
  }
};

// 取得利率表
const getProductInterestRate = () => {
  return (dispatch) => {
    productService.getProductInterestRate()
      .then(
        res => {
          res.json().then(result => {
            dispatch({type: productTypes.GET_PRODUCT_INTERESTRATE, data: result});
          });
        },
        error => {
          console.log(error);
        },
      );
  }
};

// 取得所有商品類型項目列表
const getProductTypeIndex = () => {
  return (dispatch) => {
    productService.getProductTypeIndex()
      .then(
        res => {
          res.json().then(result => {
            dispatch({type: productTypes.GET_PRODUCT_TYPE_INDEX, data: result});
          });
        },
        error => {
          console.log(error);
        },
      );
  }
};


export const actionCreators = {
  getProducts,
  getProductList,
  getProductData,
  getProductTypeList,
  getProductTypeItemsList,
  getProductBrand,
  getProductInterestRate,
  getProductTypeIndex
}