import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { actionCreators } from "src/actions/jet";

// antd & css
import { Layout, Row, Col, Card, Tabs, Button } from "antd";
import "./Jet.css";

//components
import ModalUrl from "src/components/Jet/ModalUrl";
import ActivitySet from "./ActivitySet";
import ActivityList from "./ActivityList";

const JetActivity = () => {
  const dispatch = useDispatch();

  // 取得 store 中的 state
  const tabActiveKey = useSelector(state => state.jet.tabActiveKey);

  useEffect(() => {
    dispatch(actionCreators.getEditingShopActivity());
  }, []);

  const onChangeTab = (e) => {
    dispatch(actionCreators.handleTabActiveKey(e));
  };

  return (
    <Layout className="merLayout">
      <Row justify="center">
        <Col span={23}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div className="title">活動專區</div>
            <div>
              <ModalUrl />
              <a href="/admin/ecommerce/preview" target="_blank">
                <Button style={{ marginRight: 10 }}>預覽官網</Button>
              </a>
            </div>
          </div>
          <Card className="merLayoutCard">
            <Tabs activeKey={tabActiveKey} onChange={onChangeTab}>
              <Tabs.TabPane tab="活動專區設定" key="1">
                <ActivitySet />
              </Tabs.TabPane>
              <Tabs.TabPane tab="活動專區列表" key="2">
                <ActivityList />
              </Tabs.TabPane>
            </Tabs>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}

export default JetActivity;
