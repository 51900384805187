import React from "react";

// antd
import { Row, Col } from "antd";

// component
import FrontDivCenter from "src/components/Front/Divider/DivCenter";

const Section2 = () => {
  return (
    <Row justify="center" style={{ marginTop: 100 }}>
      <Col span={24}>
        <h2 className="front" style={{ margin: 0 }}>
          <p>商家金流推手首選，</p>
          <p>0風險開啟快速獲利模式</p>
        </h2>
        <FrontDivCenter />
      </Col>
      <Col className="d-flex justify-center" span={22} md={10} sm={10} style={{ marginTop: 20 }}>
        <div className="frontHomeIntro">
          <div className="d-flex justify-center">
            <h3 className="front frontHomeTitle ecommerce">
              Jet捷店免費商城
            </h3>
          </div>
          <div className="frontHomeSubTitle">
            <h4 className="front">
              <p className="mb-none">簡單步驟即可開店</p>
              <p>0元創建線上商城</p>
            </h4>
          </div>
          <div className="content">
            <p className="mb-none">介面易懂好操作</p>
            <p className="mb-none">後台商品管理一目了然</p>
            <p>JetShop助您業績長紅</p>
          </div>
          <a href="/ecommerce">
            <button className="frontBtn" style={{ width: 180 }}>
              點我了解更多
            </button>
          </a>
        </div>
      </Col>
      <Col className="d-flex justify-center" span={22} md={10} sm={10} style={{ marginTop: 20 }}>
        <div className="frontHomeIntro">
          <div className="d-flex justify-center">
            <h3 className="front frontHomeTitle merchant">
            商品分期商家合作
            </h3>
          </div>
          <div className="frontHomeSubTitle">
            <h4 className="front">
              <p className="mb-none">創新金流合作模式</p>
              <p>看客不猶豫，業績一把抓</p>
            </h4>
          </div>
          <div className="content">
            <p className="mb-none">專人聯繫打造合適方案</p>
            <p className="mb-none">擴展業務、訂單量暴增</p>
            <p>三方獲利，心動不如馬上行動</p>
          </div>
          <a href="/installment/merchant/guide">
            <button className="frontBtn" style={{ width: 180 }}>
              點我了解更多
            </button>
          </a>
        </div>
      </Col>
    </Row>
  );
}

export default Section2;
