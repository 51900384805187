import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { actionCreators } from "src/actions/banner";
import { actionCreators as actionRootCreators } from "src/actions/root";
import { bannerService } from "src/services";

// components
import AddBanner from "./AddBanner";
import BannerImg from "./BannerImg";

//antd
import { Layout, Row, Col, Card, Select, Space, Tooltip, Table, Empty, Button, Switch, Input, Form, Modal, message } from "antd";
import { MenuOutlined, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";

// drag components
const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: "grab", color: "#999" }} />);
const SortableItem = SortableElement(props => <tr {...props} />);
const SortableBody = SortableContainer(props => <tbody {...props} />);

// editable cell rules
const editableCellRules = {
    bannerDescription: [{ required: true, message: "必填" }],
    bannerUrl: [{ type: "url", message: "須為有效的url" }],
};

// render editable cell
const EditableCell = ({editing, dataIndex, title, inputType, record, index, children, ...restProps}) => {
    let inputNode = null;
    if (inputType === "switch") {
        inputNode = <Switch defaultChecked={record.bannerDisplay} checkedChildren="顯示" unCheckedChildren="隱藏" />;
    } else {
        inputNode = <Input />;
    }
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                  name={dataIndex}
                  valuePropName={dataIndex === "bannerDisplay" ? "checked" : "value"}
                  style={{margin: 0}}
                  rules={editableCellRules[dataIndex]}
                >
                    {inputNode}
                </Form.Item>
            ) : (children)}
        </td>
    );
};

const RootBanner = () => {
    const [form] = Form.useForm();
    const [rowData, setRowData] = useState([]);
    const [bannerInfo, setBannerInfo] = useState({});
    const [bannerType, setBannerType] = useState(1);
    const [editingKey, setEditingKey] = useState("");

    // 取出 store 中的 state
    const bannerList = useSelector(state => state.banner.bannerList);

    const dispatch = useDispatch();

    const isEditing = (record) => record.key === editingKey;

    const edit = (record) => {
        form.setFieldsValue({
            bannerDescription: "",
            ...record,
        });
        setEditingKey(record.key);
    };

    const dispatchFunc = (type) => dispatch(actionCreators.getBannerList(type));

    useEffect(() => {
        // action : 取得banner
        dispatchFunc(1);
    }, [dispatch]);

    // 配值
    useEffect(() => {
        let data = [];
        let bannerIndex = 0;
        if (bannerList && bannerList.length >= 1) {
            bannerList.forEach(item => {
                data.push({
                    key: `${item.bannerNo}`,
                    bannerNo: item.bannerNo,
                    bannerOrder: item.bannerOrder,
                    bannerType: item.bannerType === 1 ? "官網" : "後台",
                    bannerDescription: item.bannerDescription,
                    bannerDisplay: item.bannerDisplay,
                    bannerDocImg: item.bannerDocImg ? item.bannerDocImg : '',
                    bannerUrl: item.bannerUrl,
                    index: bannerIndex,
                });
                bannerIndex += 1;
            });
        };
        // bannerOrder-升序
        data.sort((a, b) => {
            return a.bannerOrder - b.bannerOrder;
        });
        setRowData(data);
    }, [bannerList]);

    // 篩選-選擇廣告類別
    const onChangeType = (e) => {
        setBannerType(Number(e));
        dispatch(actionCreators.getBannerList(Number(e)));
    };

    // 打開新增視窗
    const handleAddModal = () => {
        dispatch(actionRootCreators.modalVisible());
    };

    // 打開檢視圖片視窗
    const handleViewImg = (record) => {
        setBannerInfo(record);
        dispatch(actionCreators.modalViewImgVisible());
    };

    // 編輯-刪除
    const handleDelete = async (key) => {
        const { confirm } = Modal;
        confirm({
            title: "確定要刪除此筆資料嗎？",
            onOk: () => {
                try {
                    bannerService.deleteBanner(key)
                    .then(
                        res => {
                            if (res.status === 200) {
                                // 前端更新表格
                                const dataSource = [...rowData];
                                setRowData(() => dataSource.filter(item => item.key !== key));
                                setEditingKey("");
                                message.success("刪除成功");
                            }
                            else if (res.status === 400) message.warning("沒有權限");
                            else message.error("刪除失敗");
                        },
                        error => {
                            console.log(error);
                        }
                    );
                } catch (err) {
                    console.log(err);
                }
            },
        });
    };

    // 編輯-儲存
    const save = async (key) => {
        try {
            const row = await form.validateFields();
            
            // 前端更新表格
            const newData = [...rowData];
            const index = newData.findIndex((item) => key === item.key);
            
            const item = newData[index];
            newData.splice(index, 1, { ...item, ...row });

            // api 處理
            handleEditBanner(newData, index);
        } catch (err) {
            console.log("Failed:", err);
        }
    };

    const handleEditBanner = (newData, index) => {
        const newInfo = newData[index];
        const bannerNo = newInfo.bannerNo;
        const payload = {
            bannerDescription: newInfo.bannerDescription,
            bannerDisplay: newInfo.bannerDisplay ? 1 : 0,
            bannerUrl: newInfo.bannerUrl,
        };
        bannerService.editBannerInfo(bannerNo, payload)
        .then(
            res => {
                if (res.status === 204) {
                    setRowData(newData);
                    setEditingKey("");
                    message.success("編輯成功");
                } 
                else if (res.status === 401) message.warning("存取拒絕");
                else message.error("編輯失敗");
            },
            error => {
                console.log(error);
            },
        );
    };

    // 排序後結果
    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            // 前端更新表格
            const newData = arrayMoveImmutable([].concat(rowData), oldIndex, newIndex).filter(
                el => !!el,
            );
            newData.forEach((item, idx) => {
                item.bannerOrder = idx + 1;
            });

            // api 處理
            handleResort(newData);
        };
    };

    const handleResort = async (newData) => {
        let payload = { bannerNo: [], bannerOrder: [] };
        newData.forEach(item => {
            payload.bannerNo.push(item.bannerNo);
            payload.bannerOrder.push(item.bannerOrder);
        });
        bannerService.editBannerOrder(payload)
        .then(
            res => {
                if (res.status === 200) {
                    setRowData(newData);
                    message.success("排序修改成功");
                }
                else if (res.status === 400) message.warning("沒有權限");
                else message.error("修改失敗");
            },
            error => {
                console.log(error);
            }
        );
    };

    // 拖曳內容區塊
    const DraggableContainer = props => (
        <SortableBody
          useDragHandle
          helperClass="row-dragging"
          onSortEnd={onSortEnd}
          {...props}
        />
    );

    const DraggableBodyRow = ({ className, style, ...restProps }) => {
        const index = rowData.findIndex(x => x.index === restProps["data-row-key"]);
        return <SortableItem index={index} {...restProps} />;
    };

    const columns = [
        {
            title: 
                <div>
                    拖曳&nbsp;
                    <Tooltip placement="top" title="拖曳以改變順序">
                        <QuestionCircleOutlined style={{cursor: "help"}} />
                    </Tooltip>
                </div>,
            dataIndex: "drag",
            width: "70px",
            render: () => <DragHandle />,
        },
        {
            title: "廣告代碼",
            dataIndex: "bannerNo",
            width: "75px",
        },
        {
            title: "廣告排序",
            dataIndex: "bannerOrder",
            width: "75px",
        },
        {
            title: "廣告類別",
            dataIndex: "bannerType",
            width: "75px",
        },
        {
            title: "廣告說明",
            dataIndex: "bannerDescription",
            editable: true,
            width: "200px",
        },
        {
            title: "廣告狀態",
            dataIndex: "bannerDisplay",
            editable: true,
            render: (bannerDisplay) => (<span>{bannerDisplay ? "顯示" : "隱藏"}</span>),
            width: "80px",
        },
        {
            title: "連結",
            dataIndex: "bannerUrl",
            editable: true,
            width: "200px",
        },
        {
            title: "操作",
            dataIndex: "operation",
            width: "150px",
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <Space>
                        <a onClick={() => save(record.key)}>
                            儲存
                        </a>
                        <a onClick={() => setEditingKey("")}>取消</a>
                        <a onClick={() => handleDelete(record.key)} style={{ color: "red" }}>刪除</a>
                    </Space>
                ) : (
                    <a disabled={editingKey !== ""} onClick={() => edit(record)}>
                        編輯
                    </a>
                );
            },
        },
        {
            title: "圖片",
            dataIndex: "image",
            width: "80px",
            render: (_, record) => (
                <a disabled={editingKey !== "" && record.key !== editingKey} onClick={() => handleViewImg(record)}>
                    檢視
                </a>
            ),
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        };
    
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === "bannerDisplay" ? "switch" : "input",
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    return (
        <Layout className="rootList">
            <Row justify="center">
                <Col span={23}>
                    <div className="title">廣告資料設定</div>

                    <Card className="rootListCard">
                        {/* 篩選區塊 */}
                        <div className="rootListSearch">
                            <Row>
                                <Col flex="120px" style={{lineHeight: "32px"}}>
                                    <span>選擇廣告類型</span>
                                </Col>
                                <Col flex={2}>
                                    <Select defaultValue="1" style={{width: 200}} onChange={onChangeType}>
                                        <Select.Option value="1">官網</Select.Option>
                                        <Select.Option value="2">後台</Select.Option>
                                    </Select>
                                </Col>
                            </Row>
                        </div>

                        {/* 查詢結果 */}
                        <div>
                            <Row>
                                <Col className="title" align="left" span={12} xs={12} sm={12} md={12} style={{color: "#F7BA2A"}}>查詢結果</Col>
                                <Col align="right" span={12} xs={12} sm={12} md={12} style={{marginTop: "20px"}}>
                                    <Button icon={<PlusOutlined />} onClick={handleAddModal}>新增</Button>
                                    <AddBanner bannerType={bannerType} />
                                </Col>
                            </Row>
                            <Row style={{marginTop: "20px", marginBottom: "20px"}}>
                                <Form form={form} component={false}>
                                    <Table
                                      components={{body: {
                                        cell: EditableCell,
                                        wrapper: DraggableContainer,
                                        row: DraggableBodyRow,
                                      }}}
                                      columns={mergedColumns}
                                      dataSource={rowData}
                                      rowClassName="editable-row"
                                      rowKey={record => record.index}
                                      locale={{emptyText: (
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>無資料</span>} />
                                      )}}
                                      size="small"
                                      pagination={false}
                                      scroll={{y: 250}}
                                    />
                                </Form>
                                <BannerImg bannerInfo={bannerInfo} />
                            </Row>
                        </div>
                    </Card>
                </Col>
            </Row>
        </Layout>
    );
}

export default RootBanner;
