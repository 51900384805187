 import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { actionCreators as actionRootCreators } from "src/actions/root";
import { actionCreators } from "src/actions/installment";
import { installmentService } from "src/services";

// antd
import { Modal, Form, Input, Select, Divider, Button, notification } from "antd";

const rules = {
  insInstallments: [
    { required: true, message: "請選擇分期期數" }
  ],
  insButtonName: [
    { required: true, message: "請輸入按鈕文字", trigger: "blur" }
  ],
};

const periods = [ 3, 6, 9, 12, 18, 24, 30, 36, 42, 48 ];

const FormModal = (props) => {
  const [form] = Form.useForm();
  const [defaultValue] = useState({});
  const [interestRateOptions, setInterestRateOptions] = useState([]);

  // 取出 store 中的 state
  const isVisible = useSelector(state => state.Root.modalVisible);
  const installmentDetail = useSelector(state => state.installment.installmentDetail);
  const interestRateList = useSelector(state => state.installment.interestRateList);
  
  const dispatch = useDispatch();

  // step1: 取得使用中利率表
  useEffect(() => {
    setInterestRateOptions(interestRateList);
  }, [interestRateList]);

  // step2: 設定Form初始值
  useEffect(() => {
    if (props.type === "add") {
      form.setFieldsValue({
        insInstallments: null,
        insButtonName: "",
        insDescription: "",
        interestRates: [],
      });
    } else if (props.type === "edit" && isVisible) {
      dispatch(actionCreators.getInstallmentDetail(props.insSerialNumber));
    }
  }, [isVisible]);

  useEffect(() => {
    if (props.type === "edit" && isVisible) {
      form.setFieldsValue({
        insInstallments: installmentDetail.insInstallments,
        insButtonName: installmentDetail.insButtonName,
        insDescription: installmentDetail.insDescription,
        interestRates: installmentDetail.interestRates.map(item => item.intNo),
      });
    }
  }, [installmentDetail]);

  // 取消
  const handleCancel = () => {
    dispatch(actionRootCreators.modalVisible());
  };

  // 儲存
  const onFinish = (values) => {
    let payload = {
      insInstallments: values.insInstallments,
      insButtonName: values.insButtonName,
      insDescription: values.insDescription ? values.insDescription : undefined,
    };
    if (props.type === "add") {
      payload.intNos = values.interestRates.length ? values.interestRates : undefined;
      installmentService.addInstallment(payload)
      .then(
        res => {
          res.json().then(result => {
            if (res.status === 201) {
              dispatch(actionCreators.getInstallment(props.intNo));
              dispatch(actionRootCreators.modalVisible());
              notification.success({ message: "新增成功", placement: "top" });
            } else if (res.status === 401) {
              notification.warning({ message: "存取拒絕", placement: "top" });
            } else if (res.status === 404) {
              notification.warning({ message: "找不到利率表", placement: "top" });
            } else if (res.status === 409) {
              const description = result.extra.interestRates.map(item => item.intName);
              notification.warning({ 
                message: "以下利率表已綁定相同分期",
                description: description.join(" / "),
                placement: "top",
                duration: 0,
              });
            } else {
              notification.error({ message: "新增失敗", placement: "top" })
            }
          });
        },
        error => {
          console.log(error);
        }
      );
    } else if (props.type === "edit") {
      payload.intNos = values.interestRates;
      installmentService.editInstallment(props.insSerialNumber, payload)
      .then(
        res => {
          if (res.status === 204) {
            dispatch(actionCreators.getInstallment(props.intNo));
            dispatch(actionRootCreators.modalVisible());
            notification.success({ message: "編輯成功", placement: "top" });
          } else if (res.status === 401) {
            notification.warning({ message: "存取拒絕", placement: "top" });
          } else if (res.status === 404) {
            notification.warning({ message: "找不到分期說明", placement: "top" });
          } else if (res.status === 409) {
            res.json().then(result => {
              const description = result.extra.interestRates.map(item => item.intName);
                notification.warning({ 
                  message: "以下利率表已綁定相同分期",
                  description: description.join(" / "),
                  placement: "top",
                  duration: 0,
                });
              });
          } else {
            notification.error({ message: "編輯失敗", placement: "top" })
          }
        },
        error => {
          console.log(error);
        }
      );
    }
  };

  // 儲存失敗
  const onFinishFailed = () => false;

  return (
    <Modal
      title={props.type === "add" ? "新增" : "編輯"}
      visible={isVisible}
      onCancel={handleCancel}
      maskClosable={false}
      destroyOnClose
      footer={false}
      forceRender
    >
      <Form
        form={form}
        labelAlign="left"
        labelCol={{ flex: "120px" }}
        wrapperCol={{ span: 24, flex: 1 }}
        colon={false}
        initialValues={defaultValue}
        onFinish={ onFinish }
        onFinishFailed={ onFinishFailed }
      >
        <div className="rootFormItem">
          <Form.Item label="分期期數" name="insInstallments" rules={ rules.insInstallments }>
            <Select>
              { periods.map((item) => (
                <Select.Option
                  key={item}
                  value={item}
                >
                  {item}
                </Select.Option>
              )) }
            </Select>
          </Form.Item>
          <Form.Item label="按鈕文字" name="insButtonName" rules={ rules.insButtonName }>
            <Input />
          </Form.Item>
          <Form.Item label="分期說明" name="insDescription">
            <Input />
          </Form.Item>
          <Form.Item label="適用利率表" name="interestRates">
            <Select 
              mode="multiple" 
              showArrow
            >
              { interestRateOptions.map(item => (
                <Select.Option 
                  key={item.intNo}
                  value={item.intNo}
                >
                  <span
                    style={{ 
                      color: item.intStatusTag ? "green" : "red"
                    }}
                  >
                    {item.intName}
                  </span>
                </Select.Option>
              ))}
              </Select>
          </Form.Item>
        </div>
        <Divider style={{ margin: 0 }} />
        <div style={{ textAlign: "end", padding: "10px 16px" }}>
          <Button onClick={ handleCancel } style={{ marginRight: 10 }}>取消</Button>
          <Button type="primary" htmlType="submit">儲存</Button>
        </div>
      </Form>
    </Modal>
  );
}

export default FormModal;
