import React from "react";

// antd
import { Row, Col } from "antd";


const Section1 = () => {
  return (
    <Row className="frontMerchantSection1">
      <Col span={24} className="frontMerchantTitle">
        <h1 className="front">
          商家最優的金流幫手
        </h1>
        <h2 className="front" style={{ margin: "-20px 0 0 0" }}>
          <span className="redText">0</span> 元交貨 • 
          <span className="redText">0</span> 卡分期 • 
          <span className="redText">0</span> 等待 
        </h2>
        <a href="/merchant/apply">
          <button className="frontBtn" style={{ width: 165, marginTop: 20 }}>
            專人諮詢了解
          </button>
        </a>
      </Col>
    </Row>
  );
}

export default Section1;
