import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// import styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper";

import icon09 from "src/assets/guide/icon_09.png";
import icon10 from "src/assets/guide/icon_10.png";
import icon11 from "src/assets/guide/icon_11.png";
import icon12 from "src/assets/guide/icon_12.png";

const FrontPersonaSlide = () => {
  return (
    <div>
      <Swiper 
        className="personaSwiper"
        style={{
          "--swiper-navigation-color": "#000",
          "--swiper-pagination-color": "#000",
        }}
        navigation={true} 
        loop={true}
        modules={[Navigation]}
      >
        <SwiperSlide>
          <div className="personaImg">
            <img src={icon09} alt="大學生" />
          </div>
          <div className="dialog red">
            <p className="dialogTitle"><b>20歲，大學生</b></p>
            <p className="dialogTitle"><b>急需買筆電</b></p>
            <hr />
            <p>上課討論報告需要購買筆電，有甚麼支付方式適合我呢？</p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="personaImg">
            <img src={icon10} alt="社會新鮮人" />
          </div>
          <div className="dialog green">
            <p className="dialogTitle"><b>25歲，社會新鮮人</b></p>
            <p className="dialogTitle"><b>換台新機車</b></p>
            <hr />
            <p>平常騎的機車該汰換了，但沒有信用卡可以分期付款，手上現金也不足以支付全額，難道一定要辦信用卡嗎？</p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="personaImg">
            <img src={icon11} alt="自由業者" />
          </div>
          <div className="dialog blue">
            <p className="dialogTitle"><b>30歲，自由業者</b></p>
            <p className="dialogTitle"><b>不能沒手機</b></p>
            <hr />
            <p>平常外送餐點需使用到手機，但手機因為天氣等因素容易損壞，頻繁更換手機對我來說是一個不小的負擔，有沒有能夠先買後付的支付方式呢？</p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="personaImg">
            <img src={icon12} alt="自營業者" />
          </div>
          <div className="dialog yellow">
            <p className="dialogTitle"><b>40歲，自營業者</b></p>
            <p className="dialogTitle"><b>裝潢週轉金</b></p>
            <hr />
            <p>有營運一間個人工作室，最近業務量擴大，需要整修室內空間容納更多來客，因為收入不固定，信用卡審核沒通過，若有一次性大支出，可能會有資金周轉問題，該怎麼辦呢？</p>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default FrontPersonaSlide;
