import { reportTypes } from 'src/constants';
import { reportService } from 'src/services';

// 取得 案件狀態總覽
const getReviewApplyCases = (payload) => {
  return (dispatch) => {
    reportService.getReviewApplyCases(payload).then(
      (res) => {
        res.json().then((result) => {
          dispatch({ type: reportTypes.GET_PREVIEW_APPLY_CASES, data: result });
        });
      },
      (error) => {
        console.log(error);
      }
    );
  };
};

// 取得 案件款項
const getSummryApplyCases = (payload) => {
  return (dispatch) => {
    reportService.getSummryApplyCases(payload).then(
      (res) => {
        res.json().then((result) => {
          dispatch({ type: reportTypes.GET_SUMMRY_APPLY_CASES, data: result });
        });
      },
      (error) => {
        console.log(error);
      }
    );
  };
};

// 取得 案件列表
const getListApplyCases = (payload) => {
  return (dispatch) => {
    reportService.getListApplyCases(payload).then(
      (res) => {
        res.json().then((result) => {
          dispatch({ type: reportTypes.GET_List_APPLY_CASES, data: result });
        });
      },
      (error) => {
        console.log(error);
      }
    );
  };
};

export const actionReportCreators = {
  getReviewApplyCases,
  getSummryApplyCases,
  getListApplyCases,
};
