const INITIAL_STATE = {
  previewApplyCases: [],
  summryApplyCases: [],
  listApplyCases: [],
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GET_PREVIEW_APPLY_CASES':
      return {
        ...state,
        previewApplyCases: action.data,
      };
    case 'GET_SUMMRY_APPLY_CASES':
      return {
        ...state,
        summryApplyCases: action.data,
      };
    case 'GET_List_APPLY_CASES':
      return {
        ...state,
        listApplyCases: action.data,
      };

    default:
      return state;
  }
};

export default reducer;
