const INITIAL_STATE = {
    orderList: {},
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "GET_ORDER_LIST":
            return {
                ...state,
                orderList: action.data,
            };
        default:
            return state;
    };
};

export default reducer;