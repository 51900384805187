import React from "react";

// css
import "./FrontActivityShare.css"

// component
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";

const FrontActivityShare = () => {
  return (
    <div class="frontActivityShareMain" style={{ marginBottom: 50 }}>
      <div class="frontActivityShareMainStart">
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
      </div>
    </div>
  );
}

export default FrontActivityShare;
