import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { actionCreators } from "src/actions/auth";

// Ant design
import { Layout, Form, Input, Button, Image, Row, Col, Card, Typography } from "antd";
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import "./Login.css";
import logo from 'src/assets/JetShop_logo_orange.png';

const RootLogin = () => {
    const { Text } = Typography;

    const [rules] = useState({
        merAdmAcct: [
            {required: true, message: "請輸入帳號", trigger: "blur"},
        ],
        merAdmPwd: [
            {required: true, message: "請輸入密碼", trigger: "blur"},
        ],
    });
    const [rootErrorMsg, setRootErrorMsg] = useState("");

    const dispatch = useDispatch();

    // 取出 store 中的 state
    const isRootLogined = useSelector(state => state.auth.isRootLogined);
    const rootLoginErrorMsg = useSelector(state => state.auth.rootErrorMsg);
    
    useEffect(() => {
        setRootErrorMsg(rootLoginErrorMsg);
    }, [rootLoginErrorMsg]);

    const onFinish = (values) => {
        let userInfo = { ...values, root: true };
        dispatch(actionCreators.rootLogin(userInfo));
    };

    const onFinishFailed = () => false;

    if (isRootLogined) window.location.href = "/root";

    return (
        <Layout className="loginContainer">
            <Row className="loginRow" justify="center" align="middle" gutter={16}>
                <Col xs={18} sm={16} md={10} lg={8} xl={6}>
                    <Image className="loginLogoImg" src={logo} alt="JetShop Logo" preview={false} />
                    <Card>
                        <Form
                          onFinish={onFinish}
                          onFinishFailed={onFinishFailed}
                        >
                            <div className="loginText">
                                Jetshop 維護系統登入
                            </div>

                            <Form.Item 
                              className="loginFormItem"
                              name="merAdmAcct" 
                              rules={rules.merAdmAcct}
                            >
                                <Input placeholder="帳號" prefix={<UserOutlined />} />
                            </Form.Item>

                            <Form.Item 
                              className="loginFormItem"
                              name="merAdmPwd" 
                              rules={rules.merAdmPwd}
                            >
                                <Input.Password placeholder="密碼" prefix={<LockOutlined />} />
                            </Form.Item>

                            <Text type="danger">{rootErrorMsg}</Text>

                            <Button
                              className="loginBtn"
                              htmlType="submit"
                            >
                                登入
                            </Button>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </Layout>
    );
}

export default RootLogin;
