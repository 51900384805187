import React from "react";

// css
import "./FrontEcommerce.css"

// component
import FrontBreadcrumb from "src/components/Front/Breadcrumb";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";

const FrontHome = () => {
  return (
    <div style={{ marginBottom: 50 }}>
      <FrontBreadcrumb
        link={[
          { href: '', name: "Jet捷店" }
        ]}
      />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
    </div>
  );
}

export default FrontHome;
