import Cookies from 'universal-cookie';

import { authTypes } from 'src/constants';
import { authService } from 'src/services';


const cookies = new Cookies();

// 商家管理後台登入、登出
const login = (payload) => {
  return (dispatch) => {
    authService.login(payload)
      .then(
        res => {
          if (res && res.access_token) {
            setToken(res.access_token);
            setMerAcctId(payload.merAcctId);
            dispatch({type: authTypes.LOGIN, isLogined: true});
            window.location.href = "/admin";
          }
        },
        error => {
          // console.log(error);
          if (error.status === 403) {
            dispatch({type: authTypes.ERROR_MSG, errorMsg: '此帳號無權限'});
          } else if (error.status === 500) {
            dispatch({type: authTypes.ERROR_MSG, errorMsg: '系統操作發生錯誤，請稍後再試'});
          } else {
            dispatch({type: authTypes.ERROR_MSG, errorMsg: '資料有誤，請確認商家代碼、帳號、密碼是否填寫正確'});
          }
          logout();
        }
      );
  }
};

const setToken = (token) => {
  cookies.set('_merchant_auth_token', token, { path: '/' });
};

const setMerAcctId = (id) => {
  cookies.set('_merAcctId', id, { path: '/' });
};

const logout = () => async (dispatch) => {
  cookies.remove('_merchant_auth_token', { path: '/' });
  cookies.remove('_merchant_auth_token', { path: '/admin' });
  cookies.remove('_merAcctId', { path: '/' });
  cookies.remove('_merAcctId', { path: '/admin' });
  dispatch({type: authTypes.LOGIN, isLogined: false});
  window.location.href = "/admin/login";
};

// 維護系統登入、登出
const rootLogin = (payload) => {
  return (dispatch) => {
    authService.login(payload)
      .then(
        res => {
          if (res && res.access_token) {
            setRootToken(res.access_token);
            dispatch({type: authTypes.ROOT_LOGIN, isRootLogined: true});
            window.location.href = "/root";
          }
        },
        error => {
          console.log(error);
          dispatch({type: authTypes.ROOT_ERROR_MSG, rootErrorMsg: "資料有誤，請確認帳號、密碼是否填寫正確"});
          rootLogout();
        }
      );
  }
};

const setRootToken = (token) => {
  cookies.set("_root_auth_token", token);
};

const rootLogout = () => async (dispatch) => {
  cookies.remove("_root_auth_token", { path: "/root" });
  dispatch({type: authTypes.ROOT_LOGIN, isRootLogined: false});
  window.location.href = "/root/login";
};

// 取得帳號列表
const getUsersList = (payload) => {
  return (dispatch) => {
    authService.getUsersList(payload)
      .then(
        res => {
          res.json().then(result => {
            dispatch({type: authTypes.GET_USERS_LIST, data: result});
          })
        },
        error => {
          console.log(error);
        }
      );
  }
};

// 取得單一帳號資訊
// const getAccount = (merAdmSerNo) => {
//   return (dispatch) => {
//     authService.getAccount(merAdmSerNo)
//       .then(
//         res => {
//           res.json().then(result => {
//             console.log(result);
//             // dispatch({type: authTypes.GET_USERS_LIST, data: result});
//           })
//         },
//         error => {
//           console.log(error);
//         }
//       );
//   }
// };

// 新增帳號
const addAccount = (payload) => {
  return (dispatch) => {
    authService.addAccount(payload)
      .then(
        res => {
          res.json().then(result => {
            console.log(result);
            // dispatch({type: authTypes.GET_USERS_LIST, data: result});
          })
        },
        error => {
          console.log(error);
        }
      );
  }
};

// 修改帳號
const editAccount = (merAdmSerNo, payload) => {
  return (dispatch) => {
    authService.editAccount(merAdmSerNo, payload)
      .then(
        res => {
          res.json().then(result => {
            console.log(result);
            // dispatch({type: authTypes.GET_USERS_LIST, data: result});
          })
        },
        error => {
          console.log(error);
        }
      );
  }
};


export const actionCreators = {
  login,
  logout,
  rootLogin,
  rootLogout,
  getUsersList,
  // getAccount,
  addAccount,
  editAccount
}