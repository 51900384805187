import React from "react";
import { useSelector } from "react-redux";

// antd & css
import { Row, Col } from "antd";
import "./index.css";

// images
import iconLine from "src/assets/jet/icon_line.png";
import iconFB from "src/assets/jet/icon_fb.png";
import iconIG from "src/assets/jet/icon_ig.png";
import iconWeb from "src/assets/jet/icon_web.png";

const Footer = (props) => {
  // 取出 store 中的 state
  const frontShopInfo = useSelector(state => state.frontJet.frontShopInfo);
  const shopPreviewInfo = useSelector(state => state.jet.shopPreviewInfo);
  
  if (props.pageType === "front") {
    return (
      <div>
        <Row className="jet-footer-content">
          {
            frontShopInfo.shopInfo && frontShopInfo.shopInfo.shopInfoLogo &&
            <Col className="d-flex justify-start align-center" span={22} md={4}>
              <img className="jet-footer-logo" src={frontShopInfo.shopInfo.shopInfoLogo} alt="Logo" />
            </Col>
          }
          {
            frontShopInfo.shopInfo && frontShopInfo.shopInfo.shopInfoName &&
            <Col span={22} md={5}>
              <p className="title">| 店名</p>
              <p className="content">{frontShopInfo.shopInfo.shopInfoName}</p>
            </Col>
          }
          {
            frontShopInfo.shopInfo && frontShopInfo.shopInfo.shopInfoAddress &&
            <Col span={22} md={5}>
              <p className="title">| 地址</p>
              <p className="content">{frontShopInfo.shopInfo.shopInfoAddress}</p>
            </Col>
          }
          {
            frontShopInfo.shopInfo && frontShopInfo.shopInfo.shopInfoPhone &&
            <Col span={22} md={5}>
              <p className="title">| 電話</p>
              <p className="content">{frontShopInfo.shopInfo.shopInfoPhone}</p>
            </Col>
          }
          {
            frontShopInfo.shopInfo && 
            (frontShopInfo.shopInfo.shopInfoLineURL 
              || frontShopInfo.shopInfo.shopInfoFacebookURL
              || frontShopInfo.shopInfo.shopInfoInstagramURL
              || frontShopInfo.shopInfo.shopInfoOtherURL
            ) &&
            <Col span={22} md={5}>
              <p className="title">| 官方社群</p>
              {frontShopInfo.shopInfo.shopInfoLineURL && <a 
                href={frontShopInfo.shopInfo.shopInfoLineURL}
                target="_blank"
                rel="noreferrer noopener"
              >
                <img className="content" src={iconLine} alt="Line" width={30} />
              </a>}
              {frontShopInfo.shopInfo.shopInfoFacebookURL && <a 
                href={frontShopInfo.shopInfo.shopInfoFacebookURL}
                target="_blank"
                rel="noreferrer noopener"
              >
                <img className="content" src={iconFB} alt="Facebook" width={30} />
              </a>}
              {frontShopInfo.shopInfo.shopInfoInstagramURL && <a 
                href={frontShopInfo.shopInfo.shopInfoInstagramURL}
                target="_blank"
                rel="noreferrer noopener"
              >
                <img className="content" src={iconIG} alt="Instagram" width={30} />
              </a>}
              {frontShopInfo.shopInfo.shopInfoOtherURL && <a 
                href={frontShopInfo.shopInfo.shopInfoOtherURL}
                target="_blank"
                rel="noreferrer noopener"
              >
                <img className="content" src={iconWeb} alt="Web" width={30} />
              </a>}
            </Col>
          }
        </Row>
        <Row className="jet-footer-text" align="center">
          <Col>
            Copyrights © JetShop. All Rights Reserved.
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <div>
      <Row className="jet-footer-content">
        {
          shopPreviewInfo.shopInfo && shopPreviewInfo.shopInfo.shopInfoLogo &&
          <Col className="d-flex justify-start align-center" span={22} md={4}>
            <img className="jet-footer-logo" src={shopPreviewInfo.shopInfo.shopInfoLogo} alt="Logo" />
          </Col>
        }
        {
          shopPreviewInfo.shopInfo && shopPreviewInfo.shopInfo.shopInfoName &&
          <Col span={22} md={5}>
            <p className="title">| 店名</p>
            <p className="content">{shopPreviewInfo.shopInfo.shopInfoName}</p>
          </Col>
        }
        {
          shopPreviewInfo.shopInfo && shopPreviewInfo.shopInfo.shopInfoAddress &&
          <Col span={22} md={5}>
            <p className="title">| 地址</p>
            <p className="content">{shopPreviewInfo.shopInfo.shopInfoAddress}</p>
          </Col>
        }
        {
          shopPreviewInfo.shopInfo && shopPreviewInfo.shopInfo.shopInfoPhone &&
          <Col span={22} md={5}>
            <p className="title">| 電話</p>
            <p className="content">{shopPreviewInfo.shopInfo.shopInfoPhone}</p>
          </Col>
        }
        {
          shopPreviewInfo.shopInfo && 
          (shopPreviewInfo.shopInfo.shopInfoLineURL 
            || shopPreviewInfo.shopInfo.shopInfoFacebookURL
            || shopPreviewInfo.shopInfo.shopInfoInstagramURL
            || shopPreviewInfo.shopInfo.shopInfoOtherURL
          ) &&
          <Col span={22} md={5}>
            <p className="title">| 官方社群</p>
            {shopPreviewInfo.shopInfo.shopInfoLineURL && <a 
              href={shopPreviewInfo.shopInfo.shopInfoLineURL}
              target="_blank"
              rel="noreferrer noopener"
            >
              <img className="content" src={iconLine} alt="Line" width={30} />
            </a>}
            {shopPreviewInfo.shopInfo.shopInfoFacebookURL && <a 
              href={shopPreviewInfo.shopInfo.shopInfoFacebookURL}
              target="_blank"
              rel="noreferrer noopener"
            >
              <img className="content" src={iconFB} alt="Facebook" width={30} />
            </a>}
            {shopPreviewInfo.shopInfo.shopInfoInstagramURL && <a 
              href={shopPreviewInfo.shopInfo.shopInfoInstagramURL}
              target="_blank"
              rel="noreferrer noopener"
            >
              <img className="content" src={iconIG} alt="Instagram" width={30} />
            </a>}
            {shopPreviewInfo.shopInfo.shopInfoOtherURL && <a 
              href={shopPreviewInfo.shopInfo.shopInfoOtherURL}
              target="_blank"
              rel="noreferrer noopener"
            >
              <img className="content" src={iconWeb} alt="Web" width={30} />
            </a>}
          </Col>
        }
      </Row>
      <Row className="jet-footer-text" align="center">
        <Col>
          Copyrights © JetShop. All Rights Reserved.
        </Col>
      </Row>
    </div>
  );
}

export default Footer;
