import React, { useState, useEffect } from 'react';
import { productService } from "src/services";

import { Row, Col, Upload, Modal, Typography, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';


const UploadImg = (props) => {
  const { Text } = Typography;

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    setFileList(props.images);
  }, [props]);

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('上傳檔案類型錯誤');
      return Upload.LIST_IGNORE;
    }
    const isLt2M = file.size / 1024 / 1024 < 6;
    if (!isLt2M) {
      message.error('檔案大小須小於6MB');
      return Upload.LIST_IGNORE
    }
    return false;
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const handleChange = ({fileList}) => {
    setFileList(fileList);
    props.parentCallback(fileList);
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  const handleRemove = (item) => {
    // 判斷已存在 DB 中的圖片才需打 API 刪除
    if (item && item.url && item.prodNo) {
      // 將原本已經存在 DB 中的圖片，直接刪除
      const { confirm } = Modal;
      return new Promise((resolve, reject) => {
        confirm({
          title: '確定要刪除圖片?',
          onOk: () => {
            const { prodNo, docType, photoSerNo } = item;

            productService.deleteProductImg(prodNo, docType, photoSerNo)
              .then(
                res => {
                  if (res.status === 200 && res.ok) {
                    resolve(true);
                  } else {
                    return false;
                  }
                }
              )
              .catch(error => {
                console.log(error)
              })
          },
          onCancel: () =>{
            reject(true);
         }
        });
      });
    }
  };


  return (
    <Row>
      <Col span={24} style={{ marginBottom: 10 }}>
        <Text type="danger">
          上傳檔案格式須為：jpg、jpeg、png，單一檔案上限：6MB
        </Text>
      </Col>
      <Col span={24}>
        <Upload
          action={undefined}
          listType="picture-card"
          fileList={fileList}
          beforeUpload={beforeUpload}
          onPreview={handlePreview}
          onChange={handleChange}
          onRemove={handleRemove}
          maxCount={5}
          multiple
        >
          {fileList.length >= 5 ? null : <PlusOutlined />}
        </Upload>
        <Modal
          visible={previewVisible}
          // title={previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </Col>
    </Row>
  )
}

export default UploadImg;