import React from "react";

// antd
import { Row, Col } from "antd";

import ecommerceLogo from "src/assets/ecommerce/logo_ecommerce.png";
import btnPointer from "src/assets/btn_pointer.png";

const Section1 = () => {
  return (
    <Row className="frontEcommerceSection1">
      <Col span={24} className="frontEcommerceIntro">
        <img src={ecommerceLogo} alt="Jet捷店" />
        <h2 className="front">
          <p>0元開店，一秒變電商，</p>
          <p>升級顧客的購物體驗，銷售業績大幅成長</p>
        </h2>
      </Col>
      <Col span={24} className="frontEcommerceFeature">
        <h2>
          <ul>
            <li>一頁式商店</li>
            <li>免程式串接</li>
            <li>免開店費用</li>
            <li>加值數位導流</li>
          </ul>
        </h2>
        <a href="/merchant/apply">
          <button className="frontBtn" style={{ width: 140, marginBottom: "4em" }}>
            馬上體驗
            <img src={btnPointer} alt="pointer" style={{ width: 20, marginLeft: 6 }} />
          </button>
        </a>
      </Col>
    </Row>
  );
}

export default Section1;
