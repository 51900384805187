import React from "react";

// antd & css
import { Row, Col, Card } from "antd";
import "./Merchant.css";

// components
import FrontBreadcrumb from "src/components/Front/Breadcrumb";
import FormItem from "./FormItem";
import Result from "./Result";

const FrontMerchant = () => {
  return (
    <div style={{ marginBottom: "50px" }}>
      <Result />
      <FrontBreadcrumb
        link={[
          { href: '', name: "商家註冊" }
        ]}
      />
      <div className="frontTitleImage">
        <h1 className="frontTitle">
          <span className="linearText">商</span>家註冊
        </h1>
      </div>
      <Row justify="center">
        <Col span={14} xl={12} md={14} sm={22} xs={22}>
          <Card className="frontCard">
            <p><span className="requiredText">*</span> 為必填欄位，請務必詳實填寫</p>
            <div className="contentLeft">
              <FormItem />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default FrontMerchant;
