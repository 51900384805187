import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { actionCreators } from "src/actions/jet";
import { jetService } from "src/services";
import { HOME_URL } from "src/config";

// antd
import { Row, Col, Tabs, Button, Modal, message, Input } from "antd";
import { CheckCircleFilled, CopyOutlined } from "@ant-design/icons";

// component
import Activity from "../Front/Jet/Activity";
import ProductList from "../Front/Jet/ProductList";
import ShopNote from "../Front/Jet/ShopNote";

const FrontJet = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [products, setProducts] = useState({data: []});

  const dispatch = useDispatch();

  // 取出 store 中的 state
  const shopPreviewInfo = useSelector(state => state.jet.shopPreviewInfo);

  // action: 取得商店預覽
  useEffect(() => {
    dispatch(actionCreators.getShopPreviewInfo());
  }, []);

  useEffect(() => {
    if (shopPreviewInfo && shopPreviewInfo.products) {
      setProducts({data: shopPreviewInfo.products});
    }
  }, [shopPreviewInfo]);

  const closeWindow = () => {
    window.close();
  };

  const publishShop = () => {
    let payload = {
      shopInfoSerialNumber: shopPreviewInfo.shopInfo.shopInfoSerialNumber,
      saSerialNumber: shopPreviewInfo.shopActivity ? shopPreviewInfo.shopActivity.saSerialNumber : undefined
    };

    jetService.publishShop(payload)
    .then(
      res => {
        if (res.status === 201) {
          setIsVisible(true);
        } else if (res.status === 401) {
          message.warning("存取拒絕");
        } else if (res.status === 404) {
          message.warning("找不到商店資訊");
        } else {
          message.error("發佈失敗");
        }
      },
      error => {
        console.log(error);
      },
    );
  };

  const copyToClipboard = () => {
    // 複製連結
    const el = document.createElement('input');
    el.value = `${HOME_URL}/${shopPreviewInfo.shopRouteName}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    message.success("複製成功");
  };

  return (
    <div>
      <div
        style={{
          position: "fixed",
          right: 0,
          margin: "10px 0",
          zIndex: 999
        }}
      >
        <Button
          className="jet-btn-filled"
          shape="round"
          style={{ marginRight: 10 }}
          onClick={publishShop}
        >
          確認發佈
        </Button>
        <Button
          className="jet-btn"
          shape="round"
          style={{ marginRight: 20 }}
          onClick={closeWindow}
        >
          關閉預覽模式
        </Button>
      </div>
      <div className="jet-bg" style={{ paddingTop: 50 }}>
        <h1>{shopPreviewInfo.shopInfo && shopPreviewInfo.shopInfo.shopInfoName}</h1>
        <h2>{shopPreviewInfo.shopInfo && shopPreviewInfo.shopInfo.shopInfoSlogan}</h2>
      </div>
      <Activity isAdmin={true} shopActivity={shopPreviewInfo.shopActivity} />
      <Row justify="center" style={{ marginTop: 20 }}>
        <Col span={22} style={{ marginBottom: 50 }}>
          <Tabs className="front-tab" centered>
            <Tabs.TabPane tab="商品列表" key="0">
              <ProductList isAdmin={true} shopProducts={products} shopProdType={shopPreviewInfo.productTypes} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="購物須知" key="1">
              <ShopNote shopInfoNotice={shopPreviewInfo.shopInfo && shopPreviewInfo.shopInfo.shopInfoNotice} />
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>

      {/* 發佈結果跳窗 */}
      <Modal
        className="front"
        visible={isVisible}
        onCancel={() => setIsVisible(false)}
        maskClosable={false}
        footer={false}
        width={350}
      >
        <Row justify="center" style={{ paddingTop: "1em" }}>
          <div className="success">
            <CheckCircleFilled
              style={{ color: "#52c41a", fontSize: "150%" }}
            />
            <h4 className="submitSuccessText">發佈成功</h4>
          </div>
        </Row>
        <Row style={{ padding: "2em" }}>
          <Input.Group compact>
            <Input
              style={{
                width: "calc(100% - 41px)",
              }}
              value={`${HOME_URL}/${shopPreviewInfo.shopRouteName}`}
            />
            <Button icon={<CopyOutlined />} onClick={copyToClipboard} />
          </Input.Group>
        </Row>
      </Modal>
    </div>
  );
}

export default FrontJet;
