const INITIAL_STATE = {
  modalEditFAQTypeVisible: false,
  modalAnswerVisible: false,
  FAQList: [],
  FAQTypeList: [],
  FAQDetail: {},
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "MODAL_EDIT_FAQ_TYPE_VISIBLE":
      return {
        ...state,
        modalEditFAQTypeVisible: !state.modalEditFAQTypeVisible,
      };
    case "MODAL_ANSWER_VISIBLE":
      return {
        ...state,
        modalAnswerVisible: !state.modalAnswerVisible,
      };
    case "SET_FAQ_DETAIL":
      return {
        ...state,
        FAQDetail: action.data,
      };
    case "GET_FAQ_LIST":
      return {
        ...state,
        FAQList: action.data
      };
    case "GET_FAQ_TYPE":
      return {
        ...state,
        FAQTypeList: action.data
      };
    default:
      return state;
  }
};

export default reducer;