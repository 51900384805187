import React from "react";

// antd
import { Row, Col } from "antd";

// component
import FrontDivJcoin from "src/components/Front/Divider/DivJcoin";

const giftGroups = [
  [
    { id: "01", name: "iPhone 14 128G", jcoin: 10, amount: 1 },
    { id: "02", name: "Black shark 5 PRO(12+256G)", jcoin: 10, amount: 1 },
    { id: "03", name: "SONY PlayStation 5", jcoin: 10, amount: 1 },
  ],
  [
    { id: "04", name: "Nintendo Switch OLED主機", jcoin: 9 },
    { id: "05", name: "ViewSonic VX3218C-2K 32型 1500R曲面電競螢幕", jcoin: 9 },
    { id: "06", name: "AirPods Pro 2", jcoin: 9 },
  ],
  [
    { id: "07", name: "SAMSUNG Galaxy Tab A8(4+64G)", jcoin: 8 },
    { id: "08", name: "AVITA SATUS T101 4G雙卡雙待平板(6G/128G)", jcoin: 8 },
    { id: "09", name: "Nintendo Switch Lite", jcoin: 8 },
  ],
  [
    { id: "10", name: "AirPods 3", jcoin: 7 },
    { id: "11", name: "SanDisk E61 Extreme Portable SSD 2TB 行動固態硬碟", jcoin: 7 },
    { id: "12", name: "Beats Studio Buds真無線降噪入耳式耳機", jcoin: 7 },
  ],
  [
    { id: "13", name: "Razer Basilisk Ultimate 巴塞利斯蛇 終極版 無線電競滑鼠", jcoin: 6 },
    { id: "14", name: "Arowana 極致川旅29吋立體拉絲鋁框避震輪 旅行箱/行李箱/登機箱", jcoin: 6 },
    { id: "15", name: "SanDisk E61 Extreme Portable SSD 1TB 行動固態硬碟", jcoin: 6 },
  ],
  [
    { id: "16", name: "Apple HomePod mini", jcoin: 5 },
    { id: "17", name: "PRINCESS 荷蘭公主 3.5公升健康氣炸鍋", jcoin: 5 },
    { id: "18", name: "CHIMEI QI無線充電/USB充電LED護眼檯燈(LT-WP100D)", jcoin: 5 },
  ],
  [
    { id: "19", name: "Sony 防水/防塵重低音輕便藍牙喇叭", jcoin: 4 },
    { id: "20", name: "Panasonic 奈米水離子吹風機(EH-NA27-PP)", jcoin: 4 },
    { id: "21", name: "Panasonic 國際牌 音波電動牙刷", jcoin: 4 },
  ],
  [
    { id: "22", name: "Logitech 超薄跨平台 藍牙鍵盤", jcoin: 3 },
    { id: "23", name: "Xiaomi 手環 7", jcoin: 3 },
    { id: "24", name: "丹比DANBY 1.5L智慧 溫控快煮壺", jcoin: 3 },
  ],
  [
    { id: "25", name: "E-books Z4 美型無線 鍵盤滑鼠組", jcoin: 2 },
    { id: "26", name: "E-books SS29 藍牙經典款 摺疊耳罩式耳機", jcoin: 2 },
    { id: "27", name: "adidas LOGO 健身包 XS 男/女(GN1925)", jcoin: 2 },
  ],
  [
    { id: "28", name: "英國熊超真空冰壩杯950ml", jcoin: 1 },
    { id: "29", name: "ANTIAN不鏽鋼馬克杯410ml", jcoin: 1 },
    { id: "30", name: "天天 成人平面口罩(50入/盒)", jcoin: 1 },
  ]
];

const Section2 = () => {
  return (
    <Row justify="center" style={{ marginTop: 70 }}>
      <Col span={20} id="gift-list">
        <FrontDivJcoin title={"兌換禮品列表"} />
      </Col>
      {
        giftGroups.map((group, index) => (
          <div key={index} className="jcoinGiftGroup" style={{ marginTop: 20 }}>
            {
              group.map(item => (
                <div key={item.id} className="jcoinGiftList">
                  <div className="jcoinGiftCard">
                    <div className="d-flex justify-center">
                      <div className="jcoinGiftImg">
                        <span className="jcoinNum">{item.jcoin} Jcoin兌換</span>
                        <img src={require(`src/assets/activity/Jcoin_gift_${item.id}.png`)} alt={item.name} />
                      </div>
                    </div>
                    <div className="text-center" style={{ marginTop: 20 }}>
                      <span style={{ fontSize: 16 }}>{item.name}</span>
                      {
                        item.amount &&
                        <div className="giftAmount">僅有{item.amount}台，兌完為止</div>
                      }
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        ))
      }
    </Row>
  );
}

export default Section2;
