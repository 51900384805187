import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { actionCreators as actionRootCreators } from "src/actions/root";
import { FAQService } from "src/services";

// ant design
import { Modal, Form, Input, Radio, Divider, Button, message } from "antd";

import TextEditor from 'src/components/Editor';

const rules = {
  FAQQuestion: [
    {required: true, message: "請輸入FAQ問題", trigger: "blur"},
  ],
  FAQStatus: [
    {required: true},
  ],
};

const AddFAQ = (props) => {
  const [form] = Form.useForm();
  const [isEdit, setIsEdit] = useState(false);
  const [FAQTypeName, setFAQTypeName] = useState("");
  // for text editor
  const [FAQAnswerString, setFAQAnswerString] = useState('');

  // 取出 store 中的 state
  const isVisible = useSelector(state => state.Root.modalVisible);
  const FAQTypeList = useSelector(state => state.FAQ.FAQTypeList);
  const FAQDetail = useSelector(state => state.FAQ.FAQDetail);

  const dispatch = useDispatch();

  // FAQ類型配值
  useEffect(() => {
    if (FAQTypeList.length > 0) {
      const type = FAQTypeList.filter(item => item.FAQTypeSerialNumber === props.FAQType);
      setFAQTypeName(type[0].FAQTypeName);
    }
  }, [props]);

  useEffect(() => {
    if (isVisible) {
      if (Object.keys(FAQDetail).length) {
        // 編輯
        setIsEdit(true);
        form.setFieldsValue({
          FAQQuestion: FAQDetail.FAQQuestion,
          FAQStatus: FAQDetail.FAQStatus,
        });

        setFAQAnswerString(FAQDetail.FAQAnswer);

      } else {
        // 新增
        setIsEdit(false);
        // 清空所有資訊
        form.setFieldsValue({
          FAQQuestion: "",
          FAQStatus: 0,
        });

        setFAQAnswerString("");
      }
    }
  }, [isVisible]);

  // 編輯框
  const editorCallBack = (htmlString) => {
    setFAQAnswerString(htmlString);
  };

  // 儲存
  const onFinish = (values) => {
    if (!FAQAnswerString) {
      message.warning("解答為必填欄位");
      return;
    }

    const FAQTypeSerialNumber = props.FAQType;

    const payload = {
      FAQQuestion: values.FAQQuestion,
      FAQAnswer: FAQAnswerString,
      FAQStatus: values.FAQStatus,
    };

    if (isEdit) {
      // 編輯
      FAQService.editFAQ(FAQDetail.FAQSerialNumber, payload)
        .then(
          res => {
            if (res.status === 204) {
              message.success("編輯成功");
              setFAQAnswerString("");
              // 關閉彈跳視窗 & 重新取得 FAQ
              props.getFAQData(FAQTypeSerialNumber);
              dispatch(actionRootCreators.modalVisible());
            }
            else if (res.status === 401) message.warning("存取拒絕");
            else if (res.status === 404) message.warning("找不到 FAQ");
            else message.error("編輯失敗");
          },
          error => {
            console.log(error);
          },
        );

    } else {
      // 新增
      payload.FAQTypeSerialNumber = FAQTypeSerialNumber;
      FAQService.addFAQ(payload)
      .then(
        res => {
          if (res.status === 201) {
            message.success("新增成功");
            setFAQAnswerString("");
            // 關閉彈跳視窗 & 重新取得 FAQ
            props.getFAQData(FAQTypeSerialNumber);
            dispatch(actionRootCreators.modalVisible());
          } 
          else if (res.status === 401) message.warning("存取拒絕");
          else if (res.status === 404) message.warning("找不到 FAQ 類型");
          else message.error("新增失敗");
        },
        error => {
          console.log(error);
        },
      );
    }
  };

  // 儲存失敗
  const onFinishFailed = () => false;

  // 取消
  const handleCancel = () => {
    setFAQAnswerString("");
    dispatch(actionRootCreators.modalVisible());
  };

  return (
    <Modal
      title={isEdit ? "編輯" : "新增" }
      visible={isVisible} 
      onCancel={handleCancel} 
      maskClosable={false}
      destroyOnClose
      footer={false}
    >
      <Form
        form={form}
        labelAlign="left"
        labelCol={{flex: "100px"}}
        wrapperCol={{span: 24}}
        colon={false}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        >
        {/* initialValues={{FAQType: 1, FAQStatus: 0}} */}
        <div className="rootFormItem">
          <Form.Item label="FAQ類別" name="FAQType">
            <span>{FAQTypeName}</span>
          </Form.Item>
          <Form.Item label="問題" name="FAQQuestion" rules={rules.FAQQuestion}>
            <Input.TextArea showCount maxLength={300}/>
          </Form.Item>
          <Form.Item label="答案" name="FAQAnswer">
            <TextEditor
              textString={FAQAnswerString}
              parentCallback={editorCallBack}
              editorType={'withImg'}
              imgStorage={'FAQ'}
            />
          </Form.Item>
          <Form.Item label="FAQ狀態" name="FAQStatus" rules={rules.FAQStatus}>
            <Radio.Group>
              <Radio value={0}>顯示</Radio>
              <Radio value={1}>隱藏</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        <Divider style={{margin: 0}} />
        <div style={{textAlign: "end", padding: "10px 16px"}}>
          <Button onClick={handleCancel} style={{marginRight: 10}}>取消</Button>
          <Button type="primary" htmlType="submit">儲存</Button>
        </div>
      </Form>
    </Modal>
  );
}

export default AddFAQ;
