import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { actionCreators } from "src/actions/FAQ";
import { FAQService } from "src/services";

// ant design
import { Modal, Input, Tooltip, Button, message } from "antd";
import { CheckCircleOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const EditFAQType = () => {
  const [FAQType, setFAQType] = useState([]);

  // in order to set input default value
  const inputRef = useRef(null);

  // 取出 store 中的 state
  const isVisible = useSelector(state => state.FAQ.modalEditFAQTypeVisible);
  const FAQTypeList = useSelector(state => state.FAQ.FAQTypeList);

  const dispatch = useDispatch();

  // FAQ類型配值
  useEffect(() => {
    let type = [];
    if (FAQTypeList && FAQTypeList.length >= 1) {
      FAQTypeList.forEach(item => {
        type.push({
          FAQTypeSerialNumber: item.FAQTypeSerialNumber,
          FAQTypeName: item.FAQTypeName,
          isSaved: true,
        });
      });
    };
    setFAQType(type);
  }, [FAQTypeList, isVisible]);

  // 關閉視窗
  const handleCancel = () => {
    dispatch(actionCreators.modalEditFAQTypeVisible());
  };

  // 新增input欄位
  const addField = () => {
    const lastFAQTypeSerialNumber = FAQType[FAQType.length - 1].FAQTypeSerialNumber;
    setFAQType(
      [
        ...FAQType, 
        {
          FAQTypeSerialNumber: lastFAQTypeSerialNumber + 1,
          FAQTypeName: undefined,
          isSaved: false,
        }
      ]
    );
  };

  // 偵測input文字的改變
  const changeFieldContent = (index, e) => {
    // 改變array中的值
    let copyItems = FAQType;
    let changeItem = {...copyItems[index]};
    changeItem.FAQTypeName = e.target.value;
    copyItems[index] = changeItem;
    setFAQType(copyItems);
  };

  // 新增或修改FAQ類型
  const save = (index) => {
    const FAQTypeName = FAQType[index].FAQTypeName;
    let checkSpace = new RegExp("^[ ]+$");
    if (FAQTypeName === undefined || FAQTypeName.length === 0 || checkSpace.test(FAQTypeName)) {
      message.warning("請輸入FAQ類型");
    } else {
      // 判斷新增或修改
      const payload = { FAQTypeName: FAQTypeName }
      if (!FAQType[index].isSaved) {
        FAQService.addFAQType(payload)
        .then(
          res => {
            res.json().then(result => {
              if (res.status === 201) {
                // 與後端傳回的FAQTypeSerialNumber同步
                let copyItems = FAQType;
                let changeItem = {...copyItems[index]};
                changeItem.FAQTypeSerialNumber = result.FAQTypeSerialNumber;
                changeItem.isSaved = true;
                copyItems[index] = changeItem;
                setFAQType(copyItems);
                message.success("新增成功");
              }
              else if (res.status === 401) message.warning("存取拒絕");
              else if (res.status === 409) message.warning("FAQ 類型已存在");
              else message.error("新增失敗");
            });
          },
          error => {
            console.log(error);
          },
        );
        dispatch({type: "GET_FAQ_TYPE", data: FAQType});
      } else {
        FAQService.editFAQType(FAQType[index].FAQTypeSerialNumber, payload)
        .then(
          res => {
            if (res.status === 204) message.success("修改成功");
            else if (res.status === 401) message.warning("存取拒絕");
            else if (res.status === 404) message.warning("找不到 FAQ 類型");
            else if (res.status === 409) message.warning("FAQ 類型已存在");
            else message.error("修改失敗");
          }, 
          error => {
            console.log(error);
          },
        );
      }
    }
  };

  // 刪除FAQ類型
  const deleteFAQType = (index) => {
    const isSaved = FAQType[index].isSaved;
    if (!isSaved) {
      updateFields(index);
    } else {
      const FAQTypeSerialNumber = FAQType[index].FAQTypeSerialNumber;
      FAQService.deleteFAQType(FAQTypeSerialNumber)
      .then(
        res => {
          if (res.status === 204) {
            updateFields(index);
            message.success("刪除成功");
          }
          else if (res.status === 401) message.warning("存取拒絕");
          else if (res.status === 403) message.warning("請先清除類型底下的 FAQ，再進行刪除");
          else if (res.status === 404) {
            updateFields(index);
          }
          else message.error("刪除失敗");
        },
        error => {
          console.log(error);
        },
      );
    }
  };

  // 前端更新畫面
  const updateFields = (index) => {
    const newArr = FAQType.filter((item, idx) => idx !== index);
    setFAQType(newArr);
  }

  // render input
  const getFields = () => {
    const children = [];
    if (FAQType && FAQType.length >= 1) {
      FAQType.map((item, index) => {
        children.push(
          <div key={item.FAQTypeSerialNumber}>
            <Input
              ref={inputRef}
              placeholder="請輸入類型" 
              defaultValue={item.FAQTypeName}
              onChange={(e) => changeFieldContent(index, e)}
              style={{width: FAQType.length > 1 ? "80%" : "90%", marginBottom: "16px"}} 
            />
            <Tooltip placement="bottom" title="儲存">
              <CheckCircleOutlined 
                onClick={() => save(index)}
                style={{marginLeft: "16px", color: "#1890FF"}}
              />
            </Tooltip>
            <Tooltip placement="bottom" title="刪除">
              {FAQType.length > 1 ? (
                <MinusCircleOutlined
                  onClick={() => deleteFAQType(index)}
                  style={{marginLeft: "16px", color: "red"}}
                />
              ) : null}
            </Tooltip>
          </div>
        );
      });
      return children;
    };
  };

  return (
    <Modal
      title="編輯類型" 
      visible={isVisible} 
      onCancel={handleCancel} 
      maskClosable={false}
      destroyOnClose
      footer={false}
    >
      <div style={{overflowY: "scroll", maxHeight: "450px"}}>
        <div className="rootFormItem">
          <div>
            <p style={{color: "red"}}>新增/編輯完畢後，需按下 <CheckCircleOutlined /> 才可儲存</p> 
          </div>
          {/* 動態生成欄位 */}
          {getFields()}
          {/* 新增類型按鈕 */}
          <Button
            onClick={addField}
            type="dashed"
            icon={<PlusOutlined />}
            style={{width: FAQType.length > 1 ? "80%" : "90%", marginBottom: "24px"}}
          >
            新增類型
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default EditFAQType;
