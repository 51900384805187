import { FAQTypes } from 'src/constants';
import { FAQService } from 'src/services';

// 變更 Modal 顯示/隱藏
const modalEditFAQTypeVisible = () => {
  return (dispatch) => {
    dispatch({type: FAQTypes.MODAL_EDIT_FAQ_TYPE_VISIBLE, data: null});
  };
};

const modalViewAnswerVisible = () => {
  return (dispatch) => {
    dispatch({type: FAQTypes.MODAL_ANSWER_VISIBLE, data: null});
  };
};

// 暫存單筆 FAQ 資訊
const setFAQDetail = (data) => {
  return (dispatch) => {
    dispatch({type: FAQTypes.SET_FAQ_DETAIL, data: data});
  };
};

// 取得FAQ
const getFAQ = (FAQTypeSerialNumber) => {
  return (dispatch) => {
    FAQService.getFAQ(FAQTypeSerialNumber)
    .then(
      res => {
        res.json().then(result => {
          dispatch({type: FAQTypes.GET_FAQ_LIST, data: result.data});
        });
      },
      error => {
        console.log(error);
      }
    );
  };
};

// 取得FAQ類型
const getFAQType = () => {
  return (dispatch) => {
    FAQService.getFAQType()
    .then(
      res => {
        res.json().then(result => {
          dispatch({type: FAQTypes.GET_FAQ_TYPE, data: result.data});
        });
      },
      error => {
        console.log(error);
      }
    );
  };
};

export const actionCreators = {
  modalEditFAQTypeVisible,
  modalViewAnswerVisible,
  setFAQDetail,
  getFAQ,
  getFAQType
}