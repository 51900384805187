const INITIAL_STATE = {
  // 商家資訊
  editingShopInfo: {},
  shopPreviewInfo: {},
  // 活動專區
  tabActiveKey: "1",
  isEdit: false,
  editingShopActivity: {},
  shopActivityProducts: [],
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_EDITING_SHOP_INFO":
      return {
        ...state,
        editingShopInfo: action.data
      };
    case "GET_SHOP_PREVIEW_INFO":
      return {
        ...state,
        shopPreviewInfo: action.data
      };
    case "GET_SHOP_ROUTE_NAME":
      return {
        ...state,
        editingShopInfo: {
          ...state.editingShopInfo,
          shopRouteName: action.data
        }
      };
    case "TAB_ACTIVE_KEY":
      return {
        ...state,
        tabActiveKey: action.data
      };
    case "IS_EDIT":
      return {
        ...state,
        isEdit: action.data
      };
    case "GET_EDITING_SHOP_ACTIVITY":
      return {
        ...state,
        editingShopActivity: action.data
      };
    case "GET_SHOP_ACTIVITY_PRODUCTS":
      return {
        ...state,
        shopActivityProducts: action.data
      };
    default:
      return state;
  }
};

export default reducer;