import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { actionCreators } from "src/actions/merchant";
import { merchantService } from "src/services";

import { format } from 'src/utils/format';
import { zipCode } from 'src/utils/zipCode';

// Ant design
import { Layout, Row, Col, Card, Divider,
         Form, Select, Input, Button, Alert } from "antd";

import './Merchant.css';


const Merchant = () => {
  const [form] = Form.useForm();
  const [cityList, setCityList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [defaultCity] = useState("");
  const [defaultDist, setDefaultDist] = useState("");
  const [alertStautus, setAlertStatus] = useState({message: null, type: null});

  const [merFields] = useState([
    { 'name': 'merCompName', 'chName': '公司名稱', 'type': 'text', 'required': false },
    { 'name': 'merMainProdList', 'chName': '主要銷售產品', 'type': 'text', 'required': false },
    { 'name': 'merCapital', 'chName': '資本額', 'type': 'text', 'required': false, 'unit': '萬元' },
    { 'name': 'merStartDate', 'chName': '成立日期', 'type': 'date', 'required': false },
    { 'name': 'merBusAddr', 'chName': '營業地址', 'type': 'addr-text', 'required': false },
    { 'name': 'merVatNo', 'chName': '統一編號', 'type': 'text', 'required': false },
    { 'name': 'merPhone', 'chName': '公司電話', 'type': 'input', 'required': true, 'requiredLen': 20 },
    { 'name': 'merFax', 'chName': '公司傳真', 'type': 'input', 'required': false, 'requiredLen': 20 },
    // { 'name': 'merBus', 'chName': '對帳單與發票寄送地址', 'type': 'addr-input', 'required': true },
  ]);
  const [ownerFields] = useState([
    { 'name': 'merOwnerName', 'chName': '負責人姓名', 'type': 'text', 'required': false },
    { 'name': 'merOwnerId', 'chName': '負責人身份證字號', 'type': 'text', 'required': false },
    { 'name': 'merOwnerRegistAddr', 'chName': '負責人戶籍地址', 'type': 'addr-text', 'required': false },
    { 'name': 'merOwnerBirthDay', 'chName': '負責人出生年月日', 'type': 'date', 'required': false },
    { 'name': 'merOwnerCellPhone', 'chName': '負責人手機', 'type': 'input', 'required': true, 'requiredLen': 20 },
    { 'name': 'merOwnerContPhone', 'chName': '負責人電話', 'type': 'input', 'required': true, 'requiredLen': 20 },
    { 'name': 'merContName', 'chName': '聯絡人姓名', 'type': 'input', 'required': true },
    { 'name': 'merContCellPhone', 'chName': '聯絡人手機', 'type': 'input', 'required': true, 'requiredLen': 20 },
    { 'name': 'merContLineID', 'chName': '聯絡人LineID', 'type': 'input', 'required': false, 'requiredLen': 20 },
    { 'name': 'merContEmail', 'chName': '聯絡人Email', 'type': 'input', 'required': true, 'requiredLen': 50 },
  ]);
  const [bankFields] = useState([
    { 'name': 'merBank', 'chName': '匯款銀行', 'type': 'text', 'required': false },
    { 'name': 'merBranch', 'chName': '匯款分行', 'type': 'text', 'required': false },
    { 'name': 'merBankAcctName', 'chName': '戶名', 'type': 'text', 'required': false },
    { 'name': 'merBankAcctNo', 'chName': '帳號', 'type': 'text', 'required': false },
  ])

  const { Option } = Select;

  const dispatch = useDispatch();

  // 取出 store 中的 state
  const merAcctId = useSelector(state => state.merchant.merAcctId);
  const branchData = useSelector(state => state.merchant.branchData);
  const prodTypeList = useSelector(state => state.merchant.prodTypeList);

  // action : 1.取得帳號列表 2.取得登入帳號單一商家資訊
  useEffect(() => {
    dispatch(actionCreators.getProdTypeList());
    dispatch(actionCreators.getBranchData(merAcctId));
  }, []);

  // 設定初始值
  useEffect(() => {
    let data = JSON.parse(JSON.stringify(branchData));
    data['merBillDistrict'] = data.merBillDistrict
      ? `${data.merBusPostalNo}${data.merBillDistrict}`
      : '';

    form.setFieldsValue({
      ...data
    })
  }, [form, branchData]);

  // 配值
  useEffect(() => {
    let cities = [];
    let dists = [];

    // 初始化縣市選單
    Object.keys(zipCode).map(city => {
      cities.push(<Option key={city}>{city}</Option>);
    });
    setCityList(cities);

    // 初始化鄉鎮市區選單
    if (zipCode[branchData.merBillCity]) {

      zipCode[branchData.merBillCity].map(dist => {
        dists.push(<Option key={dist}>{dist}</Option>);
      });
  
      setDistrictList(dists);
    }
  }, [branchData]);

  const getProdTypeName = (value) => {
    // value: '1,3,4'
    let typesString = '';
    if (value) {
      let typeList = value.split(',');
  
      typeList.map((typeNo, index) => {
        if (prodTypeList.length) {
          let name = prodTypeList.filter(item => (item.appTypeNo == typeNo))[0].appCaseType;
          typesString += name + `${index === typeList.length-1 ? '' : ' / '}`;
        }
      });
    }
    return typesString;
  }

  const onFinish = (formData) => {
    console.log(formData)
    let merBillPostalNo = formData.merBillDistrict.substring(0,3);
    formData.merBillDistrict = formData.merBillDistrict.substring(3);
    let payload = {
      ...formData,
      merBillPostalNo
    };

    merchantService.editBranchData(merAcctId, payload)
      .then(
        res => {
          res.json().then(() => {
            if (res.status === 200) {
              setAlertStatus({
                message: "修改成功",
                type: "success",
              });
            }
          });
        },
        error => {
          console.log(error);
          setAlertStatus({
            message: "修改失敗",
            type: "error",
          });
        },
      );
  };


  const cityChange = (value) => {
    let options = [];
    zipCode[value].map(dist => {
      options.push(<Option key={dist}>{dist}</Option>);
    });

    setDistrictList(options);

    // set district default for first option
    form.setFieldsValue({
      merBillDistrict: options[0]
    });
  };

  const districtChange = (value) => {
    setDefaultDist(value);
  };

  const closeAlert = () => {
    setAlertStatus({
      message: "",
      type: "",
    });
  };

  const address = () => {
    // 對帳單與發票寄送地址
    return (
      <Row>
        <Col className="addrItem" span={3} xs={10} sm={10} md={3}>
          <Form.Item
            name='merBillCity'
            rules={[{ required: true, message: '請輸入縣市' }]}
            value={defaultCity}
          >
            <Select
              placeholder='請選擇縣市'
              onChange={cityChange}
            >
              {cityList}
            </Select>
          </Form.Item>
        </Col>
        <Col className="addrItem" span={4} xs={12} sm={12} md={4}>
          <Form.Item
            name='merBillDistrict'
            rules={[{ required: true, message: '請輸入鄉鎮市區' }]}
          >
            <Select
              onChange={districtChange}
              value={defaultDist}
            >
              {districtList}
            </Select>
          </Form.Item>
        </Col>
        <Col className="addrItem" span={14} xs={24} sm={24} md={14}>
          <Form.Item
            name='merBillAddr'
            rules={[{ required: true, message: '請輸入地址' }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
    )
  };

  return (
    <Layout className="merLayout storeEdit" type="flex" align="left">
      <Row justify="center">
        <Col span={23}>
          <div className="title">
            基本資料維護
          </div>

          <Card className="merLayoutCard storeCard">

            {alertStautus.message 
              ? <Alert message={alertStautus.message} type={alertStautus.type} banner closable afterClose={closeAlert} /> 
              : null
            }

            <Form
              labelCol={{flex: "160px"}}
              labelAlign="left"
              colon={false}
              form={form}
              onFinish={onFinish}
              autoComplete="off"
            >

              <Row className="storeCardContent">

                {/* 公司 */}
                <Divider
                  className="subTitle"
                  orientation="left"
                  orientationMargin="0"
                  style={{margin: '5px 0'}}
                >
                  公司
                </Divider>

                {
                  merFields.map((item, index) => {
                    return (
                      <Col span={12} xs={24} sm={24} md={12} className="storeCardItem" key={index}>
                        {
                          item.type === 'text' &&
                          <Form.Item label={item.chName} name={item.name} rules={[{ required: item.required, message: '' }]}>
                            <span>
                              {
                                item.name === 'merMainProdList' ?
                                getProdTypeName(branchData[item.name])
                                : `${branchData[item.name]}${item.unit || ''}`
                              }
                            </span>
                          </Form.Item>
                        }
                        {
                          item.type === 'addr-text' &&
                          <Form.Item label={item.chName} name={item.name} rules={[{ required: item.required, message: '' }]}>
                            <span>{branchData.merBusCity}{branchData.merBusDistrict}{branchData.merBusAddr}</span>
                          </Form.Item>
                        }
                        {
                          item.type === 'date' &&
                          <Form.Item label={item.chName} name={item.name} rules={[{ required: item.required, message: '' }]}>
                            <span>{format.toROC(branchData[item.name])}</span>
                          </Form.Item>
                        }
                        {
                          item.type === 'input' &&
                          <Form.Item
                            label={item.chName}
                            name={item.name}
                            rules={[
                              { required: item.required, message: `請輸入${item.chName}` },
                              { max: item.requiredLen, message: `長度需小於${item.requiredLen}字元`}
                            ]}
                            style={{ maxWidth: 400 }}
                          >
                            <Input />
                          </Form.Item>
                        }
                      </Col>

                    )
                  })
                }
                <Col span={24} className="storeCardItem" key="address">
                  <Form.Item label="對帳單與發票寄送地址" required>
                    {address()}
                  </Form.Item>
                </Col>


                {/* 負責人及聯絡人 */}
                <Divider
                  className="subTitle"
                  orientation="left"
                  orientationMargin="0"
                  style={{margin: '5px 0'}}
                >
                  負責人及聯絡人
                </Divider>

                {
                  ownerFields.map((item, index) => {
                    return (
                      <Col span={12} xs={24} sm={24} md={12} className="storeCardItem" key={index}>
                        {
                          item.type === 'text' &&
                          <Form.Item label={item.chName} name={item.name} rules={[{ required: item.required, message: '' }]}>
                            <span>{branchData[item.name]}</span>
                          </Form.Item>
                        }
                        {
                          item.type === 'addr-text' &&
                          <Form.Item label={item.chName} name={item.name} rules={[{ required: item.required, message: '' }]}>
                            <span>{branchData.merOwnerRegistCity}{branchData.merOwnerRegistDistrict}{branchData.merOwnerRegistAddr}</span>
                          </Form.Item>
                        }
                        {
                          item.type === 'date' &&
                          <Form.Item label={item.chName} name={item.name} rules={[{ required: item.required, message: '' }]}>
                            <span>{format.toROC(branchData[item.name])}</span>
                          </Form.Item>
                        }
                        {
                          item.type === 'input' &&
                          <Form.Item
                            label={item.chName}
                            name={item.name}
                            rules={[
                              { required: item.required, message: `請輸入${item.chName}` },
                              { max: item.requiredLen, message: `長度需小於${item.requiredLen}字元`},
                              { type: item.name === 'merContEmail' ? 'email' : '', message: 'Email格式錯誤' }
                            ]}
                            style={{ maxWidth: 400 }}
                          >
                            <Input />
                          </Form.Item>
                        }
                      </Col>
                    )
                  })
                }

                {/* 匯款銀行 */}
                <Divider
                  className="subTitle"
                  orientation="left"
                  orientationMargin="0"
                  style={{margin: '5px 0'}}
                >
                  匯款銀行
                </Divider>

                {
                  bankFields.map((item, index) => {
                    return (
                      <Col span={12} xs={24} sm={24} md={12} className="storeCardItem" key={index}>
                        {
                          item.type === 'text' &&
                          <Form.Item label={item.chName} name={item.name} rules={[{ required: item.required, message: '' }]}>
                            <span>{branchData[item.name]}</span>
                          </Form.Item>
                        }
                      </Col>
                    )
                  })
                }

                {/* 暫時隱藏 */}
                {/* <Col span={24} align="center" style={{ fontSize: '28px'}}>
                  <a>「經銷商合作申請契約書.pdf」</a>
                </Col>
                <Col className="danger" span={24} align="center">
                  請下載「經銷商合作申請契約書」進行用印(蓋發票章，若無責用公司大小章代替)，並以郵件方式寄至本公司，以利完成申請程序。
                </Col> */}

                <Col className="submitBtn" span={24} align="center">
                  <Button
                    type="primary"
                    htmlType="submit"
                  >
                    儲存
                  </Button>
                </Col>

              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </Layout>
  )  
}

export default Merchant;