const INITIAL_STATE = {
  productTypeList: [],
  productItemList: [],
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_PRODUCT_TYPE_LIST":
      return {
        ...state,
        productTypeList: action.data
      };
    case "GET_PRODUCT_ITEM_LIST":
      return {
        ...state,
        productItemList: action.data
      };
    default:
      return state;
  }
};

export default reducer;