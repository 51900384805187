import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { actionCreators } from "src/actions/jet";
import { jetService } from "src/services";
import { HOME_URL } from "src/config";

// antd
import { Button, Modal, Form, Input, Divider, message } from "antd";

const ModalUrl = () => {
  const [isVisible, setIsVisible] = useState(false);

  const dispatch = useDispatch();

  // 取得 store 中的 state
  const editingShopInfo = useSelector(state => state.jet.editingShopInfo);

  // action: 取得編輯中的商家資訊
  useEffect(() => {
    dispatch(actionCreators.getEditingShopInfo());
  }, []);

  const handleVisible = () => setIsVisible(true);

  const handleCancel = () => setIsVisible(false);

  const onFinish = (values) => {
    let payload = {
      shopRouteName: values.shopRouteName,
    };

    jetService.updateShopRouteName(payload)
      .then(
        res => {
          if (res.status === 204) {
            message.success("編輯成功");
            dispatch(actionCreators.getShopRouteName(values.shopRouteName));
            setIsVisible(false);
          } else if (res.status === 401) {
            message.warning("存取拒絕");
          } else if (res.status === 409) {
            message.warning("網址名稱重複");
          } else {
            message.error("編輯失敗");
          }
        },
        error => {
          console.log(error)
        },
      );
  };

  const onFinishFailed = () => false;

  return (
    <>
      <Button
        type="primary"
        style={{ marginRight: 10 }}
        onClick={handleVisible}
      >
        修改網址
      </Button>

      <Modal
        title="修改網址"
        visible={isVisible}
        onCancel={handleCancel}
        maskClosable={false}
        destroyOnClose
        footer={false}
      >
        <Form
          labelCol={{ span: 24 }}
          labelAlign="left"
          colon={false}
          initialValues={{ shopRouteName: editingShopInfo.shopRouteName }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div className="rootFormItem">
            <Form.Item label="網址名稱" required>
              <Form.Item
                name="shopRouteName"
                style={{ marginBottom: 0 }}
                rules={[{ required: true, message: "請輸入網址名稱" }]}
              >
                <Input addonBefore={HOME_URL} showCount={true} maxLength={15} />
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <span style={{ color: "#B0B0B0" }}>若未更改網址名稱，預設以特約商代號替代</span>
              </Form.Item>
            </Form.Item>
          </div>
          <Divider style={{margin: 0}} />
          <div style={{textAlign: "end", padding: "10px 16px"}}>
            <Button onClick={handleCancel} style={{marginRight: 10}}>取消</Button>
            <Button type="primary" htmlType="submit">儲存</Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}

export default ModalUrl;
