export const productTypes = {
  GET_PRODUCTS: 'GET_PRODUCTS',
  GET_PRODUCT_LIST: 'GET_PRODUCT_LIST',
  GET_PRODUCT_DATA: 'GET_PRODUCT_DATA',
  GET_PRODUCT_TYPE_LIST: 'GET_PRODUCT_TYPE_LIST',
  GET_PRODUCT_TYPE_ITEMS_LIST: 'GET_PRODUCT_TYPE_ITEMS_LIST',
  GET_PRODUCT_BRAND: 'GET_PRODUCT_BRAND',
  GET_PRODUCT_INTERESTRATE: 'GET_PRODUCT_INTERESTRATE',
  GET_PRODUCT_TYPE_INDEX: 'GET_PRODUCT_TYPE_INDEX',
};
