import { API_URL as api_url } from 'src/config';

const reqOptions = {
  get: {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  },
};

// 取得商品詳細資料(加密網址)
const getFrontProductStatic = async (cryptoProductValue) => {
  return fetch(
    `${api_url}/product/detail/${cryptoProductValue}`,
    reqOptions.get
  );
};

// 取得商店商品詳細資料
const getFrontProductDynamic = async (shopRouteName, prodNo) => {
  return fetch(
    `${api_url}/shops/${shopRouteName}/products/${prodNo}`,
    reqOptions.get
  );
};

// 取得商店商品加密字串
const getFrontProductEncrypt = async (shopRouteName, prodNo) => {
  return fetch(
    `${api_url}/shops/${shopRouteName}/products/${prodNo}/encrypt`,
    reqOptions.get
  );
};

export const frontProductService = {
  getFrontProductStatic,
  getFrontProductDynamic,
  getFrontProductEncrypt,
};
