// 案件狀態
const appCaseStage = [
  { name: '申請未完成', value: '0', key: 0 },
  { name: '審核中', value: '1', key: 1 },
  { name: '案件補件', value: '8', key: 8 },
  { name: '案件未通過', value: '11', key: 10 },
  { name: '已撤件', value: '12', key: 11 },
  { name: '核准待對保', value: '131', key: 14 },
  { name: '對保檢核中', value: '132', key: 15 },
  { name: '核准待取貨', value: '13', key: 12 },
  { name: '完成取貨', value: '14', key: 13 },
  { name: '交易完成', value: '15', key: 16 },
];

// 訂單狀態
const appCurrPayTag = [
  { name: 'M0', value: 'M0', key: 1 },
  { name: 'M1', value: 'M1', key: 2 },
  { name: 'M2', value: 'M2', key: 3 },
  { name: 'M3', value: 'M3', key: 4 },
  { name: 'M4', value: 'M4', key: 5 },
  { name: 'M5', value: 'M5', key: 6 },
  { name: 'M6', value: 'M6', key: 7 },
  { name: 'M6+', value: 'M6%2B', key: 8 },
  { name: '結清', value: '結清', key: 9 },
];

export const order = {
  appCaseStage,
  appCurrPayTag,
};
