import Cookies from 'universal-cookie';

const cookies = new Cookies();

const INITIAL_STATE = {
  isLogined: cookies.get('_merchant_auth_token') ? true : false,
  errorMsg: '',
  usersList: {},

  isRootLogined: cookies.get("_root_auth_token") ? true : false,
  rootErrorMsg: "",
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        isLogined: action.isLogined
      };
    case 'ERROR_MSG':
      return {
        ...state,
        errorMsg: action.errorMsg
      };
    case 'GET_USERS_LIST':
      return {
        ...state,
        usersList: action.data
      };
    case "ROOT_LOGIN":
      return {
        ...state,
        isRootLogined: action.isRootLogined
      };
    case "ROOT_ERROR_MSG":
      return {
        ...state,
        rootErrorMsg: action.rootErrorMsg
      };
    default:
      return state;
  }
};

export default reducer;