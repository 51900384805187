import React from "react";

// antd
import { Row, Col } from "antd";

// component
import FrontDivJcoin from "src/components/Front/Divider/DivJcoin";

const Section3 = () => {
  const steps = [
    {
      id: 1,
      color: "red",
      content: "成為 JetShop 會員滿三十天",
    },
    {
      id: 2,
      color: "orange",
      content: "會員自行下單或推薦新用戶申辦",
    },
    {
      id: 3,
      color: "green",
      content: "該筆訂單核准且首月準時繳款",
    },
    {
      id: 4,
      color: "blue",
      content: "獲得 Jcoin 點數一枚",
    },
  ];

  return (
    <>
      <Row justify="center" style={{ marginTop: 70, marginBottom: 70 }}>
        <Col span={20} id="tip">
          <FrontDivJcoin title={"　集點秘笈　"} />
        </Col>
        <Col span={24}>
          <h3 className="front">
            <p style={{ marginBottom: 10 }}>
              JetShop會員【
              <span className="highlight-text">申辦下單</span>
              】或【
              <span className="highlight-text">推薦新戶申辦</span>
              】商品分期
            </p>
            <p>經核准且首月準時繳款即獲得一枚Jcoin</p>
          </h3>
        </Col>

        {
          steps.map(item => (
            <Col className="d-flex justify-center" key={item.id} span={12} lg={4} md={6} sm={6} style={{ marginTop: 20}}>
              <div className="jcoinStep">
                <div className="d-flex justify-center">
                  <div className={`step ${item.color}`}>
                    {item.id}
                  </div>
                </div>
                <div className="content text-center" style={{ marginTop: 20, fontSize: 16 }}>
                  {item.content}
                </div>
              </div>
            </Col>
          ))
        }
      </Row>
    </>
  );
}

export default Section3;
