import React from "react";

// antd
import { Row, Col } from "antd";

const Section1 = () => {
  return (
    <Row className="frontAboutSection1" justify="center">
      <Col className="frontAboutIntro" span={24} xl={7} lg={8} md={11} sm={24}>
        <div className="wrapper">
          <div>
            <p>B</p>
          </div>
          <div>
            <p>N</p>
          </div>
          <div>
            <p>P</p>
          </div>
          <div>
            <p>L</p>
          </div>
        </div>
      </Col>
      <Col span={24} xl={7} lg={8} md={11} sm={24}>
        <h2 className="front">Buy Now, Pay Later</h2>
        <h3 className="front" style={{ marginBottom: 50 }}>先買後付，享受人生，即刻擁有</h3>
      </Col>
    </Row>
  );
}

export default Section1;
