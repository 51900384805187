import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { actionCreators as actionRootCreators } from "src/actions/root";
import { actionCreators as actionApplyTypeCreators } from "src/actions/apply-type";
import { actionCreators } from "src/actions/product-type";
import { productTypeService } from "src/services";

// components
import AddProductType from "./AddProductType";

//antd
import { Layout, Row, Col, Card, Select, Space, Tooltip, Table, Empty, Button, Switch, Input, Form, Modal, message } from "antd";
import { MenuOutlined, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";

// drag components
const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: "grab", color: "#999" }} />)
const SortableItem = SortableElement(props => <tr {...props} />);
const SortableBody = SortableContainer(props => <tbody {...props} />);

// render editable cell
const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
  let inputNode = null;
  if (inputType === "switch") {
    inputNode = <Switch defaultChecked={record.prodTypeDisplay} checkedChildren="顯示" unCheckedChildren="隱藏" />;
  } else {
    inputNode = <Input />
  }
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          valuePropName={ dataIndex === "prodTypeDisplay" ? "checked" : "value" }
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: "必填"
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (children)}
    </td>
  )
};

const RootProductType = () => {
  const [form] = Form.useForm();
  const [appTypeOptions, setAppTypeOptions] = useState([]);
  const [appTypeNo, setAppTypeNo] = useState(1);
  const [rowData, setRowData] = useState([]);
  const [editingKey, setEditingKey] = useState("");

  // 取出 store 中的 state
  const applyTypeList = useSelector(state => state.applyType.applyTypeList);
  const productTypeList = useSelector(state => state.productType.productTypeList);

  const dispatch = useDispatch();

  const dispatchFunc = (appTypeNo) => dispatch(actionCreators.getProductType(appTypeNo));

  // step1: 取得案件類型
  useEffect(() => {
    dispatch(actionApplyTypeCreators.getApplyType());
  }, []);

  // step2: 取得商品類型
  useEffect(() => {
    dispatchFunc(appTypeNo);
  }, [dispatch]);
  
  // step3: 案件類型配值
  useEffect(() => {
    let data = [];
    if (applyTypeList && applyTypeList.length >= 1) {
      applyTypeList.forEach(item => {
        data.push({
          appTypeNo: item.appTypeNo,
          appCaseType: item.appCaseType,
          appTypeSerNo: item.appTypeSerNo,
        });
      });
    };
    data.sort((a, b) => {
      return a.appTypeSerNo - b.appTypeSerNo;
    });
    setAppTypeOptions(data);
  }, [applyTypeList]);

  // step4: 商品類型配值
  useEffect(() => {
    let data = [];
    let index = 0;
    if (productTypeList && productTypeList.length >= 1) {
      productTypeList.forEach(item => {
        data.push({
          key: `${item.prodTypeNo}`,
          prodTypeNo: item.prodTypeNo,
          prodTypeSerNo: item.prodTypeSerNo,
          prodTypeDes: item.prodTypeDes,
          prodTypeDisplay: item.prodTypeDisplay,
          index: index,
        });
        index += 1;
      });
    };
    data.sort((a, b) => {
      return a.prodTypeSerNo - b.prodTypeSerNo;
    });
    setRowData(data);
  }, [productTypeList]);

  // 切換案件類型
  const onChangeAppType = (e) => {
    setAppTypeNo(e);
    dispatchFunc(e);
  };

  // open: 新增視窗
  const handleAddModal = () => {
    dispatch(actionRootCreators.modalVisible());
  };

  const isEditing = (record) => record.key === editingKey;
  
  const edit = (record) => {
    form.setFieldsValue({
      prodTypeDes: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  // 編輯-儲存
  const save = async (key) => {
    try {
      const row = await form.validateFields();

      // 前端更新表格
      const newData = [...rowData];
      const index = newData.findIndex(item => key === item.key);
      const item  = newData[index];
      newData.splice(index, 1, { ...item, ...row });

      // api 處理
      handleEditProductType(newData, index);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditProductType = (newData, index) => {
   const newInfo = newData[index];
   const payload = {
    prodTypeDes: newInfo.prodTypeDes,
    prodTypeDisplay: newInfo.prodTypeDisplay
   };
   productTypeService.editProductType(newInfo.prodTypeNo, payload)
   .then(
    res => {
      if (res.status === 204) {
        setRowData(newData);
        setEditingKey("");
        message.success("編輯成功");
      }
      else if (res.status === 401) message.warning("存取拒絕");
      else if (res.status === 404) message.warning("找不到商品類型");
      else message.error("編輯失敗");
    },
    error => {
      console.log(error);
    }
   );
  };

  // 編輯-刪除
  const handleDelete = async (key) => {
    const { confirm } = Modal;
    confirm({
      title: "確定要刪除此筆資料嗎？",
      onOk: () => {
        try {
          const dataSource = [...rowData];
          productTypeService.deleteProductType(key)
          .then(
            res => {
              if (res.status === 204) {
                setRowData(() => dataSource.filter(item => item.key !== key));
                setEditingKey("");
                message.success("刪除成功");
              }
              else if (res.status === 401) message.warning("存取拒絕");
              else if (res.status === 404) message.warning("找不到商品類型");
              else if (res.status === 409) message.warning("無法刪除已使用的商品類型");
              else message.error("刪除失敗");
            },
            error => {
              console.log(error);
            }
          );
        } catch (error) {
          console.log(error);
        }
      },
    });
  };

  // 排序後結果
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      // 前端更新表格
      const newData = arrayMoveImmutable([].concat(rowData), oldIndex, newIndex)
                      .filter(el => !!el);
      newData.forEach((item, idx) => {
        item.prodTypeSerNo = idx + 1;
      });

      // api 處理
      handleResort(newData, newIndex);
    };
  };

  const handleResort = async (data, index) => {
    const prodTypeNo = data[index].prodTypeNo;
    const payload = { prodTypeSerNo: index + 1 };
    productTypeService.editProductTypeOrder(prodTypeNo, payload)
    .then(
      res => {
        if (res.status === 204) {
          setRowData(data);
          message.success("排序修改成功");
        }
        else if (res.status === 401) message.warning("存取拒絕");
        else if (res.status === 404) message.warning("找不到商品類型");
        else if (res.status === 409) message.warning("排序超出範圍");
        else message.error("修改失敗");
      },
      error => {
        console.log(error);
      }
    );
  };

  // 拖曳內容區塊
  const DraggableContainer = props => (
    <SortableBody 
      useDragHandle
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = rowData.findIndex(x => x.index === restProps["data-row-key"]);
    return <SortableItem index={index} {...restProps} />;
  };

  const columns = [
    {
      title:
        <div>
          拖曳&nbsp;
          <Tooltip placement="top" title="拖曳以改變順序">
            <QuestionCircleOutlined style={{ cursor: "help" }} />
          </Tooltip>
        </div>,
      dataIndex: "drag",
      width: "70px",
      render: () => <DragHandle />
    },
    {
      title: "商品類型代碼",
      dataIndex: "prodTypeNo",
      width: "100px",
      render: (prodTypeNo) => <Link to={ '/root/product-item/list?productTypeNo=' + prodTypeNo }>{prodTypeNo}</Link>
    },
    {
      title: "商品類型排序",
      dataIndex: "prodTypeSerNo",
      width: "100px",
    },
    {
      title: "商品類型說明",
      dataIndex: "prodTypeDes",
      editable: true,
      width: "150px",
    },
    {
      title: "商品類型狀態",
      dataIndex: "prodTypeDisplay",
      editable: true,
      width: "100px",
      render: (prodTypeDisplay) => (<span>{prodTypeDisplay ? "顯示" : "隱藏"}</span>),
    },
    {
      title: "操作",
      dataIndex: "operation",
      width: "150px",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Space>
            <a onClick={() => save(record.key)}>儲存</a>
            <a onClick={() => setEditingKey("")}>取消</a>
            <a onClick={() => handleDelete(record.key)} style={{ color: "red" }}>刪除</a>
          </Space>
        ) : (
          <a disabled={editingKey !== ""} onClick={() => edit(record)}>
            編輯
          </a>
        );
      },
    },
  ];

  const mergedColumns = columns.map(col => {
    if (!col.editable) return col;
    return {
      ...col,
      onCell: record => ({
        record,
        inputType: col.dataIndex === "prodTypeDisplay" ? "switch" : "input",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Layout className="rootList">
      <Row justify="center">
        <Col span={23}>
          <div className="title">商品類型設定</div>

          <Card className="rootListCard">
            {/* 篩選區塊 */}
            <div className="rootListSearch">
              <Row>
                <Col flex="120px" style={{ lineHeight: "32px" }}>
                  <span>選擇案件類型</span>
                </Col>
                <Col flex={2}>
                  <Select 
                    defaultValue={1} 
                    style={{ width: 200 }} 
                    onChange={onChangeAppType}
                  >
                    {
                      appTypeOptions.map(appType => (
                        <Select.Option value={appType.appTypeNo} key={appType.appTypeNo}>
                          {appType.appCaseType}
                        </Select.Option>
                      ))
                    }
                  </Select>
                </Col>
              </Row>
            </div>

            {/* 查詢結果 */}
            <div>
              <Row>
                <Col 
                  className="title" 
                  align="left" 
                  span={12} 
                  xs={12} 
                  sm={12} 
                  md={12} 
                  style={{ color: "#F7BA2A" }}
                >
                  查詢結果
                </Col>
                <Col
                  align="right"
                  span={12}
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ marginTop: "20px" }}
                >
                  <Button icon={ <PlusOutlined /> } onClick={handleAddModal}>新增</Button>
                  <AddProductType appTypeOptions={appTypeOptions} appTypeNo={appTypeNo} />
                </Col>
              </Row>
              <Row style={{ margin: "20px 0" }}>
                <Form form={form} component={false}>
                  <Table
                    components={{body: {
                      cell: EditableCell,
                      wrapper: DraggableContainer,
                      row: DraggableBodyRow,
                    }}}
                    columns={mergedColumns}
                    dataSource={rowData}
                    rowClassName="editable-row"
                    rowKey={ record => record.index }
                    locale={{
                      emptyText: (
                        <Empty 
                          image={ Empty.PRESENTED_IMAGE_SIMPLE }
                          description={ <span>無資料</span> }
                        />
                      )
                    }}
                    size="small"
                    pagination={false}
                    scroll={{ y: 400 }}
                  />
                </Form>
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}

export default RootProductType;
