import React from "react";

import "./Home.css";

const RootHome = () => {
    return (
        <div className="welcome">
            <div className="welcomeSvg">
                <div id="welcome" className="shapeshifter play"></div>
            </div>
            <p style={{fontSize: 30, margin: 0}}>歡迎使用 Jetshop 維護系統</p>
        </div>
    );
}

export default RootHome;
