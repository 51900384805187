import Cookies from 'universal-cookie';
import { ADMIN_API_URL as api_admin_url } from 'src/config';

const cookies = new Cookies();
const reqOptions = {
  get: {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${cookies.get('_merchant_auth_token')}`
    },
  },
  post: {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${cookies.get('_merchant_auth_token')}`
    },
  },
  postFormData: {
    method: "POST",
    headers: { "Authorization": `Bearer ${cookies.get('_merchant_auth_token')}` },
  },
  patch: {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${cookies.get('_merchant_auth_token')}`
    },
  },
};

/* -------------------------
商       家       資       訊
------------------------- */
// 取得編輯中的商店資訊
const getEditingShopInfo = async () => {
  return fetch(
    `${api_admin_url}/shops/editing`,
    reqOptions.get
  );
};

// 儲存商家資訊
const updateShopInfo = async (formData) => {
  return fetch(
    `${api_admin_url}/shops`,
    {
      ...reqOptions.postFormData,
      body: formData
    }
  );
};

// 編輯商家網址
const updateShopRouteName = async (payload) => {
  return fetch(
    `${api_admin_url}/shops`,
    {
      ...reqOptions.patch,
      body: JSON.stringify(payload)
    }
  );
};

// 發佈商店資訊
const publishShop = async (payload) => {
  return fetch(
    `${api_admin_url}/shops/publish`,
    {
      ...reqOptions.post,
      body: JSON.stringify(payload)
    }
  );
};

// 購物須知中的圖片上傳
const uploadShopNoticeImage = async (formData) => {
  return fetch(
    `${api_admin_url}/shops/image-upload`,
    {
      ...reqOptions.postFormData,
      body: formData
    }
  );
};

// 取得商店預覽
const getShopPreviewInfo = async () => {
  return fetch(
    `${api_admin_url}/shops/preview`,
    reqOptions.get
  );
};

/* -------------------------
活       動       專       區
------------------------- */
// 取得編輯中活動資訊
const getEditingShopActivity = async () => {
  return fetch(
    `${api_admin_url}/shop-activities/editing`,
    reqOptions.get
  );
};

// 儲存活動資訊
const updateShopActivity = async (payload) => {
  return fetch(
    `${api_admin_url}/shop-activities`,
    {
      ...reqOptions.post,
      body: JSON.stringify(payload)
    }
  );
};

// 儲存活動商品清單
const updateShopActivityProduct = async (payload) => {
  return fetch(
    `${api_admin_url}/shop-activities/products`,
    {
      ...reqOptions.post,
      body: JSON.stringify(payload)
    }
  );
};

export const jetService = {
  getEditingShopInfo,
  updateShopInfo,
  updateShopRouteName,
  publishShop,
  uploadShopNoticeImage,
  getShopPreviewInfo,
  getEditingShopActivity,
  updateShopActivity,
  updateShopActivityProduct,
};
