import { productTypeTypes } from "src/constants";
import { productTypeService } from "src/services";

// 取得商品類型列表
const getProductType = (appTypeNo) => {
  return (dispatch) => {
    productTypeService.getProductType(appTypeNo)
    .then(
      res => {
        res.json().then(result => {
          dispatch({ type: productTypeTypes.GET_PRODUCT_TYPE_LIST, data: result.data });
        });
      },
      error => {
        console.log(error);
      }
    );
  };
};

// 取得商品項目列表
const getProductItem = (prodTypeNo) => {
  return (dispatch) => {
    productTypeService.getProductItem(prodTypeNo)
    .then(
      res => {
        res.json().then(result => {
          dispatch({ type: productTypeTypes.GET_PRODUCT_ITEM_LIST, data: result.data });
        });
      },
      error => {
        console.log(error);
      }
    );
  };
};

export const actionCreators = {
  getProductType,
  getProductItem
};