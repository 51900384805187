import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { Row, Col, Card, Tag } from "antd";

// swiper.js
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";

import noProduct from "src/assets/no_product.jpg";

const Activity = (props) => {
  const { shopRouteName } = useParams();

  const [activity, setActivity] = useState(null);

  useEffect(() => {
    setActivity(props.shopActivity);
  }, [props]);

  if (activity) {
    return (
      <div>
        <Row justify="center">
          <div className="jet-title">活動專區</div>
        </Row>
        <Row justify="center">
          <Col span={20}>
            <div 
              style={{marginBottom: 20}}
              dangerouslySetInnerHTML={{__html: activity.saDescription}}
            >
            </div>
          </Col>
          <Col span={22}>
            <Swiper
              className="jet-swiper"
              slidesPerView={1}
              spaceBetween={10}
              pagination={{ clickable: true }}
              modules={[Pagination]}
              grabCursor={true}
              breakpoints={{
                576: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
                1350: {
                  slidesPerView: 5,
                  spaceBetween: 30,
                },
              }}
            >
              {activity.products.map(item => (
                <SwiperSlide key={item.prodNo}>
                  <Card bordered={false} style={{ width: 250 }}>
                    <a 
                      onClick={e => props.isAdmin ? e.preventDefault() : null} 
                      href={`/${shopRouteName}/product/detail/${item.prodNo}`} 
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: "inherit" }}
                    >
                      <div className="card-img">
                        {
                          item.prodCoverPhotoURL 
                          ? (
                            <img src={item.prodCoverPhotoURL} alt={item.prodName} />
                            ) : (
                            <img src={noProduct} alt={item.prodName} />
                          )
                        }
                      </div>
                      <div className="jet-h-scroll">
                        {activity.saTags.split(",").map((tag, idx) => (
                          <Tag key={idx} className="front" color="red">{tag}</Tag>
                        ))}
                      </div>
                      <p className="act-prod-name">{item.prodName}</p>
                      <p className="act-prod-amount">最低月付 <span>NT${item.prodLowestMonthlyPayment}</span></p>
                    </a>
                  </Card>
                </SwiperSlide>
              ))}
            </Swiper>
          </Col>
        </Row>
      </div>
    );
  }

  return null;
}

export default Activity;
