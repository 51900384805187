import React from "react";

import "./Divider.css";

const FrontDivLeft = () => {
  return (
    <div className="divider-left">
      <div className="divider"> 
        <span></span>
      </div>
    </div>
  );
}

export default FrontDivLeft;
