import React, { useState, useEffect } from "react";

// css
import "./FrontJetDaiActivity.css";
import "src/pages/Front/JetDai/FrontJetDai.css";

// component
import FrontBreadcrumb from "src/components/Front/Breadcrumb";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";

// image
import jetdaiActivityBackground01 from "src/assets/jetdai/jetdai-activity/background_01.png"

const FrontGuide = () => {
  // jet貸活動時間：2024/03/11 00:00:00~2024/04/01 23:59:59(活動結束後網頁才消失)
  const [today] = useState(new Date("2024/10/25 00:00:00"));
	const [endDate] = useState(new Date("2024/11/30 23:59:59"));
	const [isInActivity, setIsInActivity] = useState(false);

  useEffect(() => {
		setIsInActivity(today <= endDate);
  }, []);

  if (!isInActivity) {
    return (
      <div className="jetdai-activity-out-of-period">
        活動已結束
      </div>
    );
  }

  return (
    <div className="jetdai-activity">
      <FrontBreadcrumb
        link={[
          { href: '/about/jetdai', name: "Jet貸" },
          { href: '', name: "活動介紹" },
        ]}
      />

      <div className="jetdai-activity-banner" style={{ paddingTop: 40 }}>
        <a href="/about/jetdai/apply-step">
          <img src={jetdaiActivityBackground01} alt="jetdai-activity-banner" />
        </a>
      </div>

      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
    </div>
  );
}

export default FrontGuide;
