import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { actionCreators as actionRootCreators } from "src/actions/root";

// images
import lineQRCode from "src/assets/line_QRCode.png";

// ant design
import { Modal, Row } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";

const Result = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // 取出 store 中的 state
  const isVisible = useSelector(state => state.Root.modalVisible);

  const toHome = () => {
    dispatch(actionRootCreators.modalVisible());
    navigate("/");
  };

  return (
    <Modal
      className="front"
      visible={isVisible} 
      closable={false}
      maskClosable={false}
      destroyOnClose
      footer={false}
      width={350}
    >
      <Row justify="center" style={{ paddingTop: "32px" }}>
        <img 
          src={lineQRCode} 
          alt="Line QRCode"
          width={150} 
        />
      </Row>
      <Row justify="center" style={{ paddingTop: "1em" }}>
        <div className="success">
          <CheckCircleFilled 
            style={{ color: "#52c41a", fontSize: "150%" }}
          />
          <h4 className="submitSuccessText">提交成功</h4>
        </div>
      </Row>
      <Row align="center" style={{ padding: "1em 32px" }}>
        <div className="text-center">
          <p style={{ color: "#9E9E9E", fontSize: "16px" }}>
            您的聯絡資訊已提交，請透過上方行動條碼加入Line好友，謝謝！
          </p>
        </div>
      </Row>
      <Row align="center" style={{ padding: "0 32px 32px" }}>
        <button className="frontBtn" onClick={toHome}>
          回首頁
        </button>
      </Row>
    </Modal>
  );
}

export default Result;
