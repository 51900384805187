import moment from 'moment';

const DATEREGEX = /^[1-9]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])T(20|21|22|23|[0-1]\d):([0-9]\d):([0-9]\d).([0-9]\d+)Z$/;

const toDateTime = (dtime) => {
  if (DATEREGEX.test(dtime)) {
    // return: yyyy-mm-dd HH:MM:SS
    const localTime = moment.utc(dtime).toDate();
    return moment(localTime).format('YYYY-MM-DD HH:mm:ss');
    // return `${dtime.split(/[T.]+/)[0]}
    //         ${dtime.split(/[T.]+/)[1]}`;
  } else {
    return dtime;
  }
};

const toDate = (dtime) => {
  if (DATEREGEX.test(dtime)) {
    // return: yyyy-mm-dd
    const localTime = moment.utc(dtime).toDate();
    return moment(localTime).format('YYYY-MM-DD');
    // return `${dtime.split(/[T.]+/)[0]}`;
  } else {
    return dtime;
  }
};

const toROC = (dtime) => {
  // return: 民國xx年mm月dd日
  if (DATEREGEX.test(dtime)) {
    let tList = dtime.split(/[T.]+/)[0].split('-');
    return `民國${tList[0] - 1911}年${tList[1]}月${tList[2]}日`;
  } else {
    return dtime;
  }
};

const toNumberFormat = (val) => {
  // 此方法safari不支援
  // let val = num.toString();
  // let data = /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g;
  // return val.replace(data, ',');

  // const re = new RegExp(`\\B(?<!\\.\\d*)(?=(\\d{3})+(?!\\d))`, 'g');
  // return val?.toString().replace(re, ',');
  return new Intl.NumberFormat().format(val);
};

export const format = {
  toDateTime,
  toDate,
  toROC,
  toNumberFormat,
};
