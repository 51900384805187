import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { actionReportCreators } from 'src/actions/report';
import { actionCreators } from 'src/actions/merchant';
import moment from 'moment';
import { dictList } from './useDict';
import { format } from 'src/utils/format';
import { getAppCaseStage } from 'src/utils/useOrder';

import SearchBox from './components/searchBox';
import ResultTable from './components/resultTable';
import Overview from './components/overview';

// Ant design
import { Layout, Row, Col, Space, Card, Divider, Input, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import './casePayAmount.css';

const Report = () => {
  const dispatch = useDispatch();

  const searchInput = useRef(null);
  const [, setSearchText] = useState('');
  const [, setSearchedColumn] = useState('');

  const currentDate = moment().format('YYYY-MM-DD');
  const sevenDaysAgo = moment()
    .subtract('6', 'days')
    .format('YYYY-MM-DD');
  const oneMonthAgo = moment()
    .subtract('1', 'months')
    .add('1', 'days')
    .format('YYYY-MM-DD');
  const threeMonthsAgo = moment()
    .subtract('3', 'months')
    .add('1', 'days')
    .format('YYYY-MM-DD');

  const [branchesLoading, setBranchesLoading] = useState(false);
  const [allMerAcctIdVal, setAllMerAcctIdVal] = useState(null);
  const [merAcctIdVal, setMerAcctIdVal] = useState(null);
  const [quickSearch, setQuickSearch] = useState('1');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [dates, setDates] = useState([]);

  const [dateType, setDateType] = React.useState('applyDate');

  const [overViewList, setOverViewList] = useState([]);

  const branchesData = useSelector((state) => state.merchant.branchesData);
  const summryApplyCasesData = useSelector((state) => state.report.summryApplyCases);
  const listApplyCasesData = useSelector((state) => state.report.listApplyCases);

  const dispatchFunc = (merAcctIdData, dateType, startDate, endDate) => {
    let merAcctId = merAcctIdData === 'all' ? allMerAcctIdVal : merAcctIdData;
    // 取得 案件款項
    dispatch(actionReportCreators.getSummryApplyCases({ merAcctId, dateType, startDate, endDate }));
    // 取得 案件列表
    dispatch(actionReportCreators.getListApplyCases({ merAcctId, dateType, startDate, endDate }));
  };

  // step1 取得 所屬分店資訊
  useEffect(() => {
    setBranchesLoading(true);
    dispatch(actionCreators.getBranchesData());
  }, []);

  // step2 預設 全部合計-案件狀態總覽
  useEffect(() => {
    setBranchesLoading(false);
    if (branchesData.length > 0) {
      const initBranches = branchesData.map((ele) => ele.merAcctId).join(',');
      setAllMerAcctIdVal(initBranches);
      setMerAcctIdVal('all');

      // 取得 案件狀態總覽
      dispatchFunc(initBranches, dateType, oneMonthAgo, currentDate);
    }
  }, [branchesData]);

  // step3
  useEffect(() => {
    if (summryApplyCasesData.message) return;
    if (branchesData.length === 0) return;
    if (merAcctIdVal === null) return;

    let initAmtList = [];

    Object.entries(summryApplyCasesData).forEach(([key, value]) => {
      if (key === 'merAcctId') return;
      return initAmtList.push({
        label: dictList.dictCaseAmt[key],
        value: format.toNumberFormat(value),
      });
    });

    initAmtList.unshift({
      label: dictList.dictCaseAmt.merAcctId,
      // 選擇全部合計(只有一個分店或大於一個分店)
      value: merAcctIdVal === 'all' ? '全部合計' : merAcctIdVal,
    });

    initAmtList.unshift({
      label: dictList.dictCaseAmt.merCompName,
      value: merAcctIdVal === 'all' ? '全部合計' : branchesData.find((ele) => ele.merAcctId === merAcctIdVal)['merCompName'],
    });

    setOverViewList(initAmtList);
  }, [summryApplyCasesData]);

  useEffect(() => {
    let initCasesList = [];
    if (listApplyCasesData && listApplyCasesData.rows) {
      listApplyCasesData.rows.map((ele) => {
        return initCasesList.push({
          appCaseNo: ele.appCaseNo,
          memName: ele.memName,
          ordProdName: ele.apo.ordProdName,
          merAcctId: ele.apo.merAcctId,
          merName: ele.apo.merName,
          appCaseDate: format.toDate(ele.appCaseDate),
          appTotalAmt: format.toNumberFormat(ele.appTotalAmt),
          merTotalPayAmt: format.toNumberFormat(ele.merTotalPayAmt),
          merPayDate: format.toDate(ele.merPayDate),
          appBaseAmt: ele.appBaseAmt,
          ordProdPriceAmt: ele.ordProdPriceAmt,
          ordProdDownPayment: ele.ordProdDownPayment,
          appCaseStage: getAppCaseStage(ele),
        });
      });
    }

    setCasesListData(initCasesList);
  }, [listApplyCasesData]);

  // 變動 選擇分店
  const onChangeBranches = (value) => {
    setStartDate(null);
    setEndDate(null);
    setQuickSearch('1');
    // 取得 案件狀態總覽
    dispatchFunc(value, dateType, oneMonthAgo, currentDate);
    setMerAcctIdVal(value);
  };

  // 快速查詢功能
  const onChangeQuickSearch = (e) => {
    const val = e.target.value;
    setQuickSearch(val);
    setStartDate(null);
    setEndDate(null);
    if (val === '0') {
      dispatchFunc(merAcctIdVal, dateType, sevenDaysAgo, currentDate);
    } else if (val === '1') {
      dispatchFunc(merAcctIdVal, dateType, oneMonthAgo, currentDate);
    } else if (val === '2') {
      dispatchFunc(merAcctIdVal, dateType, threeMonthsAgo, currentDate);
    }
  };

  // 自訂查詢功能
  const search = () => {
    setQuickSearch(null);
    let startDateVal = startDate ? startDate : oneMonthAgo;
    let endDateVal = endDate ? endDate : currentDate;

    dispatchFunc(merAcctIdVal, dateType, startDateVal, endDateVal);
  };

  const disabledDate = (current) => {
    if (!dates || dates.length === 0) {
      return current > moment().endOf('day');
    }

    // 只能選擇當前日期之前，日期範圍不超過90天
    const tooLate = dates[0] && current.diff(dates[0], 'days') > 89;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > 89;
    return tooEarly || tooLate || current > moment().endOf('day');
  };

  // 變更 日期類別
  const onChangeDateType = (e) => {
    setQuickSearch('1');
    setStartDate(null);
    setEndDate(null);
    setDateType(e.target.value);
    dispatchFunc(merAcctIdVal, e.target.value, oneMonthAgo, currentDate);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleSearchReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  // 搜尋功能
  const getColumnSearchProps = (dataIndex, text) => {
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input ref={searchInput} placeholder={`搜尋${text}`} value={selectedKeys[0]} onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])} onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)} style={{ width: 188, marginBottom: 8, display: 'block' }} />
          <Space>
            <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} size="small" style={{ width: 90 }}>
              確定
            </Button>
            <Button onClick={() => handleSearchReset(clearFilters)} size="small" style={{ width: 90 }}>
              重設
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (val, record) =>
        record[dataIndex] !== null
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(val.toLowerCase())
          : '',
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current.select());
        }
      },
      render: (text) => text,
    };
  };

  const [casesListCols] = useState([
    {
      title: '訂單編號',
      dataIndex: 'appCaseNo',
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('appCaseNo', '訂單編號'),
      sorter: (a, b) => {
        a = a.appCaseNo || '';
        b = b.appCaseNo || '';
        return a - b;
      },
      render: (text, col) => {
        return (
          <Link to={'/admin/order/detail?appCaseNo=' + col.appCaseNo} target="_blank">
            {text}
          </Link>
        );
      },
      width: 150,
    },
    {
      title: '申請人',
      dataIndex: 'memName',
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('memName', '申請人'),
      sorter: (a, b) => {
        a = a.memName || '';
        b = b.memName || '';
        return a.localeCompare(b);
      },
      width: 150,
    },
    {
      title: '商品名稱',
      dataIndex: 'ordProdName',
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('ordProdName', '商品名稱'),
      sorter: (a, b) => {
        a = a.ordProdName || '';
        b = b.ordProdName || '';
        return a.localeCompare(b);
      },
      width: 150,
    },
    {
      title: '商家代碼',
      dataIndex: 'merAcctId',
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('merAcctId', '商家代碼'),
      sorter: (a, b) => {
        a = a.merAcctId || '';
        b = b.merAcctId || '';
        return a.localeCompare(b);
      },
      width: 150,
    },
    {
      title: '分店名稱',
      dataIndex: 'merName',
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('merName', '分店名稱'),
      sorter: (a, b) => {
        a = a.merName || '';
        b = b.merName || '';
        return a.localeCompare(b);
      },
      width: 150,
    },
    {
      title: '訂單日期',
      dataIndex: 'appCaseDate',
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'descend',
      ...getColumnSearchProps('appCaseDate', '訂單日期'),
      sorter: (a, b) => {
        a = a.appCaseDate || '';
        b = b.appCaseDate || '';
        return a.localeCompare(b);
      },
      width: 150,
    },
    {
      title: '分期總價',
      dataIndex: 'appTotalAmt',
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('appTotalAmt', '分期總價'),
      sorter: (a, b) => {
        a = a.appTotalAmt || '';
        b = b.appTotalAmt || '';
        return a - b;
      },
      width: 150,
    },
    {
      title: '撥款金額',
      dataIndex: 'merTotalPayAmt',
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('merTotalPayAmt', '撥款金額'),
      sorter: (a, b) => {
        a = a.merTotalPayAmt || '';
        b = b.merTotalPayAmt || '';
        return a - b;
      },
      width: 150,
    },
    {
      title: '分期基準價',
      dataIndex: 'appBaseAmt',
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('appBaseAmt', '分期基準價'),
      sorter: (a, b) => {
        a = a.appBaseAmt || '';
        b = b.appBaseAmt || '';
        return a - b;
      },
      width: 150,
    },
    {
      title: '建議售價',
      dataIndex: 'ordProdPriceAmt',
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('ordProdPriceAmt', '建議售價'),
      sorter: (a, b) => {
        a = a.ordProdPriceAmt || '';
        b = b.ordProdPriceAmt || '';
        return a - b;
      },
      width: 150,
    },
    {
      title: '頭期款',
      dataIndex: 'ordProdDownPayment',
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('ordProdDownPayment', '頭期款'),
      sorter: (a, b) => {
        a = a.ordProdDownPayment || '';
        b = b.ordProdDownPayment || '';
        return a - b;
      },
      width: 150,
    },
    {
      title: '撥款日期',
      dataIndex: 'merPayDate',
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('merPayDate', '撥款日期'),
      sorter: (a, b) => {
        a = a.merPayDate || '';
        b = b.merPayDate || '';
        return a.localeCompare(b);
      },
      width: 150,
    },
    {
      title: '狀態',
      dataIndex: 'appCaseStage',
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('appCaseStage', '狀態'),
      sorter: (a, b) => {
        a = a.appCaseStage || '';
        b = b.appCaseStage || '';
        return a.localeCompare(b);
      },
      width: 150,
    },
  ]);

  const [casesListData, setCasesListData] = useState([]);

  return (
    <Layout className="merLayout">
      <Row justify="center">
        <Col span={23}>
          <div className="title">統計報表 - 案件款項</div>
          <Card className="merLayoutCard casePayAmountCard">
            <SearchBox
              // 選擇分店
              branchesLoading={branchesLoading}
              branchesData={branchesData}
              merAcctIdVal={merAcctIdVal}
              onChangeBranches={onChangeBranches}
              // 快速查詢
              quickSearch={quickSearch}
              onChangeQuickSearch={onChangeQuickSearch}
              // 自訂查詢
              dateType={dateType}
              onChangeDateType={onChangeDateType}
              startDate={startDate}
              endDate={endDate}
              onChangeDate={(moment, dateArr) => {
                setStartDate(dateArr[0]);
                setEndDate(dateArr[1]);
              }}
              onCalendarChange={(val) => setDates(val)}
              disabledDate={disabledDate}
              // value={dateHackValue || dateValue}
              // onOpenChange={onOpenChange}
              search={search}
            />
            <Divider className="subTitle" orientation="left" orientationMargin="0" style={{ margin: '5px 20px 15px 0' }}>
              總覽
            </Divider>
            <Overview overViewList={overViewList} />
            {/* <ResultTable bordered={true} tableCols={totalCasesCols} dataSource={totalCasesData} tableScroll={false} /> */}
            <Divider className="subTitle" orientation="left" orientationMargin="0" style={{ margin: '15px 20px 15px 0' }}>
              案件列表
            </Divider>
            <Row justify="center" gutter={16}>
              <Col xs={24} sm={24} className="gutter-row caseListTable">
                <ResultTable tableCols={casesListCols} dataSource={casesListData} rowKey={(record) => record.appCaseNo} />
              </Col>
              {/* size={'small'} */}
            </Row>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
};

export default Report;
