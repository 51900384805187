import Cookies from 'universal-cookie';
import {
	ADMIN_API_URL as api_admin_url,
	API_URL as api_url,
} from 'src/config';

const cookies = new Cookies();
const reqOptions = {
  get: {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  },
  getWithJwt: {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${cookies.get('_root_auth_token')}`
    }
  },
  postFormData: {
    method: "POST",
    headers: { "Authorization": `Bearer ${cookies.get('_root_auth_token')}` },
  },
  patch: {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
  },
  patchWithJwt: {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${cookies.get('_root_auth_token')}`
    }
  },
  delete: {
    method: "DELETE",
    headers: { "Content-Type": "text/html" },
  },
};

// 新增banner
const addBanner = async (formData) => {
  return fetch(
    `${api_admin_url}/banners`,
    {
      ...reqOptions.postFormData,
      body: formData
    }
  );
};

// 取得banner資料
const getBannerList = async (type) => {
  return fetch(
    `${api_admin_url}/banners?type=${type}`,
    reqOptions.getWithJwt
  );
};

// 修改banner
const editBannerInfo = async (bannerNo, payload) => {
  return fetch(
    `${api_admin_url}/banners/${bannerNo}`,
    {
      ...reqOptions.patchWithJwt,
      body: JSON.stringify(payload)
    }
  );
};

// 取得banner圖檔
const getBannerImgs = async (payload) => {
  let { all, type } = payload;
  return fetch(
    `${api_admin_url}/banner/images?access_token=${cookies.get('_merchant_auth_token')}&all=${all}&type=${type}`,
    reqOptions.get
  );
};

// 刪除banner
const deleteBanner = async (bannerNo) => {
  return fetch(
    `${api_admin_url}/banner/${bannerNo}?access_token=${cookies.get('_root_auth_token')}`,
    reqOptions.delete
  );
};

// 修改banner order
const editBannerOrder = async (payload) => {
  return fetch(
    `${api_admin_url}/banner/order?access_token=${cookies.get('_root_auth_token')}`,
    {
      ...reqOptions.patch,
      body: JSON.stringify(payload)
    }
  );
};

// 取得官方 banner 列表
const getFrontBannerImg = async () => {
  return fetch(
    `${api_url}/banners`,
  );
};

export const bannerService = {
  addBanner,
  getBannerList,
  editBannerInfo,
  getBannerImgs,
  deleteBanner,
  editBannerOrder,
  getFrontBannerImg
};
