import React from "react";

// antd
import { Row, Col } from "antd";

// swiper.js
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";

// component
import FrontDivCenter from "src/components/Front/Divider/DivCenter";
import AdditionalBtn from "src/assets/ecommerce/additional_service_btn.png";
import AdditionalBtnHover from "src/assets/ecommerce/additional_service_btn_hover.png";
import icon_13 from "src/assets/ecommerce/icon_13.png";
import icon_14 from "src/assets/ecommerce/icon_14.png";
import icon_15 from "src/assets/ecommerce/icon_15.png";
import icon_16 from "src/assets/ecommerce/icon_16.png";

const Section1 = () => {
  const services = [
    {
      id: 1,
      icon: icon_13,
      title: "<p>Google我的商家</p>",
      content: `
        <p>『線上搜尋』+『網路評價』</p>
        <p>「關鍵字搜尋優化」創造優質好口碑</p>
      `
    },
    {
      id: 2,
      icon: icon_14,
      title: "<p>Line社群顧客行銷</p>",
      content: `
        <p>專屬社群行銷，</p>
        <p>維持留存率，提高回購率</p>
      `
    },
    {
      id: 3,
      icon: icon_15,
      title: "<p>社群行銷操作</p>",
      content: `
        <p>代操Blog/FB/IG</p>
        <p>『口碑行銷』+『帶貨直播』</p>
        <p>KOL操作，撰寫社群口碑圖文分享</p>
      `
    },
    {
      id: 4,
      icon: icon_16,
      title: "<p>智能AI行銷系統</p>",
      content: `
        <p>人工智慧分析，</p>
        <p>自動判定廣告成效好壞，</p>
        <p>提升成效預測準確率</p>
      `
    },
  ];

  const downloadFile = () => {
    fetch('services-intro.pdf').then(response => {
      response.blob().then(blob => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'Jet捷店加值服務介紹.pdf';
        alink.click();
      })
    })
  };

  return (
    <Row justify="center" style={{ marginTop: 70 }}>
      <Col span={24}>
        <h2 className="front" style={{ margin: 0 }}>
          <p style={{ display: "block" }}>MarTech行銷科技交給JetShop</p>
          <p>讓您用有限預算達到最高效益，</p>
          <p>業績一路紅！</p>
        </h2>
        <FrontDivCenter />

        <div>
          <a 
            href="https://lin.ee/ceg4qRV"
            target="_blank" rel="noreferrer"
          >
            <button className="additional-service-btn">
              <img
                className="unhover-img"
                src={AdditionalBtn}
                alt="點我速洽"
              />
              <img
                className="hover-img"
                src={AdditionalBtnHover}
                alt="點我速洽"
              />
            </button>
          </a>
        </div>
      </Col>
      <Col span={24} className="service-info">
        <Row justify="center">
          <Col span={22}>
            <div className="swiper-button-prev"></div>
            <div className="swiper-button-next"></div>
            <Swiper
              className="additional-service-swiper"
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }}
              slidesPerView={1}
              spaceBetween={20}
              loop
              modules={[Navigation]}
              breakpoints={{
                576: {
                  slidesPerView: 2.3,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
              }}
            >
              {services.map(item => (
                <SwiperSlide key={item.id}>
                  <div className="service-box">
                    <div className="service-img">
                      <img src={item.icon} alt={item.title} />
                    </div>
                    <div className="title" dangerouslySetInnerHTML={{__html: item.title}}></div>
                    <div className="content" dangerouslySetInnerHTML={{__html: item.content}}></div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </Col>
        </Row>
        <div className="frontLink" style={{ paddingRight: 20 }}>
          <button onClick={downloadFile}>{"more >>"}</button>
        </div>
      </Col>
    </Row>
  );
}

export default Section1;
