import React from "react";

// antd & css
import { Row, Col } from "antd";
import "./Terms.css";

// component
import FrontBreadcrumb from "src/components/Front/Breadcrumb";

const Privacy = () => {
  return (
    <div className="text-left">
      <FrontBreadcrumb
        link={[
          { href: '', name: "隱私權條款" }
        ]}
      />
      <div className="frontTitleImage">
        <h1 className="frontTitle">
          <span className="linearText">隱</span>私權條款
        </h1>
      </div>
      <Row align="center">
        <Col span={23} md={23} sm={21} xs={21}>
          <h2 style={{ marginTop: 0 }}>一、隱私權聲明</h2>
          <p>
            歡迎使用由大方藝彩行銷顧問股份有限公司、其關係企業及子公司（個別及統稱「JetShop」或「我們」）經營的JetShop平台。
            JetShop認真看待其依據適用之隱私權及個人資料保護相關法律及規範（下稱「隱私權法」）下的責任。我們瞭解您委託給我們的個人資料極為重要，
            我們將妥善管理、保護及處理您的個人資料是我們的責任，使用本網站時，您同意受本隱私權聲明條款及條件。
            此隱私權政策（下稱「隱私權政策」或「政策」）旨在協助您瞭解我們目前和未來會如何收集、使用、揭露及/或處理您提供給我們及/
            或我們所擁有和您有關的個人資料，為了讓您能夠安心的使用本網站的各項服務與資訊，特此向您說明本網站的隱私權保護政策，
            <span className="highlight-text">以保障您的權益，請您詳閱下列內容：</span>
          </p>
          <h2>二、個人資料蒐集</h2>
          <p className="highlight-text">＊ 我們會/可能會在下列時候收集您的個人資料：</p>
          <ol className="term">
            <li>
              當您註冊及/或使用我們的本服務或本網站，或是向我們開立帳戶時
            </li>
            <li>
              當您透過線上或實體表單方式提交表單，包括但不限於申請表或是與我們的產品或服務相關的其他表單時
            </li>
            <li>
              當您簽定協議，或是針對您與我們的互動提供其他文件或資訊，或是當您使用我們的產品和服務時
            </li>
            <li>
              當您透過電話（可能會錄音）、信件、傳真、面對面會議、社交媒體平台和電子郵件等方式與我們互動時
            </li>
            <li>
              當您使用我們的電子服務，或是透過我們的應用程式或使用我們網站上的服務與我們互動時。這包括但不限於透過 
              Cookie（可能會在您與我們的應用程式或網站互動時部署）
            </li>
            <li>
              當您透過我們的本服務進行交易時
            </li>
            <li>
              當您提供意見或投訴給我們時
            </li>
            <li>
              當您基於任何原因提交個人資料給我們時
            </li>
          </ol>
          <p className="highlight-text">＊ 個人資料蒐集的項目包括但不限於：</p>
          <ol className="term">
            <li>姓名</li>
            <li>電子郵件地址</li>
            <li>出生日期</li>
            <li>現住地址</li>
            <li>銀行帳戶和付款資訊</li>
            <li>電話號碼</li>
            <li>當使用者註冊使用我們的本服務或網站及使用者使用本服務或網站時所提供有關使用者的其他任何資訊，以及使用者如何使用我們的本服務或網站的相關資訊</li>
          </ol>
          <h2>三、個人資料的利用</h2>
          <p className="highlight-text">
            ＊ 本網站於達成利用目的之必要範圍內，得將使用者之個人資料檔案，依照原來所說明的使用目的和範圍於本網站各公司間共同利用，例示如下：
          </p>
          <ol className="term">
            <li>
              使用者對商品購買、參與贈獎等之活動或從事其他交易時，關於商品配送、價金給付、回覆使用者之詢問、本網站對使用者之詢問、
              相關售後服務及其他遂行交易所必要之業務。
            </li>
            <li>
              使用您的資料以幫助我們提供您所要求的服務，我們使用您建立之個人資料檔案中的資料及我們從您現在或過去在網站上的行為所取得之其他資料，
              來解決爭議、檢修障礙問題及執行我們的會員合約。
            </li>
            <li>
              本網站之宣傳廣告或行銷等提供使用者各種電子雜誌等資訊、透過電子郵件、郵件、電話等提供與服務有關之資訊將使用者所瀏覽之內容或廣告，
              依使用者之性別、年齡、居住地、興趣、嗜好等個人屬性或購買紀錄、本網站之瀏覽紀錄等項目，進行個人化作業、使用者使用服務之分析、
              新服務之開發或既有服務之改善等針對民調、活動、留言版等之意見資訊交換、等服務關連事項，與使用者進行聯繫。以上之分析、作業，
              可能透過本網站之合作對象進行，亦可能於台灣以外地區進行。
            </li>
            <li>
              回覆使用者之詢問針對使用者透過電子郵件、郵件、電話等方式向本網站所提出之詢問進行回覆。
            </li>
            <li>
              其他業務隨附之事項附隨於上述(1)至(4)之利用目的而為本網站提供服務所必要之使用。
            </li>
          </ol>
          <h2>四、與第三人共用個人資料之政策</h2>
          <ol className="term">
            <li>本網站絕不會提供、交換、出租或出售任何您的個人資料給其他個人、團體、私人企業或公務機關，但有法律依據或合約義務者，不在此限。</li>
          </ol>
          <p className="highlight-text">＊ 前項但書之情形包括但不限於：</p>
          <ul>
            <li>經由您書面同意。</li>
            <li>法律明文規定。</li>
            <li>為免除您生命、身體、自由或財產上之危險。</li>
            <li>與公務機關或學術研究機構合作，基於公共利益為統計或學術研究而有必要，且資料經過提供者處理或蒐集者依其揭露方式無從識別特定之當事人。</li>
            <li>
              與您在網站的行為，違反服務條款或可能損害或妨礙網站與其他使用者權益或導致任何人遭受損害時，經網站管理單位研析揭露您的
              個人資料是為了辨識、聯絡或採取法律行動所必要者。
            </li>
            <li>有利於您的權益。</li>
            <li>本網站委託廠商協助蒐集、處理或利用您的個人資料時，將對委外廠商或個人善盡監督管理之責。</li>
          </ul>
          <h2>五、個人資料的存取及資安</h2>
          <ol className="term">
            <li>JetShop將以合於產業標準之合理技術及程序，防止個人資料被竊取、竄改、毀損、滅失或洩漏，並保障資料傳送、維護之安全。</li>
          </ol>
          <h2>六、網站對外的相關連結</h2>
          <ol className="term">
            <li>
              本網站的網頁提供其他網站的網路連結，您也可經由本網站所提供的連結，點選進入其他網站。但該連結網站不適用本網站的
              隱私權保護政策，您必須參考該連結網站中的隱私權保護政策。
            </li>
          </ol>
          <h2>七、Cookie之使用</h2>
          <ol className="term">
            <li>
              為了提供您最佳的服務，本網站會在您的電腦中放置並取用我們的 Cookie，若您不願接受 Cookie的寫入，您可在您使用的
              瀏覽器功能項中設定隱私權等級為高，即可拒絕 Cookie的寫入，但可能會導致網站某些功能無法正常執行。
            </li>
          </ol>
          <h2>八、修訂之權利</h2>
          <ol className="term" style={{ marginBottom: 50 }}>
            <li>
              本網站有權隨時修訂本隱私權聲明及相關告知事項，並得於修訂後公佈在網站上或行動應用程式之適當位置，不另行個別通知，
              您可以在網站上或行動應用程式詳閱修訂後的隱私權聲明及相關告知事項。
            </li>
          </ol>
        </Col>
      </Row>
    </div>
  );
}

export default Privacy;
