import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { actionCreators as merchantActionCreators } from 'src/actions/merchant';
import { actionCreators } from 'src/actions/auth';
import { useSelector, useDispatch } from "react-redux";

import { Row, Col, Dropdown, Menu, Button } from 'antd';
import {
  DownOutlined,
} from '@ant-design/icons';
import "antd/dist/antd.css";

import './Header.css';


const Header = () => {
  const dispatch = useDispatch();
  // 取出 store 中的 state
  const personalInfo = useSelector(state => state.merchant.storePersonalInfo);

  // action : 取得個人帳號資訊
  useEffect(() => {
    dispatch(merchantActionCreators.getPersonalInfo());
  }, []);

  const handleMenuClick = (e) => {
    if (e.key === 'logout') {
      dispatch(actionCreators.logout());
    }
  }

  const menu = (
    <Menu className="menuList" onClick={handleMenuClick}>
      <Menu.Item key="userEdit">
        <Link to="/admin/user/user-edit">
          個人資料維護
        </Link>
      </Menu.Item>
      <Menu.Item key="logout">
        登出
      </Menu.Item>
    </Menu>
  );

  return (
    <Row className="header" justify="end">
      <Col>
        <Dropdown overlay={menu}>
          <Button className="headerBtn">
            {personalInfo.merAdmName}<DownOutlined />
          </Button>
        </Dropdown>
      </Col>
    </Row>
  )
}

export default Header;