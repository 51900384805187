import React, { useState, useEffect } from "react";

// antd
import { Row, Col } from "antd";

// component
import FrontDivCenter from "src/components/Front/Divider/DivCenter";
import FrontDivLeft from "src/components/Front/Divider/DivLeft";

const Section4 = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isSmallSize, setIsSmallSize] = useState(windowWidth < 768);

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);
    onWindowResize();
  
    return () => window.removeEventListener("resize", onWindowResize);
  }, [windowWidth]);

  const onWindowResize = () => {
    setWindowWidth(window.innerWidth);
    setIsSmallSize(window.innerWidth < 768);
  };

  return (
    <Row justify="center" style={{ marginTop: 70 }}>
      <Col span={22} md={10} sm={22}>
        {
          isSmallSize ? (
            <div>
              <h2 className="front" style={{ margin: 0 }}>
                聽聽其他使用者怎麼說
              </h2>
              <FrontDivCenter />
              <h3 className="front">最懂你的想要、最快給你需要</h3>
              <p style={{ margin: "0 2em 2em" }}>
                想申請商品分期的消費者，切記要確認商品分期月付金額是否就是以後每月繳費的金額，
                有沒有額外費用，是否可一次繳清，這些相關自身權益一定要確認清楚。
              </p>
            </div>
          ) : (
            <div className="userFeedback">
              <h2 className="front" style={{ margin: 0 }}>
                商品分期，快速核貸
              </h2>
              <FrontDivLeft />
              <h3 className="front">最懂你的想要、最快給你需要</h3>
              <p style={{ marginRight: "4em", marginBottom: 0 }}>
                【機車/中古車無卡分期】剛出社會的小萌新們，您的需要就是JetShop的使命，JetShop機車/中古車無卡分期服務上線囉~
              </p>
              <p style={{ marginRight: "4em" }}>
                有需要的小夥伴們可速洽客服洽詢全台特約商為您服務。
              </p>
              <div className="divider-left">
                <div className="divider reverse"> 
                  <span></span>
                </div>
              </div>
            </div>
          )
        }
      </Col>
      <Col span={22} md={10} sm={22}>
        <iframe 
          title="Jetshop youtube video"
          width="100%"
          height={240}
          src="https://www.youtube.com/embed/3GYXieq7jlw" 
          frameBorder="0"
          allowFullScreen
        >
          您的瀏覽器不支援iframe
        </iframe>
      </Col>
    </Row>
  );
}

export default Section4;
