import moment from 'moment';

// Ant design
import { Row, Col, Space, Button, Input, Select, Radio, Tooltip, DatePicker } from 'antd';
import { ClockCircleOutlined, SearchOutlined } from '@ant-design/icons';

const { Option } = Select;

const SearchBox = (props, ref) => {
  const { branchesData, branchesLoading, quickSearch, onChangeQuickSearch, dateType, startDate, endDate, onOpenChange, disabledDate, onCalendarChange, onChangeDate, onChangeDateType, search } = props;

  const onChangeBranches = (value) => {
    console.log(`selected ${value}`);
    props.onChangeBranches(value);
  };

  return (
    <div className="searchBox">
      <Row align="middle">
        <Col flex="90px">
          <span>選擇分店</span>
        </Col>
        <Col flex={1}>
          <Select placeholder="請選擇分店" onChange={onChangeBranches} defaultValue={'全部合計'} style={{ width: 200 }} loading={branchesLoading} optionLabelProp="label">
            {/* <Option key="totalMerAcctId" value={`total-${totalMerAcctId}`} label={'全部合計'}> */}
            <Option key="totalMerAcctId" value="all" label={'全部合計'}>
              全部合計
            </Option>
            {branchesData?.map((branch) => {
              const { merAcctId, merCompName, merIsBranch } = branch;
              return (
                <Option
                  key={merAcctId}
                  value={merAcctId}
                  label={`${merAcctId}-${merCompName}
                  ${merIsBranch ? '' : '(總店)'}`}
                >
                  {merAcctId}-{merCompName}
                  {merIsBranch ? '' : '(總店)'}
                </Option>
              );
            })}
          </Select>
        </Col>
      </Row>
      <Row align="middle" style={{ marginTop: '15px' }}>
        <Col flex="90px">
          <span>日期類別</span>
        </Col>
        <Col flex={1}>
          <Radio.Group onChange={onChangeDateType} value={dateType}>
            <Radio value={'applyDate'}>申請日期</Radio>
            <Radio value={'merPayDate'}>撥款日期</Radio>
          </Radio.Group>
        </Col>
      </Row>
      <Row align="middle" style={{ marginTop: '15px' }}>
        <Col flex="90px">
          <span>起訖日期</span>
        </Col>
        {/* <Col flex={1}> */}
        <Col flex={1}>
          <Space size={[10, 5]} wrap>
            <Radio.Group buttonStyle="solid" value={quickSearch} onChange={onChangeQuickSearch}>
              <Radio.Button value="0">
                <ClockCircleOutlined /> 近七日
              </Radio.Button>
              <Radio.Button value="1">
                <ClockCircleOutlined /> 近一個月
              </Radio.Button>
              <Radio.Button value="2">
                <ClockCircleOutlined /> 近三個月
              </Radio.Button>
            </Radio.Group>
            <div>
              <Input.Group compact>
                <Input style={{ width: '53px', background: '#fff', color: 'rgba(0, 0, 0, 0.85)' }} defaultValue="自訂" className="disabledInput" disabled />
                <DatePicker.RangePicker
                  placeholder={['申請開始日', '申請結束日']}
                  disabledDate={disabledDate}
                  onCalendarChange={onCalendarChange}
                  onChange={onChangeDate}
                  onOpenChange={onOpenChange}
                  value={startDate === null || endDate === null || startDate === '' || endDate === '' ? null : [moment(startDate, 'YYYY-MM-DD'), moment(endDate, 'YYYY-MM-DD')]}
                />
                <Tooltip placement="bottom" title="搜尋">
                  <Button type="primary" icon={<SearchOutlined />} onClick={search} />
                </Tooltip>
              </Input.Group>
            </div>
            <div>
              <span>* 自訂查詢最多不可超過3個月</span>
            </div>
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default SearchBox;
