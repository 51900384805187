import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { actionCreators } from "src/actions/apply-type";
import { actionCreators as actionRootCreators } from "src/actions/root";
import { applyTypeService } from "src/services";

// components
import AddApplyType from "./AddApplyType";

// antd & css
import { Layout, Row, Col, Card, Space, Tooltip, Table, Empty, Button, Switch, Input, Form, Modal, message } from "antd";
import { MenuOutlined, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";

// drag components
const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: "grab", color: "#999" }} />);
const SortableItem = SortableElement(props => <tr {...props} />);
const SortableBody = SortableContainer(props => <tbody {...props} />);

// render editable cell
const EditableCell = ({editing, dataIndex, title, inputType, record, index, children, ...restProps}) => {
  let inputNode = null;
  if (inputType === "switch") {
    inputNode = <Switch defaultChecked={record.appTypeDisplay} checkedChildren="顯示" unCheckedChildren="隱藏"/>;
  } else if (inputType === "inputLengthLimited") {
    inputNode = <Input maxLength={2} showCount />;
  } else {
    inputNode = <Input/>
  }
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          valuePropName={dataIndex === "appTypeDisplay" ? "checked" : "value"}
          style={{margin: 0}}
          rules={[
            {
              required: true,
              message: "必填"
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (children)}
    </td>
  );
};

const RootApplyType = () => {
  const [form] = Form.useForm();
  const [rowData, setRowData] = useState([]);
  const [editingKey, setEditingKey] = useState("");

  // 取出 store 中的 state
  const applyTypeList = useSelector(state => state.applyType.applyTypeList);

  const dispatch = useDispatch();

  // step1: 取得案件類型
  useEffect(() => {
    dispatch(actionCreators.getApplyType());
  }, []);

  // step2: 案件類型配值
  useEffect(() => {
    let data = [];
    let index = 0;
    if (applyTypeList && applyTypeList.length >= 1) {
      applyTypeList.forEach(item => {
        data.push({
          key: `${item.appTypeNo}`,
          appTypeNo: item.appTypeNo,
          appTypeSerNo: item.appTypeSerNo,
          appSymbol: item.appSymbol,
          appCaseType: item.appCaseType,
          appTypeDisplay: item.appTypeDisplay,
          index: index,
        });
        index += 1;
      });
    };
    data.sort((a, b) => {
      return a.appTypeSerNo - b.appTypeSerNo;
    });
    setRowData(data);
  }, [applyTypeList]);

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      appSymbol: "",
      appCaseType: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  // open: 新增視窗
  const handleAddModal = () => {
    dispatch(actionRootCreators.modalVisible());
  };

  // 編輯-儲存
  const save = async (key) => {
    try {
      const row = await form.validateFields();

      // 前端更新表格
      const newData = [...rowData];
      const index = newData.findIndex(item => key === item.key);
      const item = newData[index];
      newData.splice(index, 1, { ...item, ...row }); 

      // api 處理
      handleEditApplyType(newData, index);
    } catch (err) {
      console.log("Failed:", err);
    }
  };

  const handleEditApplyType = (data, index) => {
    const info = data[index];
    const appTypeNo = info.appTypeNo;
    const payload = {
      appSymbol: info.appSymbol,
      appCaseType: info.appCaseType,
      appTypeDisplay: + info.appTypeDisplay,
    };
    applyTypeService.editApplyType(appTypeNo, payload)
    .then(
      res => {
        if (res.status === 204) {
          setRowData(data);
          setEditingKey("");
          message.success("編輯成功");
        }
        else if (res.status === 401) message.warning("存取拒絕");
        else if (res.status === 404) message.warning("找不到案件類型");
        else message.error("編輯失敗");
      },
      error => {
        console.log(error);
      },
    );
  };

  // 編輯-刪除
  const handleDelete = async (key) => {
    const { confirm } = Modal;
    confirm({
      title: "確定要刪除此筆資料嗎？",
      onOk: () => {
        try {
          applyTypeService.deleteApplyType(key)
          .then(
            res => {
              if (res.status === 204) {
                // 前端更新表格
                const dataSource = [...rowData];
                setRowData(() => dataSource.filter(item => item.key !== key));
                setEditingKey("");
                message.success("刪除成功");
              }
              else if (res.status === 401) message.warning("存取拒絕");
              else if (res.status === 404) message.warning("找不到案件類型");
              else if (res.status === 409) message.warning("無法刪除已使用的案件類型");
              else message.error("刪除失敗");
            },
            error => {
              console.log(error);
            },
          );
        } catch (error) {
          console.log(error);
        }
      },
    });
  };

  // 排序後結果
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      // 前端更新表格
      const newData = arrayMoveImmutable([].concat(rowData), oldIndex, newIndex).filter(
        el => !!el,
      );
      newData.forEach((item, idx) => {
        item.appTypeSerNo = idx + 1;
      });
      setRowData(newData);

      // api 處理
      handleResort(newData, newIndex);
    };
  };

  const handleResort = async (data, index) => {
    const appTypeNo = data[index].appTypeNo;
    const payload = { appTypeSerNo: index + 1 };
    applyTypeService.editApplyTypeOrder(appTypeNo, payload)
    .then(
      res => {
        if (res.status === 204) message.success("排序修改成功");
        else if (res.status === 401) message.warning("存取拒絕");
        else if (res.status === 404) message.warning("找不到案件類型");
        else if (res.status === 409) message.warning("排序超出範圍");
        else message.error("修改失敗");
      },
      error => {
        console.log(error);
      },
    );
  };

  // 拖曳內容區塊
  const DraggableContainer = props => (
    <SortableBody
      useDragHandle
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = rowData.findIndex(x => x.index === restProps["data-row-key"]);
    return <SortableItem index={index} {...restProps} />;
  };

  const columns = [
    {
      title:
        <div>
          拖曳&nbsp;
          <Tooltip placement="top" title="拖曳以改變順序">
            <QuestionCircleOutlined style={{cursor: "help"}} />
          </Tooltip>
        </div>,
      dataIndex: "drag",
      width: "70px",
      render: () => <DragHandle />,
    },
    {
      title: "案件類型代碼",
      dataIndex: "appTypeNo",
      width: "100px",
    },
    {
      title: "案件類型排序",
      dataIndex: "appTypeSerNo",
      width: "100px",
    },
    {
      title: "案件類型編碼",
      dataIndex: "appSymbol",
      editable: true,
      width: "100px",
    },
    {
      title: "案件類型說明",
      dataIndex: "appCaseType",
      editable: true,
      width: "150px",
    },
    {
      title: "案件類型狀態",
      dataIndex: "appTypeDisplay",
      editable: true,
      render: (appTypeDisplay) => (<span>{appTypeDisplay ? "顯示" : "隱藏"}</span>),
      width: "100px",
    },
    {
      title: "操作",
      dataIndex: "operation",
      width: "150px",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Space>
            <a onClick={() => save(record.key)}>儲存</a>
            <a onClick={() => setEditingKey("")}>取消</a>
            <a onClick={() => handleDelete(record.key)} style={{ color: "red" }}>刪除</a>
          </Space>
        ) : (
          <a disabled={editingKey !== ""} onClick={() => edit(record)}>
            編輯
          </a>
        );
      },
    },
  ];

  const mergedColumns = columns.map(col => {
    if (!col.editable) return col;

    return {
      ...col,
      onCell: record => ({
        record,
        inputType: (function() {
          if (col.dataIndex === "appTypeDisplay") return "switch";
          else if (col.dataIndex === "appSymbol") return "inputLengthLimited";
          else return "input";
        }()),
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Layout className="rootList">
      <Row justify="center">
        <Col span={23}>
          <div className="title">案件類型設定</div>

          <Card className="rootListCard">
            <Row>
              <Col align="right" span={24} xs={24} sm={24} md={24} >
                <Button icon={<PlusOutlined />} onClick={handleAddModal}>新增</Button>
                <AddApplyType />
              </Col>
            </Row>
            <Row style={{margin: "20px 0"}}>
              <Form form={form} component={false}>
                <Table
                  components={{body: {
                    cell: EditableCell,
                    wrapper: DraggableContainer,
                    row: DraggableBodyRow,
                  }}}
                  columns={mergedColumns}
                  dataSource={rowData}
                  rowClassName="editable-row"
                  rowKey={record => record.index}
                  locale={{emptyText: (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>無資料</span>} />
                  )}}
                  size="small"
                  pagination={false}
                  scroll={{y: 400}}
                />
              </Form>
            </Row>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}

export default RootApplyType;
