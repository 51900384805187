import React from "react";

// antd & css
import { Row, Col } from "antd";
import "./Terms.css";

// component
import FrontBreadcrumb from "src/components/Front/Breadcrumb";

const Payment = () => {
  return (
    <div className="text-left">
      <FrontBreadcrumb
        link={[
          { href: '', name: "支付使用條款" }
        ]}
      />
      <div className="frontTitleImage">
        <h1 className="frontTitle">
          <span className="linearText">支</span>付使用條款
        </h1>
      </div>
      <Row align="center">
        <Col span={23} md={23} sm={21} xs={21}>
          <p>
            歡迎使用JetShop平台 (下稱「本網站」)。使用本網站或開設JetShop分期帳戶管理 (下稱「帳戶」) 前請詳細閱讀以下服務條款，
            當會員完成 本網站之會員註冊手續、或開始使用本服務時，即表示已完全閱讀、瞭解並同意接受本服務條款及「隱私權聲明」之全部內容，
            包括 但不限於本服務現有與未來衍生的服務項目及內容。
          </p>
          <p>
            如未滿二十歲之未成年人使用本網站提供之服務時，應得法定代理人同意，並遵守本合約及相關法律規定。若該未成年會員與交易相對人其他第三人，
            因使用本網站服務所發生之糾紛，本網站概不負責。當未滿二十歲的您使用或繼續使用本網站時，即表示您的法定代理人（或監護人）已閱讀、瞭解
            並同意接受本約定書之所有內容及其後續之修改或變更。
          </p>
          <p>
            會員同意使用本服務之意思表示等，皆以電子文件為表示方式。
          </p>
          <h2>一、會員服務條款</h2>
          <ol className="term">
            <li>
              本會員服務條款所稱之「會員」，為依照本站所定之加入會員程序加入完成並通過認證者或申請帳務分期者，提供本人正確、
              最新及完整的個人資料。
            </li>
            <li>
              當您使用本站服務時，即表示您同意及遵守本服務條款的規定事項及相關法律之規定。
            </li>
            <li>
              本站保留有審核加入會員資格之權利，另外已加入會員者，本站亦保留有解除其會員資格之權利。
            </li>
            <li>
              本會員服務條款之修訂，適用於所有會員，當本站修訂本服務條款時，將於本站上公告。
            </li>
          </ol>
          <h2>二、隱私權保護</h2>
          <ol className="term">
            <li>
              尊重並依據本網站「隱私權保護聲明」保護您的隱私（請參閱「隱私權保護聲明」條款），
              本網站「隱私權聲明」之訂定均係依中華民國「個人資料保護法」為準則依據。
            </li>
          </ol>
          <h2>三、帳號、密碼及安全</h2>
          <ol className="term">
            <li>
              使用本站所提供之會員服務時，於加入會員時所登錄之帳號及密碼使用之。
            </li>
            <li>
              會員須善盡帳號及密碼的使用與管理之責任。對於使用該會員之帳號及密碼（無關於會員本身或其他人）利用本站服務所造成或衍生之所有行為及結果，
              會員須自行負擔全部責任。
            </li>
            <li>
              會員之帳號及密碼遺失，或發現無故遭第三者盜用時，應立即通知本站連絡掛失，因未即時通知，導致本站無法有效防止及修改時，
              所造成的所有損失，會員應自負全責。
            </li>
            <li>
              每次結束使用本服務，執行會員之登出並關閉視窗，以確保您的會員權益。
            </li>
            <li>
              盜用第三者會員之帳號及密碼，導致第三者或本公司遭其他第三人或行政機關之調查或追訴時，第三者會員或本公司有權向您請求損害賠償，
              包括但不限於訴訟費用、律師費及商譽損失等。
            </li>
            <li>
              會員帳號或密碼資料不能洩露、轉讓、贈與、出租或借貸予他人。
            </li>
            <li>
              非經本網站之書面同意，會員不能與他人共用帳號。
            </li>
            <li>
              會員登錄資料不得有偽造、不實等之情事（如會員資料），一經發現本網站可拒絕其加入會員資格之權利。並得以暫停或終止其會員資格，
              若違反中華民國相關法律，亦將依法追究。
            </li>
          </ol>
          <h2>四、使用者的守法義務及承諾</h2>
          <ol className="term">
            <li>
              會員承諾絕不為任何非法目的或以任何非法方式使用本服務，並承諾遵守中華民國相關法規及一切使用網際網路之國際慣例。
              會員若係中華民國以外之使用者亦同，對於使用者的一切行為，您須自行負擔全部責任。
            </li>
            <li>
              您同意絕不為非法之目的或以非法方式使用本服務，與確實遵守中華民國相關法規及網際網路之國際慣例。並保證不得利用本服務從事侵害他人權益或違法之行為。
            </li>
          </ol>
          <p className="highlight-text">＊ 您於使用本站會員服務時應遵守以下限制：</p>
          <ul>
            <li>有損他人人格或商標權、著作權等智慧財產權或其他權利內容。</li>
            <li>使用違反公共秩序或善良風俗或其他不法之文字。</li>
            <li>強烈政治、宗教色彩的偏激言論。</li>
            <li>未經本公司許可，不得利用本服務或本網站所提供其他資源，包括但不限於圖文資料庫、編寫製作網頁之軟體等，從事任何商業交易行為，或招攬廣告商或贊助人。</li>
            <li>其他違反本站「會員服務條款」的內容。</li>
          </ul>
          <h2>五、服務終止</h2>
          <ol className="term">
            <li>
              您使用本服務之行為若有任何違反法令或本使用條款或危害本網站或第三者權益之虞時，本網站有權不經告知您，立即暫時或永久終止您使用本服務之授權，
              本網站保留追究賠償責任的權利。
            </li>
          </ol>
          <h2>六、商品後續維修保固</h2>
          <ol className="term">
            <li>
              商品後續維修保固及售後服務均由特約商負責，會員瞭解本網站不提供後續售後商品維修保固等。
            </li>
          </ol>
          <h2>七、商品後續消費爭議</h2>
          <ol className="term">
            <li>
              如有消費爭議情事發生時，除已向消費者保護機構或司法機關聲請調解或提起訴訟，並取得可止付款項之相關證明者，不得逕行止付分期款項。
            </li>
          </ol>
          <h2>八、終止會員資格</h2>
          <ol className="term">
            <li>
              本網站保留有審核加入會員資格之權利，另外已加入會員者，本網站亦保留有解除其會員資格之權利。
            </li>
          </ol>
          <h2>九、智慧財產的保護</h2>
          <ol className="term">
            <li>
              本網站所使用之軟體、圖片或程式，網站上所有內容包括但不限於圖像、產品設計、著作、圖片、檔案、資訊、資料、網站畫面的安排、
              網頁設計均由本網站或其他權利人依法擁有其智慧財產權，包括但不限於商標權、專利權、著作權、營業秘密與專有技術等。
            </li>
            <li>
              任何人未經本網站及權利人授權，不得以任何方式擅自使用、展示、修改、重置、公開播送、公開傳輸、轉載、改作、散佈、發行、公開發表，
              進行還原工程解編或反向組譯。如有違反，除依著作權法及相關法律規定論處，並應對本網站負損害賠償責任（包括但不限於訴訟費用及律師費用等）。
            </li>
            <li>
              對於會員所登錄之個人資料，會員同意本網站得於合理之範圍內蒐集、處理、保存、傳遞及使用該等資料，以提供使用者其他資訊或服務、
              或作成會員統計資料、或進行關於網路行為之調查或行銷研究。
            </li>
          </ol>
          <h2>十、管轄法院</h2>
          <ol className="term" style={{ marginBottom: 50 }}>
            <li>
              本約定書之解釋與適用，以及與本約定書有關的爭議，將依照中華民國法律規定，並以臺灣臺北地方法院為第一審管轄法院。
            </li>
          </ol>
        </Col>
      </Row>
    </div>
  );
}

export default Payment;
