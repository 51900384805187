import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { actionCreators } from "src/actions/auth";
import { useNavigate } from "react-router-dom";

// Ant design
import { Form, Input, Button, Image, Layout, Row, Col, Card, Typography } from "antd";
import { ShopOutlined, UserOutlined, LockOutlined } from '@ant-design/icons';
import "antd/dist/antd.css";

import './Login.css';
import logo from 'src/assets/JetShop_logo_orange.png';


const Login = () => {
  const { Text } = Typography;

  const [rules] = useState({
    merAcctId: [
      { required: true, message: '請輸入商家代碼', trigger: 'blur' },
    ],
    merAdmAcct: [
      { required: true, message: '請輸入帳號', trigger: 'blur' }
    ],
    merAdmPwd: [
      { required: true, message: '請輸入密碼', trigger: 'blur' }
    ]
  });
  const [errorMsg, setErrorMsg] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // 取出 store 中的 state
  const isLogined = useSelector(state => state.auth.isLogined);
  const loginErrorMsg = useSelector(state => state.auth.errorMsg);

  useEffect(() => {
    setErrorMsg(loginErrorMsg);
  }, [loginErrorMsg]);

  const onFinish = (values) => {
    let userInfo = { ...values, root: false };
    dispatch(actionCreators.login(userInfo));
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    return false;
  };

  if (isLogined) {
    navigate('/admin');
  }

  return (
    <Layout className="loginContainer">
      <Row className="row" justify="center" align="middle" gutter={16}>
        <Col xs={18} sm={16} md={10} lg={8} xl={6}>
          {/* JetShop 官網首頁 */}
          <a href="/">
            <Image className="logoImg" alt="JetShop Logo" src={logo} preview={false} />
          </a>
          <Card>
            <Form
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <div className="text">
                商家管理系統登入
              </div>

              <Form.Item
                className="inputItem"
                name="merAcctId"
                rules={rules.merAcctId}
              >
                <Input
                  placeholder="商家代碼"
                  prefix={
                    <ShopOutlined />
                  }
                />
              </Form.Item>

              <Form.Item
                className="inputItem"
                name="merAdmAcct"
                rules={rules.merAdmAcct}
              >
                <Input
                  placeholder="帳號"
                  prefix={
                    <UserOutlined />
                  }
                />
              </Form.Item>

              <Form.Item
                className="inputItem"
                name="merAdmPwd"
                rules={rules.merAdmPwd}
              >
                <Input.Password
                  placeholder="密碼"
                  prefix={
                    <LockOutlined />
                  }
                />
              </Form.Item>

              <Text type="danger">
                {errorMsg}
              </Text>

              <Button
                className="bgDefault loginBtn"
                htmlType="submit"
              >
                登入
              </Button>

            </Form>
          </Card>
        </Col>
      </Row>
    </Layout>
  )
};

export default Login;