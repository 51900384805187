import Cookies from 'universal-cookie';
import { ADMIN_API_URL as api_admin_url } from 'src/config';

const cookies = new Cookies();
const reqOptions = {
  get: {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${cookies.get('_root_auth_token')}`
    },
  },
  post: {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${cookies.get('_root_auth_token')}`
    },
  },
  patch: {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${cookies.get('_root_auth_token')}`
    },
  },
  delete: {
    method: "DELETE",
    headers: {
      "Content-Type": "text/html",
      "Authorization": `Bearer ${cookies.get('_root_auth_token')}`
    },
  },
};

// 取得案件類型
const getApplyType = async () => {
  return fetch(
    `${api_admin_url}/apply-type-indexes`,
    reqOptions.get
  );
};

// 新增案件類型
const addApplyType = async (payload) => {
  return fetch(
    `${api_admin_url}/apply-type-indexes`,
    {
      ...reqOptions.post,
      body: JSON.stringify(payload)
    }
  );
};

// 刪除案件類型
const deleteApplyType = async (appTypeNo) => {
  return fetch(
    `${api_admin_url}/apply-type-indexes/${appTypeNo}`,
    reqOptions.delete
  );
};

// 編輯案件類型
const editApplyType = async (appTypeNo, payload) => {
  return fetch(
    `${api_admin_url}/apply-type-indexes/${appTypeNo}`,
    {
      ...reqOptions.patch,
      body: JSON.stringify(payload)
    }
  );
};

// 修改案件類型 order
const editApplyTypeOrder = async (appTypeNo, payload) => {
  return fetch(
    `${api_admin_url}/apply-type-indexes/${appTypeNo}/order`,
    {
      ...reqOptions.patch,
      body: JSON.stringify(payload)
    }
  );
};

export const applyTypeService = {
  getApplyType,
  addApplyType,
  deleteApplyType,
  editApplyType,
  editApplyTypeOrder,
};
