import { Result } from 'antd';

const Error403 = () => {
  return (
    <Result
      status="403"
      title="403"
      subTitle="您無權限瀏覽此網頁"
    />
  )
};
export default Error403;