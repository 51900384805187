import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { actionCreators as actionRootCreators } from "src/actions/root";
import { actionCreators } from "src/actions/product-type";
import { productTypeService } from "src/services";

//antd design
import { Modal, Form, Input, Radio, Divider, Button, message } from "antd";

const rules = {
  prodItemDes: [
    { required: true, message: "請輸入商品項目說明", trigger: "blur" }
  ],
  prodItemDisplay: [
    { required: true }
  ],
};

const AddProductItem = (props) => {
  // 取出 store 中的 state
  const isVisible = useSelector(state => state.Root.modalVisible);

  const dispatch = useDispatch();

  // 取消
  const handleCancel = () => {
    dispatch(actionRootCreators.modalVisible());
  };

  // 儲存
  const onFinish = (values) => {
    const payload = {
      prodItemDes: values.prodItemDes,
      prodItemDisplay: values.prodItemDisplay,
    };
    productTypeService.addProductItem(props.prodTypeNo, payload)
    .then(
      res => {
        if (res.status === 201) {
          dispatch(actionCreators.getProductItem(props.prodTypeNo));
          dispatch(actionRootCreators.modalVisible());
          message.success("新增成功");
        }
        else if (res.status === 401) message.warning("存取拒絕");
        else if (res.status === 404) message.warning("找不到商品類型");
        else message.error("新增失敗");
      },
      error => {
        console.log(error);
      }
    );
  };
  
  // 儲存失敗
  const onFinishFailed = () => false;

  return (
    <Modal
      title="新增"
      visible={isVisible}
      onCancel={handleCancel}
      maskClosable={false}
      destroyOnClose
      footer={false}
    >
      <Form
        labelAlign="left"
        labelCol={{ flex: "120px" }}
        wrapperCol={{ span: 24, flex: 1 }}
        colon={false}
        initialValues={{ prodItemDisplay: 1 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className="rootFormItem">
          <Form.Item label="商品類型" name="prodType">
            <span>{props.prodTypeDes}</span>
          </Form.Item>
          <Form.Item label="商品項目說明" name="prodItemDes" rules={rules.prodItemDes}>
            <Input />
          </Form.Item>
          <Form.Item label="商品項目狀態" name="prodItemDisplay" rules={rules.prodItemDisplay}>
            <Radio.Group>
              <Radio value={1}>顯示</Radio>
              <Radio value={0}>隱藏</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        <Divider style={{margin: 0}} />
        <div style={{textAlign: "end", padding: "10px 16px"}}>
          <Button onClick={handleCancel} style={{marginRight: 10}}>取消</Button>
          <Button type="primary" htmlType="submit">儲存</Button>
        </div>
      </Form>
    </Modal>
  );
}

export default AddProductItem;