import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { actionCreators as actionRootCreators } from "src/actions/root";
import { actionCreators } from "src/actions/installment";
import { installmentService } from "src/services";

// components
import FormModal from "./FormModal";

// antd
import { Layout, Row, Col, Card, Select, Space, Table, Empty, Button, Form, Modal, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const RootInstallment = () => {
  const [form] = Form.useForm();
  const [interestRateOptions, setInterestRateOptions] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [operationType, setOprationType] = useState(null);
  const [insSerialNumber, setInsSerialNumber] = useState(null);
  const [intNo, setIntNo] = useState(0);

  // 取出 store 中的 state
  const installmentList = useSelector(state => state.installment.installmentList);
  const interestRateList = useSelector(state => state.installment.interestRateList);

  const dispatch = useDispatch();

  const dispatchFunc = (intNo) => dispatch(actionCreators.getInstallment(intNo));

  // step1: 取得使用中利率表 & 取得分期說明列表
  useEffect(() => {
    dispatchFunc(0);
    dispatch(actionCreators.getInterestRate());
  }, []);

  // step3: 利率表配值
  useEffect(() => {
    setInterestRateOptions(interestRateList);
  }, [interestRateList]);

  // step4: 分期說明配值
  useEffect(() => {
    let data = [];
    if (installmentList && installmentList.length > 0) {
      installmentList.forEach(item => {
        data.push({
          key: item.insSerialNumber,
          insSerialNumber: item.insSerialNumber,
          insInstallments: item.insInstallments,
          insButtonName: item.insButtonName,
          insDescription: item.insDescription,
        });
      });
    };
    setRowData(data);
  }, [installmentList]);

  const onChangeInterest = (e) => {
    dispatchFunc(e);
    setIntNo(e);
  };

  // open: 新增視窗
  const handleModalVisible = (type, record) => {
    dispatch(actionRootCreators.modalVisible());
    setOprationType(type);
    if (record) {
      setInsSerialNumber(record.insSerialNumber);
    };
  };

  const handleDelete = ({ insSerialNumber }) => {
    const { confirm } = Modal;
    confirm({
      title: "確定要刪除此筆資料嗎？",
      onOk: () => {
        try {
          const dataSource = [...rowData];
          installmentService.deleteInstallment(insSerialNumber)
          .then(
            res => {
              if (res.status === 204) {
                setRowData(() => dataSource.filter(item => item.key !== insSerialNumber));
                message.success("刪除成功");
              }
              else if (res.status === 401) message.warning("存取拒絕");
              else if (res.status === 404) message.warning("找不到分期說明");
              else if (res.status === 409) message.warning("請先清空綁定此分期的所有利率表");
              else message.error("刪除失敗");
            },
            error => {
              console.log(error);
            }
          );
        } catch (error) {
          console.log(error);
        }
      },
    });
  }

  const columns = [
    {
      title: "分期說明代碼",
      dataIndex: "insSerialNumber",
      width: "100px",
    },
    {
      title: "分期期數",
      dataIndex: "insInstallments",
      defaultSortOrder: "ascend",
      width: "100px",
      sorter: (a, b) => {
        a = a.insInstallments || "";
        b = b.insInstallments || "";
        return a - b;
      },
    },
    {
      title: "按鈕文字",
      dataIndex: "insButtonName",
      width: "100px",
    },
    {
      title: "分期說明",
      dataIndex: "insDescription",
      width: "250px",
    },
    {
      title: "操作",
      dataIndex: "operation",
      width: "150px",
      render: (_, record) => (
        <Space>
          <a onClick={() => handleModalVisible("edit", record)}>編輯</a>
          <a onClick={() => handleDelete(record)} style={{ color: "red" }}>刪除</a>
        </Space>
      ),
    }
  ];

  return (
    <Layout className="rootList">
      <FormModal type={operationType} insSerialNumber={insSerialNumber} intNo={intNo} />
      <Row justify="center">
        <Col span={23}>
          <div className="title">分期說明設定</div>

          <Card className="rootListCard">
            {/* 篩選區塊 */}
            <div className="rootListSearch">
              <Row>
                <Col flex="120px" style={{ lineHeight: "32px" }}>
                  <span>選擇適用利率表</span>
                </Col>
                <Col>
                  <Select defaultValue={0} style={{width: 350}} onChange={onChangeInterest}>
                    <Select.Option value={0}>全部</Select.Option>
                    {interestRateOptions.map(item => (
                      <Select.Option 
                        value={item.intNo} 
                        key={item.intNo}
                      >
                        {item.intName}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
              </Row>
            </div>

            {/* 查詢結果 */}
            <div>
              <Row>
                <Col align="right" span={24} xs={24} sm={24} md={24} style={{marginTop: "20px"}}>
                  <Button icon={<PlusOutlined />} onClick={() => handleModalVisible("add")}>新增</Button>
                </Col>
              </Row>
              <Row style={{ margin: "20px 0" }}>
                <Form form={form} component={false}>
                  <Table
                    columns={columns}
                    dataSource={rowData}
                    locale={{
                      emptyText: (
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={<span>無資料</span>}
                        />
                      )
                    }}
                    size="small"
                    pagination={false}
                    scroll={{ y: 400 }}
                  />
                </Form>
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}

export default RootInstallment;
