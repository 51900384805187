import { merchantTypes } from 'src/constants';
import { merchantService } from 'src/services';

// 取得此登入帳號所有分(總)店資訊
const getBranchesData = () => {
  return (dispatch) => {
    merchantService.getBranchesData().then(
      (res) => {
        res.json().then((result) => {
          dispatch({ type: merchantTypes.GET_BRANCHES_DATA, data: result });
        });
      },
      (error) => {
        console.log(error);
      }
    );
  };
};

// 取得登入帳號商家資訊
const getBranchData = (merAcctId) => {
  return (dispatch) => {
    merchantService.getBranchData(merAcctId).then(
      (res) => {
        res.json().then((result) => {
          dispatch({ type: merchantTypes.GET_BRANCH_DATA, data: result });
        });
      },
      (error) => {
        console.log(error);
      }
    );
  };
};

// 修改商家資訊
const editBranchData = (merAcctId, payload) => {
  console.log(payload);
  return (dispatch) => {
    merchantService.editBranchData(merAcctId, payload).then(
      (res) => {
        res.json().then((result) => {
          dispatch({ type: merchantTypes.GET_BRANCH_DATA, data: result });
        });
      },
      (error) => {
        console.log(error);
      }
    );
  };
};

// 取得個人帳號資訊
const getPersonalInfo = () => {
  return (dispatch) => {
    merchantService.getPersonalInfo().then(
      (res) => {
        res.json().then((result) => {
          dispatch({ type: merchantTypes.GET_PERSONAL_INFO, data: result });
        });
      },
      (error) => {
        console.log(error);
      }
    );
  };
};

// 取得銷售產品類型列表
const getProdTypeList = () => {
  return (dispatch) => {
    merchantService.getProdTypeList().then(
      (res) => {
        res.json().then((result) => {
          dispatch({ type: merchantTypes.GET_PROD_TYPE_LIST, data: result });
        });
      },
      (error) => {
        console.log(error);
      }
    );
  };
};

export const actionCreators = {
  getBranchesData,
  getBranchData,
  editBranchData,
  getPersonalInfo,
  getProdTypeList,
};
