import Cookies from 'universal-cookie';
import { ADMIN_API_URL as api_admin_url } from 'src/config';

const cookies = new Cookies();
const reqOptions = {
  get: {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  },
  patch: {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
  },
  post: {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  },
  delete: {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
  },
};

const login = async (payload) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload)
  };

  return fetch(`${api_admin_url}/login`, requestOptions)
    .then(handleResponse)

}

// 取得商家(基本)資訊
const getUsersList = async (payload) => {
  let { search, searchType } = payload;
  return fetch(
    `${api_admin_url}/merchant/account?access_token=${cookies.get('_merchant_auth_token')}&search=${search}&searchType=${searchType}`,
    reqOptions.get
  );
};

// 取得單一帳號資訊
const getAccount = async (merAdmSerNo) => {
  return fetch(
    `${api_admin_url}/merchant/account/${merAdmSerNo}?access_token=${cookies.get('_merchant_auth_token')}`,
    reqOptions.get
  );
};

// 新增帳號
const addAccount = async (payload) => {
  return fetch(
    `${api_admin_url}/merchant/account?access_token=${cookies.get('_merchant_auth_token')}`,
    {
      ...reqOptions.post,
      body:  JSON.stringify(payload)
    }
  );
};

// 修改帳號
const editAccount = async (merAdmSerNo, payload) => {
  return fetch(
    `${api_admin_url}/merchant/account/${merAdmSerNo}?access_token=${cookies.get('_merchant_auth_token')}`,
    {
      ...reqOptions.patch,
      body:  JSON.stringify(payload)
    }
  );
};

// 刪除帳號
const deleteAccount = async (merAdmSerNo) => {
  return fetch(
    `${api_admin_url}/merchant/account/${merAdmSerNo}?access_token=${cookies.get('_merchant_auth_token')}`,
    reqOptions.delete
  );
};


const handleResponse = (response) => {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 400) {
        // auto logout if 400 response returned from api
        cookies.remove('_merchant_auth_token');
        // window.location.assign('/login');
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(response);
    }

    return data;
  });
}

export const authService = {
  login,
  getUsersList,
  getAccount,
  addAccount,
  editAccount,
  deleteAccount
};
