import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { actionCreators } from "src/actions/front-jet";

// antd
import { Row, Col, Tabs } from "antd";

// component
import Activity from "./Activity";
import ProductList from "./ProductList";
import ShopNote from "./ShopNote";

const FrontJet = () => {
  const { shopRouteName } = useParams();

  const [routeName] = useState(shopRouteName);

  const dispatch = useDispatch();

  // 取出 store 中的 state
  const frontShopProducts = useSelector(state => state.frontJet.frontShopProducts);
  const frontShopProdType = useSelector(state => state.frontJet.frontShopProdType);
  const frontShopInfo = useSelector(state => state.frontJet.frontShopInfo);
  const frontShopInfoNotice = useSelector(state => state.frontJet.frontShopInfoNotice);

  // action: 取得商店渲染資訊
  useEffect(() => {
    dispatch(actionCreators.getFrontShopInfo(routeName));
    dispatch(actionCreators.getFrontShopProducts(routeName));
    dispatch(actionCreators.getFrontShopProdType(routeName));
  }, []);

  const onChangeTab = (key) => {
    if (key === "1") {
      dispatch(actionCreators.getShopInfoNotice(routeName));
    } 
  };

  return (
    <div>
      <div className="jet-bg" style={{ paddingTop: 20 }}>
        <h1>{frontShopInfo.shopInfo && frontShopInfo.shopInfo.shopInfoName}</h1>
        <h2>{frontShopInfo.shopInfo && frontShopInfo.shopInfo.shopInfoSlogan}</h2>
      </div>
      <Activity shopActivity={frontShopInfo.shopActivity} />
      <Row justify="center" style={{ marginTop: 20 }}>
        <Col span={22} style={{ marginBottom: 50 }}>
          <Tabs className="front-tab" centered onChange={onChangeTab}>
            <Tabs.TabPane tab="商品列表" key="0">
              <ProductList routeName={routeName} shopProducts={frontShopProducts} shopProdType={frontShopProdType} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="購物須知" key="1">
              <ShopNote shopInfoNotice={frontShopInfoNotice} />
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
    </div>
  );
}

export default FrontJet;
