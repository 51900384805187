import { jetTypes } from "src/constants";
import { jetService } from "src/services";

/* -------------------------
商       家       資       訊
------------------------- */
// 取得編輯中的商家資訊
const getEditingShopInfo = () => {
  return (dispatch) => {
    jetService.getEditingShopInfo()
      .then(
        res => {
          res.json().then(result => {
            dispatch({type: jetTypes.GET_EDITING_SHOP_INFO, data: result});
          });
        },
        error => {
          console.log(error);
        }
      );
  };
};

// 取得商店預覽
const getShopPreviewInfo = () => {
  return (dispatch) => {
    jetService.getShopPreviewInfo()
      .then(
        res => {
          res.json().then(result => {
            dispatch({type: jetTypes.GET_SHOP_PREVIEW_INFO, data: result});
          });
        },
        error => {
          console.log(error);
        },
      );
  };
};

// 更新後取得商家網址
const getShopRouteName = (shopRouteName) => {
  return (dispatch) => {
    dispatch({type: jetTypes.GET_SHOP_ROUTE_NAME, data: shopRouteName});
  };
};

/* -------------------------
活       動       專       區
------------------------- */
// set active tab
const handleTabActiveKey = (key) => {
  return (dispatch) => {
    dispatch({type: jetTypes.TAB_ACTIVE_KEY, data: key});
  };
};

// set edit or non-edit
const handleIsEdit = (isEdit) => {
  return (dispatch) => {
    dispatch({type: jetTypes.IS_EDIT, data: isEdit});
  };
};

// 取得編輯中活動資訊
const getEditingShopActivity = () => {
  return (dispatch) => {
    jetService.getEditingShopActivity()
      .then(
        res => {
          res.json().then(result => {
            dispatch({type: jetTypes.GET_EDITING_SHOP_ACTIVITY, data: result});
          });
        },
        error => {
          console.log(error);
        },
      );
  };
};

// 前端暫存活動專區商品列表
const getShopActivityProducts = (products) => {
  return (dispatch) => {
    dispatch({type: jetTypes.GET_SHOP_ACTIVITY_PRODUCTS, data: products});
  };
};

export const actionCreators = {
  getEditingShopInfo,
  getShopPreviewInfo,
  getShopRouteName,
  handleTabActiveKey,
  handleIsEdit,
  getEditingShopActivity,
  getShopActivityProducts,
};