import Cookies from 'universal-cookie';

const cookies = new Cookies();

const INITIAL_STATE = {
  merAcctId: cookies.get('_merAcctId') ? cookies.get('_merAcctId') : '',
  branchesData: [],
  branchData: [],
  storePersonalInfo: [],
  prodTypeList: [],
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'MERACCTID':
      return {
        ...state,
        merAcctId: action.id
      };
    case 'GET_BRANCHES_DATA':
      return {
        ...state,
        branchesData: action.data
      };
    case 'GET_BRANCH_DATA':
      return {
        ...state,
        branchData: action.data
      };
    case 'GET_PERSONAL_INFO':
      return {
        ...state,
        storePersonalInfo: action.data
      };
    case 'GET_PROD_TYPE_LIST':
      return {
        ...state,
        prodTypeList: action.data
      };
    default:
      return state;
  }
};

export default reducer;