import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { Row, Col, Menu } from "antd";
import { SettingOutlined } from "@ant-design/icons";

import "src/components/Drawer/Drawer.css";
import logo from "src/assets/JetShop_logo_orange.png";

const RootDrawer = () => {
    const { SubMenu } = Menu;

    const navigate = useNavigate();

    const [collapsed] = useState(false);

    return (
        <Row className="menu">
            <Col span={24}>
                <Link to="/root">
                    <img className="logoImg" src={logo} alt="JetShop Logo" />
                </Link>
                <Menu
                  mode="inline"
                  theme="dark"
                  inlineCollapsed={collapsed}
                  style={{backgroundColor: "#324057", color: "white"}}
                >
                    <SubMenu key="100" icon={<SettingOutlined />} title="維護設定">
                        <Menu.Item
                          key="101"
                          onClick={() => navigate("/root/apply-type/list")}
                        >
                            案件類型設定
                        </Menu.Item>
                        <Menu.Item
                          key="102"
                          onClick={() => navigate("/root/product-type/list")}
                        >
                            商品類型設定
                        </Menu.Item>
                        <Menu.Item
                          key="103"
                          onClick={() => navigate("/root/banner/list")}
                        >
                            廣告資料設定
                        </Menu.Item>
                        <Menu.Item
                          key="104"
                          onClick={() => navigate("/root/installment/list")}
                        >
                            分期說明設定
                        </Menu.Item>
                        <Menu.Item
                          key="105"
                          onClick={() => navigate("/root/FAQ/list")}
                        >
                            FAQ 設定
                        </Menu.Item>
                    </SubMenu>
                </Menu>
            </Col>
        </Row>
    );
}

export default RootDrawer;
