import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actionReportCreators } from 'src/actions/report';
import { actionCreators } from 'src/actions/merchant';
import moment from 'moment';
import { dictList } from './useDict';

import SearchBox from './components/searchBox';
import ResultTable from './components/resultTable';
// Ant design
import { Layout, Row, Col, Progress, Card, Typography } from 'antd';
import './caseStage.css';
const { Text } = Typography;

const Report = () => {
  const dispatch = useDispatch();

  const [statusData, setStatusData] = useState([]);
  const [indicatorData, setIndicatorData] = useState([]);

  const [statusCols] = useState([
    {
      title: '狀態',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: '案件數',
      dataIndex: 'caseNum',
      key: 'caseNum',
    },
  ]);

  const [indicatorCols] = useState([
    {
      title: '指標',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
    },
    {
      title: '案件數',
      dataIndex: 'caseNum',
      key: 'caseNum',
      width: '30%',
    },
    {
      title: '比例',
      dataIndex: 'percent',
      key: 'percent',
      width: '40%',
      render: (progress) => (
        <>
          {/* {progress} === 100 ? <span>{progress}</span> : <Progress percent={progress} />; */}
          {/* {progress.map((num) => {
            return num === 100 ? <span>{num}</span> : <Progress percent={num} />;
          })} */}
          <Progress percent={progress} status="normal" />
        </>
      ),
    },
  ]);

  const currentDate = moment().format('YYYY-MM-DD');
  const sevenDaysAgo = moment()
    .subtract('6', 'days')
    .format('YYYY-MM-DD');
  const oneMonthAgo = moment()
    .subtract('1', 'months')
    .add('1', 'days')
    .format('YYYY-MM-DD');
  const threeMonthsAgo = moment()
    .subtract('3', 'months')
    .add('1', 'days')
    .format('YYYY-MM-DD');

  const [branchesLoading, setBranchesLoading] = useState(false);
  const [allMerAcctIdVal, setAllMerAcctIdVal] = useState(null);
  const [merAcctIdVal, setMerAcctIdVal] = useState(null);
  const [quickSearch, setQuickSearch] = useState('1');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [dates, setDates] = useState([]);

  const [dateType, setDateType] = React.useState('applyDate');

  const branchesData = useSelector((state) => state.merchant.branchesData);
  const previewApplyCasesData = useSelector((state) => state.report.previewApplyCases);

  const dispatchFunc = (merAcctIdData, dateType, startDate, endDate) => {
    let merAcctId = merAcctIdData === 'all' ? allMerAcctIdVal : merAcctIdData;
    dispatch(actionReportCreators.getReviewApplyCases({ merAcctId, dateType, startDate, endDate }));
  };

  // step1 取得 所屬分店資訊
  useEffect(() => {
    setBranchesLoading(true);
    dispatch(actionCreators.getBranchesData());
  }, []);

  // step2 預設 全部合計-案件狀態總覽
  useEffect(() => {
    setBranchesLoading(false);
    if (branchesData.length > 0) {
      const initBranches = branchesData.map((ele) => ele.merAcctId).join(',');
      setAllMerAcctIdVal(initBranches);
      setMerAcctIdVal('all');

      // 取得 案件狀態總覽
      dispatchFunc(initBranches, dateType, oneMonthAgo, currentDate);
    }
  }, [branchesData]);

  // step3
  useEffect(() => {
    if (previewApplyCasesData.status === undefined || previewApplyCasesData.length === 0) return;

    let initStatus = [];
    let initIndicator = [];

    Object.entries(previewApplyCasesData.status).forEach(([key, value]) => {
      return initStatus.push({
        status: dictList.dictStatus[key],
        caseNum: value,
      });
    });

    let totalCases = initStatus.map((ele) => ele.caseNum).reduce((a, b) => a + b);

    initStatus.push({ status: '總案件數', caseNum: totalCases });

    setStatusData(initStatus);

    Object.entries(previewApplyCasesData.indicator).forEach(([key, value]) => {
      return initIndicator.push({
        name: dictList.dictIndicator[key],
        caseNum: value,
        percent: key === 'total' ? (value === 0 ? 0 : 100) : Math.round((value / previewApplyCasesData.indicator.total) * 100 * 10) / 10,
      });
    });

    setIndicatorData(initIndicator);
  }, [previewApplyCasesData]);

  // 變動 選擇分店
  const onChangeBranches = (value) => {
    setStartDate(null);
    setEndDate(null);
    setQuickSearch('1');
    // 取得 案件狀態總覽
    dispatchFunc(value, dateType, oneMonthAgo, currentDate);
    setMerAcctIdVal(value);
  };

  // 快速查詢功能
  const onChangeQuickSearch = (e) => {
    const val = e.target.value;
    setQuickSearch(val);
    setStartDate(null);
    setEndDate(null);
    if (val === '0') {
      dispatchFunc(merAcctIdVal, dateType, sevenDaysAgo, currentDate);
    } else if (val === '1') {
      dispatchFunc(merAcctIdVal, dateType, oneMonthAgo, currentDate);
    } else if (val === '2') {
      dispatchFunc(merAcctIdVal, dateType, threeMonthsAgo, currentDate);
    }
  };

  // 自訂查詢功能
  const search = () => {
    setQuickSearch(null);
    let startDateVal = startDate ? startDate : oneMonthAgo;
    let endDateVal = endDate ? endDate : currentDate;

    dispatchFunc(merAcctIdVal, dateType, startDateVal, endDateVal);
  };

  // const onOpenChange = (open) => {
  //   if (open) {
  //     setDateHackValue([]);
  //     setDates([]);
  //   } else {
  //     setDateHackValue(undefined);
  //   }
  // };

  const disabledDate = (current) => {
    if (!dates || dates.length === 0) {
      // return false;
      return current > moment().endOf('day');
    }

    // 只能選擇當前日期之前，日期範圍不超過90天
    const tooLate = dates[0] && current.diff(dates[0], 'days') > 89;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > 89;
    return tooEarly || tooLate || current > moment().endOf('day');
  };

  // 變更 日期類別
  const onChangeDateType = (e) => {
    setQuickSearch('1');
    setStartDate(null);
    setEndDate(null);
    setDateType(e.target.value);
    dispatchFunc(merAcctIdVal, e.target.value, oneMonthAgo, currentDate);
  };

  return (
    <Layout className="merLayout">
      <Row justify="center">
        <Col span={23}>
          <div className="title">統計報表 - 案件處理</div>
          <Card className="merLayoutCard caseStageCard">
            <SearchBox
              // 選擇分店
              branchesLoading={branchesLoading}
              branchesData={branchesData}
              merAcctIdVal={merAcctIdVal}
              onChangeBranches={onChangeBranches}
              // 快速查詢
              quickSearch={quickSearch}
              onChangeQuickSearch={onChangeQuickSearch}
              // 自訂查詢
              dateType={dateType}
              onChangeDateType={onChangeDateType}
              startDate={startDate}
              endDate={endDate}
              onChangeDate={(moment, dateArr) => {
                setStartDate(dateArr[0]);
                setEndDate(dateArr[1]);
              }}
              onCalendarChange={(val) => setDates(val)}
              disabledDate={disabledDate}
              // value={dateHackValue || dateValue}
              // onOpenChange={onOpenChange}
              search={search}
            />
            <Row justify="center" gutter={[16, { sm: 16 }]}>
              <Col xs={24} sm={12} className="gutter-row caseStatusTable">
                <ResultTable bordered={true} tableCols={statusCols} dataSource={statusData} tableScroll={false} rowKey={(record) => record.status} size={'small'} pagination={{ position: ['none'] }} />
              </Col>
              <Col xs={24} sm={12} className="gutter-row casePercentTable">
                <ResultTable bordered={true} tableCols={indicatorCols} dataSource={indicatorData} tableScroll={false} rowKey={(record) => record.name} size={'small'} pagination={{ position: ['none'] }} />
                <Text>＊ 以審核完成案件數統計</Text>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
};

export default Report;
