const INITIAL_STATE = {
  products: [],
  productList: [],
  productData: {},
  productTypeList: [],
  productTypeItemsList: [],
  productBrand: [],
  productInterestRate: [],
  productTypeIndex: []
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GET_PRODUCTS':
      return {
        ...state,
        products: action.data
      };
    case 'GET_PRODUCT_LIST':
      return {
        ...state,
        productList: action.data
      };
    case 'GET_PRODUCT_DATA':
      return {
        ...state,
        productData: action.data
      };
    case 'GET_PRODUCT_TYPE_LIST':
      return {
        ...state,
        productTypeList: action.data
      };
    case 'GET_PRODUCT_TYPE_ITEMS_LIST':
      return {
        ...state,
        productTypeItemsList: action.data
      };
    case 'GET_PRODUCT_BRAND':
      return {
        ...state,
        productBrand: action.data
      };
    case 'GET_PRODUCT_INTERESTRATE':
      return {
        ...state,
        productInterestRate: action.data
      };
    case 'GET_PRODUCT_TYPE_INDEX':
      return {
        ...state,
        productTypeIndex: action.data
      };
    default:
      return state;
  }
};

export default reducer;