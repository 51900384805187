import { rootTypes } from 'src/constants';

// 變更 Modal 顯示/隱藏
const modalVisible = () => {
  return (dispatch) => {
    dispatch({type: rootTypes.MODAL_ADD_VISIBLE, data: null});
  };
};

export const actionCreators = {
  modalVisible,
}