import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { actionCreators } from "src/actions/banner";
import { actionCreators as actionRootCreators } from "src/actions/root";
import { bannerService } from "src/services";

// ant design
import { Modal, Form, Input, Radio, Upload, Divider, Button, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const rules = {
    bannerDescription: [
        {required: true, message: "請輸入廣告說明", trigger: "blur"},
    ],
    bannerDisplay: [
        {required: true, message: "請選擇廣告狀態", trigger: "blur"},
    ],
    bannerUrl: [
        {type: "url", message: "須為有效的url", trigger: "blur"},
    ]
};

const AddBanner = (props) => {
    const [fileList, setFileList] = useState([]);
    const [bannerType, setBannerType] = useState(null);
    const [bannerDocImg, setBannerDocImg] = useState(null);
    const [bannerImgWidth, setBannerImgWidth] = useState(null);
    const [bannerImgHeight, setBannerImgHeight] = useState(null);

    const dispatch = useDispatch();

    // 取出 store 中的 state
    const isVisible = useSelector(state => state.Root.modalVisible);

    useEffect(() => {
        if (props.bannerType === 1) setBannerType(1);
        else setBannerType(2);
    }, [isVisible]);

    // Upload 驗證
    const checkImg = (rule, value) => {
        if (value === undefined) {
            return Promise.reject("請選擇廣告圖片");
        } 
        if (bannerImgWidth !== 1920 && bannerImgHeight !== 650 && Number(bannerType) === 1) {
            return Promise.reject("上傳照片規格須為 1920×650");
        }
        if (bannerImgWidth !== 768 && bannerImgHeight !== 500 && Number(bannerType) === 2) {
            return Promise.reject("上傳照片規格須為 768x500");
        }
        return Promise.resolve();
    };

    // 預覽圖片
    const onPreview = async (file) => {
        const image = new Image();
        image.src = file.url;
        const imgWindow = window.open(file.url);
        imgWindow.document.write(image.outerHTML);
    };

    // 上傳前更新 fileList、判斷圖片尺寸
    const beforeUpload = (file) => {
        setBannerDocImg(file);
        const reader = new FileReader();
        reader.addEventListener("load", () => {
            const image = new Image();
            image.src = reader.result;
            image.addEventListener("load", () => {
                setBannerImgWidth(image.width);
                setBannerImgHeight(image.height);
            });
            setFileList([{
                uid: reader.uid,
                url: reader.result,
            }]);
        });
        reader.readAsDataURL(file);
        return false;
    };

    // 儲存
    const onFinish = async (values) => {
        const formData = new FormData();
        formData.append("bannerDisplay", values.bannerDisplay);
        formData.append("bannerType", bannerType);
        formData.append("imageFile", bannerDocImg);
        formData.append("bannerDescription", values.bannerDescription);
        if (values.bannerUrl) {
            formData.append("bannerUrl", values.bannerUrl);
        }
        bannerService.addBanner(formData)
        .then(
            res => {
                if (res.status === 201) {
                    dispatch(actionCreators.getBannerList(bannerType));
                    message.success("新增成功");
                } 
                else if (res.status === 401) message.warning("存取拒絕");
                else message.error("新增失敗");
            },
            error => {
                console.log(error);
            },
        );
        dispatch(actionRootCreators.modalVisible());
        setFileList([]);
        setBannerType(null);
        setBannerImgWidth(null);
        setBannerImgHeight(null);
    };

    // 儲存失敗
    const onFinishFailed = () => false;
    
    // 取消
    const handleCancel = () => {
        setFileList([]);
        setBannerType(null);
        setBannerImgWidth(null);
        setBannerImgHeight(null);
        dispatch(actionRootCreators.modalVisible());
    };

    return (
        <Modal 
          title="新增" 
          visible={isVisible} 
          onCancel={handleCancel} 
          maskClosable={false}
          destroyOnClose
          footer={false}
        >
            <Form
                labelAlign="left"
                labelCol={{flex: "100px"}}
                wrapperCol={{span: 24, flex: 1}}
                colon={false}
                initialValues={{bannerDisplay: "1"}}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <div className="rootFormItem">
                    <Form.Item label="廣告類別" name="bannerType">
                        <span>{bannerType === 1 ? "官網" : "後台"}</span>
                    </Form.Item>
                    <Form.Item label="廣告說明" name="bannerDescription" rules={rules.bannerDescription}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="廣告狀態" name="bannerDisplay" rules={rules.bannerDisplay}>
                        <Radio.Group>
                            <Radio value="1">顯示</Radio>
                            <Radio value="0">隱藏</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item 
                      label="廣告圖片" 
                      name="doc-image" 
                      rules={[
                        {required: true, validator: checkImg, validateTrigger: "onClick"}
                      ]}
                    >
                        <Upload 
                          listType="picture-card"
                          accept="image/*"
                          maxCount={1}
                          fileList={fileList}
                          onPreview={onPreview}
                          beforeUpload={beforeUpload}
                          showUploadList={{showRemoveIcon: false}}
                        >
                            <PlusOutlined />
                        </Upload>
                    </Form.Item>
                    <Form.Item label="廣告連結" name="bannerUrl" rules={rules.bannerUrl}>
                        <Input />
                    </Form.Item>
                </div>
                <Divider style={{margin: 0}} />
                <div style={{textAlign: "end", padding: "10px 16px"}}>
                    <Button onClick={handleCancel} style={{marginRight: 10}}>取消</Button>
                    <Button type="primary" htmlType="submit">儲存</Button>
                </div>
            </Form>
        </Modal>
    );
}

export default AddBanner;
