import React from "react";
import { useSelector } from "react-redux";

import "./index.css";

const FrontJetHeader = (props) => {
	// 取出 store 中的 state
	const frontShopInfo = useSelector(state => state.frontJet.frontShopInfo);
	const shopPreviewInfo = useSelector(state => state.jet.shopPreviewInfo);
	
	if (props.pageType === "front") {
		return (
			<div className="jet-header">
				<div className="jet-header-logo">
					{
						frontShopInfo.shopInfo && frontShopInfo.shopInfo.shopInfoLogo &&
						<img src={frontShopInfo.shopInfo.shopInfoLogo} alt="Logo" style={{height: "60px"}} />
					}
				</div>
			</div>
		);
	}

	return (
		<div className="jet-header">
			<div className="jet-header-logo">
				{
					shopPreviewInfo.shopInfo && shopPreviewInfo.shopInfo.shopInfoLogo &&
					<img src={shopPreviewInfo.shopInfo.shopInfoLogo} alt="Logo" style={{height: "60px"}} />
				}
			</div>
		</div>
	)
}

export default FrontJetHeader;
