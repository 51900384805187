import React, { useState, useEffect, useCallback } from "react";
import Cookies from "universal-cookie";
import { useDispatch } from "react-redux";
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";
import { actionCreators as actionRootCreators } from "src/actions/root";
import { frontMerchantService } from "src/services";
import { RECAPTCHA_KEY } from 'src/config';

import { zipCode } from "src/utils/zipCode";

// antd & css
import { Row, Col, Form, Input, Select, message } from "antd";

const FrontMerchantFormInput = () => {
  const [form] = Form.useForm();
  const [cityList, setCityList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const cookies = new Cookies();

  const dispatch = useDispatch();

  // 配值
  useEffect(() => {
    let cities = [];

    // 初始化縣市選單
    Object.keys(zipCode).map(city => {
      cities.push(<Select.Option key={city}>{city}</Select.Option>)
    });
    setCityList(cities);
  }, []);

  // 儲存 google recaptcha token
  const saveCaptcha = useCallback((token) => {
    if (!token) return;
    cookies.set("_front_token", token);
	}, []);

  const cityChange = (value) => {
    let options = [];
    zipCode[value].map(dist => {
      options.push(<Select.Option key={dist}>{dist}</Select.Option>)
    });

    setDistrictList(options);

    // set district default for first option
    form.setFieldsValue({
      merAppDistrict: []
    });
  };

  const onFinish = (values) => {
    const payload = {
      merAppCompName: values.merAppCompName,
      merAppVatNo: values.merAppVatNo ? values.merAppVatNo : undefined,
      merAppContTime: values.merAppContTime ? values.merAppContTime : undefined,
      merAppName: values.merAppName,
      merAppEmail: values.merAppEmail,
      merAppCellPhone: values.merAppCellPhone,
      merAppCompPhone: values.merAppCompPhone ? values.merAppCompPhone : undefined,
      merAppCity: values.merAppCity,
      merAppDistrict: values.merAppDistrict.slice(3),
      merAppAddr: values.merAppAddr,
      merAppWebsite: values.merAppWebsite ? values.merAppWebsite : undefined,
    };
    frontMerchantService.addMerchantApply(payload)
    .then(
      res => {
        if (res.status === 201) {
          dispatch(actionRootCreators.modalVisible());
        }
        else if (res.status === 403) message.warning("存取拒絕");
        else message.error("提交失敗");
      },
      error => {
        console.log(error);
      },
    );
    setRefreshReCaptcha(recaptcha => !recaptcha);
  };

  const onFinishFailed = () => false;

  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
      <Form
        form={form}
        labelAlign="left"
        labelCol={{ flex: "120px" }}
        wrapperCol={{ span: 24 }}
        colon={false}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="商家名稱"
          name="merAppCompName"
          rules={[{ required: true, message: "請輸入商家名稱" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="統一編號"
          name="merAppVatNo"
          rules={[
            { required: true, message: "請輸入統一編號" },
            () => ({
              validator(_, value) {
                if (value) {
                  if (!value.match(/^\d+$/)) return Promise.reject("須為數字格式");
                  if (value.length !== 8) return Promise.reject("須為8碼");
                }
                return Promise.resolve();
              },
            })
          ]}
        >
          <Input maxLength={8} />
        </Form.Item>
        <Form.Item label="聯絡時間" name="merAppContTime">
          <Input />
        </Form.Item>
        <Form.Item
          label="聯絡人姓名"
          name="merAppName"
          rules={[{ required: true, message: "請輸入聯絡人姓名" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="聯絡人Email"
          name="merAppEmail"
          rules={[
            { required: true, message: "請輸入聯絡人Email" },
            { type: "email", message: "請輸入正確的格式" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="聯絡人手機"
          name="merAppCellPhone"
          rules={[
            { required: true, message: "請輸入聯絡人手機" },
            () => ({
              validator(_, value) {
                if (value) {
                  if (!value.match(/^\d+$/)) return Promise.reject("須為數字格式");
                  if (value.length !== 10) return Promise.reject("須為10碼");
                }
                return Promise.resolve();
              },
            })
          ]}
        >
          <Input maxLength={10} />
        </Form.Item>
        <Form.Item
          label="公司電話"
          name="merAppCompPhone"
          rules={[
            () => ({
              validator(_, value) {
                if (value) {
                  if (!value.match(/^\d+$/)) return Promise.reject("須為數字格式");
                }
                return Promise.resolve();
              },
            })
          ]}
        >
          <Input maxLength={10} />
        </Form.Item>
        <Form.Item className="merAddr" label="公司營業地址" required>
          <Row gutter={[16, 0]}>
            <Col span={12} md={12} sm={24} xs={24}>
              <Form.Item
                name="merAppCity"
                rules={[{ required: true, message: "請選擇縣市" }]}
              >
                <Select placeholder="選擇縣市" onChange={cityChange}>
                  {cityList}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12} md={12} sm={24} xs={24}>
              <Form.Item
                name="merAppDistrict"
                rules={[{ required: true, message: "請選擇區域" }]}
              >
                <Select placeholder="選擇區域">
                  {districtList}
                </Select>
              </Form.Item>
            </Col>
            </Row>
            <Form.Item
              name="merAppAddr"
              rules={[{ required: true, message: "請輸入地址" }]}
            >
              <Input />
            </Form.Item>
        </Form.Item>
        <Form.Item
          label="公司網站"
          name="merAppWebsite"
          rules={[{ type: "url", message: "請輸入正確的格式" }]}
        >
          <Input />
        </Form.Item>
        <GoogleReCaptcha onVerify={saveCaptcha} refreshReCaptcha={refreshReCaptcha} />
        <div className="text-center" style={{ marginTop: "40px" }}>
          <button className="frontBtn" type="submit">
            提交申請
          </button>
        </div>
      </Form>
    </GoogleReCaptchaProvider>
  );
}

export default FrontMerchantFormInput;
