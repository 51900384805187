import React, { useState, useEffect } from "react";
import moment from 'moment';

// antd
import { Row, Col } from "antd";

// component
import FrontDivCenter from "src/components/Front/Divider/DivCenter";

import divBg01 from "src/assets/jetdai/common/div_bg_01.png";
import divBg02 from "src/assets/jetdai/common/div_bg_02.png";
import divBg03 from "src/assets/jetdai/common/div_bg_03.png";
import divBg04 from "src/assets/jetdai/common/div_bg_04.png";

const oldProjects = [
  {
    title: "老客戶尊享貸",
    bg: divBg01,
    color: "red",
    cardInfo: ["9萬 / 36期", "月付$703起"],
    cardNote: "老客戶專享高期數、高額度，資金應用更彈性",
    content: "為老客戶量身打造專屬分期方案，只要曾經使用過JetShop的服務，都能夠申辦此方案，優惠利率及高額度方案，只提供給我們的會員夥伴。",
    fundRange: "$10,000~$90,000",
    periods: "6、9、12、18、24、30、36期",
    conditions: [
      "限滿18歲之本國國民",
      "持有中華民國國民身分證",
      "需備妥身分證件，有勞/健保證明者可增貸",
      "只要曾申辦過JetShop服務",
    ],
    hasExpireDate: true,
  },
  {
    title: "有薪皆可貸",
    bg: divBg02,
    color: "orange",
    cardInfo: ["8萬 / 36期", "月付$813起"],
    cardNote: "不限身份，有收入證明即可申請，審核通過一個工作天快速放貸",
    content: "不限身分皆可輕鬆申辦，無薪轉、領現、信用小白都OK，只需擁有一隻手機就能線上快速申辦，審貸門檻全台最低，20分鐘快速審核，核准後一個工作天立即放款。",
    fundRange: "$10,000~$80,000",
    periods: "6、9、12、18、24、30、36期",
    conditions: [
      "限滿18歲之本國國民",
      "持有中華民國國民身分證",
      "需備妥身分證件，有勞/健保證明者可增貸",
    ],
    hasExpireDate: true,
  },
];

const newProjects = [
  {
    title: "手機方案",
    bg: divBg01,
    color: "red",
    cardInfo: ["5萬 / 36期", "月付$703起"],
    cardNote: "手機最高可申請5萬元，超長36期，讓您資金應用更彈性",
    content: "只需擁有一支手機就能線上快速申辦，審核效率全台最速，20分鐘快速審核，核准後一個工作天內立即撥款。",
    fundRange: "$10,000~$50,000",
    periods: "6、9、12、18、24、30、36期",
    conditions: [
      "限滿18歲之本國國民",
      "持有中華民國國民身分證",
      "須備妥身分證件，勞/健保證明為加分條件",
    ],
  },
  {
    title: "筆電電腦方案",
    bg: divBg02,
    color: "orange",
    cardInfo: ["5萬 / 36期", "月付$703起"],
    cardNote: "筆電電腦小額資助方案，不限機台型號，自營工作者、大學生的最愛",
    content: "不限身分皆可輕鬆申辦，無薪轉、領現、信用小白都OK，手中的生財工具助您現金週轉，讓您創業無壓力，工作更開心。",
    fundRange: "$10,000~$50,000",
    periods: "6、9、12、18、24、30、36期",
    conditions: [
      "限滿18歲之本國國民",
      "持有中華民國國民身分證",
      "須備妥身分證件，勞/健保證明為加分條件",
    ],
  },
  {
    title: "客製化電腦方案",
    bg: divBg03,
    color: "blue",
    cardInfo: ["8萬 / 36期", "月付$703起"],
    cardNote: "最高可申辦8萬元，快速解決您的現金需求，開啟無限可能",
    content: "客製化電腦高額方案，免抵押擔保，在您最需要的瞬間助您一臂之力，陪憂解難，打造美好未來。",
    fundRange: "$10,000~$80,000",
    periods: "6、9、12、18、24、30、36期",
    conditions: [
      "限滿18歲之本國國民",
      "持有中華民國國民身分證",
      "須備妥身分證件，勞/健保證明為加分條件",
    ],
  },
  {
    title: "軍公教醫護低利方案",
    bg: divBg04,
    color: "green",
    cardInfo: ["5萬 / 24期", "月付$941起"],
    cardNote: "5萬額度快速借，對保費減半，年利率只要6.6%起！",
    content: "為軍公教醫護人員提供超值優惠！對保費50% off、免徵保人、利率減半只要6.6%，降低你的負擔。",
    fundRange: "$10,000~$50,000",
    periods: "6、9、12、18、24期",
    conditions: [
      "限滿18歲之本國國民",
      "持有中華民國國民身分證",
      "須備妥身分證件及相關職業證明",
      "在職、提供近三個月軍餉明細、銀行薪轉明細、存摺封面",
      "近三個月勞健保證明( 健保快易通/投保證明）",
      "無重大信用瑕疵",
      "任職於國營事業也符合申請資格",
    ],
  },
  {
    title: "大學生低利方案",
    bg: divBg04,
    color: "red",
    cardInfo: ["3萬 / 24期", "月付$706起"],
    cardNote: "3萬額度快速借，年利率只要5%起！",
    content: "在學學生皆可申辦，提供相關證明即可免徵保人、年利率只要5%起，審核門檻低，超多福利等你拿！",
    fundRange: "$10,000~$30,000",
    periods: "3、6、9、12、18、24期",
    conditions: [
      "限滿18~26歲之本國國民",
      "持有中華民國國民身分證",
      "須備妥學生證(113上註冊章)或課表(113上)需顯示姓名畫面等在學證明",
      "無重大信用瑕疵",
    ],
  },
  {
    title: "U質社會人方案",
    bg: divBg04,
    color: "orange",
    cardInfo: ["3萬 / 24期", "月付$941起"],
    cardNote: "3萬額度快速借，對保費減半，年利率只要6.6%起！",
    content: "只要您任職於資本額三千萬(含)以上的公司，持勞健保、薪轉或收入證明符合條件就能免徵保人、對保費50% off、利率狂降只要6.6%，超多福利等你拿！",
    fundRange: "$10,000~$30,000",
    periods: "6、9、12、18、24期",
    conditions: [
      "限滿18歲之本國國民",
      "持有中華民國國民身分證",
      "須備妥身分證件及相關職業證明",
      "在職、提供近三個月銀行薪轉明細、存摺封面",
      "近三個月勞健保證明( 健保快易通/投保證明）",
      "資本額認定以經濟部商工登記公示資料查詢結果為依據",
      "無重大信用瑕疵",
    ],
  },
];

const clickBtn = () => {
  window.gtag_report_conversion('https://jetshop.tw/about/jetdai/apply-step')
  window.uetq = window.uetq || [];window.uetq.push('event', 'https://jetshop.tw/about/jetdai/apply-step', {});
};

const Section3 = () => {
  const [expireDate] = useState('2023/12/10');
  const [expireChangeDate] = useState('2023/12/11 09:30:00');
  const [expireWeek] = useState('日');
  const [isExpireDate, setIsExpireDate] = useState(false);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    setIsExpireDate(moment().format('YYYY/MM/DD HH:mm:ss') > expireChangeDate);
  }, []);

  useEffect(() => {
    setProjects(isExpireDate
      ? [...newProjects]
      : [...oldProjects]
    );
  }, [isExpireDate]);
  
  return (
    <>
      <Row justify="center" style={{ marginTop: 70, marginBottom: 70 }}>
        <Col span={24}>
          <h2 className="front" style={{ margin: 0 }}>
            專屬分期方案
          </h2>
          <FrontDivCenter />
        </Col>
        <Col span={22}>
          {
            projects.map((item, idx) => (
              <Row justify="center" gutter={[16, 8]} key={idx} style={{ marginTop: 20 }}>
                <Col span={22} xl={6} lg={6} md={6} sm={22}>
                  <div className={`d-flex justify-center align-center frontJetDaiProject ${item.color}`}>
                    <div>
                      <h3 className="front text-white">{item.title}</h3>
                      <h2 className="front mt-none mb-none text-white">{item.cardInfo[0]}</h2>
                      <h2 className="front mt-none text-white">{item.cardInfo[1]}</h2>
                      <p className="text-white">{item.cardNote}</p>
                      <a href="/about/jetdai/apply-step" target="_blank" rel="noreferrer" onClick={() => { return window.gtag_report_conversion('https://jetshop.tw/about/jetdai/apply-step' , window.uet_report_conversion()) }}>
                        <button className="frontBtn-transparent" style={{ margin: "10px 0" }}>
                          {"洽詢專員申請"}
                        </button>
                      </a>
                      {
                        !isExpireDate && item.hasExpireDate &&
                        <p className="text-white" style={{ marginTop: 8, marginBottom: 0 }}>
                          此優惠方案將於 {`${expireDate}(${expireWeek})`} 結束
                        </p>
                      }
                    </div>
                  </div>
                </Col>
                <Col span={22} xl={14} lg={14} md={14} sm={14}>
                  <div className="projectDetail">
                    <h3 className="front mb-none">
                      {item.title}
                      {
                        !isExpireDate && item.hasExpireDate &&
                        <span className="expire-warning-text">
                          （此優惠方案將於 {`${expireDate}(${expireWeek})`} 結束）
                        </span>
                      }
                    </h3>
                    <p>{item.content}</p>
                    <p className="quote mb-none">申請額度：{item.fundRange}</p>
                    <p className="quote mb-none">申請期數：{item.periods}</p>
                    <p className="quote mb-none">申請條件：</p>
                    <ul>
                      {item.conditions.map((cond, i) => (
                          <li key={i}>{cond}</li>
                      ))}
                    </ul>
                  </div>
                </Col>
              </Row>
            ))
          }
        </Col>
      </Row>
    </>
  );
}

export default Section3;
