import React from "react";

// antd & css
import { Row, Col } from "antd";
import "../FrontGuide.css";

// component
import FrontBreadcrumb from "src/components/Front/Breadcrumb";
import Table from "./Table";

import Step from "src/assets/guide/step.png";

const FrontGuide = () => {
  const situations = [
    { id: '1', question: '我持有多張信用卡，還款紀錄正常，額度充足', answer: '建議使用「信用卡分期」' },
    { id: '2', question: '我僅有幾張信用卡，還款紀錄偶有遲繳，額度略顯不足', answer: '當額度足夠時優先使用「信用卡分期」，額度不足時輔以「商品分期」' },
    { id: '3', question: '我曾經持有信用卡，還款紀錄不良，信用略有瑕疵，還款協商中', answer: '依舊可以申請「商品分期」，經審核通過後，準時繳款維持良好紀錄，便可持續使用「商品分期」' },
    { id: '4', question: '我沒有信用卡或我無法辦信用卡', answer: '「商品分期」是您最佳的選擇' },
  ];

  return (
    <div style={{ marginBottom: 50, fontSize: "16px" }}>
      <FrontBreadcrumb
        link={[
          { href: '/installment/guide', name: "我是消費者" },
          { href: '', name: "商品分期懶人包" }
        ]}
      />
      
      <Row>
        <Col span={22} className="frontGuideIntro">
          <div className="text-left">
            <div className="title">
              <h1 className="front">商品分期懶人包</h1>
            </div>
            <div className="intro">
              <h2 className="front">
                商品分期申請流程、優缺點分析，懶人包幫您快速了解常見問題！
              </h2>
            </div>
          </div>
          <div className="ins-faq-divider"></div>
        </Col>
      </Row>

      <div className="text-left">
        <Row align="center">
          <Col span={23} md={21} sm={21} xs={21}>
            <div className="ins-faq-title">
              <h2>什麼是「商品分期」？</h2>
            </div>
            <div>
              「商品分期」或稱呼為「無卡分期」或「免卡分期」，泛指目前市面上提供可以在"不使用信用卡"的情況下，也能進行分期消費的服務；
              「商品分期」是一種彈性的分期消費方式，使用門檻限制低，有合理穩定的收入來源即可分期消費，不論任何職業身分，也不論是否持有信用卡，都可以申請使用。
            </div>

            <div className="ins-faq-title">
              <h2>商品分期安全嗎？</h2>
            </div>
            <div>
              「商品分期」是一種新型的金融支付方式，當您<span className="highlight-text">透過商家</span>選購某個產品後，向第三方金融支付公司申辦分期<span className="highlight-text">付款買賣</span>，
              經過第三方金融支付公司內部風險審核通過後，便可使用商品分期消費，這種支付模式的商品債權會<span className="highlight-text">轉讓</span>第三方金融支付公司，
              商品交易所簽訂的合約都是合乎中華民國法律規範，您可以用以下三大要點做為評估基準，就可以安心的使用商品分期消費：
            </div>
            <div>
              <div className="list-title">
                1. 遵守法規的第三方金融支付公司
              </div>
              「商品分期」必須遵守民法規範，僅接受<span className="highlight-text">年滿18歲</span>之本國國民進行申辦使用。

              <div className="list-title">
                2. 透明的月付金額
              </div>
              「商品分期」每期應繳金額皆標明清楚且固定金額，沒有最低應繳與循環利息，繳一期少一期，利率符合法律規範。

              <div className="list-title">
                3. 專人審核把關
              </div>
              「商品分期」申辦必須有明確的合約規範，過程需要嚴謹的審核流程，審慎確認並告知申辦人所有申辦內容及金額，防止不肖業者事後收取不合理的手續費、代辦費，造成消費糾紛。
            </div>

            <div className="ins-faq-title">
              <h2>「商品分期」和「信用卡分期」有什麼不同？</h2>
            </div>
            <div>
              當想要購買的商品單筆金額太高，希望降低消費負擔或無法一次付清，需要使用分期消費時，「商品分期」和「信用卡分期」
              同樣都是分期消費服務，兩者有甚麼不同，應該如何選擇，JetShop幫您統整差異處讓您一目了然：
            </div>
            <br />
            <div>
              「商品分期」的分期消費自由度非常高，不需要信用卡，也沒有消費額度限制，對於許多無卡族、學生族、信用小白或信用瑕疵者、
              另針對有信用卡但信用額度不高的消費族群而言，「商品分期」是首選消費方式，雖然使用商品分期有種種好處，
              但因商品分期的消費模式不需要附擔保品，也不會調聯徵紀錄，對於第三方金融支付公司來說風險較大，故利息也較高。
            </div>
            <br />
            <div>
              綜上所述，<span className="highlight-text">商品分期的優點是「申請門檻低、自由度高、不限身分皆可申請、不占用信用額度」</span>，
              缺點是「利息相較信用卡高、遲繳會有滯納金，消費者可依自身情況評估來選擇要使用哪種消費模式。
            </div>
            <br />
            <div>
              「信用卡分期」是銀行依據每個人的信用評等，給予可消費額度，消費者在額度範圍內進行分期消費，假若商品單價超過信用卡額度便無法做分期，
              且若還款繳費紀錄不良時，恐會影響到個人的信用評分紀錄；因此信用卡分期的使用門檻高，須謹慎使用，但相對的利息較低，泛用性也高，
              還有額外的附加紅利；不過需要注意，若未繳清信用卡費，如僅繳規定最低繳款金額，所產生的循環利息反而是非常高的，有時候甚至會高於商品分期的利息。
            </div>
            <br />
            <div>
              綜上所述，信用卡分期的優點是「利息低、部分卡片分期有優惠、有最低月付選項」，<span className="highlight-text">缺點是「申請門檻高、未繳清月付恐衍伸循環利息、
              遲繳會留下信用不良紀錄影響評分」</span>，信用卡因加入門檻高並非人人都有，商品分期此時就可代替信用卡提供消費者進行分期交易。
            </div>
            <Table />

            <div className="ins-faq-title">
              <h2>當我需要分期消費時，該使用「商品分期」或是「信用卡分期」？</h2>
            </div>
            { situations.map(situation => (
              <div key={situation.id}>
                <div className="speech-box-qu">
                  <div className="speech-avatar-qu">Q{situation.id}</div>
                  <div className="speech-bubble-qu">{situation.question}</div>
                </div>
                <div className="speech-box-ans">
                  <div className="speech-avatar-ans">A{situation.id}</div>
                  <div className="speech-bubble-ans">{situation.answer}</div>
                </div>
              </div>
            )) }

            <div className="ins-faq-title">
              <h2>商品分期會查聯徵紀錄嗎？</h2>
            </div>
            <div>
              商品分期的審核重點在於申請人的身分真實性、職業以及近期收入條件，不會查看個人信用評分。
            </div>

            <div className="ins-faq-title">
              <h2>商品分期適用於哪些人？</h2>
            </div>
            <ul style={{ marginLeft: -20 }}>
              <li>因<b>年齡限制</b>無法持有信用卡的群體，例如：學生、工讀生</li>
              <li>因<b>收入模式</b>較難申請信用卡的群體，例如：自營商、小吃店、攤商、耕農、網拍業、SOHO族、領現臨時工、外送員、八大等</li>
            </ul>

            <div className="ins-faq-title">
              <h2>申辦條件</h2>
            </div>
            <ul style={{ marginLeft: -20 }}>
              <li>必須<b>年滿18歲</b></li>
              <li>持有<b>中華民國國民身分證</b></li>
            </ul>

            <div className="ins-faq-title">
              <h2>申辦商品分期需檢附的文件</h2>
            </div>
            <div className="list-title">
              1. 一般身分別(上班族/自由業)
            </div>
            <ul>
              <li>身分證正/反面</li>
              <li>第二證件(健保卡或駕照)</li>
            </ul>
            <div className="list-title">
              2. 學生身分
            </div>
            <ul>
              <li>身分證正/反面</li>
              <li>學生證(有蓋本學期註冊章)</li>
            </ul>
            <div className="list-title">
              3. 軍人/警察/消防/醫護身分
            </div>
            <ul>
              <li>身分證正/反面</li>
              <li>軍/警/消防/醫護證正反面</li>
            </ul>

            <div className="ins-faq-title">
              <h2>申辦商品分期的流程步驟？</h2>
            </div>
            <img src={Step} alt="商品分期流程步驟" className="step-img" />

            <div className="ins-faq-title">
              <h2>線上申請提交後，審核需要多久時間？</h2>
            </div>
            <div>
              申請文件完整，審核僅需約「30分鐘」即可完成；若文件有缺漏，則需請您將文件補全後才會再次進入審核流程。
              完成審核後，客服人員會立即回覆您審核結果，並通知「線上核對消費合約」。
              提醒您，提交申請時請留意若已超過服務時間，則審核結果會在隔一個工作日回覆。
            </div>

            <div className="ins-faq-title">
              <h2>商品分期不小心遲繳分期款項怎麼辦？</h2>
            </div>
            <div>
              「商品分期」若未按時繳費，並不會在聯徵信用報告上留下紀錄，但會產生滯納金，若長期拖欠款項，
              可能會收到法院支付命令、吃上官司，未來想再申辦也會變得困難喔！
            </div>

            <div className="ins-faq-title">
              <h2>總結</h2>
            </div>
            <div>
              「商品分期」對於領現、收入不固定、無信用紀錄的消費者來說，比起「信用卡分期」是相當便利的分期消費方式，
              可以在能力範圍內輕鬆月付，但也<span className="highlight-text">提醒分期消費要理性，衡量每月還款能力，無論是「商品分期」或是「信用卡分期」都需要保持良好信用紀錄！</span>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default FrontGuide;
