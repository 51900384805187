import React, { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { orderService } from 'src/services';
import { order } from 'src/utils/order';
import { getAppCaseStage } from 'src/utils/useOrder';
import moment from 'moment';

import * as A from 'antd';

const Orderdetail = () => {
  const [detail, setDetial] = useState({});

  // 取得 query string 的值
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);

  // 取得指定訂單
  useEffect(() => {
    orderService.getOrderDetail(Number(params.appCaseNo)).then(
      (res) => {
        res.json().then((result) => {
          assignDatas(result);
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  const assignDatas = (result) => {
    let data = {};
    data.appCaseNo = result.appCaseNo;
    data.memName = result.memName;
    data.ordApplyDate = moment(result.apo.ordApplyDate).format('YYYY/MM/DD');
    data.appCaseStage = getAppCaseStage(result);
    data.memCellPhone = result.m.memCellPhone;
    data.memLineID = result.m.memLineID;
    data.appTotalAmt = result.appTotalAmt;
    data.merTotalPayAmt = result.merTotalPayAmt;
    data.appPeriods = result.appPeriods;
    data.appPeriodAmt = result.appPeriodAmt;
    data.ordNote = result.apo.ordNote;
    data.ordProdName = result.apo.ordProdName;
    data.ordProdbrandName = result.apo.ordProdbrandName;
    data.ordProdSpec = result.apo.ordProdSpec;
    data.ordProdNum = result.apo.ordProdNum;
    data.ordGift = result.apo.ordGift;
    setDetial(data);
  };

  return (
    <A.Layout className="merLayout">
      <A.Row justify="center">
        <A.Col span={23}>
          <div className="title">{'訂單查詢 > 詳細資料'}</div>

          <A.Card className="merLayoutCard">
            <A.Row gutter={30}>
              <A.Col xs={24} sm={12} md={12}>
                <A.Form labelCol={{ flex: '100px' }} wrapperCol={{ span: 24, flex: 1 }} labelAlign="left">
                  <A.Divider className="subTitle" orientation="left" orientationMargin="0" style={{ margin: '5px 0' }}>
                    訂單
                  </A.Divider>

                  <A.Form.Item label="訂單編號" className="formItem">
                    <span>{detail.appCaseNo}</span>
                  </A.Form.Item>
                  <A.Form.Item label="申請人" className="formItem">
                    <span>{detail.memName}</span>
                  </A.Form.Item>
                  <A.Form.Item label="日期" className="formItem">
                    <span>{detail.ordApplyDate}</span>
                  </A.Form.Item>
                  <A.Form.Item label="狀態" className="formItem">
                    <span>{detail.appCaseStage}</span>
                  </A.Form.Item>
                  <A.Form.Item label="客戶手機" className="formItem">
                    <span>{detail.memCellPhone}</span>
                  </A.Form.Item>
                  <A.Form.Item label="Line ID" className="formItem">
                    <span>{detail.memLineID}</span>
                  </A.Form.Item>
                  <A.Form.Item label="分期總價" className="formItem">
                    <span>{detail.appTotalAmt ? `$${detail.appTotalAmt}` : ''}</span>
                  </A.Form.Item>
                  <A.Form.Item label="撥款金額" className="formItem">
                    <span>{detail.merTotalPayAmt ? `$${detail.merTotalPayAmt}` : ''}</span>
                  </A.Form.Item>
                  <A.Form.Item label="分期期數" className="formItem">
                    <span>{detail.appPeriods}</span>
                  </A.Form.Item>
                  <A.Form.Item label="分期期金" className="formItem">
                    <span>{detail.appPeriodAmt ? `$${detail.appPeriodAmt}` : ''}</span>
                  </A.Form.Item>
                  <A.Form.Item label="訂單備註" className="formItem">
                    <span>{detail.ordNote}</span>
                  </A.Form.Item>
                </A.Form>
              </A.Col>
              <A.Col xs={24} sm={12} md={12}>
                <A.Form labelCol={{ flex: '100px' }} wrapperCol={{ span: 18, md: 18, sm: 15, xs: 6, flex: 1 }} labelAlign="left">
                  <A.Divider className="subTitle" orientation="left" orientationMargin="0" style={{ margin: '5px 0' }}>
                    商品
                  </A.Divider>

                  <A.Form.Item label="商品名稱" className="formItem">
                    <span>{detail.ordProdName}</span>
                  </A.Form.Item>
                  <A.Form.Item label="商品品牌" className="formItem">
                    <span>{detail.ordProdbrandName}</span>
                  </A.Form.Item>
                  <A.Form.Item label="商品規格" className="formItem">
                    <span>{detail.ordProdSpec}</span>
                  </A.Form.Item>
                  <A.Form.Item label="商品數量" className="formItem">
                    <span>{detail.ordProdNum}</span>
                  </A.Form.Item>
                  <A.Form.Item label="贈品名稱" className="formItem">
                    <span>{detail.ordGift}</span>
                  </A.Form.Item>
                </A.Form>
              </A.Col>
            </A.Row>

            <A.Row className="submitBtn" align="center">
              <Link to="/admin/order/list">
                <A.Button>回上一頁</A.Button>
              </Link>
            </A.Row>
          </A.Card>
        </A.Col>
      </A.Row>
    </A.Layout>
  );
};

export default Orderdetail;
