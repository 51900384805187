import React, { useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { actionCreators } from "src/actions/merchant";
import { merchantService } from "src/services";

import * as A from "antd";
import "./Profile.css";

const Profile = () => {
    const [form] = A.Form.useForm();
    const [defaultValue] = useState({});
    const [pwd, setPwd] = useState({newPwd: "", checkPwd: ""});
    const [merAdmCellPhone, setMerAdmCellPhone] = useState("");
    const [merAdmPhone, setMerAdmPhone] = useState("");
    const [merAdmEmail, setMerAdmEmail] = useState("");
    const [alertStautus, setAlertStatus] = useState({message: null, type: null});

    const dispatch = useDispatch();
    // 取出 store 中的 state
    const personalInfo = useSelector(state => state.merchant.storePersonalInfo);

    // action : 取得個人帳號資訊
    useEffect(() => {
        dispatch(actionCreators.getPersonalInfo());
    }, []);

    // 設定初始值
    useEffect(() => {
        form.setFieldsValue({
            merAdmCellPhone: personalInfo.merAdmCellPhone ? personalInfo.merAdmCellPhone : "",
            merAdmPhone: personalInfo.merAdmPhone ? personalInfo.merAdmPhone : "",
            merAdmEmail: personalInfo.merAdmEmail ? personalInfo.merAdmEmail : "",
        })
    }, [form, personalInfo]);

    // 配值
    useEffect(() => {
        setMerAdmCellPhone(personalInfo.merAdmCellPhone);
        setMerAdmPhone(personalInfo.merAdmPhone);
        setMerAdmEmail(personalInfo.merAdmEmail);
    }, [personalInfo]);

    const onFinish = (values) => {
        let payload = {
            cellPhone: merAdmCellPhone,
            phone: merAdmPhone,
            email: merAdmEmail,
        };
        if (pwd.newPwd) payload["newPwd"] = pwd.newPwd;

        merchantService.updatePersonalInfo(payload)
        .then(
            res => {
                res.json().then(result => {
                    if (result.message === "ok") {
                        setAlertStatus({
                            message: "修改成功",
                            type: "success",
                        });
                    } else if (result.code === "9100") {
                        setAlertStatus({
                            message: "填寫有誤",
                            type: "warning",
                        });
                    } else {
                        setAlertStatus({
                            message: "修改失敗",
                            type: "error",
                        });
                    }
                });
            },
            error => {
                console.log(error);
            },
        );
    };

    const onFinishFailed = (values) => {
        return false;
    };

    const closeAlert = () => {
        setAlertStatus({
            message: "",
            type: "",
        });
    };

    return (
        <A.Layout className="merLayout">
            <A.Row justify="center">
                <A.Col span={23}>
                    <div className="title">
                        個人資料維護
                    </div>

                    <A.Card className="merLayoutCard userEditCard">
                        {alertStautus.message 
                            ? <A.Alert message={alertStautus.message} type={alertStautus.type} banner closable afterClose={closeAlert} /> 
                            : null
                        }
                        <A.Row className="userEditCardContent">
                            <A.Col className="userEditCardItem" span={23} xs={23} sm={23} md={23}>
                                <A.Form
                                  form={form}
                                  labelCol={{flex: "100px"}} 
                                  wrapperCol={{span: 9, flex: 1}} 
                                  labelAlign="left" 
                                  colon={false}
                                  initialValues={defaultValue}
                                  onFinish={onFinish}
                                  onFinishFailed={onFinishFailed}
                                >
                                    <A.Form.Item label="帳號" name="merAdmAcct">
                                        <span>{personalInfo.merAdmAcct}</span>
                                    </A.Form.Item>
                                    <A.Form.Item
                                      label="修改密碼"
                                      name="newPwd"
                                      rules={pwd.newPwd ? [
                                        () => ({
                                            validator(_, value) {
                                                if (!value.match(/.*[A-Za-z]+.*[0-9]+.*/) && !value.match(/.*[0-9]+.*[A-Za-z]+.*/) && value.length !== 0) {
                                                    return Promise.reject("須為包含1個英文字的英數混合字符");
                                                }
                                                if ((value.length < 4 || value.length > 25) && value.length !== 0) {
                                                    return Promise.reject("須介於4至25字元");
                                                }
                                                return Promise.resolve();
                                            },
                                        })
                                      ] : [{}]}
                                    >
                                        <A.Input.Password placeholder="重新設定" onChange={(e) => setPwd({...pwd, newPwd: e.target.value})} />
                                    </A.Form.Item>
                                    <A.Form.Item
                                      label="確認密碼"
                                      name="checkPwd"
                                      rules={[
                                        () => ({
                                            validator(_, value) {
                                                if (pwd.newPwd && value !== pwd.newPwd) {
                                                    return Promise.reject("與「修改密碼」不一致");
                                                }
                                                return Promise.resolve();
                                            },
                                        })
                                      ]}
                                    >
                                        <A.Input.Password onChange={(e) => setPwd({...pwd, checkPwd: e.target.value})} />
                                    </A.Form.Item>
                                    <A.Form.Item label="姓名" name="merAdmName">
                                        <span>{personalInfo.merAdmName}</span>
                                    </A.Form.Item>
                                    <A.Form.Item 
                                      label="手機" 
                                      name="merAdmCellPhone"
                                      rules={[
                                        () => ({
                                            validator(_, value) {
                                                if (value.length > 20) return Promise.reject("須小於20字元");
                                                return Promise.resolve();
                                            },
                                        })
                                      ]}
                                    >
                                        <A.Input onChange={(e) => setMerAdmCellPhone(e.target.value)} />
                                    </A.Form.Item>
                                    <A.Form.Item 
                                      label="公司電話" 
                                      name="merAdmPhone"
                                      rules={[
                                        () => ({
                                            validator(_, value) {
                                                if (value.length > 20) return Promise.reject("須小於20字元");
                                                return Promise.resolve();
                                            },
                                        })
                                      ]}
                                    >
                                        <A.Input onChange={(e) => setMerAdmPhone(e.target.value)} />
                                    </A.Form.Item>
                                    <A.Form.Item 
                                      label="公司信箱" 
                                      name="merAdmEmail" 
                                      rules={[
                                        {
                                            type: "email",
                                            message: "請輸入正確的信箱格式"
                                        },
                                        () => ({
                                            validator(_, value) {
                                                if (value.length > 50) return Promise.reject("須小於50字元");
                                                return Promise.resolve();
                                            },
                                        })
                                      ]}
                                    >
                                        <A.Input onChange={(e) => setMerAdmEmail(e.target.value)} />
                                    </A.Form.Item>
                                    <div style={{textAlign: "center"}}>
                                        <A.Button className="submitBtn" type="primary" htmlType="submit">儲存</A.Button>
                                    </div>
                                </A.Form>
                            </A.Col>
                        </A.Row>
                    </A.Card>
                </A.Col>
            </A.Row>
        </A.Layout>
    );
};

export default Profile;
