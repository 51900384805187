import React from "react";
import { NEW_MEMBER_URL, LINE_LIFF_ID_MEMBER } from "src/config";

// import styles
import "swiper/css";
import "swiper/css/navigation";
// import img
import activityShare1 from "src/assets/jetdai/activity-share/activity_share1.png";
import activityShare1Mobile from "src/assets/jetdai/activity-share/activity_share1_mobile.png";
import activityShareTitle1 from "src/assets/jetdai/activity-share/activity_share_title1.png";

import activitySharePeople from "src/assets/jetdai/activity-share/jetshop_people.png";
import activityShareLinePoint from "src/assets/jetdai/activity-share/line_point.png";
// antd
import { Row, Col } from "antd";

const Section1 = () => {
  return (
    <Row className="frontActivityShareSection1" justify="center">
      <Col className="d-flex justify-center" span={24}>
        <div>
          <a href={`https://liff.line.me/${LINE_LIFF_ID_MEMBER}?via=share`}>
            <img src={activityShare1} alt="Jet貸" className="activityShare1 isHideMobile" />
            <img src={activityShare1Mobile} alt="Jet貸" className="activityShare1 isShowMobile" />
          </a>
        </div>
      </Col>
      <Col span={24} style={{ marginTop: 20, paddingLeft: 15, paddingRight: 15 }}>
        <h1 class="frontActivityShareTextStyle frontActivityShareFontStyle" data-stroke='"薦"者有份'>
          "薦"者有份
        </h1>
        <h1 class="frontActivityShareTextStyle isHideMobile" data-stroke="舊戶推薦禮累計最高回饋2,600元，新戶申請享 50 元用戶金回饋">
          舊戶推薦禮累計最高回饋2,600元，新戶申請享 50 元用戶金回饋
        </h1>
        <h1 class="frontActivityShareTextStyle isShowMobile" data-stroke="舊戶推薦禮累計最高回饋2,600元">
          舊戶推薦禮累計最高回饋2,600元
        </h1>
        <h1 class="frontActivityShareTextStyle isShowMobile" data-stroke="新戶申請享 50 元用戶金回饋">
          新戶申請享 50 元用戶金回饋
        </h1>
      </Col>
      <Col span={24} style={{ marginTop: 20, marginBottom: 10 }}>
        <Row justify="center" gutter={[24, 16]} style={{ marginLeft: 0, marginRight: 0 }}>
          <Col>
            <a href={`${NEW_MEMBER_URL}`} style={{ marginRight: 20 }}>
              <button className="frontLandingBtn-dark frontLandingBtn-dark-orange">
                {"新 戶 申 請"}
              </button>
            </a>
            <a href={`https://liff.line.me/${LINE_LIFF_ID_MEMBER}?via=share`}>
              <button className="frontLandingBtn-dark frontLandingBtn-dark-orange">
                {"前 往 推 薦 獎 勵 專 區"}
              </button>
            </a>
          </Col>
        </Row>
        {/* <Row justify="center" gutter={[24, 16]} style={{ marginTop: "20px", marginLeft: 0, marginRight: 0 }}>
          <Col>
            <img src={activityShare3} className="activityShare3" alt="薦者有份" />
          </Col>
        </Row> */}
      </Col>
      <Col className="d-flex justify-center" span={24} style={{ marginTop: 25 }}>
        <div>
          <img src={activityShareTitle1} alt="獎勵列表" className="activityShareTitle" />
        </div>
      </Col>
      <Col className="justify-center" span={24} style={{ marginTop: 10 }}>
        <div className="activitySharePeople">
          <img src={activitySharePeople} alt="獎勵列表" />
        </div>
        <div className="activityShareMain">
          <img src={activityShareLinePoint} alt="獎勵列表" />
          成功邀請 1 位好友申辦核准<span class="isHideMobile">，</span><br class="isShowMobile"></br>即可獲得<span class="linePointGreen">&nbsp;100&nbsp;點</span>Line Point回饋
        </div>
        <div className="activityShareMain">
          <img src={activityShareLinePoint} alt="獎勵列表" />
          成功邀請 3 位好友申辦核准<span class="isHideMobile">，</span><br class="isShowMobile"></br>即可獲得<span class="linePointGreen">&nbsp;500&nbsp;點</span>Line Point回饋
        </div>
        <div className="activityShareMain">
          <img src={activityShareLinePoint} alt="獎勵列表" />
          成功邀請 10 位好友申辦核准<span class="isHideMobile">，</span><br class="isShowMobile"></br>即可獲得<span class="linePointGreen">&nbsp;2,000&nbsp;點</span>Line Point回饋
        </div>
      </Col>
    </Row>
  );
}

export default Section1;
