import Cookies from 'universal-cookie';
import { ADMIN_API_URL as api_admin_url } from 'src/config';

const cookies = new Cookies();
const reqOptions = {
  get: {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  },
  getWithJwt: {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${cookies.get('_merchant_auth_token')}`
    }
  },
};

// 取得商家訂單
const getOrderList = async (payload) => {
  let { startDate, endDate, appCaseStage, appCurrPayTag } = payload;
  return fetch(
      `${api_admin_url}/order?access_token=${cookies.get('_merchant_auth_token')}&limit=9999999&startDate=${startDate}&endDate=${endDate}&appCaseStage=${appCaseStage}&appCurrPayTag=${appCurrPayTag}`,
      reqOptions.get
  );
};

// 取得指定訂單
const getOrderDetail = async (appCaseNo) => {
  return fetch(
    `${api_admin_url}/order/${appCaseNo}?access_token=${cookies.get('_merchant_auth_token')}`,
    reqOptions.get
  );
};

// 取得客戶切結書及取貨文件
const getDeclaration = async (appSerNo) => {
  return fetch(
    `${api_admin_url}/apply-cases/${appSerNo}/declaration`,
    reqOptions.getWithJwt
  );
};

export const orderService = {
    getOrderList,
    getOrderDetail,
    getDeclaration,
};
