import Cookies from 'universal-cookie';
import { ADMIN_API_URL as api_admin_url } from 'src/config';

const cookies = new Cookies();
const reqOptions = {
  get: {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${cookies.get('_root_auth_token')}`
    },
  },
  post: {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${cookies.get('_root_auth_token')}`
    },
  },
  postFormData: {
    method: "POST",
    headers: { "Authorization": `Bearer ${cookies.get('_root_auth_token')}` },
  },
  patch: {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${cookies.get('_root_auth_token')}`
    },
  },
  delete: {
    method: "DELETE",
    headers: {
      "Content-Type": "text/html",
      "Authorization": `Bearer ${cookies.get('_root_auth_token')}`
    },
  },
};

// 取得FAQ
const getFAQ = async (FAQTypeSerialNumber) => {
  return fetch(
    `${api_admin_url}/frequently-asked-questions?FAQTypeSerialNumber=${FAQTypeSerialNumber}`,
    reqOptions.get
  );
};

// 新增FAQ
const addFAQ = async (payload) => {
  return fetch(
    `${api_admin_url}/frequently-asked-questions`,
    {
      ...reqOptions.post,
      body: JSON.stringify(payload)
    }
  );
};

// FAQ中的圖片上傳
const uploadFAQImage = async (formData) => {
  return fetch(
    `${api_admin_url}/frequently-asked-questions/image-upload`,
    {
      ...reqOptions.postFormData,
      body: formData
    }
  );
};

// 刪除FAQ
const deleteFAQ = async (FAQSerialNumber) => {
  return fetch(
    `${api_admin_url}/frequently-asked-questions/${FAQSerialNumber}`,
    reqOptions.delete
  );
};

// 編輯FAQ
const editFAQ = async (FAQSerialNumber, payload) => {
  return fetch(
    `${api_admin_url}/frequently-asked-questions/${FAQSerialNumber}`,
    {
      ...reqOptions.patch,
      body: JSON.stringify(payload)
    }
  );
};

// 修改FAQ order
const editFAQOrder = async (FAQSerialNumber, payload) => {
  return fetch(
    `${api_admin_url}/frequently-asked-questions/${FAQSerialNumber}/order`,
    {
      ...reqOptions.patch,
      body: JSON.stringify(payload)
    }
  );
};

// 取得FAQ類型
const getFAQType = async () => {
  return fetch(
    `${api_admin_url}/frequently-asked-question-types`,
    reqOptions.get
  );
};

// 新增FAQ類型
const addFAQType = async (payload) => {
  return fetch(
    `${api_admin_url}/frequently-asked-question-types`,
    {
      ...reqOptions.post,
      body: JSON.stringify(payload)
    }
  );
};

// 修改FAQ類型
const editFAQType = async (FAQTypeSerialNumber, payload) => {
  return fetch(
    `${api_admin_url}/frequently-asked-question-types/${FAQTypeSerialNumber}`,
    {
      ...reqOptions.patch,
      body: JSON.stringify(payload)
    }
  );
};

// 刪除FAQ類型
const deleteFAQType = async (FAQTypeSerialNumber) => {
  return fetch(
    `${api_admin_url}/frequently-asked-question-types/${FAQTypeSerialNumber}`,
    reqOptions.delete
  );
};

export const FAQService = {
  getFAQ,
  addFAQ,
  uploadFAQImage,
  deleteFAQ,
  editFAQ,
  editFAQOrder,
  getFAQType,
  addFAQType,
  editFAQType,
  deleteFAQType
};
