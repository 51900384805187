export const jetTypes = {
  // 商家資訊
  GET_EDITING_SHOP_INFO: "GET_EDITING_SHOP_INFO",
  GET_SHOP_PREVIEW_INFO: "GET_SHOP_PREVIEW_INFO",
  GET_SHOP_ROUTE_NAME: "GET_SHOP_ROUTE_NAME",
  // 活動專區
  TAB_ACTIVE_KEY: "TAB_ACTIVE_KEY",
  IS_EDIT: "IS_EDIT",
  GET_EDITING_SHOP_ACTIVITY: "GET_EDITING_SHOP_ACTIVITY",
  GET_SHOP_ACTIVITY_PRODUCTS: "GET_SHOP_ACTIVITY_PRODUCTS",
};