import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { actionCreators } from "src/actions/jet";
import { jetService } from "src/services";

// antd & css
import { Layout, Row, Col, Card, Divider, Form, Input, Upload, Button, message, notification } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "./Jet.css";

//component
import ModalSample from "src/components/Jet/ModalSample";
import ModalUrl from "src/components/Jet/ModalUrl";
import TextEditor from "src/components/Editor";

import SampleProdList from "src/assets/sample_prodList.png";
import SampleShopNote from "src/assets/sample_shopNote.png";

// 圖片 url 轉圖檔格式
const toDataURL = async (url, callback) => {
  const xhr = new XMLHttpRequest();
  xhr.onload = () => {
    const reader = new FileReader();
    reader.onloadend = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
};

// 圖檔格式轉 binary
const convertBase64ToBlob = (base64Img) => {
  if (base64Img) {
    // Split into two parts
    const parts = base64Img.split(";base64,");
    // Hold the content type
    const imgType = parts[0].split(":")[1];
    // Decode Base64 string
    const decodedData = window.atob(parts[1]);
    // Create UNIT8ARRAY of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length);
    // Insert all character code into uInt8Array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }

    // Return BLOB image after conversion
    return new Blob([uInt8Array], { type: imgType });
  }
};

const JetInfo = () => {
  const [form] = Form.useForm();
  const [defaultValue] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isSmallSize, setIsSmallSize] = useState(windowWidth < 576);
  const [fileList, setFileList] = useState([]);
  const [shopLogo, setShopLogo] = useState(null);
  const [shopInfoNotice, setShopInfoNotice] = useState("");

  const dispatch = useDispatch();
  
  // 取出 store 中的 state
  const editingShopInfo = useSelector(state => state.jet.editingShopInfo);

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);
    onWindowResize();
  
    return () => window.removeEventListener("resize", onWindowResize);
  }, [windowWidth]);

  const onWindowResize = () => {
    setWindowWidth(window.innerWidth);
    setIsSmallSize(window.innerWidth < 576);
  };

  // action: 取得編輯中的商家資訊
  useEffect(() => {
    dispatch(actionCreators.getEditingShopInfo());
  }, []);

  // 設定初始值
  useEffect(() => {
    if (editingShopInfo.shopInfo && JSON.stringify(editingShopInfo.shopInfo) !== "{}") {
      const info = editingShopInfo.shopInfo;
      form.setFieldsValue({
        shopInfoName: info.shopInfoName,
        shopInfoSlogan: info.shopInfoSlogan,
        shopInfoAddress: info.shopInfoAddress,
        shopInfoPhone: info.shopInfoPhone,
        shopInfoLineURL: info.shopInfoLineURL,
        shopInfoFacebookURL: info.shopInfoFacebookURL,
        shopInfoInstagramURL: info.shopInfoInstagramURL,
        shopInfoOtherURL: info.shopInfoOtherURL,
      });
      setShopInfoNotice(info.shopInfoNotice);
      if (info.shopInfoLogo) {
        setFileList([{ url: info.shopInfoLogo }]);
        toDataURL(info.shopInfoLogo, resUrl => {
          uploadImageFormat(resUrl);
        });
      }
    }
  }, [editingShopInfo.shopInfo]);

  const uploadImageFormat = (base64Img) => {
    const blobData = convertBase64ToBlob(base64Img);
    setShopLogo(blobData);
  };

  // 預覽圖片
  const onPreview = async (file) => {
    const image = new Image();
    image.src = file.url;
    const imgWindow = window.open(file.url);
    imgWindow.document.write(image.outerHTML);
  };

  // 刪除圖片
  const onRemove = async () => {
    setFileList([]);
    setShopLogo(null);
  };

  // 上傳前更新 fileList、驗證圖片大小
  const beforeUpload = (file) => {
    const isLt8M = file.size / 1024 / 1024 < 8;
    if (!isLt8M) {
      message.error("檔案大小須小於8MB");
      return Upload.LIST_IGNORE;
    }
    setShopLogo(file);
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const image = new Image();
      image.src = reader.result;
      setFileList([{
        uid: reader.uid,
        url: reader.result,
      }]);
    });
    reader.readAsDataURL(file);
    return false;
  };

  const editorCallBack = (htmlString) => {
    // 編輯框
    setShopInfoNotice(htmlString);
  };

  // 預覽官網
  const handlePreviewPage = () => {
    const info = editingShopInfo.shopInfo;
    if (info) {
      if (!info.shopInfoName && !info.shopInfoSlogan) {
        notification.warning({
          message: "以下必填欄位資料未填寫，請填入資料並儲存。",
          description: "「商家名稱」、「宣傳標語」",
        });
      }
      window.open("/admin/ecommerce/preview", "_blank");
    } else {
      notification.warning({
        message: "以下必填欄位資料未填寫，請填入資料並儲存。",
        description: "「商家名稱」、「宣傳標語」",
      });
    }
  };

  const onFinish = (values) => {
    const formData = new FormData();
    Object.keys(values).forEach(item => {
      if (item !== "shopInfoLogo" && values[item]) {
        formData.append(item, values[item]);
      }
    });
    if (shopLogo) formData.append("logoFile", shopLogo);
    formData.append("shopInfoNotice", shopInfoNotice);

    jetService.updateShopInfo(formData)
      .then(
        res => {
          if (res.status === 201) {
            message.success("儲存成功");
            dispatch(actionCreators.getEditingShopInfo());
          } else if (res.status === 401) {
            message.warning("存取拒絕");
          } else {
            message.error("儲存失敗");
          }
        },
        error => {
          console.log(error);
        },
      );
  };

  const onFinishFailed = () => false;

  return (
    <Layout className="merLayout">
      <Row justify="center">
        <Col span={23}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div className="title">商家資訊</div>
            <div>
              <ModalUrl />
              <Button onClick={handlePreviewPage} style={{ marginRight: 10 }}>預覽官網</Button>
            </div>
          </div>
          <Card className="merLayoutCard">
            <Form
              form={form}
              className="jet-form"
              labelCol={{ flex: "100px" }}
              wrapperCol={{ span: 18 }}
              labelAlign="left"
              colon={false}
              initialValues={defaultValue}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Row gutter={30}>
                {/* 資料 */}
                <Col xs={24} sm={12} md={12}>
                  {isSmallSize && <ModalSample sourceType="info" />}
                  <Divider className="subTitle" orientation="left" orientationMargin="0" style={{ margin: '5px 0 1em' }}>
                    基本資料
                  </Divider>
                  <Form.Item label="Logo上傳">
                    <Form.Item name="shopInfoLogo" style={{ marginBottom: 0 }}>
                      <Upload 
                        listType="picture-card"
                        accept="image/*"
                        maxCount={1}
                        fileList={fileList}
                        onPreview={onPreview}
                        onRemove={onRemove}
                        beforeUpload={beforeUpload}
                      >
                        <PlusOutlined />
                      </Upload>
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 0 }}>
                      <span style={{ color: "#B0B0B0" }}>• 圖片大小限制8MB</span>
                    </Form.Item>
                  </Form.Item>
                  <Form.Item 
                    label="商家名稱" 
                    name="shopInfoName" 
                    rules={[
                      { required: true, message: "請輸入商家名稱" },
                      () => ({
                        validator(_, value) {
                          if (value.length > 25) return Promise.reject("須小於25字元");
                          return Promise.resolve();
                        },
                      })
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item 
                    label="宣傳標語" 
                    name="shopInfoSlogan" 
                    rules={[
                      { required: true, message: "請輸入宣傳標語" },
                      () => ({
                        validator(_, value) {
                          if (value.length > 255) return Promise.reject("須小於255字元");
                          return Promise.resolve();
                        },
                      })
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label="聯絡地址" name="shopInfoAddress">
                    <Input />
                  </Form.Item>
                  <Form.Item 
                    label="聯絡電話" 
                    name="shopInfoPhone"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (value) {
                            if (!value.match(/(\d{2,3}-?|\(\d{2,3}\))\d{3,4}-?\d{4}|09\d{2}(\d{6}|-\d{3}-\d{3})/)) {
                              return Promise.reject("須為市話或手機格式")
                            };
                          }
                          return Promise.resolve();
                        },
                      })
                    ]}
                  >
                    <Input maxLength={20} />
                  </Form.Item>
                  <Form.Item 
                    label="Line帳號連結" 
                    name="shopInfoLineURL"
                    rules={[{ type: "url", message: "請輸入正確的網址格式" }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item 
                    label="FB粉專連結" 
                    name="shopInfoFacebookURL"
                    rules={[{ type: "url", message: "請輸入正確的網址格式" }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="IG粉專連結" 
                    name="shopInfoInstagramURL"
                    rules={[{ type: "url", message: "請輸入正確的網址格式" }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item 
                    label="其他網址" 
                    name="shopInfoOtherURL"
                    rules={[{ type: "url", message: "請輸入正確的網址格式" }]}
                  >
                    <Input />
                  </Form.Item>

                  <Divider className="subTitle" orientation="left" orientationMargin="0" style={{ margin: '3em 0 1em' }}>
                    購物須知說明
                  </Divider>
                  <TextEditor
                    textString={shopInfoNotice}
                    parentCallback={editorCallBack}
                    editorType={'withImg'}
                    imgStorage={'shopNotice'}
                  />
                </Col>
                {/* 示意圖 */}
                {!isSmallSize && <Col sm={12} md={12}>
                  <div className="jet-sample">
                    <img className="jet-sample-img" src={SampleProdList} alt="基本資料示意圖" />
                  </div>
                  <div className="jet-sample">
                    <img className="jet-sample-img" src={SampleShopNote} alt="購物須知示意圖" />
                  </div>
                </Col>}
              </Row>

              <Row align="center">
                <Button 
                  type="primary"
                  htmlType="submit"
                  style={{ marginTop: 30 }}
                >
                  儲存
                </Button>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}

export default JetInfo;
