import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import auth from 'src/reducers/auth';
import merchant from 'src/reducers/merchant';
import product from 'src/reducers/product';
import order from 'src/reducers/order';
import Root from 'src/reducers/root';
import applyType from 'src/reducers/apply-type';
import productType from 'src/reducers/product-type';
import banner from 'src/reducers/banner';
import installment from 'src/reducers/installment';
import FAQ from 'src/reducers/FAQ';
import report from 'src/reducers/report';
// 前台官網
import frontFAQ from 'src/reducers/front-FAQ';
// jet捷店
import jet from 'src/reducers/jet';
import frontJet from 'src/reducers/front-jet';

const reducers = combineReducers({
  auth,
  merchant,
  product,
  order,
  Root,
  applyType,
  productType,
  banner,
  installment,
  FAQ,
  report,
  frontFAQ,
  jet,
  frontJet,
});

const configureStore = () => {
  return createStore(reducers, compose(applyMiddleware(thunk)));
};

export default configureStore;
