import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { frontProductService } from "src/services";
import { MEMBER_URL } from "src/config";
// import { code } from "./statusCode";

import { Row, Col, Card, Tag, Divider, Select, Radio, Button, Image, Modal } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import ProductCarousel from "src/components/Front/Carousel";
import Loading from "src/components/Loading/Loading";

import "./index.css";

const ProductDetail = (props) => {
	const {shopRouteName, prodNo} = useParams();

	const [prodData, setProdData] = useState({});
	const [prodSpec, setProdSpec] = useState(null);
	const [period, setPeriod] = useState(0);
  const [hasData, setHasData] = useState(true);
	const [isLoading, setIsLoading] = useState(true);

	// 取得商品詳細資料
	useEffect(() => {
		let isMounted = true;
    if (!props.isDynamic) {
      frontProductService.getFrontProductStatic(prodNo).then(
        (res) => {
          res.json().then(result => {
            if (res.status === 200 && isMounted) assignData(result);
            else {
              setHasData(false);
							setIsLoading(false);
            }
          });
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      frontProductService.getFrontProductDynamic(shopRouteName, prodNo).then(
        (res) => {
          res.json().then(result => {
            if (res.status === 200 && isMounted) assignData(result);
            else {
              setHasData(false);
							setIsLoading(false);
            }
          });
        },
        (error) => {
          console.log(error);
        }
      );
    }
		return () => isMounted = false;
	}, [prodNo]);

	const assignData = (result) => {
		let data = {};
		data.merAcctId = result.merAcctId;
		data.periods = result.periods.reverse();
		data.prodBrandData = result.prodBrandData;
		data.prodDesPhotos = result.prodDesPhotos;
		data.prodDetailDes = result.prodDetailDes;
		data.prodGift = result.prodGift;
		data.prodItemData = result.prodItemData;
		data.prodName = result.prodName;
		data.prodNo = result.prodNo;
		data.prodPhotos = result.prodPhotos;
		// data.prodPromotion = result.prodPromotion;
		// data.prodPromtStatus = code.prodPromtStatus[result.prodPromtStatus];
		data.prodSpecItem = result.prodSpecItem;
		data.prodTypeData = result.prodTypeData;
		data.initPeriod = result.periods[0];
		setProdData(data);
		setProdSpec(data.prodSpecItem[0]);
		setPeriod(data.initPeriod);
		setIsLoading(false);
	};

	const onChangeSpec = (e) => {
		const spec = prodData.prodSpecItem.filter(item => item.prodSpecNo === e);
		setProdSpec(spec[0]);
	};

	const onChangePeriod = (e) => {
		const p = prodData.periods[e.target.value];
		setPeriod(p);
	};

	const submit = () => {
		const { warning } = Modal
		if (!prodSpec) {
			warning({
				title: "請選擇商品規格",
				okText: "關閉",
				okType: "default"
			});
		} else if (!props.isDynamic) {
			window.location.href=
				`${MEMBER_URL}/member/order/step1?merAcctId=${prodData.merAcctId}&prodNo=${prodData.prodNo}&prodName=${prodData.prodName}&prodSpec=${prodSpec.prodSpecName}&prodNum=1&prodPeriods=${period.period}&prodPeriodAmt=${period.periodAmt}&prodGift=${prodData.prodGift ? prodData.prodGift : ''}&prodEncryptNo=${prodNo}`;
		} else {
			frontProductService.getFrontProductEncrypt(shopRouteName, prodNo)
			.then(
				(res) => {
					res.json().then(result => {
						window.location.href=
							`${MEMBER_URL}/member/order/step1?merAcctId=${prodData.merAcctId}&prodNo=${prodData.prodNo}&prodName=${prodData.prodName}&prodSpec=${prodSpec.prodSpecName}&prodNum=1&prodPeriods=${period.period}&prodPeriodAmt=${period.periodAmt}&prodGift=${prodData.prodGift ? prodData.prodGift : ''}&prodEncryptNo=${result.prodEncryptNo}`;
					});
				},
				(error) => {
					console.log(error);
				}
			);
		}
	};

	if (isLoading) {
		return (
			<Loading />
		)
	}

  if (!hasData) {
    return (
      <div style={{ marginTop: 50, fontSize: 35 }}>
        <CloseCircleOutlined style={{ color: "red" }} /> 找不到此商品
      </div>
    )
  }

	return (
		<div>
			{/* 商品分類 */}
			<Row style={{margin: '20px 24px 0'}}>
				{`商品分類：${prodData.prodTypeData ? prodData.prodTypeData.prodTypeDes : ''} > ${prodData.prodItemData ? prodData.prodItemData.prodItemDes : ''}`}
			</Row>
			{/* 商品頁上半部 */}
			<Row justify="center" style={{marginTop: '20px'}}>
				<Col span={23} md={23} sm={22} xs={22}>
					<Card className="frontProductCard">
						<Row>
							<Col span={10} md={10} sm={24} xs={24} style={{marginBottom: '2em'}}>
								<ProductCarousel prodPhotos={prodData.prodPhotos} />
							</Col>
							<Col span={14} md={14} sm={24} xs={24}>
								<div className="prodIntro">
									<p className="prod-name">{prodData.prodName}</p>
									{
										prodData.prodBrandData &&
										<p className="text">
											<span>商品品牌：</span>
											<span>{prodData.prodBrandData.prodBrandDes}</span>
										</p>
									}
									{/*
										{prodData.prodPromtStatus && <Tag className="front" color="red">{prodData.prodPromtStatus}</Tag>}
										{prodData.prodPromotion && <Tag className="front" color="gold">{prodData.prodPromotion}</Tag>}
									*/}
									<Divider />
									<p className="text">商品規格：</p>
									<Select className="select-spec" defaultValue={1} onChange={onChangeSpec}>
										{
											prodData.prodSpecItem && prodData.prodSpecItem.map(item => (
												<Select.Option key={item.prodSpecNo} value={item.prodSpecNo} disabled={item.prodInventory === 0}>
													{item.prodSpecName}
												</Select.Option>
											))
										}
									</Select>
									<p className="text" style={{marginTop: '15px'}}>分期期數：</p>
									<p className="description">
										{period.insDescription}
									</p>
									<Radio.Group defaultValue={0} onChange={onChangePeriod}>
										{
											prodData.periods &&
											prodData.periods.map((item, idx) => (
												<Radio.Button
													className='period-btn'
													key={item.period}
													value={idx}
												>
													{item.insButtonName}
												</Radio.Button>
											))
										}
									</Radio.Group>
									<p className="text">
										月付：
										<b className="amount">{`NT $${period.periodAmt}`} </b>
										<span className="period">{`* ${period.period}期`}</span>
									</p>
								</div>
								<div style={{textAlign: 'right'}}>
									<Button className="install" type="primary" size="large" onClick={submit}>
										立即分期
									</Button>
								</div>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
			{/* 商品頁下半部 */}
			{
				prodData.prodGift
				|| (typeof prodData.prodDesPhotos === 'object' ? prodData.prodDesPhotos.length > 0 : prodData.prodDesPhotos)
				|| prodData.prodDetailDes
				? (<Row justify="center">
					<Col span={23} md={23} sm={22} xs={22}>
						<Card className="frontProductCard">
							{
								prodData.prodGift &&
								[
									<div className="prod-title" span={24} md={24} sm={24} xs={24} key={1}>
										<p className="text">商品贈品說明</p>
									</div>,
									<div span={24} md={24} sm={24} xs={24} style={{textAlign: 'left', margin: '20px'}} key={2}>
										<p>{prodData.prodGift}</p>
									</div>
								]
							}
							{
								(typeof prodData.prodDesPhotos === 'object' ? prodData.prodDesPhotos.length > 0 : prodData.prodDesPhotos)
								|| prodData.prodDetailDes
								? (<div className="prod-title" span={24} md={24} sm={24} xs={24}>
									<p className="text">商品說明</p>
								</div>) : null
							}
							{
								prodData.prodDesPhotos &&
								prodData.prodDesPhotos.map(item => (
									<Image
									key={item.prodDesPhotoItemNo}
										width="100%"
										className="product-img"
										src={item.prodDesPhotos}
										alt="product-description"
									/>
								))
							}
							{
								prodData.prodDetailDes &&
								<div
									style={{textAlign: 'left', margin: '20px'}}
									dangerouslySetInnerHTML={{__html: prodData.prodDetailDes}}
								>
								</div>
							}
						</Card>
					</Col>
				</Row>) : null
			}
		</div>
	);
}

export default ProductDetail;
