import React from "react";

// antd
import { Row, Col, Collapse } from "antd";

// component
import FrontDivCenter from "src/components/Front/Divider/DivCenter";

const Section4 = () => {
  return (
    <>
      <Row justify="center" style={{ marginTop: 70, marginBottom: 70 }}>
        <Col span={24}>
          <h2 className="front" style={{ margin: 0 }}>
            常見問題
          </h2>
          <FrontDivCenter />
        </Col>
        <Col span={20} md={20} sm={20} xs={20}>
          <Collapse>

            <Collapse.Panel
              header="Q1. 申請Jet貸需要準備那些文件？"
            >
              <div className="faq-expanded-item">
                <p className="pre-line">
                只需準備身分證件、第二證件（駕照、健保卡等）、本人銀行存摺即可申辦。
                </p>
              </div>
            </Collapse.Panel>

            <Collapse.Panel
              header="Q2. 沒有信用紀錄也可以申請嗎？"
            >
              <div className="faq-expanded-item">
                <p className="pre-line">
                可以。Jet貸會評估您的自身情況提供適合的方案，若希望了解更多，可以加入Line官方客服諮詢：
                  <a href="https://lin.ee/E7mTEiS" target="_blank" rel="noreferrer">https://lin.ee/E7mTEiS</a>
                  洽詢。
                </p>
              </div>
            </Collapse.Panel>

            <Collapse.Panel
              header="Q3. Jet貸跟銀行信貸有甚麼不一樣？"
            >
              <div className="faq-expanded-item">
                <p className="pre-line">
                  Jet貸不查聯徵，不需具備信用紀錄，只要您有需求都能申請。
                </p>
              </div>
            </Collapse.Panel>

            <Collapse.Panel
              header="Q4. 申請Jet貸需要留手機嗎？"
            >
              <div className="faq-expanded-item">
                <p className="pre-line">
                  不需要唷，申請Jet貸不會向您收取手機。
                </p>
              </div>
            </Collapse.Panel>

            <Collapse.Panel
              header="Q5. 送出申請後甚麼時候可以拿到錢？"
            >
              <div className="faq-expanded-item">
                <p className="pre-line">
                  送出申請並經核准後，一個工作天後款項將會匯款至指定帳戶。
                </p>
              </div>
            </Collapse.Panel>

            <Collapse.Panel
              header="Q6. 商品分期和Jet貸可以同時申請嗎？"
            >
              <div className="faq-expanded-item">
                <p className="pre-line">
                  不行，若其中一個案件經核准/婉拒，需間隔3個月後才能再次申請。
                </p>
              </div>
            </Collapse.Panel>

            <Collapse.Panel
              header="Q7. 我可以提供別人的銀行帳戶作為撥款帳戶嗎？"
            >
              <div className="faq-expanded-item">
                <p className="pre-line">
                  撥款帳戶需為申請人本人之銀行帳戶。
                  <span className="highlight-text">(戶名必須為申辦人姓名)</span>
                </p>
              </div>
            </Collapse.Panel>

            <Collapse.Panel
              header="Q8. 申辦是否一定通過呢?是否需要保證人？"
            >
              <div className="faq-expanded-item">
                <p className="pre-line">
                  我們會評估各方面的資訊，所以還是要申請審核後，才會知道審核結果。
                  (本公司<span className="highlight-text">擁有申請案件核准與否同意權</span>。)
                </p>
              </div>
            </Collapse.Panel>

            <Collapse.Panel
              header="Q9. 請問申辦Jet貸成功後，有哪些繳款方式？"
            >
              <div className="faq-expanded-item">
                <p className="pre-line">
                  Step1. 請登入【消費者中心】網址：
                  <a
                    href="https://app.jetshop.tw/login"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    https://app.jetshop.tw/login
                  </a>
                </p>
                <p className="pre-line">
                  {"Step2. 至【會員中心】>【我要繳款】，選擇繳款方式「電子條碼繳款」至超商繳款，或「網銀ATM」轉帳繳款皆很方便。"}
                </p>
                <p className="pre-line">
                  {"step3.收據可直接透過【繳款收據】>【上傳】系統。"}
                </p>
                <p className="pre-line">
                  ** 收據不用再提供給客服，直接上傳平台即可，方便調閱留存不怕糾紛 **
                </p>
              </div>
            </Collapse.Panel>

          </Collapse>
        </Col>

        <Col span={20} md={20} sm={20} xs={20} style={{ marginTop: 20 }}>
          <p className="highlight-text text-left">
            ※不預收任何費用，若有佯稱須預先收費者，即為惡意冒名之詐騙代辦集團，請勿上當！※
          </p>
        </Col>
      </Row>
    </>
  );
}

export default Section4;
