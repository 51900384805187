import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { actionCreators } from "src/actions/jet";
import { jetService } from "src/services";
import { format } from "src/utils/format";
import { code } from "src/pages/Product/statusCode";

// antd & css
import { Row, Col, Button, Table, Empty, Tooltip, message } from "antd";
import { MenuOutlined, QuestionCircleOutlined } from "@ant-design/icons";

// drag components
const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: "grab", color: "#999" }} />);
const SortableItem = SortableElement(props => <tr {...props} />);
const SortableBody = SortableContainer(props => <tbody {...props} />);

const ActivityList = () => {
  const [rowData, setRowData] = useState([]);

  const dispatch = useDispatch();

  //取得 store 中的 state
  const isEdit = useSelector(state => state.jet.isEdit);
  const editingShopActivity = useSelector(state => state.jet.editingShopActivity);
  const shopActivityProducts = useSelector(state => state.jet.shopActivityProducts);

  useEffect(() => {
    // 判斷 -> 存暫存的資料 or 同步後端資料
    if (shopActivityProducts.length > 0) {
      setRowData(shopActivityProducts);
      // 暫存活動列表，加入商品時可以判斷
      localStorage.setItem("shopActivityProducts", JSON.stringify(shopActivityProducts));
    } else {
      if (editingShopActivity && JSON.stringify(editingShopActivity) !== "{}") {
        assignData(editingShopActivity.products);
      }
    }
  }, []);

  const assignData = (data) => {
    let newData = [];
    data.forEach((item, idx) => {
      newData.push({
        key: item.prodNo,
        prodOrder: idx + 1,
        prodName: item.prodName,
        merAcctId: item.merAcctId,
        merCompName: item.merchantBasic.merCompName,
        prodPriceAmt: item.prodPriceAmt,
        prodPayAmt: item.prodPayAmt,
        prodMgtStatus: item.prodMgtStatus,
        prodTypeDes: item.prodTypeData.prodTypeDes + "/" + item.prodItemData.prodItemDes,
        prodCreateDate: format.toDateTime(item.prodCreateDate),
        index: item.prodNo,
      });
    });
    setRowData(newData);
    // 暫存活動列表在redux，方便配值、清空
    dispatch(actionCreators.getShopActivityProducts(newData));
    // 暫存活動列表，加入商品時可以判斷
    localStorage.setItem("shopActivityProducts", JSON.stringify(newData));
  };

  // 切換編輯狀態
  const handleEdit = () => {
    dispatch(actionCreators.handleIsEdit(true));
  };

  // 儲存
  const save = () => {
    const rowDataKeys = rowData.map(item => item.key);
    let payload = {
      productSerialNumbers: rowDataKeys
    };
    jetService.updateShopActivityProduct(payload)
    .then(
      res => {
          if (res.status === 201) {
            message.success("儲存成功");
            dispatch(actionCreators.handleIsEdit(false));
            dispatch(actionCreators.getShopActivityProducts([]));
            dispatch(actionCreators.getEditingShopActivity());
          } else if (res.status === 401) {
            message.warning("存取拒絕");
          } else {
            message.error("儲存失敗");
          }
        },
        error => {
          console.log(error);
        },
      );
  };

  // 移除
  const handleRemove = (key) => {
    const dataSource = [...rowData];
    const notRemovedData = dataSource.filter(item => item.key !== key);
    // 重新計算排序
    notRemovedData.forEach((item, idx) => item.prodOrder = idx + 1);
    setRowData(notRemovedData);
    // 暫存活動列表，加入商品時可以判斷
    localStorage.setItem("shopActivityProducts", JSON.stringify(notRemovedData));
  };

  // 排序後結果
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      // 前端更新表格
      const newData = arrayMoveImmutable([].concat(rowData), oldIndex, newIndex).filter(
        el => !!el,
      );
      newData.forEach((item, idx) => {
        item.prodOrder = idx + 1;
      });
      setRowData(newData);
      // 暫存活動列表，加入商品時可以判斷
      localStorage.setItem("shopActivityProducts", JSON.stringify(newData));
    }
  };

  // 拖曳內容區塊
  const DraggableContainer = props => (
    <SortableBody
      useDragHandle
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = rowData.findIndex(x => x.index === restProps["data-row-key"]);
    return <SortableItem index={index} {...restProps} />;
  };

  const columns = [
    {
      title:
        <div>
          拖曳&nbsp;
          <Tooltip placement="top" title="拖曳以改變順序">
            <QuestionCircleOutlined style={{ cursor: "help" }} />
          </Tooltip>
        </div>,
      dataIndex: "drag",
      width: "70px",
      render: () => <Button type="text" disabled={!isEdit}><DragHandle /></Button>
    },
    {
      title: "商品排序",
      dataIndex: "prodOrder",
    },
    {
      title: "商品名稱",
      dataIndex: "prodName",
      render: (text, col) => {
        return <Link disabled={!isEdit} to={'/admin/product/list/edit?no=' + col.key}>{text}</Link>
      },
    },
    {
      title: "商家代碼",
      dataIndex: "merAcctId",
    },
    {
      title: "分店名稱",
      dataIndex: "merCompName",
    },
    {
      title: "建議售價",
      dataIndex: "prodPriceAmt",
    },
    {
      title: "撥款金額",
      dataIndex: "prodPayAmt",
    },
    {
      title: "狀態",
      dataIndex: "prodMgtStatus",
      render: (text) => {
        return code.prodMgtStatusList()[text];
      },
    },
    {
      title: "商品分類",
      dataIndex: "prodTypeDes",
    },
    {
      title: "建立時間",
      dataIndex: "prodCreateDate",
    },
    {
      title: "操作",
      dataIndex: "operation",
      render: (_, col) => (
        <a className={!isEdit ? "disabled-link" : ""} disabled={!isEdit} onClick={() => handleRemove(col.key)}>移除</a>
      )
    }
  ];

  return (
    <Row>
      <Col span={24}>
        {!isEdit && (
          <Button onClick={handleEdit}>編輯</Button>
        )}
        {isEdit && (
          <Link to="/admin/ecommerce/activity/edit-list">
            <Button>加入</Button>
          </Link>
        )}
      </Col>
      <Col span={24} style={{ margin: "20px 0" }}>
        <Table
          components={{body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,            
          }}}
          columns={columns}
          dataSource={rowData}
          rowKey="index"
          locale={{emptyText: (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>無資料</span>} />
          )}}
          size="small"
          pagination={false}
          scroll={{ x: 1500, y: 400 }}
        />
      </Col>
      <Col span={24} style={{ display: "flex", justifyContent: "center", margin: "20px 0" }}>
        {isEdit && (
          <Button type="primary" onClick={save}>儲存</Button>
        )}
      </Col>
    </Row>
  );
}

export default ActivityList;
