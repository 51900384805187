import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { actionCreators as actionProductCreators } from "src/actions/product";
import { actionCreators } from "src/actions/jet";
import { format } from "src/utils/format";
import { code } from "src/pages/Product/statusCode";

// antd & css
import { Layout, Row, Col, Card, Table, Button, Input, Space } from "antd";
import { SearchOutlined } from '@ant-design/icons';

//components
import ModalUrl from "src/components/Jet/ModalUrl";

const EditList = () => {
  // table search
  const searchInput = useRef(null);

  const getColumnSearchProps = () => {
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            value={selectedKeys}
            onChange={(e) => setSelectedKeys(e.target.value ? e.target.value : "")}
            onPressEnter={() => confirm()} 
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button 
              type="primary" 
              onClick={() => confirm()}
              size="small"
              style={{ width: 90 }}
            >
              確定
            </Button>
            <Button 
              onClick={() => clearFilters()}
              size="small"
              style={{ width: 90 }}
            >
              重設
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current.select());
        }
      },
    };
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [existData, setExistData] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // 取得 store 中的 state
  const products = useSelector(state => state.product.products);

  useEffect(() => {
    // 排除掉已經select的rows
    const data = JSON.parse(localStorage.getItem("shopActivityProducts")).map(item => {
      return item.key;
    });
    setExistData(data);
    dispatch(actionProductCreators.getProducts(null, 10, 1, null, data));
  }, []);

  useEffect(() => {
    let data = [];
    if (products && products.data) {
      products.data.forEach((item, idx) => {
        data.push({
          key: item.prodNo,
          prodOrder: idx + 1,
          prodName: item.prodName,
          merAcctId: item.merAcctId,
          merCompName: item.merchantBasic.merCompName,
          prodPriceAmt: item.prodPriceAmt,
          prodPayAmt: item.prodPayAmt,
          prodMgtStatus: item.prodMgtStatus,
          prodTypeDes: item.prodTypeData.prodTypeDes + "/" + item.prodItemData.prodItemDes,
          prodCreateDate: format.toDateTime(item.prodCreateDate),
          index: item.prodNo,
        });
      });
    }
    setRowData(data);
  }, [products]);

  const rowSelection = {
    selectedRowKeys,
    hideSelectAll: true,
    preserveSelectedRowKeys: true,
    onChange: (selectedRowKeys, selectedRow) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedData(selectedRow.sort((a, b) => b.prodCreateDate.localeCompare(a.prodCreateDate)));
    },
    getCheckboxProps: (record) => ({
      disabled: selectedRowKeys.length >= (15 - existData.length) && !selectedRowKeys.includes(record.key),
    }),
  };

  const handleTableChange = (pagination, filter, sorter) => {
    let keyword = filter.prodName;
    let pageSize = pagination.pageSize;
    let page = pagination.current;
    let sortData = sorter.order === "ascend" ? "prodCreateDate:ASC" : "prodCreateDate:DESC";
    dispatch(actionProductCreators.getProducts(keyword, pageSize, page, sortData, existData));
  };

  const handleCancel = () => {
    window.history.go(-1);
  };

  const confirmAdd = () => {
    let originAddData = JSON.parse(localStorage.getItem("shopActivityProducts"));
    let allAddData = originAddData.concat(selectedData);
    allAddData.forEach((item, idx) => item.prodOrder = idx + 1);
    dispatch(actionCreators.getShopActivityProducts(allAddData));
    navigate("/admin/ecommerce/activity");
  };

  const columns = [
    {
      title: "商品名稱",
      dataIndex: "prodName",
      render: (text, col) => {
        return <Link to={"/admin/product/list/edit?no=" + col.key}>{text}</Link>
      },
      ...getColumnSearchProps(),
    },
    {
      title: "商家代碼",
      dataIndex: "merAcctId",
    },
    {
      title: "分店名稱",
      dataIndex: "merCompName"
    },
    {
      title: "建議售價",
      dataIndex: "prodPriceAmt",
    },
    {
      title: "撥款金額",
      dataIndex: "prodPayAmt",
    },
    {
      title: "狀態",
      dataIndex: "prodMgtStatus",
      render: (text) => {
        return code.prodMgtStatusList()[text];
      },
    },
    {
      title: "商品分類",
      dataIndex: "prodTypeDes",
    },
    {
      title: "建立時間",
      dataIndex: "prodCreateDate",
      sorter: true,
    }
  ];

  return (
    <Layout className="merLayout">
      <Row justify="center">
        <Col span={23}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div className="title">{"加入列表"}</div>
            <div>
              <ModalUrl />
              <a href="/admin/ecommerce/preview" target="_blank">
                <Button style={{ marginRight: 10 }}>預覽官網</Button>
              </a>
            </div>
          </div>

          <Card className="merLayoutCard">
            <Row style={{ margin: "20px 0" }} justify="center">
              <Col span={23}>
                <p style={{ color: "red" }}>* 活動專區皆以上架商品為主，最多挑選15項</p>
              </Col>
              <Col span={23} style={{ display: "flex", justifyContent: "space-between" }}>
                <h3>未加入的商品：</h3>
                <p>已加入：{existData.length}&emsp;尚可選：{15 - existData.length}</p>
              </Col>
              <Col span={23}>
                <Table
                  rowSelection={rowSelection}
                  columns={columns}
                  dataSource={rowData}
                  scroll={{ x: 1500, y: 300 }}
                  pagination={{ 
                    total: products.totalItems,
                    pageSizeOptions: [10, 15, 20],
                    pageSize: 10
                  }}
                  onChange={handleTableChange}
                />
              </Col>
              <Col span={23} style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
                <Button onClick={handleCancel} style={{ marginRight: 20 }}>取消</Button>
                <Button type="primary" onClick={confirmAdd}>確認加入</Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}

export default EditList;
