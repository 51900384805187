import React from "react";

// antd
import { Row, Col } from "antd";

import icon05 from "src/assets/guide/icon_05.png";
import icon06 from "src/assets/guide/icon_06.png";
import icon07 from "src/assets/guide/icon_07.png";
import icon08 from "src/assets/guide/icon_08.png";

const steps = [
  {
    step: 1,
    title: "Step1: 選購商品",
    icon: icon05,
    content: ["店家提供商品連結", "進入專屬商城買東西"]
  },
  {
    step: 2,
    title: "Step2: 線上申請無卡分期",
    icon: icon06,
    content: ["無紙化申請流程", "一支手機就能解決"]
  },
  {
    step: 3,
    title: "Step3: Jetshop專人審核",
    icon: icon07,
    content: ["從申請到通知只需要一杯咖啡的時間", "Line線上專屬客服處理您所有疑難雜症"]
  },
  {
    step: 4,
    title: "Step4: 分期繳款",
    icon: icon08,
    content: ["6~36期自由選擇", "超商及ATM便利繳款"]
  },
];

const ShopNote = (props) => {
  return (
    <div>
      {props.shopInfoNotice &&
        <Row justify="center">
          <div className="jet-title">購物須知</div>
        </Row>
      }
      {props.shopInfoNotice &&
        <Row justify="center">
          <div 
            className="jet-note-box"
            style={{textAlign: 'left', margin: '20px'}}
            dangerouslySetInnerHTML={{__html: props.shopInfoNotice}}
          >
          </div>
        </Row>
      }
      <Row justify="center" style={{ marginTop: 20 }}>
        <a 
          className="no-style-link"
          href="/installment/guide" 
          target="_blank" 
          rel="noreferrer noopener"
        >
          <div className="jet-title">{"商品分期指南 >>"}</div>
        </a>
      </Row>
      <Row className="jet-step" justify="center">
        {
          steps.map(item => (
            <Col id={`step${item.step}`} key={item.step} span={22} xl={9} lg={10} md={11} sm={11} xs={22}>
              <img src={item.icon} alt={item.title} />
              <div>
                <p className="title">{item.title}</p>
                <p className="detail">{item.content[0]}</p>
                <p className="detail">{item.content[1]}</p>
              </div>
          </Col>
          ))
        }
      </Row>
    </div>
  );
}

export default ShopNote;
