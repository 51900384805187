import React from "react";

import "./Arrow.css";

const FrontAnimatedArrow = () => {
  return (
    <div className="animated-arrow">
			<div className="arrow-main"></div>
		</div>
  );
}

export default FrontAnimatedArrow;
