import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// import styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "src/components/Front/Carousel/Carousel.css";

// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper";

import noProduct from "src/assets/no_product.jpg";

const FrontProductCarousel = (props) => {
	const [prodData, setProdData] = useState([]);
	const [thumbsSwiper, setThumbsSwiper] = useState(null);

	useEffect(() => {
		if (props.prodPhotos !== undefined) {
			setProdData(props.prodPhotos);
		}
	}, [props.prodPhotos]);

	if (prodData.length === 0) {
		return (
			<div className="slider-wrapper">
				<Swiper
					style={{
						"--swiper-navigation-color": "#fff",
						"--swiper-pagination-color": "#fff",
					}}
					spaceBetween={10}
					navigation={true}
					thumbs={{ swiper: thumbsSwiper }}
					modules={[FreeMode, Navigation, Thumbs]}
					className="main"
				>
					<SwiperSlide>
						<img src={noProduct} alt="no-product" />
					</SwiperSlide>
				</Swiper>
				<Swiper
					onSwiper={setThumbsSwiper}
					spaceBetween={10}
					slidesPerView={4}
					freeMode={true}
					watchSlidesProgress={true}
					modules={[FreeMode, Navigation, Thumbs]}
					className="thumb"
				>
					<SwiperSlide>
						<img src={noProduct} alt="no-product" />
					</SwiperSlide>
				</Swiper>
			</div>
		)
	}

	return (
		<div className="slider-wrapper">
			<Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        spaceBetween={10}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs]}
				className="main"
      >
				{
					prodData.map((item, idx) => {
						return (
							<SwiperSlide key={idx}>
								<img src={item.prodPhotos} alt={item.prodPhotoItemNo} />
							</SwiperSlide>
						)
					})
				}
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
				className="thumb"
      >
				{
					prodData.map((item, idx) => {
						return (
							<SwiperSlide key={idx}>
								<img src={item.prodPhotos} alt={item.prodPhotoItemNo} />
							</SwiperSlide>
						)
					})
				}
      </Swiper>
		</div>
	);
}

export default FrontProductCarousel;
