import React, { useRef, useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { actionCreators } from "src/actions/auth";
import { authService } from "src/services";

import { format } from 'src/utils/format';

// Ant design
import { Layout, Row, Col, Card, Button,
         Table, Input, Modal, Alert } from "antd";
import { SearchOutlined } from '@ant-design/icons';


const Account = () => {
  // Ant design table filter
  const [, setSearchText] = useState('');
  const [, setSearchedColumn] = useState('');
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const searchInput = useRef(null);
  const [alertStautus, setAlertStatus] = useState({message: null, type: null});

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={ searchInput }
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Row justify="end">
          <Button onClick={() => handleReset(clearFilters)} size="small">
            重置
          </Button>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ marginLeft: 5}}
          >
            搜尋
          </Button>
        </Row>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    }
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const [columns] = useState([
    {
      title: '帳號',
      dataIndex: 'merAdmAcct',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        a = a.merAdmAcct || '';
        b = b.merAdmAcct || '';
        return a.localeCompare(b);
      },
      render: (text, col) => {
        return <Link to={'/admin/user/list/edit?no=' + col.key}>{text}</Link>
      },
      ...getColumnSearchProps('merAdmAcct')
    },
    {
      title: '姓名',
      dataIndex: 'merAdmName',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        a = a.merAdmName || '';
        b = b.merAdmName || '';
        return a.localeCompare(b);
      },
      ...getColumnSearchProps('merAdmName')
    },
    {
      title: '手機',
      dataIndex: 'merAdmPhone',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        a = a.merAdmPhone || '';
        b = b.merAdmPhone || '';
        return a.localeCompare(b);
      },
      ...getColumnSearchProps('merAdmPhone')
    },
    {
      title: '使用狀態',
      dataIndex: 'merAdmStatusTag',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        a = a.merAdmStatusTag || '';
        b = b.merAdmStatusTag || '';
        return a.localeCompare(b);
      },
      ...getColumnSearchProps('merAdmStatusTag')
    },
    {
      title: '商家代碼',
      dataIndex: 'merAcctId',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        a = a.merAcctId || '';
        b = b.merAcctId || '';
        return a.localeCompare(b);
      },
      ...getColumnSearchProps('merAcctId')
    },
    {
      title: '分店名稱',
      dataIndex: 'merCompName',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        a = a.merCompName || '';
        b = b.merCompName || '';
        return a.localeCompare(b);
      },
      ...getColumnSearchProps('merCompName')
    },
    {
      title: '建立日期',
      dataIndex: 'merAdmCreateDate',
      defaultSortOrder: 'descend',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        a = a.merAdmCreateDate || '';
        b = b.merAdmCreateDate || '';
        return a.localeCompare(b);
      },
      ...getColumnSearchProps('merAdmCreateDate')
    },
    {
      title: '更新日期',
      dataIndex: 'merAdmUpdateDate',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        a = a.merAdmUpdateDate || '';
        b = b.merAdmUpdateDate || '';
        return a.localeCompare(b);
      },
      ...getColumnSearchProps('merAdmUpdateDate')
    }
  ]);

  const [rowData, setRowData] = useState([]);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  // 取出 store 中的 state
  const usersList = useSelector(state => state.auth.usersList);

  // 新增/編輯後，返回此列表頁，顯示成功或失敗提示
  useEffect(() => {
    if (location && location.state && location.state.status && location.state.msg) {
      setAlertStatus({
        message: location.state.msg,
        type: location.state.status,
      });
    }
  }, [location]);

  // action : 取得帳號列表
  useEffect(() => {
    dispatch(actionCreators.getUsersList({search: '', searchType: ''}));
  }, []);

  // 設定初始值
  useEffect(() => {
    let data = [];
    if (usersList && usersList.rows) {
      usersList.rows.map(item => {
        data.push({
          key: item.merAdmSerNo,
          merAdmAcct: item.merAdmAcct,
          merAdmName: item.merAdmName,
          merAdmPhone: item.merAdmPhone,
          merAdmStatusTag: item.merAdmStatusTag ? '停用' : '啟用',
          merAcctId: item.merAcctId,
          merCompName: item.merchantBasic.merCompName,
          merAdmCreateDate: format.toDate(item.merAdmCreateDate),
          merAdmUpdateDate: format.toDate(item.merAdmUpdateDate)
        })
      });
    }
    setRowData(data);
  }, [usersList]);


  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleModalConfirm = () => {
    let count = 0;
    selectedRowKeys.map((_merAdmSerNo, index) => {
      authService.deleteAccount(_merAdmSerNo)
        .then(
          res => {
            if (res.status === 200) {
              count += 1;
            }
          },
          error => {
            console.log(error);
          }
        )
        .finally(
          () => {
            if (selectedRowKeys.length === index+1) {  
              // 刪除帳號的最後一個
              if (selectedRowKeys.length === count) {
                setAlertStatus({
                  message: "刪除成功",
                  type: "success",
                });
              } else {
                setAlertStatus({
                  message: "有資料刪除失敗",
                  type: "error",
                });
              }
              // 重新再取得最新帳號資料
              dispatch(actionCreators.getUsersList({search: '', searchType: ''}));
              setIsModalVisible(false);
            }
          }
        );
    });

  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => { setSelectedRowKeys(selectedRowKeys) }
  };

  const closeAlert = () => {
    setAlertStatus({
      message: "",
      type: "",
    });
    navigate('/admin/user/list', {state: {}});
  };

  return (
    <Layout className="merLayout" type="flex" align="left">
      <Row justify="center">
        <Col span={23}>
          <div className="title">
            管理者帳號維護
          </div>

          <Card className="merLayoutCard">

            {alertStautus.message 
              ? <Alert message={alertStautus.message} type={alertStautus.type} banner closable afterClose={closeAlert} style={{ marginBottom: 10 }} /> 
              : null
            }

            <Row justify="space-end">
              <Col align="right" span={24}>
                <Button type="primary" style={{marginRight: 10 }}>
                  <Link to={'/admin/user/list/edit'}>
                    新增
                  </Link>
                </Button>
                <Button type="primary" onClick={showModal}>
                  刪除
                </Button>
              </Col>
            </Row>

            <Col span={24} style={{ marginTop: 10 }}>
              <Table
                rowSelection={rowSelection}
                columns={columns}
                dataSource={rowData}
                scroll={{x: 'auto'}}
              />
            </Col>

          </Card>

          <Modal
            visible={isModalVisible}
            onOk={handleModalConfirm}
            onCancel={handleModalCancel}
          >
            <p>確認刪除選取?</p>
          </Modal>
        </Col>
      </Row>
    </Layout>
  )  
}

export default Account;