import React from "react";

// antd
import { Row, Col } from "antd";

// component
import FrontDivJcoin from "src/components/Front/Divider/DivJcoin";

const Section5 = () => {
  return (
    <>
      <Row justify="center" style={{ marginTop: 70, marginBottom: 70 }}>
        <Col span={20} id="rule">
          <FrontDivJcoin title={"　活動規則　"} />
        </Col>
        <Col span={20} md={20} sm={20} xs={20}>
          <div className="text-left">
            <ol>

              <li>
                參加資格：
                加入 JetShop 會員後
                <span className="highlight-text">滿三十日</span>
                即可參與集點活動。
              </li>

              <li>
                活動期間：
                2023年4月17日至2023年12月31日止，期間認定將以該案件申請日為準。
              </li>

              <li>
                點數使用時間：
                活動起日至2024年2月29日止，超過使用期限 Jcoin 點數歸零。
              </li>

              <li>
                集點方式：
                以下二種方式皆可以累積 Jcoin 點數，點數剩餘查詢可於 Line 線上客服帳號：
                <a href="https://lin.ee/R5LfSO7" target="_blank" rel="noreferrer">https://lin.ee/R5LfSO7</a>
                洽詢。
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    申辦獎勵：
                    JetShop 會員再次申辦核准，將發放1枚 Jcoin；並且正常繳費（不含逾期）一期後，再額外發放1枚 Jcoin。
                  </li>
                  <li>
                    推薦獎勵：
                    JetShop 會員推薦親友申辦，親友於下單時填寫推薦人 ID（身份證字號），親友（非 JetShop 會員）成功申辦並正常繳費第一期後發放給推薦人1枚 Jcoin。
                  </li>
                </ul>
              </li>

              <li>
                點數累計規則及兌換方式：
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    Jcoin點數兌換僅限 JetShop 會員本人兌換。
                  </li>
                  <li>
                    Jcoin點數累計將於每月15日更新計入前一個月份之有效點數。
                  </li>
                  <li>
                    活動期間內僅限兌換一次禮品，
                    <span className="highlight-text">兌換後將不會再累積 Jcoin 點數</span>。
                  </li>
                  <li>
                    <span className="highlight-text">點數兌換一次只可選擇一個禮品</span>
                    。例如：現已累計6枚 Jcoin 點數，不可選擇兌換5枚 Jcoin 點數禮品 + 1枚 Jcoin 點數禮品。
                  </li>
                  <li>
                    Jcoin 點數兌換請於 Line 線上客服帳號：
                    <a href="https://lin.ee/R5LfSO7" target="_blank" rel="noreferrer">https://lin.ee/R5LfSO7</a>
                    告知客服人員欲兌換禮品，經核對身分資訊後，將安排於隔月月中寄出兌換禮品。
                  </li>
                  <li>
                    各項禮品皆為限量，若該品項數量不足時將通知兌換人以等值禮品替換。
                  </li>
                  <li>
                    10 Jcoin 禮品數量皆僅有1個，活動期間內兌完為止。
                  </li>
                </ul>
              </li>

              <li>
                注意事項：
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    Jcoin 點數僅限兌換 JetShop 禮品使用，不得轉讓、抵扣或折換現金。
                  </li>
                  <li>
                    禮品簽收後若有瑕疵，請於Line線上客服帳號：
                    <a href="https://lin.ee/R5LfSO7" target="_blank" rel="noreferrer">https://lin.ee/R5LfSO7</a>
                    聯繫專人協助處理。
                  </li>
                  <li>
                    禮品寄出後如遇未簽收，未聯繫到收件人的情形，將視同放棄權益，禮品將不重新寄送，兌換點數亦不退還。
                  </li>
                  <li>
                    JetShop 會員點數回饋秉持道德公義原則，若發生從中採不正當手段非法獲利之詐欺情事，本司將保留法律追訴權。
                  </li>
                  <li>
                    JetShop 會員點數回饋自公告實施日起算，不溯及既往。
                  </li>
                  <li>
                    JetShop 保有最終解釋、調整與修改本活動辦法之權利。
                  </li>
                </ul>
              </li>

            </ol>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Section5;
