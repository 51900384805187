import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { actionCreators } from 'src/actions/merchant';
import { authService } from 'src/services';
import { code } from './StatusCode';

// Ant design
import { Layout, Row, Col, Card, Divider, Form, Select, Button, Alert, Input, Radio, Checkbox } from 'antd';

import './UserInfo.css';

const UserInfo = () => {
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();
  const [merAdmSerNo] = useState(searchParams.get('no'));

  const [alertStautus, setAlertStatus] = useState({ message: null, type: null });

  const [acctId, setAcctId] = useState("");
  const [checkedFunctList, setCheckedFunctList] = useState([]);
  const [checkedFuncChildList, setCheckedFuncChildList] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [funcOptions, setFuncOptions] = useState([]);
  const [funcIndexList, setFuncIndexList] = useState([]);
  const [loading, setLoading] = useState(false);

  const { Option } = Select;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // 取出 store 中的 state
  const merAcctId = useSelector((state) => state.merchant.merAcctId);
  const branchesData = useSelector((state) => state.merchant.branchesData);

  // action : 取得 所屬分店資訊
  useEffect(() => {
    dispatch(actionCreators.getBranchesData());
  }, [dispatch]);

  // 取得單一帳號資訊
  useEffect(() => {
    if (merAdmSerNo) {
      authService.getAccount(merAdmSerNo).then(
        (res) => {
          res.json().then((result) => {
            setAcctId(result.merAcctId);
            form.setFieldsValue({
              ...result,
              merAdmPwd: '',
            });
            let merAdminPermission = JSON.parse(result.merAdminPermission);
            if (merAdminPermission.length === code.funcIndexList.length) setCheckAll(true);
            let _funcList = [];
            let _childFuncList = [];
            merAdminPermission.forEach((item) => {
              if (item % 100 === 0) {
                _funcList.push(Number(item));
              } else {
                _childFuncList.push(Number(item));
              }
            });
            setCheckedFunctList(_funcList);
            setCheckedFuncChildList(_childFuncList);
          });
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      form.setFieldsValue({
        merAcctId: merAcctId,
        merAdmStatusTag: 0,
      });
      setAcctId(merAcctId);
    }
  }, [form, merAcctId, merAdmSerNo]);

  // 設定權限 checkbox 母子項目
  useEffect(() => {
    const branchData = branchesData.filter(item => item.merAcctId === acctId);
    let funcItems = [];
    let funcList = [];
    if (code.funcIndexList.length && branchData && branchData.length > 0) {
      code.funcIndexList.forEach((item) => {
        if (item.merFunNo >= 500 && item.merFunNo < 600) {
          // 判斷核心系統是否開放Jet捷店權限，若有 => 顯示Jet捷店的checkbox，若沒有 => 不顯示Jet捷店的checkbox
          if (branchData[0].merJetECEnable && Number(item.merFunNo) === 500) {
            funcItems.push({
              value: item.merFunNo,
              label: item.merFunName,
              childFuncItems: [],
            });
            funcList.push(item.merFunNo);
          } else if (branchData[0].merJetECEnable) {
            funcItems.forEach((funcItem) => {
              if (funcItem.value === 500) {
                funcItem.childFuncItems.push({
                  value: item.merFunNo,
                  label: item.merFunName,
                });
                funcList.push(item.merFunNo);
              }
            });
          }
        } else if (Number(item.merFunNo) % 100 === 0) {
          funcItems.push({
            value: item.merFunNo,
            label: item.merFunName,
            childFuncItems: [],
          });
          funcList.push(item.merFunNo);
        } else {
          funcItems.forEach((funcItem) => {
            if (parseInt(funcItem.value / 100) === parseInt(item.merFunNo / 100) && item.merFunNo !== 102) {
              funcItem.childFuncItems.push({
                value: item.merFunNo,
                label: item.merFunName,
              });
              funcList.push(item.merFunNo);
            }
          });
        }
      });
    }
    setFuncOptions(funcItems);
    setFuncIndexList(funcList);
  }, [acctId, branchesData]);

  // 全選
  const onCheckAll = (e) => {
    let checkAllVals = funcOptions.map((item) => item.value);
    let checkAllChildVals = [];

    funcOptions.forEach((item) => {
      if (item.childFuncItems.length > 0) {
        let childVals = item.childFuncItems.map((item) => item.value);
        checkAllChildVals = checkAllChildVals.concat(childVals);
      }
    });

    setCheckedFunctList(e.target.checked ? checkAllVals : []);
    setCheckedFuncChildList(e.target.checked ? checkAllChildVals : []);
    setCheckAll(e.target.checked);
  };

  // 勾選主項目
  const onCheckChange = (checkedList, index) => {
    let originCheckedList = checkedFunctList;
    let originCheckedChildList = checkedFuncChildList;
    let focusIndex = index + 1;
    let funcCode = focusIndex * 100;
    let functObj = funcOptions.filter((item) => item.value === funcCode);
    let finalCheckedList = [];
    let finalCheckedChildList = [];

    // 只處理起頭是 index+1 的值，其他主項目保留不動
    originCheckedList.forEach((code) => {
      if (parseInt(code / 100) !== focusIndex) finalCheckedList.push(code);
    });

    // 只處理起頭是 index+1 的值，其他子項目保留不動
    originCheckedChildList.forEach((code) => {
      if (parseInt(code / 100) !== focusIndex) finalCheckedChildList.push(code);
    });

    finalCheckedList = finalCheckedList.concat(checkedList);
    setCheckedFunctList(finalCheckedList);

    // 處理子項目
    if (functObj[0].childFuncItems.length > 0 && finalCheckedList.indexOf(funcCode) > -1) {
      let childFuncCode = functObj[0].childFuncItems.map((item) => item.value);
      finalCheckedChildList = finalCheckedChildList.concat(childFuncCode);
    }
    setCheckedFuncChildList(finalCheckedChildList);

    // 是否全選設定
    setCheckAll(finalCheckedList.length === funcOptions.length ? true : false);
  };

  // 勾選主項目中的子項目
  const onCheckChildChange = (checkedList, index) => {
    let originCheckedList = checkedFunctList;
    let originCheckedChildList = checkedFuncChildList;
    let finalCheckedList = [];

    // 只處理起頭是 index+1 的值，其他保留不動
    let focusIndex = index + 1;
    originCheckedChildList.forEach((code) => {
      if (parseInt(code / 100) !== focusIndex) finalCheckedList.push(code);
    });
    // 將此動作勾選或不勾選的 group 中最後有 check 的值加回到 finalCheckedList
    finalCheckedList = finalCheckedList.concat(checkedList);
    setCheckedFuncChildList(finalCheckedList);

    // 判斷子項目勾/不勾選時，主項目連動勾/不勾選
    let finalIndex = [];
    let checkedIndex = finalCheckedList.map((code) => parseInt(code / 100) * 100);
    checkedIndex = checkedIndex.concat(originCheckedList);
    finalIndex = checkedIndex.filter((elem, index) => {
      return checkedIndex.indexOf(elem) === index;
    });
    setCheckedFunctList(finalIndex);

    // 判斷所有子項目+主項目全勾/不勾選時，全選連動勾/不勾選
    setCheckAll(finalCheckedList.length + checkedFunctList.length === code.funcIndexList.length ? true : false);
  };

  const onChangeBranch = (e) => {
    setAcctId(e);
  };

  const onFinish = (formData) => {
    setLoading(true);
    const checkedList = checkedFunctList.filter(item => funcIndexList.includes(item));
    const checkedChildList = checkedFuncChildList.filter(item => funcIndexList.includes(item));
    const merAdminPermission = [...checkedList, ...checkedChildList];
    
    if (merAdmSerNo) {
      // 編輯
      authService
        .editAccount(merAdmSerNo, {
          ...formData,
          merAdminPermission: merAdminPermission.toString().split(','),
        })
        .then(
          (res) => {
            res.json().then((result) => {
              if (res.status === 200) {
                navigate('/admin/user/list', { state: { msg: '編輯成功', status: 'success' } });
              } else {
                navigate('/admin/user/list', { state: { msg: '編輯失敗', status: 'error' } });
              }
            });
            setLoading(false);
          },
          (error) => {
            navigate('/admin/user/list', { state: { msg: '編輯失敗', status: 'error' } });
          }
        );
    } else {
      // 新增
      // merAdmType=1, 新增時預設都是1
      authService
        .addAccount({
          ...formData,
          merAdminPermission: merAdminPermission.toString().split(','),
          merAdmType: 1,
        })
        .then(
          (res) => {
            res.json().then((result) => {
              if (res.status === 200) {
                navigate('/admin/user/list', { state: { msg: '新增成功', status: 'success' } });
              } else {
                navigate('/admin/user/list', { state: { msg: '新增失敗', status: 'error' } });
              }
            });
            setLoading(false);
          },
          (error) => {
            console.log(error);
            navigate('/admin/user/list', { state: { msg: '新增失敗', status: 'error' } });
          }
        );
    }
  };

  const onFinishFailed = () => false;

  const closeAlert = () => {
    setAlertStatus({
      message: '',
      type: '',
    });
  };

  return (
    <Layout className="merLayout" type="flex" align="left">
      <Row justify="center">
        <Col span={23}>
          <div className="title">管理者帳號維護 {merAdmSerNo ? '> 編輯' : '> 新增'}</div>

          <Card className="merLayoutCard">
            {alertStautus.message ? <Alert message={alertStautus.message} type={alertStautus.type} banner closable afterClose={closeAlert} /> : null}

            <Form labelCol={{ flex: '200px' }} wrapperCol={{ span: 11 }} labelAlign="left" colon={false} form={form} onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off">
              <Row className="userInfo">
                {/* 基本資料 */}
                <Col span={12} xs={24} sm={24} md={12} style={{ paddingRight: '20px' }}>
                  <Divider className="subTitle" orientation="left" orientationMargin="0" style={{ margin: '5px 20px 5px 0' }}>
                    基本資料
                  </Divider>

                  <Form.Item
                    label="帳號"
                    name="merAdmAcct"
                    rules={[
                      { required: true, message: '請輸入帳號' },
                      () => ({
                        validator(_, value) {
                          if (/[\W]/g.test(value)) {
                            return Promise.reject('輸入格式有誤,限英文或數字');
                          }
                          if ((value.length < 4 || value.length > 25) && value.length !== 0) {
                            return Promise.reject('須介於4至25字元');
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input disabled={merAdmSerNo} />
                  </Form.Item>
                  <Form.Item
                    label="密碼"
                    name="merAdmPwd"
                    rules={[
                      { required: merAdmSerNo ? false : true, message: '請輸入密碼' },
                      () => ({
                        validator(_, value) {
                          if (!value.match(/.*[A-Za-z]+.*[0-9]+.*/) && !value.match(/.*[0-9]+.*[A-Za-z]+.*/) && value.length !== 0) {
                            return Promise.reject('須為包含1個英文字的英數混合字符');
                          }
                          if ((value.length < 4 || value.length > 25) && value.length !== 0) {
                            return Promise.reject('須介於4至25字元');
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input.Password placeholder={merAdmSerNo ? '重新設定' : ''} />
                  </Form.Item>
                  <Form.Item
                    label="確認密碼"
                    name="merAdmPwdCheck"
                    rules={[
                      { required: merAdmSerNo ? false : true, message: '請確認密碼' },
                      () => ({
                        validator(_, value) {
                          let pwd = form.getFieldValue('merAdmPwd');
                          if (pwd && value !== pwd) {
                            return Promise.reject('請確認密碼');
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item label="姓名" name="merAdmName" rules={[{ required: true, message: '請輸入姓名' }]}>
                    <Input />
                  </Form.Item>
                  <Form.Item label="手機" name="merAdmCellPhone">
                    <Input />
                  </Form.Item>
                  <Form.Item label="公司電話" name="merAdmPhone">
                    <Input />
                  </Form.Item>
                  <Form.Item label="公司信箱" name="merAdmEmail">
                    <Input />
                  </Form.Item>
                  <Form.Item label="備註" name="merAdmRemark">
                    <Input />
                  </Form.Item>
                  <Form.Item label="狀態" name="merAdmStatusTag" rules={[{ required: true, message: '請輸入狀態' }]}>
                    <Radio.Group>
                      <Radio value={0}>啟用</Radio>
                      <Radio value={1}>停用</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item label="所屬分店" name="merAcctId">
                    <Select onChange={onChangeBranch}>
                      {branchesData.map((branch) => {
                        return (
                          <Option key={branch.merAcctId}>
                            {branch.merAcctId}-{branch.merCompName}
                            {branch.merIsBranch ? '' : '(總店)'}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                {/* 權限 */}
                <Col span={12} xs={24} sm={24} md={12}>
                  <Divider className="subTitle" orientation="left" orientationMargin="0" style={{ margin: '5px 20px 5px 0' }}>
                    權限
                  </Divider>

                  <Form.Item>
                    <Checkbox onChange={onCheckAll} checked={checkAll}>
                      全選
                    </Checkbox>
                    {funcOptions.map((funcItem, index) => {
                      return (
                        <Row className="checkboxItems" key={index}>
                          <Col span={24}>
                            <Checkbox.Group
                              value={checkedFunctList}
                              onChange={(checkedValues) => {
                                onCheckChange(checkedValues, index);
                              }}
                            >
                              <Checkbox value={funcItem.value}>{funcItem.label}</Checkbox>
                            </Checkbox.Group>
                          </Col>
                          <Checkbox.Group
                            className="checkboxGroup"
                            value={checkedFuncChildList}
                            onChange={(checkedValues) => {
                              onCheckChildChange(checkedValues, index);
                            }}
                          >
                            {funcItem.childFuncItems.map((childItem) => (
                              <Row key={childItem.value}>
                                <Checkbox value={childItem.value}>{childItem.label}</Checkbox>
                              </Row>
                            ))}
                          </Checkbox.Group>
                        </Row>
                      );
                    })}
                  </Form.Item>
                </Col>
              </Row>

              <Col span={24} align="center">
                <Button type="primary" htmlType="submit" disabled={loading} loading={loading}>
                  儲存
                </Button>
              </Col>
            </Form>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
};

export default UserInfo;
