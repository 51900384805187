import React from "react";

// antd
import { Row, Col } from "antd";

// component
import FrontDivCenter from "src/components/Front/Divider/DivCenter";

import icon01 from "src/assets/about/icon_01.png";
import icon02 from "src/assets/about/icon_02.png";
import icon03 from "src/assets/about/icon_03.png";

const boxes = [
 {
  title: "聰明消費",
  icon: icon01,
  color: "red",
  content: "商品分期支付透過時間增加消費空間，管理收支平衡，打造理想生活。"
 },
 {
  title: "安心信任",
  icon: icon02,
  color: "orange",
  content: "杜絕不當操作商家，保護消費隱私與支付安全，互相信任，合作互惠。"
 },
 {
  title: "注重體驗",
  icon: icon03,
  color: "blue",
  content: "打造親民的服務設計，操作超順手，倡議普惠金融，以人為本。"
 },
];

const Section3 = () => {
  return (
    <Row justify="center" style={{ marginTop: 70 }}>
      <Col span={24}>
        <h2 className="front" style={{ margin: 0 }}>
          核心理念
        </h2>
        <FrontDivCenter />
      </Col>
      {
        boxes.map((item, idx) => (
          <Col className="d-flex justify-center" span={24} md={7} sm={24} key={idx} style={{ marginTop: 20 }}>
            <div className="coreBox">
              <div>
                <img src={item.icon} alt={item.title} />
              </div>
              <h3 className="front">{item.title}</h3>
              <hr style={{ margin: "0 2em" }} />
              <h4 className="front" style={{ margin: "10px 2em" }}>
                {item.content}
              </h4>
            </div>
          </Col>
        ))
      }
    </Row>
  );
}

export default Section3;
