const INITIAL_STATE = {
  frontShopProducts: {},
  frontShopProdType: [],
  frontShopInfo: {},
  frontShopInfoNotice: "",
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_FRONT_SHOP_PRODUCTS":
      return {
        ...state,
        frontShopProducts: action.data
      };
    case "GET_FRONT_SHOP_PRODUCT_TYPE":
      return {
        ...state,
        frontShopProdType: action.data
      };
    case "GET_FRONT_SHOP_INFO":
      return {
        ...state,
        frontShopInfo: action.data
      };
    case "GET_FRONT_SHOP_INFO_NOTICE":
      return {
        ...state,
        frontShopInfoNotice: action.data
      }
    default:
      return state;
  }
};

export default reducer;