import React from "react";

// css
import "./FrontAbout.css"

// component
import FrontBreadcrumb from "src/components/Front/Breadcrumb";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";

const FrontHome = () => {
  return (
    <div style={{ marginBottom: 50 }}>
      <FrontBreadcrumb
        link={[
          { href: '', name: "關於我們" }
        ]}
      />
      <div className="frontTitleImage">
        <h1 className="frontTitle">
          <span className="linearText">關</span>於我們
        </h1>
      </div>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
    </div>
  );
}

export default FrontHome;
