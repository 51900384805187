import React from 'react';

import { Row, Col } from 'antd';
import "antd/dist/antd.css";

import './Footer.css';


const Footer = () => {
  return (
    <Row>
      <Col className="footer">
        @JetShop 支付商城
      </Col>
  </Row>
  )
}

export default Footer;