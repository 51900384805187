import React, { useState, useEffect } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { jetService, FAQService } from 'src/services';

import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { message } from 'antd';

// 解決 Unknown DraftEntity key: null. 插入圖片，再輸入中文會報錯的問題
// 不適用於 iframe
function myBlockRenderer(contentBlock) {
  const type = contentBlock.getType();

  // convert image type to mediaComponent
  if (type === 'atomic') {
    return {
      component: MediaComponent,
      editable: false,
      props: { foo: 'bar' },
    };
  }
};

class MediaComponent extends React.Component {
  render() {
    const { block, contentState } = this.props;
    const data = contentState.getEntity(block.getEntityAt(0)).getData();

    const emptyHtml = ' ';
    
    return (
      <div>
        {emptyHtml}
        <img
          src={data.src}
          alt={data.alt || ''}
          style={{height: data.height || 'auto', width: data.width || 'auto'}}
        />
      </div>
    );
  };
};


const TextEditor = (props) => {
  const options = props.editorType === "withImg" 
                  ? ['history', 'link', 'inline', 'colorPicker', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'image']
                  : ['history', 'link', 'inline', 'colorPicker', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign']

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [message, setMessage] = useState('');
  const [editorHeight] = useState('auto');

  useEffect(() => {
    setMessageToEditorState(props.textString);
  }, [props]);

  const onEditorStateChange = (editorState) => {
    const hasText = convertToRaw(editorState.getCurrentContent()).blocks.filter(item => {
      return item.type !== "unstyled" || item.text.length > 0;
    });
    setEditorState(editorState);

    if (hasText.length > 0) {
      setMessage(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    } else {
      setMessage('');
    }
  };

  const setMessageToEditorState = (message) => {
    if (message) {
      const contentBlock = htmlToDraft(message);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        setEditorState(EditorState.createWithContent(contentState));
      }
    }
  };

  const blur = () => {
    // 離開編輯框時再將值傳回到父元件
    props.parentCallback(message);
  };

  const uploadImageCallBack = (file) => {
    return new Promise((resolve, reject) => {
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        message.error("檔案大小須小於10MB");
        reject();
      } else {
        const formData = new FormData();
        formData.append("imageFile", file);
      
        if (props.imgStorage === 'shopNotice') {
          jetService.uploadShopNoticeImage(formData)
          .then(
            res => {
              res.json().then(result => {
                resolve({ data: { link: result.imageURL } });
              });
            },
            error => {
              reject(error);
            },
          );

        } else {
          FAQService.uploadFAQImage(formData)
          .then(
            res => {
              res.json().then(result => {
                resolve({ data: { link: result.imageURL } });
              });
            },
            error => {
              reject(error);
            },
          );

        }
      }
    });
  };

  return (
    <div style={{border: "1px solid #d9d9d9", height: editorHeight}}>
      <Editor
        style={{overflow: "auto"}}
        toolbar={{
          options: options,                                
          inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],
            bold: { className: "demo-option-custom" },
            italic: { className: "demo-option-custom" },
            underline: { className: "demo-option-custom" },
            strikethrough: {className: "demo-option-custom" },
            monospace: { className: "demo-option-custom" },
            superscript: {className: "demo-option-custom" },
            subscript: { className: "demo-option-custom" }
          },
          blockType: {
            className: "demo-option-custom-wide",
            dropdownClassName: "demo-dropdown-custom"
          },
          fontSize: { className: "demo-option-custom-medium" },
          image: {
            uploadCallback: uploadImageCallBack,
            previewImage: true,
            alt: { present: false, mandatory: false }
          }
        }}
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={onEditorStateChange}
        onBlur={blur}
        blockRendererFn={myBlockRenderer}
        localization={{ locale: 'zh_tw' }}
      />
    </div>
  )
};

export default TextEditor;
