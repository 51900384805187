import React, { useState, useEffect, useRef } from 'react';

// Ant design
import { Row, Col, Descriptions, Statistic, Button } from 'antd';
import { BorderlessTableOutlined, BankOutlined } from '@ant-design/icons';

const Overview = (props) => {
  const { overViewList } = props;

  const valueStyle = {
    width: '45%',
    textAlign: 'right',
  };

  // const overViewList = [
  //   { label: '商家編號', value: 'C0001' },
  //   { label: '商店名稱', value: '台北分店' },
  //   { label: '建議售價總金額', value: '1,200,000' },
  //   { label: '分期基準價總金額', value: '1,000,000' },
  //   { label: '應撥款總金額', value: '950,000' },
  //   { label: '待撥金額', value: '400,000' },
  //   { label: '已撥金額', value: '550,000' },
  // ];

  return (
    <div className="overview">
      <Row>
        <Col xs={24} sm={8} md={10} lg={10} xl={8} className="gutter-row caseListTable">
          <Descriptions bordered column={1} size={'small'}>
            {overViewList.map((item, index) => {
              return (
                <Descriptions.Item label={item.label} labelStyle={{ width: '55%' }} contentStyle={{ textAlign: valueStyle.textAlign, width: valueStyle.width }} key={item.label}>
                  {item.value}
                </Descriptions.Item>
              );
            })}
          </Descriptions>
        </Col>
      </Row>
      <br />
    </div>

    // <Row gutter={[16, 16]} class="overview">
    //   <Col span={12}>
    //     <Statistic title="商家編號" value={'test012'} prefix={<BorderlessTableOutlined />} valueStyle={{ fontSize: valueStyle.fontSize }} />
    //   </Col>
    //   <Col span={12}>
    //     <Statistic title="分店名稱" value={'總店'} prefix={<BankOutlined />} valueStyle={{ fontSize: valueStyle.fontSize }} />
    //   </Col>
    //   <Col span={12}>
    //     <Statistic title="建議售價總金額" value={11112} prefix={'NT$'} valueStyle={{ fontSize: valueStyle.fontSize }} />
    //   </Col>
    //   <Col span={12}>
    //     <Statistic title="應撥款總金額" value={10000} prefix={'NT$'} valueStyle={{ fontSize: valueStyle.fontSize }} />
    //   </Col>
    //   <Col span={12}>
    //     <Statistic title="待撥金額" value={0} prefix={'NT$'} valueStyle={{ fontSize: valueStyle.fontSize }} />
    //   </Col>
    //   <Col span={12}>
    //     <Statistic title="已撥金額" value={10000} prefix={'NT$'} valueStyle={{ fontSize: valueStyle.fontSize }} />
    //   </Col>
    //   <Col span={12}>
    //     <Statistic title="平均每件建議售價" value={11112} prefix={'NT$'} valueStyle={{ fontSize: valueStyle.fontSize }} />
    //   </Col>
    // </Row>
  );
};

export default Overview;
