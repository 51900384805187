import React from "react";

import "./Divider.css";

const FrontDivJcoin = (props) => {
  return (
    <h2 className="activity-divider front">{props.title}</h2>
  );
}

export default FrontDivJcoin;
