import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import ResponsiveAntMenu from 'responsive-ant-menu';
import { Row, Col, Menu } from 'antd';
import { UserOutlined, GiftOutlined, FileTextOutlined, BarChartOutlined, ShopOutlined, MenuOutlined } from '@ant-design/icons';

import './DrawerHide.css';
import logo from 'src/assets/JetShop_logo_orange.png';

const DrawerHide = (props) => {
  const { SubMenu } = Menu;

  const navigate = useNavigate();

  const [authList, setAuthList] = useState([]);

  useEffect(() => {
    setAuthList(props.authList);
  }, [props.authList]);

  const changeRouter = (path, onLinkClick) => {
    navigate(path);
    onLinkClick();
  };

  return (
    <Row className="menuHide" align="middle">
      <Col span={24} style={{ display: 'flex' }}>
        {authList && authList.length > 0 && (
          <ResponsiveAntMenu
            activeLinkKey={'/admin'}
            mobileMenuContent={() => (
              <button>
                <MenuOutlined />
              </button>
            )}
            menuClassName={'responsive-menu'}
            mode={'vertical'}
            mobileBreakPoint={1000}
          >
            {(onLinkClick) => (
              <Menu theme="dark" style={{ color: '#324057', border: 'none' }}>
                {/* 商家管理 */}
                {authList.indexOf('100') > -1 && (
                  <SubMenu popupClassName={'responsive-subMenu'} key="100" icon={<UserOutlined />} title="商家管理">
                    {authList.indexOf('101') > -1 && (
                      <Menu.Item key="101" onClick={() => changeRouter('/admin/store/edit', onLinkClick)}>
                        基本資料維護
                      </Menu.Item>
                    )}
                    {/* {authList.indexOf('102') > -1 && (
                      <Menu.Item key="102" onClick={() => changeRouter('/admin/store/verify-code-edit', onLinkClick)}>
                        消費者登入認證時效
                      </Menu.Item>
                    )} */}
                    {authList.indexOf('103') > -1 && (
                      <Menu.Item key="103" onClick={() => changeRouter('/admin/user/list', onLinkClick)}>
                        管理者帳號維護
                      </Menu.Item>
                    )}
                  </SubMenu>
                )}

                {/* 商品管理 */}
                {authList.indexOf('200') > -1 && (
                  <Menu.Item key="200" icon={<GiftOutlined />} onClick={() => changeRouter('/admin/product/list', onLinkClick)}>
                    商品管理
                  </Menu.Item>
                )}

                {/* 訂單查詢 */}
                {authList.indexOf('300') > -1 && (
                  <Menu.Item key="300" icon={<FileTextOutlined />} onClick={() => changeRouter('/admin/order/list', onLinkClick)}>
                    訂單查詢
                  </Menu.Item>
                )}

                {/* 統計報表 */}
                {authList.indexOf('400') > -1 && (
                  <SubMenu popupClassName={'responsive-subMenu'} key="400" icon={<BarChartOutlined />} title="統計報表">
                    {authList.indexOf('401') > -1 && (
                      <Menu.Item key="401" onClick={() => changeRouter('/admin/report/case-stage', onLinkClick)}>
                        案件處理
                      </Menu.Item>
                    )}
                    {authList.indexOf('402') > -1 && (
                      <Menu.Item key="402" onClick={() => changeRouter('/admin/report/case-pay-amount', onLinkClick)}>
                        案件款項
                      </Menu.Item>
                    )}
                  </SubMenu>
                )}

                {/* Jet捷店 */}
                {authList.indexOf('500') > -1 && (
                  <SubMenu popupClassName={'responsive-subMenu'} key="500" icon={<ShopOutlined />} title="Jet捷店">
                    {authList.indexOf('501') > -1 && (
                      <Menu.Item key="501" onClick={() => changeRouter('/admin/ecommerce/info', onLinkClick)}>
                        商家資訊
                      </Menu.Item>
                    )}
                    {authList.indexOf('502') > -1 && (
                      <Menu.Item key="502" onClick={() => navigate('/admin/ecommerce/activity')}>
                        活動專區
                      </Menu.Item>
                    )}
                  </SubMenu>
                )}
              </Menu>
            )}
          </ResponsiveAntMenu>
        )}

        <Link to={'/admin'}>
          <img className="logoImg" alt="JetShop Logo" src={logo} />
        </Link>
      </Col>
    </Row>
  );
};

export default DrawerHide;
