import { Result } from 'antd';

const NotFound = () => {
  return (
    <Result
      status="404"
      title="404"
      subTitle="找不到網頁"
    />
  )
};
export default NotFound;