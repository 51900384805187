import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { actionCreators } from 'src/actions/merchant';
import { actionCreators as bannerActionCreators } from 'src/actions/banner';

// import { format } from 'src/utils/format';

// Ant design
import { Layout, Row, Col, Carousel, Button } from "antd";
import { SoundOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import "antd/dist/antd.css";

import './Home.css';


const Home = () => {
  const [merCompName, setMerCompName] = useState("");
  // const [invalidDay, setInvalidDay] = useState("");
  const [banners, setBanners] = useState([]);
  const [bannerHeight, setBannerHeight] = useState("");

  const dispatch = useDispatch();
  const notifyRef = useRef(null);
  const slider = useRef(null);

  // 取出 store 中的 state
  const merAcctId = useSelector(state => state.merchant.merAcctId);
  const branchData = useSelector(state => state.merchant.branchData);
  const images = useSelector(state => state.banner.bannerImgs);

  // action : 1.取得登入帳號單一商家資訊 2.取得banners
  useEffect(() => {
    dispatch(actionCreators.getBranchData(merAcctId));
    dispatch(bannerActionCreators.getBannerImgs({
      all: 0, // 0: 已啟用的banner 1: 全部banner
      type: 2 // 1: 官網 2: 後台
    }));
  }, []);

  // 配值
  useEffect(() => {
    setMerCompName(branchData.merCompName || '商家'); // default: 永久
    // setInvalidDay(format.toDateTime(branchData.merInvalidDays));
  }, [branchData]);

  useEffect(() => {
    setBanners(images.reverse());
  }, [images]);

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);
  }, []);

  const onWindowResize = () => {
    let width = 768;
    if (notifyRef.current !== null) {
      if (notifyRef.current.clientWidth <= 768) width = notifyRef.current.clientWidth;
    }

    let height = (width * 500) / 768;
    setBannerHeight(height);
  };

  const SamplePrevArrow = (props) => {
    const { className, style } = props
    return (
      <div className={className} style={{ ...style }}>
        <Button
          className="leftArrow"
          shape="circle"
          size="small"
          icon={<LeftOutlined />}
          onClick={() => slider.current.prev()}
        />
      </div>
    )
  }

  const SampleNextArrow = (props) => {
    const { className, style } = props

    return (
      <div className={className} style={{ ...style }}>
        <Button
          className="leftArrow"
          shape="circle"
          size="small"
          icon={<RightOutlined />}
          onClick={() => slider.current.next()}
        />
      </div>
    )
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
}

  return (
    <Layout className="home">
      <Row justify="center" align="middle">
        <Col className="homeContent" span={18}>
          <h3>JetShop 支付商城 - 商家管理系統</h3>
        </Col>
        <Col span={24}>
          {merCompName}您好!
        </Col>
        {/* <Col span={24}>
          消費者登入認證碼
            { branchData.merValidDays && <span>將於<span className="required"> {invalidDay} </span>失效</span> }
            { !branchData.merValidDays && <span className="required">永久</span> }
          ，請失效後重新設定。
        </Col> */}

        <Col span={22}>
          <Row justify="center" align="middle">
            <Col span={22} className="homeNotify" style={{ maxWidth: '768px' }}>
              <SoundOutlined className="menuIcon" />最新公告
            </Col>
          </Row>

          <Row justify="center" align="middle">
            <Col span={22} ref={notifyRef}>
              {
                banners.length > 0 &&
                <Carousel
                  { ...settings }
                  arrows={true}
                  ref={slider}
                  className="carouselItems"
                  autoplay
                  style={{ height: bannerHeight, maxWidth: '768px' }}
                >
                  {
                    banners.map((item, index) => {
                      return (
                        <div key={index}>
                          <img src={item.url} />
                        </div>
                      )
                    })
                  }
                </Carousel>
              }
            </Col>
          </Row>

        </Col>
      </Row>
    </Layout>
  )
}

export default Home;