import React from "react";

// antd
import { Row, Col } from "antd";

import JcoinLogo from "src/assets/activity/Jcoin_logo.png";
import icon01 from "src/assets/activity/icon_01.png";
import icon02 from "src/assets/activity/icon_02.png";
import icon03 from "src/assets/activity/icon_03.png";
import icon04 from "src/assets/activity/icon_04.png";

const pageContents = [
  {
    id: 1,
    title: "兌換禮品列表",
    icon: icon01,
    color: "red",
    target: "gift-list",
  },
  {
    id: 2,
    title: "集點秘笈",
    icon: icon02,
    color: "orange",
    target: "tip",
  },
  {
    id: 3,
    title: "常見問題",
    icon: icon03,
    color: "green",
    target: "FAQ",
  },
  {
    id: 4,
    title: "活動規則",
    icon: icon04,
    color: "blue",
    target: "rule",
  },
];

const Section1 = () => {
  return (
    <div className="frontActivitySection1">
      {/* Section1-1 */}
      <Row justify="center">
        <Col className="frontJcoinLogo">
          <img alt="Jcoin Logo" src={JcoinLogo} />
        </Col>
      </Row>
      {/* Section1-2 */}
      <Row justify="center" style={{ marginTop: 70 }}>
        {
          pageContents.map(item => (
            <Col className="d-flex justify-center" key={item.id} span={12} lg={4} md={6} sm={6} style={{ marginTop: 20 }}>
              <div className="jcoinPageContent">
                <a href={'#' + item.target}>
                  <div className="d-flex justify-center">
                    <div className={`image ${item.color}`}>
                      <img src={item.icon} alt={item.title} />
                    </div>
                  </div>
                  <div className="title text-center" style={{ marginTop: 10, fontSize: "22px" }}>
                    {item.title}
                  </div>
                </a>
              </div>
            </Col>
          ))
        }
      </Row>
    </div>
  );
}

export default Section1;
