import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { orderService } from "src/services";
import moment from "moment";

import pdfPlaceholder from "src/assets/pdf-placeholder.png";

const Document = () => {
  // 取得 query string 的值
  const [searchParams] = useSearchParams();
  const appSerNo = Object.fromEntries([...searchParams]).appSerNo;

  const [declationInfo, setDeclationInfo] = useState({});
  const [pickupFiles, setPickupFiles] = useState([]);

  useEffect(() => {
    orderService.getDeclaration(appSerNo)
    .then(
      res => {
        res.json().then(result => {
          assignData(result)
        });
      },
      error => {
        console.log(error);
      },
    );
  }, []);

  const assignData = (result) => {
    let data = {};
    data.merAcctId = result.merAcctId;
    data.appCaseNo = result.appCaseNo;
    data.ordProdName = result.ordProdName;
    data.ordProdSpec = result.ordProdSpec;
    data.ordProdNum = result.ordProdNum;
    data.ordProdSerCode = result.ordProdSerCode;
    data.ordGift = result.ordGift;
    data.appTotalAmt = result.appTotalAmt;
    data.appPeriods = result.appPeriods;
    data.appPeriodAmt = result.appPeriodAmt;
    data.merName = result.merName;
    data.merSealImgUrl = result.merSealImgUrl;
    data.appDeclarImgUrl = result.appDeclarImgUrl;
    data.memIdNum = result.memIdNum;
    data.cellPhone = result.cellPhone;
    data.appDeclarDate = moment(result.appDeclarDate).format("l");
    setDeclationInfo(data);
    setPickupFiles(result.pickupFiles);
  };

  return (
    <div className="doc-wrapper">
      <div className="title-top">
        <p>特約商代碼：<span className="doc-data w-365 text-left">{declationInfo.merAcctId}</span></p>
        <p>案件編號：<span className="doc-data w-290 text-left">{declationInfo.appCaseNo}</span></p>
      </div>
      <div className="form-content">
        {/* content start */}
        <h3>客戶切結書</h3>
        <ul>
          <div className="stamp">
            <img src={declationInfo.merSealImgUrl} alt="印章" />
          </div>
          <li>立切結書人（以下簡稱本人）同意以面交方式點收以下商品，該商品不適用《消保法》<span className="red">第19條第1項規定；網路購物7天猶豫期（鑑賞期）</span>」。</li>
          <li>本人確實已收到所購買之商品並查驗（收）無誤，且同意下列事項：</li>
          <table cellSpacing="1" cellPadding="0" className="table-s1">
            <tbody>
              <tr>
                <td className="text-center">商品名稱</td>
                <td colSpan="7">
                  <span className="doc-data text-left">{declationInfo.ordProdName}</span>
                  <em>（商品明細須與分期付款申請書內容一致）</em>
                </td>
              </tr>
              <tr>
                <td className="text-center">型號規格（顏色）</td>
                <td colSpan="7">
                  <span className="doc-data text-left">{declationInfo.ordProdSpec}</span>
                </td>
              </tr>
              <tr>
                <td className="text-center">商品數量 </td>
                <td>
                  <span className="doc-data text-left">{declationInfo.ordProdNum}</span>
                </td>
                <td colSpan="2">型號</td>
                <td colSpan="4">
                  <span className="doc-data text-left">{declationInfo.ordProdSerCode}</span>
                </td>
              </tr>
              <tr>
                <td className="text-center">贈品 </td>
                <td colSpan="7">
                  <span className="doc-data text-left">{declationInfo.ordGift}&nbsp;</span>
                </td>
              </tr>
              <tr>
                <td className="text-center">分期總金額 </td>
                <td colSpan="2">
                  新台幣<span className="doc-data w-150">{declationInfo.appTotalAmt}</span>元
                </td>
                <td colSpan="2">分期期數</td>
                <td>
                  <span className="doc-data w-70">{declationInfo.appPeriods}</span>期
                </td>
                <td className="text-center">每期金額 </td>
                <td className="text-center">
                  新台幣<span className="doc-data w-90">{declationInfo.appPeriodAmt}</span>元
                </td>
              </tr>
            </tbody>
          </table>
          <li>本人已充分瞭解及知悉，向 大方藝彩行銷顧問股份有限公司（以下簡稱貴公司）之特約商購買並同意接受上列商品，且知悉與同意特約商得將請求本人支付分期價款及其所有附隨權利轉讓與 貴公司或指定受讓人。</li>
          <li>
            上列商品後續維修保固及售後服務均由特約商
            <span className="doc-data w-240 text-left line">{declationInfo.merName}</span>
            <br />
            （蓋發票章或公司大小章）負責，瞭解 貴公司僅提供分期付款服務（不提供後續<br />售後商品維修保固等）。
          </li>
          <li>茲因本人實際上需要，特要求 貴公司先行將消費金額一次撥入特約商指定之銀行帳戶中，以為收買該筆應收帳款債權，本人並切結承諾於 貴公司撥付款項時點起，每月依約定日期及金額繳納分期款項給付 貴公司。</li>
          <li>
            立切結書人於簽立本切結書時，確實已經合理期間審閱並允諾本切結書內容，本切結書之簽署確為本人所親簽，倘經查若有不實、冒貸之情事，願付法律上刑事及民事責任，若致 貴公司遭名譽、
            財務金錢損失時，本人仍應負完全賠償責任，概無異議。
          </li>
        </ul>
        <p className="mb-10">　　　　此致</p>
        <p className="mb-10">　　　　大方藝彩行銷顧問股份有限公司</p>
        <p className="mb-15">　　　　立切結書人（親簽）：
          <span className="inline-block">
          {declationInfo.appDeclarImgUrl && (
            <span className="sign">
              <img src={declationInfo.appDeclarImgUrl} alt="本人簽名" />
            </span>
          )}
          </span>
          {!declationInfo.appDeclarImgUrl && (
            <span className="sign doc-data">
              尚未簽名
            </span>
          )}
          身份證字號：<span className="doc-data w-310">{declationInfo.memIdNum}</span>
        </p>
        <p className="mb-20">　　　　連絡電話：<span className="doc-data w-365">{declationInfo.cellPhone}</span>
          {declationInfo.appDeclarDate && declationInfo.appDeclarDate.length > 0 && (
            <React.Fragment>
              中 華 民 國<span className="doc-data w-110">{declationInfo.appDeclarDate.split("/")[2] - 1911}</span>年
              <span className="doc-data w-70">{declationInfo.appDeclarDate.split("/")[0]}</span>月
              <span className="doc-data w-70">{declationInfo.appDeclarDate.split("/")[1]}</span>日
            </React.Fragment>
          )}
        </p>
      </div>
      <div className="doc-img">
        <h3>取貨文件影像資料</h3>
      </div>
      <div className="form-content">
        <div className="row">
          {
            pickupFiles.map((file, idx) => (
              <div className="col-6 mb-5" key={file.pickDocSerNo}>
                <h6>取貨文件{idx + 1}</h6>
                <div className="img-id">
                  {file.pickDocImgUrl.includes(".pdf") && (
                    <a href={file.pickDocImgUrl} target="_blank" rel="noreferrer">
                      <img src={pdfPlaceholder} alt="取貨文件" />
                    </a>
                  )}
                  {!file.pickDocImgUrl.includes(".pdf") && (
                    <img className="img-fluid" src={file.pickDocImgUrl} alt="取貨文件" />
                  )}
                </div>
              </div>
            ))
          }
        </div>
      </div>
  </div>
  );
}

export default Document;
