import { frontFAQTypes } from 'src/constants';
import { frontFAQService } from 'src/services';

// 取得FAQ
const getFrontFAQ = (FAQTypeSerialNumber) => {
  return (dispatch) => {
    frontFAQService.getFrontFAQ(FAQTypeSerialNumber)
    .then(
      res => {
        res.json().then(result => {
          dispatch({type: frontFAQTypes.GET_FRONT_FAQ, data: result.data});
        });
      },
      error => {
        console.log(error);
      }
    );
  };
};

// 取得FAQ類型
const getFrontFAQType = () => {
  return (dispatch) => {
    frontFAQService.getFrontFAQType()
    .then(
      res => {
        res.json().then(result => {
          dispatch({type: frontFAQTypes.GET_FRONT_FAQ_TYPE, data: result.data});
        });
      },
      error => {
        console.log(error);
      }
    );
  };
};

export const actionCreators = {
  getFrontFAQ,
  getFrontFAQType
}