import React, { useState } from "react";

// antd
import { Button, Modal } from "antd";

import SampleProdList from "src/assets/sample_prodList.png";
import SampleShopNote from "src/assets/sample_shopNote.png";
import SampleActivity from "src/assets/sample_activity.png";

const ModalSample = (props) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleVisible = () => setIsVisible(true);

  const handleCancel = () => setIsVisible(false);

  return (
    <div>
      <Button
        style={{ margin: '10px 0' }}
        onClick={handleVisible}
      >
        查看示意圖
      </Button>

      <Modal 
        title="示意圖"
        visible={isVisible}
        onCancel={handleCancel}
        footer={false}
      >
        {props.sourceType === "info" && (
          <>
            <div className="jet-sample">
              <img className="jet-sample-img" src={SampleProdList} alt="基本資料示意圖" />
            </div>
            <div className="jet-sample" style={{ paddingBottom: 30 }}>
              <img className="jet-sample-img" src={SampleShopNote} alt="購物須知示意圖" />
            </div>
          </>
        )}
        {
          props.sourceType === "activity" && (
            <>
              <div className="jet-sample" style={{ paddingBottom: 30 }}>
                <img className="jet-sample-img" src={SampleActivity} alt="活動專區設定示意圖" />
              </div>
            </>
          )
        }
      </Modal>
    </div>
  );
}

export default ModalSample;
