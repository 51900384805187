import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { actionCreators } from "src/actions/FAQ";
import { actionCreators as actionRootCreators } from "src/actions/root";
import { FAQService } from "src/services";

// components
import FAQDetail from "./FAQDetail";
import EditFAQType from "./EditFAQType";
import FAQAnswerModal from "./FAQAnswer";

// antd
import { Layout, Row, Col, Card, Select, Space, Tooltip, Table, Empty, Button, Input, Form, Modal, message } from "antd";
import { MenuOutlined, PlusOutlined, QuestionCircleOutlined, SearchOutlined } from "@ant-design/icons";

// drag components
const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: "grab", color: "#999" }} />);
const SortableItem = SortableElement(props => <tr {...props} />);
const SortableBody = SortableContainer(props => <tbody {...props} />);

const RootFAQ = () => {
  const [form] = Form.useForm();
  const [FAQTypeOptions, setFAQTypeOptions] = useState([]);
  const [FAQType, setFAQType] = useState(1);
  const [rowData, setRowData] = useState([]);
  const [FAQAnswer, setFAQAnswer] = useState("");
  // search
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [, setSearchedColumn] = useState("");

  // 取出 store 中的 state
  const FAQList = useSelector(state => state.FAQ.FAQList);
  const FAQTypeList = useSelector(state => state.FAQ.FAQTypeList);
  const isEditFAQTypeVisible = useSelector(state => state.FAQ.modalEditFAQTypeVisible);

  const dispatch = useDispatch();

  const dispatchFunc = (FAQTypeSerialNumber) => dispatch(actionCreators.getFAQ(FAQTypeSerialNumber));

  // step1: 取得FAQ類型
  useEffect(() => {
    dispatch(actionCreators.getFAQType());
  }, [isEditFAQTypeVisible])

  // step2: 取得FAQ
  useEffect(() => {
    dispatchFunc(1);
  }, [dispatch]);

  // step3: FAQ類型配值
  useEffect(() => {
    setFAQTypeOptions(FAQTypeList);
  }, [FAQTypeList]);

  // step4: FAQ配值
  useEffect(() => {
    let data = [];
    let FAQIndex = 0;
    if (FAQList && FAQList.length >= 1) {
      FAQList.forEach(item => {
        data.push({
          key: `${item.FAQSerialNumber}`,
          FAQSerialNumber: item.FAQSerialNumber,
          FAQOrder: item.FAQOrder,
          FAQQuestion: item.FAQQuestion,
          FAQAnswer: item.FAQAnswer,
          FAQStatus: item.FAQStatus,
          index: FAQIndex,
        });
        FAQIndex += 1;
      });
    };
    data.sort((a, b) => {
      return a.FAQOrder - b.FAQOrder;
    });
    setRowData(data);
  }, [FAQList]);

  // 切換FAQ類型
  const onChangeType = (e) => {
    setFAQType(e);
    dispatchFunc(e);
  };

  // open: 新增/編輯視窗
  const handleFAQDetailModal = (record={}) => {
    dispatch(actionCreators.setFAQDetail(record));
    dispatch(actionRootCreators.modalVisible());
  };
  
  // open: 編輯類型視窗
  const handleEditFAQTypeModal = () => {
    dispatch(actionCreators.modalEditFAQTypeVisible());
  };

  // open: 解答視窗
  const handleAnswerModal = (answer) => {
    setFAQAnswer(answer);
    dispatch(actionCreators.modalViewAnswerVisible());
  };

  // 搜尋功能
  const getColumnSearchProps = (dataIndex, text) => {
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{padding: 8}}>
          <Input 
            ref={searchInput}
            placeholder={`搜尋${text}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{width: 188, marginBottom: 8, display: "block"}}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              size="small"
              style={{ width: 90 }}
            >
              確定
            </Button>
            <Button
              onClick={() => handleSearchReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              重設
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
      onFilter: (val, record) =>
        record[dataIndex] !== null
          ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(val.toLowerCase())
          : "",
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) setTimeout(() => searchInput.current.select());
      },
      render: (text) => text,
    };
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleSearchReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  // 編輯-刪除
  const handleDelete = async (key) => {
    const { confirm } = Modal;
    confirm({
      title: "確定要刪除此筆資料嗎？",
      onOk: () => {
        try {
          FAQService.deleteFAQ(key)
          .then(
            res => {
              if (res.status === 204) {
                // 前端更新表格
                const dataSource = [...rowData];
                setRowData(() => dataSource.filter(item => item.key !== key));
                message.success("刪除成功");
              }
              else if (res.status === 401) message.warning("存取拒絕");
              else if (res.status === 404) message.warning("找不到 FAQ");
              else message.error("刪除失敗");
            },
            error => {
              console.log(error);
            },
          );
        } catch (error) {
          console.log(error);
        }
      },
    });
  };

  // 排序後結果
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      // 前端更新表格
      const newData = arrayMoveImmutable([].concat(rowData), oldIndex, newIndex).filter(
        el => !!el,
      );
      newData.forEach((item, idx) => {
        item.FAQOrder = idx + 1;
      });
      setRowData(newData);

      // api 處理
      handleResort(newData, newIndex);
    };
  };

  const handleResort = async (data, index) => {
    const FAQSerialNumber = data[index].FAQSerialNumber;
    const payload = { FAQOrder: index + 1 };
    FAQService.editFAQOrder(FAQSerialNumber, payload)
    .then(
      res => {
        if (res.status === 204) message.success("排序修改成功");
        else if (res.status === 401) message.warning("存取拒絕");
        else if (res.status === 404) message.warning("找不到 FAQ");
        else if (res.status === 409) message.warning("排序超出範圍");
        else message.error("修改失敗");
      },
      error => {
        console.log(error);
      }
    );
  };

  // 拖曳內容區塊
  const DraggableContainer = props => (
    <SortableBody
      useDragHandle
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = rowData.findIndex(x => x.index === restProps["data-row-key"]);
    return <SortableItem index={index} {...restProps} />;
  };

  const columns = [
    {
      title: 
        <div>
          拖曳&nbsp;
          <Tooltip placement="top" title="拖曳以改變順序">
            <QuestionCircleOutlined style={{cursor: "help"}} />
          </Tooltip>
        </div>,
      dataIndex: "drag",
      width: "70px",
      render: () => <Button type="text" disabled={searchText}><DragHandle /></Button>,
    },
    {
      title: "FAQ代碼",
      dataIndex: "FAQSerialNumber",
      width: "95px",
    },
    {
      title: "FAQ排序",
      dataIndex: "FAQOrder",
      width: "95px",
    },
    {
      title: "問題",
      dataIndex: "FAQQuestion",
      width: "200px",
      ...getColumnSearchProps("FAQQuestion", "問題"),
    },
    {
      title: "解答",
      dataIndex: "FAQAnswer",
      width: "70px",
      // ...getColumnSearchProps("FAQAnswer", "解答"),
      render: (FAQAnswer) => (<a onClick={() => handleAnswerModal(FAQAnswer)}>檢視</a>),
    },
    {
      title: "FAQ狀態",
      dataIndex: "FAQStatus",
      width: "95px",
      render: (FAQStatus) => (<span>{FAQStatus == 1 ? "隱藏" : "顯示"}</span>),
    },
    {
      title: "操作",
      dataIndex: "operation",
      width: "100px",
      render: (_, record) => {
        return (
          <Space>
            <a onClick={() => handleFAQDetailModal(record)}>編輯</a>
            <a onClick={() => handleDelete(record.key)} style={{ color: "red" }}>刪除</a>
          </Space>
        );
      },
    },
  ];

  return (
    <Layout className="rootList">
      <Row justify="center">
        <Col span={23}>
          <div className="title">FAQ設定</div>

          <Card className="rootListCard">
            {/* 篩選區塊 */}
            <div className="rootListSearch">
              <Row>
                <Col flex="120px" style={{lineHeight: "32px"}}>
                  <span>選擇FAQ類型</span>
                </Col>
                <Col>
                  <Select defaultValue={1} style={{width: 200}} onChange={onChangeType}>
                    {FAQTypeOptions.map(item => (
                      <Select.Option value={item.FAQTypeSerialNumber} key={item.FAQTypeSerialNumber}>{item.FAQTypeName}</Select.Option>
                    ))}
                  </Select>
                </Col>
                <Col style={{marginLeft: "16px"}}>
                  <Button type="primary" onClick={handleEditFAQTypeModal}>編輯類型</Button>
                  <EditFAQType />
                </Col>
              </Row>
            </div>

            {/* 查詢結果 */}
            <div>
              <Row>
                <Col className="title" align="left" span={12} xs={12} sm={12} md={12} style={{color: "#F7BA2A"}}>查詢結果</Col>
                <Col align="right" span={12} xs={12} sm={12} md={12} style={{marginTop: "20px"}}>
                    <Button icon={<PlusOutlined />} onClick={() => handleFAQDetailModal({})}>新增</Button>
                    <FAQDetail FAQType={FAQType} getFAQData={dispatchFunc} />
                </Col>
              </Row>
              <Row style={{margin: "20px 0"}}>
                <Form form={form} component={false}>
                  <Table
                    components={{body: {
                      wrapper: DraggableContainer,
                      row: DraggableBodyRow,
                    }}}
                    columns={columns}
                    dataSource={rowData}
                    rowClassName="editable-row"
                    rowKey={record => record.index}
                    locale={{emptyText: (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>無資料</span>} />
                    )}}
                    size="small"
                    pagination={false}
                    scroll={{y: 400}}
                  />
                  <FAQAnswerModal answer={FAQAnswer} />
                </Form>
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}

export default RootFAQ;
