import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import { frontJetService } from 'src/services';

import Header from 'src/components/Header';
import Footer from 'src/components/Footer';
import Drawer from 'src/components/Drawer';
import DrawerHide from 'src/components/DrawerHide';

import RootHeader from 'src/components/Root/Header';
import RootFooter from 'src/components/Root/Footer';
import RootDrawer from 'src/components/Root/Drawer';
import RootDrawerHide from 'src/components/Root/DrawerHide';

import FrontHeader from 'src/components/Front/Header';
import FrontFooter from 'src/components/Front/Footer';

import FrontJetHeader from 'src/components/Front/Jet/Header';
import FrontJetFooter from 'src/components/Front/Jet/Footer';

import Error404 from '../Error404';
import Error403 from '../Error403';

import routes from 'src/routes';

import { Layout } from 'antd';

import 'src/css/Main.css';
import 'src/css/MainRoot.css';
import 'src/css/MainFront.css';


const Main = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isSmallSize, setIsSmallSize] = useState(windowWidth <= 1000);
  const [mainPage, setMainPage] = useState('');
  const [isRoutePage, setIsRoutePage] = useState(true); // 判斷 url 是否在設定router中
  const [isHasAuth, setIsHasAuth] = useState(true); // 判斷使用者是否有權限瀏覽進入之 url
  const [authList, setAuthList] = useState([]); // 登入帳號可瀏覽 url 權限列表

  // 取出 store 中的 state
  const isLogin = useSelector(state => state.auth.isLogined);
  const isRootLogin = useSelector(state => state.auth.isRootLogined);
  const personalInfo = useSelector(state => state.merchant.storePersonalInfo);

  const location = useLocation();

  // 配值
  useEffect(() => {
    setAuthList(personalInfo.merAdminPermission);
    // if (personalInfo.merAdminPermission) setAuthList(personalInfo.merAdminPermission.toString().split(','));

    const { pathname } = location;
    const obj = routes.props.children.filter(item => item.props.path === pathname);

    if (obj.length <= 0 && !pathname.includes('/product/detail')) {
      // 檢查Jet捷店是否存在
      frontJetService.checkShopExist(location.pathname.slice(1))
      .then(
        res => {
          if (res.status === 200) setIsRoutePage(true);
          else setIsRoutePage(false);
        },
        error => {
          console.log(error)
        },
      );
    } else if (pathname.match('/product/detail')) {
      setIsRoutePage(true);
    } else {
      setIsRoutePage(true);
    }

    if (obj && obj.length) {
      let { key } = obj[0];
      if (authList && authList.length) {
        if (!key || authList.indexOf(key) > -1) setIsHasAuth(true);
        else setIsHasAuth(false);
      }
    }

    // 偵測商家後台|維護系統|官網|Jet捷店頁面
    // 商家後台: admin
    // 維護系統: root
    // 官網: frontHome
    // Jet捷店: jetEcommerce
    if (
      pathname.startsWith('/admin') 
      && pathname !== '/admin/ecommerce/preview'
      && !pathname.startsWith('/admin/order/document')
    ) {
      setMainPage('admin');
    } else if (pathname.startsWith('/root')) {
      setMainPage('root');
    } else if (
      pathname === '/'
      || pathname.startsWith('/installment') 
      || pathname.startsWith('/ecommerce')
      || pathname.startsWith('/about')
      || pathname.startsWith('/merchant')
      || pathname.match('/product/detail')
    ) {
      setMainPage('frontHome');
    } else if (pathname.startsWith('/admin/order/document')) {
      setMainPage('noHeader');
    } else {
      setMainPage('jetEcommerce');
    }
  }, [location.pathname, personalInfo]);

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);
    onWindowResize();
  
    return () => window.removeEventListener("resize", onWindowResize);
  }, []);

  const onWindowResize = () => {
    setWindowWidth(window.innerWidth);
    setIsSmallSize(window.innerWidth <= 1000);
  };

  const getClass = () => {
    let className = '';
    if (isLogin && location.pathname.startsWith("/admin")) {
      className = isSmallSize ? 'mobileContent' : 'webContent';
    }
    if (isRootLogin && location.pathname.startsWith("/root")) {
      className = isSmallSize ? 'mobileContent' : 'webContent';
    }
    return className;
  };

  const adminMain = () => {
    return (
      <Layout className="adminBase">
        { isLogin && <Header /> }
  
        { isSmallSize && isLogin && <DrawerHide authList={authList} /> }
        { !isSmallSize && isLogin && <Drawer authList={authList} /> }
  
        <div className={getClass()}>
          {routes}
        </div>
  
        { isLogin && <Footer /> }
      </Layout>
    )
  };

  const rootMain = () => {
    if (isRootLogin === false) {
      return (
        <div>
            {routes}
        </div>
      )
    }
    return (
      <Layout className="adminBase">
        { <RootHeader /> }
  
        { isSmallSize && <RootDrawerHide /> }
        { !isSmallSize && <RootDrawer /> }
  
        <div className={getClass()}>
          {routes}
        </div>
  
        { <RootFooter /> }
      </Layout>
    )
  }

  const frontMain = () => {
    return (
      <Layout className="adminBase frontLayout">
        <Layout.Header style={{padding: '0'}}>
          { <FrontHeader /> }
        </Layout.Header>
  
        <Layout.Content className="frontContent">
          {routes}
        </Layout.Content>

        <Layout.Footer style={{padding: '0'}}>
          { <FrontFooter /> }
        </Layout.Footer>
      </Layout>
    )
  }

  const frontJetMain = () => {
    return (
      <Layout className="adminBase frontLayout">
        <Layout.Header style={{padding: '0'}}>
          { <FrontJetHeader 
              pageType={location.pathname === '/admin/ecommerce/preview' ? 'preview' : 'front'} 
            /> }
        </Layout.Header>
  
        <Layout.Content className="frontContent">
          {routes}
        </Layout.Content>

        <Layout.Footer style={{padding: '0'}}>
          { <FrontJetFooter
              pageType={location.pathname === '/admin/ecommerce/preview' ? 'preview' : 'front'}
            /> }
        </Layout.Footer>
      </Layout>
    )
  }
  
  const noHeader = () => {
    return (
      <Layout className="adminBase noHeader">
        {routes}
      </Layout>
    )
  }

  const unAuthPage = () => {
    return (
      <Error403 />
    )
  };

  const notFoundPage = () => {
    return (
      <Error404 />
    )
  };


  return (
    <>
      { (mainPage === 'admin' && isRoutePage && isHasAuth) && adminMain() }
      { (mainPage === 'root' && isRoutePage) && rootMain() }
      { (mainPage === 'frontHome' && isRoutePage) && frontMain() }
      { (mainPage === 'jetEcommerce' && isRoutePage) && frontJetMain() }

      { (mainPage === 'noHeader' && isRoutePage) && noHeader() }

      {/* 沒有權限瀏覽頁面 */}
      { (!isHasAuth) && unAuthPage() }

      {/* 找不到頁面 */}
      { (!isRoutePage) && notFoundPage() }
    </>
  )
}

export default Main;