const INITIAL_STATE = {
  installmentList: [],
  installmentDetail: {},
  interestRateList: [],
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_INSTALLMENT_LIST":
      return {
        ...state,
        installmentList: action.data
      };
    case "GET_INSTALLMENT_DETAIL":
      return {
        ...state,
        installmentDetail: action.data
      };
    case "GET_INTEREST_RATE_LIST":
      return {
        ...state,
        interestRateList: action.data
      };
    default:
      return state;
  }
};

export default reducer;