import Cookies from 'universal-cookie';
import { ADMIN_API_URL as api_admin_url } from 'src/config';

const cookies = new Cookies();
const reqOptions = {
  get: {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  },
};

// 取得 案件狀態總覽
const getReviewApplyCases = async (payload) => {
  let { merAcctId, dateType, startDate, endDate } = payload;
  return fetch(`${api_admin_url}/report/previewApplyCases?access_token=${cookies.get('_merchant_auth_token')}&merAcctId=${merAcctId}&dateType=${dateType}&startDate=${startDate}&endDate=${endDate}`, reqOptions.get);
};

// 取得 案件款項
const getSummryApplyCases = async (payload) => {
  let { merAcctId, dateType, startDate, endDate } = payload;
  return fetch(`${api_admin_url}/report/summryApplyCases?access_token=${cookies.get('_merchant_auth_token')}&merAcctId=${merAcctId}&dateType=${dateType}&startDate=${startDate}&endDate=${endDate}`, reqOptions.get);
};

// 取得 案件列表
const getListApplyCases = async (payload) => {
  let { merAcctId, dateType, startDate, endDate } = payload;
  return fetch(`${api_admin_url}/report/listApplyCases?access_token=${cookies.get('_merchant_auth_token')}&merAcctId=${merAcctId}&dateType=${dateType}&startDate=${startDate}&endDate=${endDate}`, reqOptions.get);
};

export const reportService = {
  getReviewApplyCases,
  getSummryApplyCases,
  getListApplyCases,
};
