import React from "react";

// antd
import { Row, Col } from "antd";

// css
import "../FrontGuide.css";

// component
import FrontBreadcrumb from "src/components/Front/Breadcrumb";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";

import JcoinBtn from "src/assets/activity/Jcoin_button.png";

const FrontGuide = () => {
  return (
    <div style={{ marginBottom: 50 }}>
      <FrontBreadcrumb
        link={[
          { href: '', name: "Jcoin 集點活動" }
        ]}
      />

      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />

      <Row justify="center" style={{ marginTop: 70 }}>
        <Col span={24}>
          <div>
          <a 
            href="https://loco.com.tw/#/questionnaire/GlFOuX19aRLagDQe"
            target="_blank" rel="noreferrer"
          >
            <button className="jcoinBtn">
              <img alt="Jcoin Button" src={JcoinBtn} />
            </button>
          </a>
        </div>
        </Col>
      </Row>
    </div>
  );
}

export default FrontGuide;
