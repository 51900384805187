import { applyTypeTypes } from 'src/constants';
import { applyTypeService } from 'src/services'; 

// 取得案件類型
const getApplyType = () => {
  return (dispatch) => {
    applyTypeService.getApplyType()
    .then(
      res => {
        res.json().then(result => {
          dispatch({type: applyTypeTypes.GET_APPLY_TYPE_LIST, data: result.data});
        });
      },
      error => {
        console.log(error);
      }
    );
  };
};

export const actionCreators = {
  getApplyType
}