import Cookies from "universal-cookie";
import { API_URL as api_url } from 'src/config';

const cookies = new Cookies();

// 商家註冊申請
const addMerchantApply = async (payload) => {
  return fetch(
    `${api_url}/merchant-apply`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${cookies.get("_front_token")}`
      },
      body: JSON.stringify(payload)
    }
  );
};

export const frontMerchantService = {
  addMerchantApply
};
