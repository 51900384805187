import Cookies from "universal-cookie";
import { ADMIN_API_URL as api_admin_url } from 'src/config';

const cookies = new Cookies();
const reqOptions = {
  get: {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${cookies.get('_root_auth_token')}`
    },
  },
  post: {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${cookies.get('_root_auth_token')}`
    },
  },
  patch: {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${cookies.get('_root_auth_token')}`
    },
  },
  delete: {
    method: "DELETE",
    headers: {
      "Content-Type": "text/html",
      "Authorization": `Bearer ${cookies.get('_root_auth_token')}`
    },
  },
};

// 取得商品類型列表
const getProductType = async (appTypeNo) => {
  return fetch(
    `${api_admin_url}/product-type-indexes?appTypeNo=${appTypeNo}`,
    reqOptions.get
  );
};

// 新增商品類型
const addProductType = async (payload) => {
  return fetch(
    `${api_admin_url}/product-type-indexes`,
    {
      ...reqOptions.post,
      body: JSON.stringify(payload),
    }
  );
};

// 編輯商品類型
const editProductType = async (prodTypeNo, payload) => {
  return fetch(
    `${api_admin_url}/product-type-indexes/${prodTypeNo}`,
    {
      ...reqOptions.patch,
      body: JSON.stringify(payload),
    }
  );
};

// 刪除商品類型
const deleteProductType = async (prodTypeNo) => {
  return fetch(
    `${api_admin_url}/product-type-indexes/${prodTypeNo}`,
    reqOptions.delete
  );
};

// 編輯商品類型排序
const editProductTypeOrder = async (prodTypeNo, payload) => {
  return fetch(
    `${api_admin_url}/product-type-indexes/${prodTypeNo}/order`,
    {
      ...reqOptions.patch,
      body: JSON.stringify(payload),
    }
  );
};

// 取得商品項目列表
const getProductItem = async (prodTypeNo) => {
  return fetch(
    `${api_admin_url}/product-type-indexes/${prodTypeNo}/items`,
    reqOptions.get
  );
};

// 新增商品項目
const addProductItem = async (prodTypeNo, payload) => {
  return fetch(
    `${api_admin_url}/product-type-indexes/${prodTypeNo}/items`,
    {
      ...reqOptions.post,
      body: JSON.stringify(payload),
    }
  );
};

// 編輯商品項目
const editProductItem = async (prodTypeNo, prodItemNo, payload) => {
  return fetch(
    `${api_admin_url}/product-type-indexes/${prodTypeNo}/items/${prodItemNo}`,
    {
      ...reqOptions.patch,
      body: JSON.stringify(payload),
    }
  );
};

// 編輯商品項目排序
const editProductItemOrder = async (prodTypeNo, prodItemNo, payload) => {
  return fetch(
    `${api_admin_url}/product-type-indexes/${prodTypeNo}/items/${prodItemNo}/order`,
    {
      ...reqOptions.patch,
      body: JSON.stringify(payload),
    }
  );
};

// 刪除商品項目
const deleteProductItem = async (prodTypeNo, prodItemNo) => {
  return fetch(
    `${api_admin_url}/product-type-indexes/${prodTypeNo}/items/${prodItemNo}`,
    reqOptions.delete
  );
};

export const productTypeService = {
  getProductType,
  addProductType,
  editProductType,
  deleteProductType,
  editProductTypeOrder,
  getProductItem,
  addProductItem,
  editProductItem,
  editProductItemOrder,
  deleteProductItem
};
