import React from "react";

// antd
import { Row, Col } from "antd";

// component
import FrontDivCenter from "src/components/Front/Divider/DivCenter";

import logo from "src/assets/ecommerce/logo_ecommerce.png";
import iconTriangle from "src/assets/ecommerce/icon_triangle.png";
import iconSquare from "src/assets/ecommerce/icon_square.png";
import icon04 from "src/assets/ecommerce/icon_04.png";
import icon05 from "src/assets/ecommerce/icon_05.png";
import icon06 from "src/assets/ecommerce/icon_06.png";
import icon07 from "src/assets/ecommerce/icon_07.png";
import icon08 from "src/assets/ecommerce/icon_08.png";
import icon09 from "src/assets/ecommerce/icon_09.png";
import icon10 from "src/assets/ecommerce/icon_10.png";
import icon11 from "src/assets/ecommerce/icon_11.png";

const steps = [
  {
    id: 1,
    icon: icon04,
    decorate: iconTriangle,
    color: "#F0F0F0",
    content: [
      "申請加入",
      "Jet捷店專人服務"
    ]
  },
  {
    id: 2,
    icon: icon05,
    decorate: iconTriangle,
    color: "#E5E5E5",
    content: [
      "輕鬆設定秒開店",
      "即刻轉型電子商務"
    ]
  },
  {
    id: 3,
    icon: icon06,
    decorate: iconTriangle,
    color: "#D5D5D5",
    content: [
      "線上下單全程e化",
      "迅速完成分期申請"
    ]
  },
  {
    id: 4,
    icon: icon07,
    decorate: iconSquare,
    color: "#C5C5C5",
    content: [
      "商品交付",
      "隔日撥款指定帳戶"
    ]
  },
];

const features = [
  {
    title: "在地商家",
    icon: icon08,
    color: "red",
    content: "線上線下消費整合，把握每一個看看客。"
  },
  {
    title: "生活批發",
    icon: icon09,
    color: "orange",
    content: "提供消費者不同支付方式，快速合作申請，營業額輕鬆增加。"
  },
  {
    title: "品牌電商",
    icon: icon10,
    color: "blue",
    content: "加強品牌行銷提供平台曝光，商品分期支付增加顧客帶貨率。"
  },
  {
    title: "專業服務",
    icon: icon11,
    color: "green",
    content: "裝潢、美體美容等專業服務，交給Jet微店省時不費力，交易流程更順暢。"
  },
];

const Section3 = () => {
  return (
    <div className="frontEcommerceSection3">
      {/* Section3-1 */}
      <Row justify="center" style={{ marginTop: 70 }}>
        <Col span={24}>
          <img src={logo} alt="Jet捷店" style={{ width: 150, marginBottom: 10 }} />
          <h2 className="front" style={{ margin: 0 }}>
            簡單四步驟立即加入
          </h2>
          <FrontDivCenter />
        </Col>
        {steps.map((item, idx) => (
          <Col className="frontEcommerceStep d-flex justify-center" span={11} md={5} sm={6} key={idx} style={{ marginTop: 20 }}>
            <div style={{ width: "100%" }}>
              <div>
                <img src={item.icon} alt={item.id} />
              </div>
              <div className="step" style={{ backgroundColor: item.color }}>
                <div className="d-flex justify-start align-center">
                  <div className="number">
                    {item.id}
                  </div>
                  <img src={item.decorate} alt={item.id} style={{ width: 50 }} />
                </div>
                <h4 className="front" style={{ margin: "10px 10px 6px" }}>
                  {item.content[0]}
                </h4>
                <h4 className="front" style={{ margin: "0 10px 24px" }}>
                  {item.content[1]}
                </h4>
              </div>
            </div>
          </Col>
        ))}
      </Row>
      {/* Section3-2 */}
      <Row justify="center" style={{ marginTop: 70 }}>
        <Col span={24} style={{ marginBottom: 20 }}>
          <h2 className="front" style={{ margin: 0 }}>
            <p>專人服務貼近您的需求，</p>
            <p>攜手創造更大價值</p>
          </h2>
          <FrontDivCenter />
        </Col>
        {features.map((item, idx) => (
          <Col className="d-flex justify-center" span={11} md={5} sm={6} key={idx}>
            <div className={`featureBox ${item.color}`}>
              <div style={{ height: 100 }}>
                <img src={item.icon} alt={item.title} />
              </div>
              <hr style={{ border: "1px #fff solid", borderRadius: 20, margin: 10 }} />
              <h3 className="front" style={{ color: "#fff" }}>
                <b>{item.title}</b>
              </h3>
              <h4 className="front" style={{ color: "#fff", margin: "auto 12px 10px" }}>
                {item.content}
              </h4>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default Section3;
