import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Row, Col, Menu } from 'antd';
import { UserOutlined, GiftOutlined, FileTextOutlined, BarChartOutlined, ShopOutlined } from '@ant-design/icons';

import './Drawer.css';
import logo from 'src/assets/JetShop_logo_orange.png';

const Drawer = (props) => {
  const { SubMenu } = Menu;

  const navigate = useNavigate();

  const [collapsed] = useState(false);
  const [authList, setAuthList] = useState([]);

  useEffect(() => {
    setAuthList(props.authList);
  }, [props.authList]);

  return (
    <Row className="menu">
      <Col span={24}>
        <Link to={'/admin'}>
          <img className="logoImg" alt="JetShop Logo" src={logo} />
        </Link>

        {authList && authList.length > 0 && (
          <Menu mode="inline" theme="dark" inlineCollapsed={collapsed} style={{ backgroundColor: '#324057', color: '#fff' }}>
            {/* 商家管理 */}
            {authList.indexOf('100') > -1 && (
              <SubMenu key="100" icon={<UserOutlined />} title="商家管理">
                {authList.indexOf('101') > -1 && (
                  <Menu.Item key="101" onClick={() => navigate('/admin/store/edit')}>
                    基本資料維護
                  </Menu.Item>
                )}
                {/* {authList.indexOf('102') > -1 && (
                  <Menu.Item key="102" onClick={() => navigate('/admin/store/verify-code-edit')}>
                    消費者登入認證時效
                  </Menu.Item>
                )} */}
                {authList.indexOf('103') > -1 && (
                  <Menu.Item key="103" onClick={() => navigate('/admin/user/list')}>
                    管理者帳號維護
                  </Menu.Item>
                )}
              </SubMenu>
            )}

            {/* 商品管理 */}
            {authList.indexOf('200') > -1 && (
              <Menu.Item key="200" icon={<GiftOutlined />} onClick={() => navigate('/admin/product/list')}>
                商品管理
              </Menu.Item>
            )}

            {/* 訂單查詢 */}
            {authList.indexOf('300') > -1 && (
              <Menu.Item key="300" icon={<FileTextOutlined />} onClick={() => navigate('/admin/order/list')}>
                訂單查詢
              </Menu.Item>
            )}

            {/* 統計報表 */}
            {authList.indexOf('400') > -1 && (
              <SubMenu key="400" icon={<BarChartOutlined />} title="統計報表">
                {authList.indexOf('401') > -1 && (
                  <Menu.Item key="401" onClick={() => navigate('/admin/report/case-stage')}>
                    案件處理
                  </Menu.Item>
                )}
                {authList.indexOf('402') > -1 && (
                  <Menu.Item key="402" onClick={() => navigate('/admin/report/case-pay-amount')}>
                    案件款項
                  </Menu.Item>
                )}
              </SubMenu>
            )}

            {/* Jet捷店 */}
            {authList.indexOf('500') > -1 && (
              <SubMenu key="500" icon={<ShopOutlined />} title="Jet捷店">
                {authList.indexOf('501') > -1 && (
                  <Menu.Item key="501" onClick={() => navigate('/admin/ecommerce/info')}>
                    商家資訊
                  </Menu.Item>
                )}
                {authList.indexOf('502') > -1 && (
                  <Menu.Item key="502" onClick={() => navigate('/admin/ecommerce/activity')}>
                    活動專區
                  </Menu.Item>
                )}
              </SubMenu>
            )}
          </Menu>
        )}
      </Col>
    </Row>
  );
};

export default Drawer;
