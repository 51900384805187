const INITIAL_STATE = {
  frontFAQList: [],
  frontFAQTypeList: [],
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_FRONT_FAQ":
      return {
        ...state,
        frontFAQList: action.data
      };
    case "GET_FRONT_FAQ_TYPE":
      return {
        ...state,
        frontFAQTypeList: action.data
      };
    default:
      return state;
  }
};

export default reducer;