import "./App.css";
import React from "react";
import Main from "./pages/Main";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import zhTW from "antd/lib/locale/zh_TW";

import ScrollToTop from "src/components/ScrollToTop";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop>
          <ConfigProvider locale={zhTW}>
            <Main/>
          </ConfigProvider>
        </ScrollToTop>
      </BrowserRouter>
    </div>
  );
}

export default App;
