import React from "react";
// import styles
import "swiper/css";
import "swiper/css/navigation";
// import img
import activityShareTitle2 from "src/assets/jetdai/activity-share/activity_share_title2.png";

// antd
import { Row, Col } from "antd";

const Section2 = () => {
  return (
    <Row className="frontActivityShareSection2" justify="center" style={{ marginTop: 10 }}>
      <Col className="d-flex justify-center" span={24} style={{ marginTop: 10 }}>
        <div>
          <img src={activityShareTitle2} alt="活動說明" className="activityShareTitle" />
        </div>
      </Col>
      <Col className="d-flex justify-center activityShareMain" span={24} style={{ marginTop: 10 }}>
        <div className="activityShareBox">
          <div className="activityShareBoxTitle">Step 1：登入會員中心，至好友推薦獎勵專區</div>
          <div className="activityShareBoxTitle">Step 2：複製『專屬申請連結』分享給好友</div>
          <div className="activityShareBoxTitle">Step 3：好友點擊專屬連結申請完成</div>
          <div className="activityShareBoxTitle">Step 4：好友申請案件經審核核准</div>
          <div className="activityShareBoxTitle">Step 5：進入好友推薦獎勵專區領取獎金</div>
        </div>
      </Col>
    </Row>
  );
}

export default Section2;
