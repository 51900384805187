import { order } from "./order";

// 取得案件狀態碼
const getAppCaseStage = (item) => {
  let _caseStage = order.appCaseStage.filter((el) => {
    if (1 <= item.appCaseStage && 7 >= item.appCaseStage) return Number(el.value) === 1;
    if (8 <= item.appCaseStage && 10 >= item.appCaseStage) return Number(el.value) === 8;
    return item.appCaseStage === Number(el.value);
  });
  
  return _caseStage[0] && _caseStage[0].name ? _caseStage[0].name : ''
};

export {
  getAppCaseStage,
};