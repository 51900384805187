import React from "react";

// antd
import { Row, Col } from "antd";

// component
import FrontDivCenter from "src/components/Front/Divider/DivCenter";

import icon01 from "src/assets/ecommerce/icon_01.png";
import icon02 from "src/assets/ecommerce/icon_02.png";
import icon03 from "src/assets/ecommerce/icon_03.png";

const boxes = [
  {
    title: "低門檻",
    icon: icon01,
    color: "red",
    content: "免程式串接，平台介面操作直覺，輕鬆就可上手。"
  },
  {
    title: "零成本",
    icon: icon02,
    color: "orange",
    content: "申請註冊加入即可免費享有Jet捷店服務，0元電商馬上用。"
  },
  {
    title: "高營收",
    icon: icon03,
    color: "blue",
    content: "線上線下整合模式，一頁式商店，下單快速又方便，見證業績飛耀成長。"
  },
];

const Section2 = () => {
  return (
    <Row justify="center" style={{ marginTop: 70 }}>
      <Col span={24}>
        <h2 className="front" style={{ margin: 0 }}>
          <p>任何地點、時間，</p>
          <p>都能享受完美的購物體驗</p>
        </h2>
        <FrontDivCenter />
      </Col>
      {boxes.map((item, idx) => (
        <Col className="d-flex justify-center" span={22} md={7} sm={7} key={idx} style={{ marginTop: 20 }}>
          <div className="linearBox">
            <img src={item.icon} alt={item.title} />
            <h3>
              <div className={`textBox ${item.color}`}>
                <div>
                  <p className="front">{item.title.slice(0, 1)}</p>
                </div>
                <div>
                  <p className="front">{item.title.slice(1, 2)}</p>
                </div>
                <div>
                  <p className="front">{item.title.slice(2, 3)}</p>
                </div>
              </div>
            </h3>
            <h3 style={{ fontSize: 20, margin: "10px 24px" }}>
              {item.subTitle}
            </h3>
            <h4 className="front" style={{ color: "#000", margin: "0 24px 24px" }}>
              {item.content}
            </h4>
          </div>
        </Col>
      ))}
    </Row>
  );
}

export default Section2;
