import React from "react";

// antd
import { Row, Col } from "antd";

// component
import FrontDivCenter from "src/components/Front/Divider/DivCenter";

const Section3 = () => {
  return (
    <>
      <Row justify="center" style={{ marginTop: 70, marginBottom: 70 }}>
        <Col span={24}>
          <h2 className="front" style={{ margin: 0 }}>
            商品分期申辦四大重點
          </h2>
          <FrontDivCenter />
          <a href="/installment/guide/faq" target="_blank" rel="noreferrer">
            <button className="frontBtn" style={{ width: 165, marginTop: 10 }}>
              {"商品分期懶人包 >>"}
            </button>
          </a>
        </Col>
        <div className="d-flex justify-center" style={{ margin: '50px 0 30px', flexWrap: "wrap" }}>
          <div className="diamond red">
            <h4 className="front">
            申辦商品分期需滿18歲
            </h4>
          </div>
          <div className="diamond orange">
            <h4 className="front">
              申辦商品分期必需【真實身份文件】、【具還款能力】
            </h4>
          </div>
          <div className="diamond green">
            <h4 className="front">
              商品分期不必【一次全額支付】，【享受分期付款】
            </h4>
          </div>
          <div className="diamond blue">
            <h4 className="front">
              商品分期年利率【「約定利率」不得超過法定16%】
            </h4>
          </div>
        </div>
      </Row>
    </>
  );
}

export default Section3;
