const funcIndexList = [
  { merFunNo: 100, merFunName: "商家管理" },
  { merFunNo: 101, merFunName: "基本資料維護" },
  { merFunNo: 102, merFunName: "消費者登入認證時效" },
  { merFunNo: 103, merFunName: "管理者帳號維護" },
  { merFunNo: 200, merFunName: "商品管理" },
  { merFunNo: 300, merFunName: "訂單管理" },
  { merFunNo: 400, merFunName: "統計報表" },
  { merFunNo: 401, merFunName: "案件處理" },
  { merFunNo: 402, merFunName: "案件款項" },
  { merFunNo: 500, merFunName: "Jet捷店" },
  { merFunNo: 501, merFunName: "商家資訊" },
  { merFunNo: 502, merFunName: "活動專區" },
];

export const code = {
  funcIndexList,
};