import React from "react";
import ProductDetail from "src/components/Front/Jet/ProductDetail";

import "../Jet/index.css";

const ProdDynamic = () => {
	return (
		<ProductDetail isDynamic={false} />
	);
}

export default ProdDynamic;
