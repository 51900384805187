import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { actionCreators } from "src/actions/FAQ";

// ant design
import { Modal, Row, Col } from "antd";

const BannerImg = (props) => {
    const [FAQAnswer, setFAQAnswer] = useState("");

    const dispatch = useDispatch();

    // 取出 store 中的 state
    const isVisible = useSelector(state => state.FAQ.modalAnswerVisible);

    // 配值
    useEffect(() => {
        setFAQAnswer(props.answer);
    }, [isVisible]);

    // 取消
    const handleCancel = () => {
        dispatch(actionCreators.modalViewAnswerVisible());
    };

    return (
        <Modal 
          title="解答" 
          visible={isVisible}
          onCancel={handleCancel} 
          destroyOnClose
          footer={false}
          width={500}
        >
            <div
                dangerouslySetInnerHTML={{__html: FAQAnswer}}
                style={{padding: 20}}
            />
        </Modal>
    );
}

export default BannerImg;