import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { actionCreators } from 'src/actions/merchant';

import Login from 'src/pages/Login';
import Home from './pages/Home';
import Profile from './pages/Profile';
import Merchant from './pages/Merchant';
// import VerifyCode from './pages/Merchant/VerifyCode';
import UserList from './pages/Merchant/UserList';
import UserInfo from './pages/Merchant/UserInfo';
import ProductList from './pages/Product/ProductList';
import ProductInfo from './pages/Product/ProductInfo';
import Order from './pages/Order';
import OrderDetail from './pages/Order/OrderDetail';
import Document from './pages/Order/Document';
import ReportCaseStage from './pages/Report/caseStage';
import ReportCasePayAmount from './pages/Report/casePayAmount';
import JetInfo from './pages/Jet/JetInfo';
import JetActivity from './pages/Jet/JetActivity';
import JetActivityEditList from './pages/Jet/EditList';
import JetPreviewPage from './pages/Jet/PreviewPage';

import RootLogin from './pages/Root/Login';
import RootHome from './pages/Root/Home';
import RootApplyType from './pages/Root/ApplyType';
import RootProductType from './pages/Root/ProductType';
import RootProductItem from './pages/Root/ProductItem';
import RootBanner from './pages/Root/Banner';
import RootInstallment from './pages/Root/Installment';
import RootFAQ from './pages/Root/FAQ';

import FrontHome from './pages/Front/Home';
import FrontInsGuide from './pages/Front/Guide';
import FrontMerchantGuide from './pages/Front/Guide/Merchant';
import FrontEcommerce from './pages/Front/Guide/Ecommerce';
import FrontInsActivity from './pages/Front/Guide/Activity';
import FrontInsFAQ from './pages/Front/Guide/FAQ';
import FrontJetDai from './pages/Front/JetDai';
import FrontJetDaiActivity from './pages/Front/JetDai/Activity';
import FrontJetDaiFAQ from './pages/Front/JetDai/FAQ';
import FrontJetDaiApplyStep from './pages/Front/JetDai/ApplyStep';
import FrontJetActivityShare from './pages/Front/JetDai/ActivityShare';
import FrontFAQ from './pages/Front/FAQ';
import FrontAbout from './pages/Front/About';
import FrontMerchantApply from './pages/Front/Merchant';
import FrontPaymentTerms from './pages/Front/Terms/payment';
import FrontPrivacyTerms from './pages/Front/Terms/privacy';
import FrontJet from './pages/Front/Jet';
import ProdDynamic from './pages/Front/Product/ProdDynamic';
import ProdStatic from './pages/Front/Product/ProdStatic';

const AdminRequireAuth = ({ children }) => {
  const dispatch = useDispatch();
  
  useEffect(() => {
    // 取得個人帳號資訊
    dispatch(actionCreators.getPersonalInfo());
  }, []);

  const isLogin = useSelector((state) => state.auth.isLogined);
  const location = useLocation();

  if (isLogin && location.pathname !== '/admin/login') {
    return children;
  } else {
    // 未登入
    window.location.href = '/admin/login';
    return children;
  }
};

const RootRequireAuth = ({ children }) => {
  const isRootLogin = useSelector((state) => state.auth.isRootLogined);

  if (isRootLogin) {
    return children;
  } else {
    // 未登入
    window.location.href = '/root/login';
  }
};

const AdminLogin = ({ children }) => {
  const dispatch = useDispatch();
  
  useEffect(() => {
    // 取得個人帳號資訊
    dispatch(actionCreators.getPersonalInfo());
  }, []);

  const isLogin = useSelector((state) => state.auth.isLogined);

  if (isLogin) {
    // 已是登入狀態，自動導首頁
    window.location.href = '/admin';
  } else {
    return children;
  }
};

const routes = (
  <Routes>
    {/* 後台-登入 */}
    <Route
      path="/admin/login"
      element={
        <AdminLogin>
          <Login />
        </AdminLogin>
      }
    />

    {/* 後台-首頁 */}
    <Route
      path="/admin"
      element={
        <AdminRequireAuth>
          <Home />
        </AdminRequireAuth>
      }
    />

    {/* 後台-個人資料維護 */}
    <Route
      path="/admin/user/user-edit"
      element={
        <AdminRequireAuth>
          <Profile />
        </AdminRequireAuth>
      }
    />

    {/* 後台-商家管理 */}
    <Route
      key="101"
      path="/admin/store/edit"
      element={
        <AdminRequireAuth>
          <Merchant />
        </AdminRequireAuth>
      }
    />
    {/* <Route
      key="102"
      path="/admin/store/verify-code-edit"
      element={
        <AdminRequireAuth>
          <VerifyCode />
        </AdminRequireAuth>
      }
    /> */}
    <Route
      key="103"
      path="/admin/user/list"
      element={
        <AdminRequireAuth>
          <UserList />
        </AdminRequireAuth>
      }
    />
    <Route
      key="103"
      path="/admin/user/list/edit"
      element={
        <AdminRequireAuth>
          <UserInfo />
        </AdminRequireAuth>
      }
    />

    {/* 後台-商品管理 */}
    <Route
      key="200"
      path="/admin/product/list"
      element={
        <AdminRequireAuth>
          <ProductList />
        </AdminRequireAuth>
      }
    />
    <Route
      key="200"
      path="/admin/product/list/edit"
      element={
        <AdminRequireAuth>
          <ProductInfo />
        </AdminRequireAuth>
      }
    />

    {/* 後台-訂單管理 */}
    <Route
      key="300"
      path="/admin/order/list"
      element={
        <AdminRequireAuth>
          <Order />
        </AdminRequireAuth>
      }
    />
    <Route
      key="300"
      path="/admin/order/detail"
      element={
        <AdminRequireAuth>
          <OrderDetail />
        </AdminRequireAuth>
      }
    />
    <Route
      key="300"
      path="/admin/order/document"
      element={
        <AdminRequireAuth>
          <Document />
        </AdminRequireAuth>
      }
    />

    {/* 後台-統計報表 */}
    <Route
      key="400"
      path="/admin/report/case-stage"
      element={
        <AdminRequireAuth>
          {' '}
          <ReportCaseStage />
        </AdminRequireAuth>
      }
    />
    <Route
      key="400"
      path="/admin/report/case-pay-amount"
      element={
        <AdminRequireAuth>
          <ReportCasePayAmount />
        </AdminRequireAuth>
      }
    />

    {/* 後台-Jet捷店 */}
    <Route
      key="501"
      path="/admin/ecommerce/info"
      element={
        <AdminRequireAuth>
          <JetInfo />
        </AdminRequireAuth>
      }
    />
    <Route
      key="502"
      path="/admin/ecommerce/activity"
      element={
        <AdminRequireAuth>
          <JetActivity />
        </AdminRequireAuth>
      }
    />
    <Route
      key="502"
      path="/admin/ecommerce/activity/edit-list"
      element={
        <AdminRequireAuth>
          <JetActivityEditList />
        </AdminRequireAuth>
      }
    />
    <Route
      path="/admin/ecommerce/preview"
      element={
        <AdminRequireAuth>
          <JetPreviewPage />
        </AdminRequireAuth>
      }
    />

    {/* 維護系統 */}
    {/* 登入 */}
    <Route path="/root/login" element={<RootLogin />} />

    {/* 首頁 */}
    <Route
      path="/root"
      element={
        <RootRequireAuth>
          <RootHome />
        </RootRequireAuth>
      }
    />

    {/* 案件類型設定 */}
    <Route
      path="/root/apply-type/list"
      element={
        <RootRequireAuth>
          <RootApplyType />
        </RootRequireAuth>
      }
    />

    {/* 商品類型設定 */}
    <Route
      path="/root/product-type/list"
      element={
        <RootRequireAuth>
          <RootProductType />
        </RootRequireAuth>
      }
    />

    <Route
      path="/root/product-item/list"
      element={
        <RootRequireAuth>
          <RootProductItem />
        </RootRequireAuth>
      }
    />

    {/* 廣告資料設定 */}
    <Route
      path="/root/banner/list"
      element={
        <RootRequireAuth>
          <RootBanner />
        </RootRequireAuth>
      }
    />

    {/* 分期說明設定 */}
    <Route
      path="/root/installment/list"
      element={
        <RootRequireAuth>
          <RootInstallment />
        </RootRequireAuth>
      }
    />

    {/* FAQ設定 */}
    <Route
      path="/root/FAQ/list"
      element={
        <RootRequireAuth>
          <RootFAQ />
        </RootRequireAuth>
      }
    />

    {/* 前台官網 */}
    {/* 首頁 */}
    <Route
      path="/"
      element={
        <FrontHome />
      }
    />
    {/* 商品分期-我是消費者 */}
    <Route
      path="/installment/guide"
      element={
        <FrontInsGuide />
      }
    />
    {/* 商品分期-我是商家-合作服務介紹 */}
    <Route
      path="/installment/merchant/guide"
      element={
        <FrontMerchantGuide />
      }
    />
    {/* 商品分期-我是商家-Jet捷店 */}
    <Route
      path="/ecommerce"
      element={
        <FrontEcommerce />
      }
    />
    {/* 商品分期-活動介紹 */}
    <Route
      path="/installment/activity"
      element={
        <FrontInsActivity />
      }
    />
    {/* 商品分期懶人包 */}
    <Route
      path="/installment/guide/faq"
      element={
        <FrontInsFAQ />
      }
    />
    {/* Jet貸 */}
    <Route
      path="/about/jetdai"
      element={
        <FrontJetDai />
      }
    />
    {/* Jet貸-活動介紹 */}
    <Route
      path="/about/jetdai/activity"
      element={
        <FrontJetDaiActivity />
      }
    />
    {/* 商品貸懶人包 */}
    <Route
      path="/about/jetdai/faq"
      element={
        <FrontJetDaiFAQ />
      }
    />
    {/* 申辦說明 */}
    <Route
      path="/about/jetdai/apply-step"
      element={
        <FrontJetDaiApplyStep />
      }
    />
    {/* 推薦獎勵活動介紹 */}
    <Route
      path="/about/jetdai/activity-share"
      element={
        <FrontJetActivityShare />
      }
    />
    {/* 常見問題 */}
    <Route
      path="/about/faq"
      element={
        <FrontFAQ />
      }
    />
    {/* 關於我們 */}
    <Route
      path="/about/us"
      element={
        <FrontAbout />
      }
    />
    {/* 商家註冊 */}
    <Route
      path="/merchant/apply"
      element={
        <FrontMerchantApply />
      }
    />
    {/* 支付使用條款 */}
    <Route
      path="/about/terms/payment"
      element={
        <FrontPaymentTerms />
      }
    />
    {/* 隱私權政策 */}
    <Route
      path="/about/terms/privacy"
      element={
        <FrontPrivacyTerms />
      }
    />
    {/* Jet捷店商城 */}
    <Route
      path="/:shopRouteName"
      element={
        <FrontJet />
      }
    />
    {/* 商品詳細頁 */}
    <Route
      path="/:shopRouteName/product/detail/:prodNo"
      element={
        <ProdDynamic />
      }
    />
    <Route
      path="/product/detail/:prodNo"
      element={
        <ProdStatic />
      }
    />
  </Routes>
);
export default routes;
