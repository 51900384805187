import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { actionCreators } from "src/actions/front-jet";

// antd
import { Row, Col, Menu, Card, Input, Select, Tag, Pagination, Button, Dropdown } from "antd";
import { SearchOutlined, FilterOutlined } from '@ant-design/icons';

import noProduct from "src/assets/no_product.jpg";

const ProductList = (props) => {
  const { shopRouteName } = useParams();

  const { SubMenu } = Menu;

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isSmallSize, setIsSmallSize] = useState(windowWidth < 768);
  const [products, setProducts] = useState({});
  const [prodTypeNo, setProdTypeNo] = useState("101");
  const [keyword, setKeyword] = useState(null);
  const [sortData, setSortData] = useState(null);
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);
    onWindowResize();
  
    return () => window.removeEventListener("resize", onWindowResize);
  }, [windowWidth]);

  useEffect(() => {
    setProducts(props.shopProducts);
  }, [props]);

  // 排序改變時的 dispatch
  useEffect(() => {
    dispatchUpdate(1);
  }, [sortData]);

  // 分類的 dispatch
  useEffect(() => {
    dispatchUpdate(1);
  }, [prodTypeNo]);

  const onWindowResize = () => {
    setWindowWidth(window.innerWidth);
    setIsSmallSize(window.innerWidth < 768);
  };

  const dispatchUpdate = (pg) => {
    if (!props.isAdmin) {
      dispatch(actionCreators.getFrontShopProducts(props.routeName, prodTypeNo === "101" ? null : prodTypeNo, keyword, pg, sortData));
    }
  };

  const handleProdType = (event) => {
    if (event.key === "101") setProdTypeNo("101");
    else setProdTypeNo(event.key);
    setPage(1);
  };

  const onChangeKeyword = (event) => {
    setKeyword(event.target.value);
  };

  // 搜尋關鍵字的 dispatch
  const handleSearch = () => {
    setPage(1);
    dispatchUpdate(1);
  };

  const onChangeSort = (event) => {
    if (event === 0) {
      setSortData("prodCreateDate:DESC");
    } else if (event === 1) {
      setSortData("prodLowestMonthlyPayment:ASC");
    } else if (event === 2) {
      setSortData("prodLowestMonthlyPayment:DESC");
    }
    setPage(1);
  };

  // 切換頁面的 dispatch
  const onChangePage = (current) => {
    setPage(current);
    dispatchUpdate(current);
  };

  const menu = (
    <Menu className="jet-dropdown" selectable selectedKeys={[prodTypeNo]} onClick={handleProdType}>
      <Menu.ItemGroup title="商品分類">
        <Menu.Item key="101">
          全部
        </Menu.Item>
        {props.shopProdType && props.shopProdType.map(item => (
          <Menu.Item key={item.prodTypeNo}>
            {item.prodTypeDes}
          </Menu.Item>
        ))}
      </Menu.ItemGroup>
    </Menu>
  );

  return (
    <Row align="center">
      {!isSmallSize && <Col className="jet-category-menu" span={23} lg={5} md={7}>
        <Menu
          mode="inline"
          theme="dark"
          defaultOpenKeys={["100"]}
          openKeys={props && props.isAdmin && ["100"]}
          selectedKeys={[prodTypeNo]}
          onClick={handleProdType}
        >
          <SubMenu key="100" title="商品分類">
            <Menu.Item key="101">
              全部
            </Menu.Item>
            {props.shopProdType && props.shopProdType.map(item => (
              <Menu.Item key={item.prodTypeNo} disabled={props.isAdmin}>
                {item.prodTypeDes}
              </Menu.Item>
            ))}
          </SubMenu>
        </Menu>
      </Col>}
      <Col span={23} lg={18} md={16}>
        <Row className="jet-prod-search">
          <Col span={23} lg={14} md={12} sm={12} xs={23}>
            <Input 
              placeholder="搜尋" 
              allowClear
              suffix={<Button 
                        shape="circle" 
                        type="text" 
                        size="small" 
                        icon={<SearchOutlined />}
                        onClick={handleSearch}
                      />} 
              disabled={props && props.isAdmin}
              onChange={onChangeKeyword}
            />
          </Col>
          <Col span={23} lg={8} md={8} sm={8} xs={23}>
            {isSmallSize && 
              <Dropdown overlay={menu} trigger={["click"]} disabled={props && props.isAdmin}>
                <Button 
                  className={props && props.isAdmin ? "jet-btn-disabled" : "jet-btn"}
                  shape="round"
                  icon={<FilterOutlined />}
                  style={{ margin: "10px 10px 0 0" }}
                >
                  篩選
                </Button>
              </Dropdown>
            }
            <Select defaultValue={0} disabled={props && props.isAdmin} onChange={onChangeSort}>
              <Select.Option key={0} value={0}>最新上架</Select.Option>
              <Select.Option key={1} value={1}>價格由低到高</Select.Option>
              <Select.Option key={2} value={2}>價格由高到低</Select.Option>
            </Select>
          </Col>
        </Row>
        <Row>
          {products.data && products.data.map(item => (
            <Col className="jet-prod-list-col" key={item.prodNo} span={24} lg={8} md={12} sm={12}>
              <Card bordered={false}>
                <a 
                  onClick={e => props.isAdmin ? e.preventDefault() : null} 
                  href={`/${shopRouteName}/product/detail/${item.prodNo}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {item.prodCoverPhotoURL &&
                    <img src={item.prodCoverPhotoURL} alt={item.prodName} />
                  }
                  {!item.prodCoverPhotoURL &&
                    <img src={noProduct} alt={item.prodName} />
                  }
                  <div className="jet-h-scroll jet-prod-tag">
                    {item.shopActivityTags && item.shopActivityTags.split(",").map((tag, idx) => (
                      <Tag className="front" key={idx} color="red">{tag}</Tag>
                    ))}
                  </div>
                  <div className="jet-prod-info">
                    {item.prodBrandData &&
                      <p className="title">{`[${item.prodBrandData.prodBrandDes}] ${item.prodName}`}</p>
                    }
                    {!item.prodBrandData &&
                      <p className="title">{`${item.prodName}`}</p>
                    }
                  </div>
                  <div className="jet-card-footer">
                    <p style={{ color: "#000" }}>最低月付 <span>NT ${item.prodLowestMonthlyPayment}</span></p>
                  </div>
                </a>
              </Card>
            </Col>
          ))
          }
        </Row>
        <Row justify="center" style={{ marginTop: 20 }}>
          <Pagination 
            className="jet-pagination" 
            current={page} 
            onChange={onChangePage} 
            pageSize={6} 
            total={products.totalItems}
            disabled={props && props.isAdmin}
          />
        </Row>
      </Col>
    </Row>
  );
}

export default ProductList;
