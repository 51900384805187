import Cookies from 'universal-cookie';
import { ADMIN_API_URL as api_admin_url } from 'src/config';

const cookies = new Cookies();
const reqOptions = {
  get: {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  },
  patch: {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
  },
};

// 取得此登入帳號所有分(總)店資訊
const getBranchesData = async () => {
  return fetch(
    `${api_admin_url}/merchant/basic?access_token=${cookies.get('_merchant_auth_token')}`,
    reqOptions.get
  );
};

// 取得登入帳號商家資訊
const getBranchData = async (merAcctId) => {
  return fetch(
    `${api_admin_url}/merchant/basic/${merAcctId}?access_token=${cookies.get('_merchant_auth_token')}`,
    reqOptions.get
  );
};

// 修改商家資訊
const editBranchData = async (merAcctId, payload) => {
  return fetch(
    `${api_admin_url}/merchant/basic/${merAcctId}?access_token=${cookies.get('_merchant_auth_token')}`,
    {
      ...reqOptions.patch,
      body: JSON.stringify(payload)
    }
  );
};

// 取得個人帳號資訊
const getPersonalInfo = async () => {
  return fetch(
    `${api_admin_url}/personalInfo?access_token=${cookies.get("_merchant_auth_token")}`,
    reqOptions.get
  );
};

// 會員資料維護
const updatePersonalInfo = async (payload) => {
  return fetch(
    `${api_admin_url}/personalInfo?access_token=${cookies.get("_merchant_auth_token")}`,
    {
      ...reqOptions.patch,
      body: JSON.stringify(payload)
    }
  );
};

// 取得銷售產品類型列表
const getProdTypeList = async () => {
  return fetch(
    `${api_admin_url}/merchant/applyType?access_token=${cookies.get('_merchant_auth_token')}`,
    reqOptions.get
  );
};

export const merchantService = {
  getBranchesData,
  getBranchData,
  editBranchData,
  getPersonalInfo,
  updatePersonalInfo,
  getProdTypeList,
};
