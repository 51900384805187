import React from "react";

// antd
import { Row, Col } from "antd";

// component
import FrontDivCenter from "src/components/Front/Divider/DivCenter";

import icon01 from "src/assets/merchant-guide/icon_01.png";
import icon02 from "src/assets/merchant-guide/icon_02.png";
import icon03 from "src/assets/merchant-guide/icon_03.png";

const boxes = [
  {
    title: "先買後付",
    icon: icon01,
    color: "red",
    subTitle: "交易便利，帶動業績UP UP！",
    content: "創新支付方式，加入JetShop跟上金流趨勢，走在競爭對手前頭，輕鬆創造更多商機。"
  },
  {
    title: "免設定費",
    icon: icon02,
    color: "orange",
    subTitle: "加入JetShop零負擔！",
    content: "Jetshop提供免費的架設商城服務，且不需收取系統管理費用，僅成交時收取小額手續費，為您節省固定成本。"
  },
  {
    title: "專人審核",
    icon: icon03,
    color: "blue",
    subTitle: "為您打造專屬設定！",
    content: "24小時線上收件，進件後即安排專人審核，流程快速且客製化，依照您的需求彈性調整。"
  },
];

const Section2 = () => {
  return (
    <Row justify="center" style={{ marginTop: 70 }}>
      <Col span={24}>
        <h2 className="front" style={{ margin: 0 }}>
          <p>JetShop 神隊友，</p>
          <p>助您再創業績高峰</p>
        </h2>
        <FrontDivCenter />
      </Col>
      {boxes.map((item, idx) => (
        <Col className="d-flex justify-center" span={22} md={7} sm={7} key={idx} style={{ marginTop: 20 }}>
          <div className="linearBox">
            <img src={item.icon} alt={item.title} />
            <h3>
              <div className={`textBox ${item.color}`}>
                <div>
                  <p className="front">{item.title.slice(0, 1)}</p>
                </div>
                <div>
                  <p className="front">{item.title.slice(1, 2)}</p>
                </div>
                <div>
                  <p className="front">{item.title.slice(2, 3)}</p>
                </div>
                <div>
                  <p className="front">{item.title.slice(3)}</p>
                </div>
              </div>
            </h3>
            <h3 style={{ fontSize: 20, margin: "10px 24px" }}>
              {item.subTitle}
            </h3>
            <h4 className="front" style={{ margin: "0 24px 50px" }}>
              {item.content}
            </h4>

            <div className="frontLink">
              <a href="/ecommerce">{"more >>"}</a>
            </div>
          </div>
        </Col>
      ))}
    </Row>
  );
}

export default Section2;
