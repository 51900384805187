import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { actionCreators } from 'src/actions/order';
import moment from 'moment';
import { order } from 'src/utils/order';
import { getAppCaseStage } from 'src/utils/useOrder';

import * as A from 'antd';
import { ClockCircleOutlined, SearchOutlined, RedoOutlined } from '@ant-design/icons';
import './Order.css';

const Order = () => {
  const [rowData, setRowData] = useState([]);
  const [quickSearch, setQuickSearch] = useState('1');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [appCaseStage, setAppCaseStage] = useState(null);
  const [appCurrPayTag, setAppCurrPayTag] = useState(null);
  // search
  const searchInput = useRef(null);
  const [, setSearchText] = useState('');
  const [, setSearchedColumn] = useState('');

  const currentDate = moment().format('YYYY-MM-DD');
  const sevenDaysAgo = moment()
    .subtract('6', 'days')
    .format('YYYY-MM-DD');
  const oneMonthAgo = moment()
    .subtract('1', 'months')
    .add('1', 'day')
    .format('YYYY-MM-DD');
  const threeMonthsAgo = moment()
    .subtract('3', 'months')
    .add('1', 'day')
    .format('YYYY-MM-DD');

  const dispatch = useDispatch();

  // 搜尋功能
  const getColumnSearchProps = (dataIndex, text) => {
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <A.Input ref={searchInput} placeholder={`搜尋${text}`} value={selectedKeys[0]} onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])} onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)} style={{ width: 188, marginBottom: 8, display: 'block' }} />
          <A.Space>
            <A.Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} size="small" style={{ width: 90 }}>
              確定
            </A.Button>
            <A.Button onClick={() => handleSearchReset(clearFilters)} size="small" style={{ width: 90 }}>
              重設
            </A.Button>
          </A.Space>
        </div>
      ),
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (val, record) =>
        record[dataIndex] !== null
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(val.toLowerCase())
          : '',
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current.select());
        }
      },
      render: (text) => text,
    };
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleSearchReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const dispatchFunc = (startDate, endDate, appCaseStage, appCurrPayTag) => {
    dispatch(actionCreators.getOrderList({ startDate, endDate, appCaseStage, appCurrPayTag }));
  };

  // 取出 store 中的 state
  const orderList = useSelector((state) => state.order.orderList);

  useEffect(() => {
    // action : 取得商家訂單
    dispatchFunc(oneMonthAgo, currentDate, '', '');
  }, [dispatch]);

  //配值
  useEffect(() => {
    let data = [];
    if (orderList && orderList.rows) {
      orderList.rows.forEach((item) => {
        data.push({
          key: item.appSerNo,
          appCaseNo: item.appCaseNo,
          memName: item.memName,
          ordProdName: item.apo.ordProdName,
          merAcctId: item.merAcctId,
          merName: item.apo.merName ? item.apo.merName : '',
          ordApplyDate: moment(item.apo.ordApplyDate).format('YYYY/MM/DD'),
          ordProdTotalAmt: item.apo.ordProdTotalAmt,
          ordMerPayAmt: item.apo.ordMerPayAmt,
          appBaseAmt: item.appBaseAmt,
          ordProdPriceAmt: item.ordProdPriceAmt,
          ordProdDownPayment: item.ordProdDownPayment,
          appCaseStage: getAppCaseStage(item),
          appCurrPayTag: item.cps !== null ? item.cps.appCurrPayTag : '',
        });
      });
    }
    setRowData(data);
  }, [orderList]);

  // 欄位設定
  const columns = [
    {
      title: '訂單編號',
      dataIndex: 'appCaseNo',
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('appCaseNo', '訂單編號'),
      sorter: (a, b) => {
        a = a.appCaseNo || '';
        b = b.appCaseNo || '';
        return a - b;
      },
      render: (text) => {
        return <Link to={'/admin/order/detail?appCaseNo=' + text}>{text}</Link>;
      },
    },
    {
      title: '取貨文件',
      dataIndex: "document",
      render: (_, col) => {
        let isDisabled = false;
        const obj = order.appCaseStage.filter(item => item.name === col.appCaseStage);
        if (obj[0] && obj[0].value) {
          if (Number(obj[0].value) < 13 || Number(obj[0].value) > 15) isDisabled = true;
        }
        return (
          <a 
            href={`/admin/order/document?appSerNo=${col.appCaseNo}`} 
            target="_blank" rel="noreferrer"
          >
            <A.Button disabled={isDisabled}>查看</A.Button>
          </a>
        );
      },
    },
    {
      title: '申請人',
      dataIndex: 'memName',
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('memName', '申請人'),
      sorter: (a, b) => {
        a = a.memName || '';
        b = b.memName || '';
        return a.localeCompare(b);
      },
    },
    {
      title: '商品名稱',
      dataIndex: 'ordProdName',
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('ordProdName', '商品名稱'),
      sorter: (a, b) => {
        a = a.ordProdName || '';
        b = b.ordProdName || '';
        return a.localeCompare(b);
      },
    },
    {
      title: '商家代碼',
      dataIndex: 'merAcctId',
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('merAcctId', '商家代碼'),
      sorter: (a, b) => {
        a = a.merAcctId || '';
        b = b.merAcctId || '';
        return a.localeCompare(b);
      },
    },
    {
      title: '分店名稱',
      dataIndex: 'merName',
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('merName', '分店名稱'),
      sorter: (a, b) => {
        a = a.merName || '';
        b = b.merName || '';
        return a.localeCompare(b);
      },
    },
    {
      title: '訂單日期',
      dataIndex: 'ordApplyDate',
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'descend',
      ...getColumnSearchProps('ordApplyDate', '訂單日期'),
      sorter: (a, b) => {
        a = a.ordApplyDate || '';
        b = b.ordApplyDate || '';
        return a.localeCompare(b);
      },
    },
    {
      title: '分期總價',
      dataIndex: 'ordProdTotalAmt',
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('ordProdTotalAmt', '分期總價'),
      sorter: (a, b) => {
        a = a.ordProdTotalAmt || '';
        b = b.ordProdTotalAmt || '';
        return a - b;
      },
    },
    {
      title: '撥款金額',
      dataIndex: 'ordMerPayAmt',
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('ordMerPayAmt', '撥款金額'),
      sorter: (a, b) => {
        a = a.ordMerPayAmt || '';
        b = b.ordMerPayAmt || '';
        return a - b;
      },
    },
    {
      title: '分期基準價',
      dataIndex: 'appBaseAmt',
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('appBaseAmt', '分期基準價'),
      sorter: (a, b) => {
        a = a.appBaseAmt || '';
        b = b.appBaseAmt || '';
        return a - b;
      },
    },
    {
      title: '建議售價',
      dataIndex: 'ordProdPriceAmt',
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('ordProdPriceAmt', '建議售價'),
      sorter: (a, b) => {
        a = a.ordProdPriceAmt || '';
        b = b.ordProdPriceAmt || '';
        return a - b;
      },
    },
    {
      title: '頭期款',
      dataIndex: 'ordProdDownPayment',
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('ordProdDownPayment', '頭期款'),
      sorter: (a, b) => {
        a = a.ordProdDownPayment || '';
        b = b.ordProdDownPayment || '';
        return a - b;
      },
    },
    {
      title: '狀態',
      dataIndex: 'appCaseStage',
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('appCaseStage', '狀態'),
      sorter: (a, b) => {
        a = a.appCaseStage || '';
        b = b.appCaseStage || '';
        return a.localeCompare(b);
      },
    },
    {
      title: '訂單狀態',
      dataIndex: 'appCurrPayTag',
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('appCurrPayTag', '訂單狀態'),
      sorter: (a, b) => {
        a = a.appCurrPayTag || '';
        b = b.appCurrPayTag || '';
        return a.localeCompare(b);
      },
    },
  ];

  // 快速查詢功能
  const onChangeQuickSearch = (e) => {
    const val = e.target.value;
    setQuickSearch(val);
    setStartDate(null);
    setEndDate(null);
    setAppCaseStage(null);
    setAppCurrPayTag(null);
    if (val === '0') {
      dispatchFunc(sevenDaysAgo, currentDate, '', '');
    } else if (val === '1') {
      dispatchFunc(oneMonthAgo, currentDate, '', '');
    } else if (val === '2') {
      dispatchFunc(threeMonthsAgo, currentDate, '', '');
    }
  };

  // 自訂查詢功能
  const search = () => {
    setQuickSearch(null);
    let param1 = startDate ? startDate : oneMonthAgo;
    let param2 = endDate ? endDate : currentDate;
    let param3 = '';
    if (appCaseStage === '1') param3 = '1,2,3,4,5,6,7';
    else if (appCaseStage === '8') param3 = '8,9,10';
    else param3 = appCaseStage ? appCaseStage : '';
    let param4 = appCurrPayTag ? appCurrPayTag : '';
    dispatchFunc(param1, param2, param3, param4);
  };

  // 重設功能
  const reset = () => {
    setQuickSearch('1');
    setStartDate(null);
    setEndDate(null);
    setAppCaseStage(null);
    setAppCurrPayTag(null);
    dispatchFunc(oneMonthAgo, currentDate, '', '');
  };

  return (
    <A.Layout className="merLayout">
      <A.Row justify="center">
        <A.Col span={23}>
          <div className="title">訂單查詢</div>

          <A.Card className="merLayoutCard orderListCard">
            {/* 篩選區塊 */}
            <div className="orderSearch">
              <A.Row>
                <A.Col flex="90px">
                  <span>快速查詢</span>
                </A.Col>
                <A.Col flex={1}>
                  <A.Radio.Group buttonStyle="solid" value={quickSearch} onChange={onChangeQuickSearch}>
                    <A.Radio.Button value="0">
                      <ClockCircleOutlined /> 近一週
                    </A.Radio.Button>
                    <A.Radio.Button value="1">
                      <ClockCircleOutlined /> 近一個月
                    </A.Radio.Button>
                    <A.Radio.Button value="2">
                      <ClockCircleOutlined /> 近三個月
                    </A.Radio.Button>
                  </A.Radio.Group>
                </A.Col>
              </A.Row>
              <A.Row style={{ marginTop: '15px' }}>
                <A.Col flex="90px">
                  <span>自訂查詢</span>
                </A.Col>
                <A.Col flex={1}>
                  <A.Space size={[10, 5]} wrap>
                    <A.DatePicker.RangePicker
                      placeholder={['申請開始日', '申請結束日']}
                      value={startDate === null || endDate === null || startDate === '' || endDate === '' ? null : [moment(startDate, 'YYYY-MM-DD'), moment(endDate, 'YYYY-MM-DD')]}
                      onChange={(moment, dateArr) => {
                        setStartDate(dateArr[0]);
                        setEndDate(dateArr[1]);
                      }}
                    />
                    <A.Select placeholder="選擇案件狀態" allowClear style={{ width: '200px' }} value={appCaseStage} onChange={(value) => setAppCaseStage(value)}>
                      {order.appCaseStage.map((item) => (
                        <A.Select.Option value={item.value} key={item.key}>
                          {item.name}
                        </A.Select.Option>
                      ))}
                    </A.Select>
                    <A.Select placeholder="選擇訂單狀態" allowClear style={{ width: '200px' }} value={appCurrPayTag} onChange={(value) => setAppCurrPayTag(value)}>
                      {order.appCurrPayTag.map((item) => (
                        <A.Select.Option value={item.value} key={item.key}>
                          {item.name}
                        </A.Select.Option>
                      ))}
                    </A.Select>
                    <A.Tooltip placement="bottom" title="搜尋">
                      <A.Button type="primary" icon={<SearchOutlined />} onClick={search} />
                    </A.Tooltip>
                    <A.Tooltip placement="bottom" title="重設">
                      <A.Button icon={<RedoOutlined />} style={{ background: '#8492A6', borderColor: '#8492A6', color: 'white' }} onClick={reset} />
                    </A.Tooltip>
                  </A.Space>
                </A.Col>
              </A.Row>
            </div>

            {/* 查詢結果 */}
            <div>
              <A.Row className="title" style={{ marginTop: '20px', color: '#F7BA2A' }}>
                查詢結果
              </A.Row>
              <A.Row style={{ marginTop: '20px' }}>
                <A.Table columns={columns} dataSource={rowData} locale={{ emptyText: <A.Empty image={A.Empty.PRESENTED_IMAGE_SIMPLE} description={<span>無資料</span>} /> }} scroll={{ x: 1750, y: 300 }} />
              </A.Row>
            </div>
          </A.Card>
        </A.Col>
      </A.Row>
    </A.Layout>
  );
};

export default Order;
