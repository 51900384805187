import React from "react";
import { Link } from "react-router-dom";
import { actionCreators } from "src/actions/auth";
import { useDispatch } from "react-redux";
import Cookies from "universal-cookie";

import { Row, Col, Dropdown, Menu, Button } from "antd";
import { DownOutlined } from "@ant-design/icons";

import "src/components/Header/Header.css";

const cookies = new Cookies();

const RootHeader = () => {
    const dispatch = useDispatch();

    const handleMenuClick = (e) => {
        if (e.key === "logout") {
            cookies.remove("_root_auth_token");
            dispatch(actionCreators.rootLogout());
        };
    };

    const menu = (
        <Menu className="menuList" onClick={handleMenuClick}>
            <Menu.Item key="userEdit">
                <Link to="/root/user/user-edit">
                    修改密碼
                </Link>
            </Menu.Item>
            <Menu.Item key="logout">
                登出
            </Menu.Item>
        </Menu>
    );

    return (
        <Row className="header" justify="end">
            <Col>
                <Dropdown overlay={menu}>
                    <Button className="headerBtn">
                        {"大方藝彩"}<DownOutlined />
                    </Button>
                </Dropdown>
            </Col>
        </Row>
    );
}

export default RootHeader;
